
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function LitteringDumpingIcon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path d="M459.2 435 448 324.3c-4-39.2-33.2-71.4-72.4-79.9l36.3 41.5-12.6 10.6-35.3-40.4v51.4h-16.6v-51.4L312 296.6 299.4 286l36.3-41.5c-18.1 3.9-34.6 13.1-47.4 26.3l-15 51.2c32.1 12 55.2 40 60.6 73.3l5.7 35.4c2.3 14.2-1.9 28.6-11.4 39.5-.2.3-.5.6-.8.8H426c18.4 0 33.3-14.6 33.3-32.6.1-1.2 0-2.3-.1-3.4z"/><path d="m138.7 430.6 5.7-35.4c5.3-33.3 28.5-61.3 60.6-73.3L189.6 269c-1.3-4.3 1.3-8.9 5.7-10.1.7-.2 1.5-.3 2.3-.3h79.5c12.3-12.4 27.4-21.7 44.2-27.2L306 179.2c-1.3-4.3 1.3-8.9 5.7-10.1.7-.2 1.5-.3 2.3-.3h13.8c-22.7-30.8-57.3-51.1-95.7-56L279 172l-13.1 10-43.3-54.7v74.2H206v-74.2L162.5 182l-13.1-10 46.9-59.2c-64.2 8-114.4 58.3-121.3 121.5L52.8 434.7c-2 17.9 11.2 34 29.5 36 1.2.1 2.4.2 3.6.2h65l-.8-.8c-9.5-10.9-13.7-25.4-11.4-39.5zm75.5-335.3c10.2 0 20.5 1 30.5 3L264.8 30H162l18.9 68.8c10.9-2.3 22.1-3.5 33.3-3.5z"/><path d="M337.4 227.5c12.1-2 24.4-2 36.5 0l12.3-42.3h-61.1l12.3 42.3zm-19.9 170.3c-5.1-31.4-29.1-56.7-60.7-63.8l30.8 42.3-13.5 9.5-26.5-36.4v39.8H231v-39.8l-26.5 36.4-13.5-9.5 30.8-42.3c-31.6 7.1-55.7 32.3-60.7 63.7l-5.7 35.4c-2.9 17.8 9.5 34.5 27.7 37.4 1.7.3 3.5.4 5.2.4h102.3c18.4 0 33.3-14.6 33.3-32.7 0-1.7-.1-3.4-.4-5.1l-6-35.3zm-78.3-82c6.1 0 12.2.6 18.2 1.7l12.4-42.6h-61.1l12.4 42.6c5.9-1.2 12-1.7 18.1-1.7z"/></SvgIcon>
      );
    }
  
    export default LitteringDumpingIcon;