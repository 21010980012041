import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePersonPhotoEdit } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import Dictionary from 'components/Dictionary';
import Checkbox2 from 'components/Checkbox2';
import { gridStyle, Row, RowInner, ColCard, Fills, formatSaveData } from 'utils/formStyles';
import TextField from '@material-ui/core/TextField';
import { editPersonImage } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  item: gridStyle(200, 800),
}));

function EditPersonPhotoDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const [ImageTypeCode, setImageTypeCode] = useState(null);
  const [MarkType, setMarkType] = useState(null);
  const [Location, setLocation] = useState(null);
  const [Gang, setGang] = useState(null);
  const [IsMark, setIsMark] = useState(false);
  const [Description, setDescription] = useState('');

  useEffect(() => {
    renderExistingValues();
  }, []);

  const renderExistingValues = () => {
    const { ImageTypeCode, MarkType, Location, Gang, IsMark, Description } = data;
    setIsMark(IsMark);
    setDescription(Description);
    setImageTypeCode(ImageTypeCode);
    setMarkType(MarkType);
    setLocation(Location);
    setGang(Gang);
  };

  const close = () => {
    props.closePersonPhotoEdit();
  };

  const save = async () => {
    const saveObj = formatSaveData({
      ImageTypeCode,
      IsMark,
      Location,
      Gang,
      Description,
      MarkType,
    });
    try {
      await editPersonImage(data.ptsImageID, saveObj);
      props.notifyDataUpdate({ type: 'person-image-upload', data: {} });
      //props.notify('Info updated', 'success');
    } catch (err) {
      props.handleError(err);
    }
    close();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Edit" actions={renderActions()}>
      <div>
        <Dictionary
          options="MugshotTypes"
          className={classes.item}
          onChange={(ev, val) => setImageTypeCode(val)}
          value={ImageTypeCode}
          label="Image Type"
          compact="true"
          editingForm={true}
        />
        <Checkbox2
          checked={IsMark}
          onChange={() => setIsMark(!IsMark)}
          label="Scar, Mark, or Tattoo"
        />
        {IsMark && (
          <Row>
            <ColCard minWidth={500}>
              <RowInner>
                <Dictionary
                  options="Marks"
                  className={classes.item}
                  onChange={(ev, val) => setMarkType(val)}
                  value={MarkType}
                  label="Mark Type"
                  compact="true"
                  editingForm={true}
                />
                <Dictionary
                  options="TattooLocation"
                  className={classes.item}
                  onChange={(ev, val) => setLocation(val)}
                  value={Location}
                  label="Location"
                  compact="true"
                  editingForm={true}
                />
              </RowInner>
              <RowInner>
                <Dictionary
                  options="Gang"
                  className={classes.item}
                  onChange={(ev, val) => setGang(val)}
                  value={Gang}
                  label="Gang"
                  compact="true"
                  editingForm={true}
                />
              </RowInner>
              <RowInner>
                <TextField
                  label="Description"
                  variant="outlined"
                  size="small"
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={classes.item}
                />
              </RowInner>
            </ColCard>
          </Row>
        )}
      </div>
    </Dialog>
  );
}

// export default connect(null, { closePersonPhotoEdit })(EditPersonPhotoDialog);
export default connect(null, {
  closePersonPhotoEdit,
  notify,
  handleError,
  notifyDataUpdate,
})(EditPersonPhotoDialog);
