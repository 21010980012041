import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { OverlayView } from '@react-google-maps/api';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EventTooltip from 'components/EventTooltip';
import { editEvent } from 'reducers/DialogsReducer';
import { areObjEqual } from 'utils/functions';
import { assignUnitToEvent } from 'reducers/UnitStatusReducer';
import { getCoords } from 'utils/mapFunctions';
import EventAvatar from 'components/EventAvatar';

const positionOverlayView = (width, height) => ({ x: -width / 2, y: -height });

const avatarSize = 70;
const iconSize = avatarSize / 2.1;
const iconLeft = (avatarSize - iconSize) / 2;
const iconTop = (avatarSize - iconSize) / 4;
const borderSize = 2;
const avatarWidth = 33;

const useStyles = makeStyles((theme) => ({
  label: {
    position: 'absolute',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    fontSize: 12,
    top: -6,
    left: '50%',
    color: theme.map.labelColor,
    transform: 'translate(-50%, 0)',
    textShadow: `0 1px 0 ${theme.map.labelBorder},
                 0 -1px 0 ${theme.map.labelBorder},
                 1px 0 0 ${theme.map.labelBorder},
                 -1px 0 0 ${theme.map.labelBorder}`,
  },
  marker: {
    position: 'relative',
  },
  iconBorderDrag: {
    position: 'absolute',
    color: theme.palette.secondary.main,
    width: avatarSize + borderSize * 2 + 4,
    height: avatarSize + borderSize * 2 + 4,
    top: -borderSize - 2,
    left: -borderSize - 2,
  },
  iconBorder: {
    position: 'absolute',
    color: theme.map.labelBorder,
    width: avatarSize + borderSize * 2,
    height: avatarSize + borderSize * 2,
    top: -borderSize,
    left: -borderSize,
  },
  icon: {
    position: 'absolute',
    width: iconSize,
    height: iconSize,
    top: iconTop,
    left: iconLeft,
    textAlign: 'center',
    fontSize: iconSize / 1.8,
    fontWeight: 600,
    '& > span': {
      position: 'absolute',
      inset: 0,
      display: 'block',
      borderRadius: '50%',
    },
    '& > div': {
      color: '#000',
      position: 'absolute',
      inset: 0,
      borderRadius: '50%',
      background: 'rgba(255, 255, 255, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  evAvatar: {
    background: theme.card.bg2,
    borderRadius: '50%',
    fontSize: '0.7em',
    overflow: 'hidden',
    flex: `0 0 ${avatarWidth}px`,
    width: avatarWidth,
    height: avatarWidth,
    '& > div': {
      width: avatarWidth,
      height: avatarWidth,
    },
  },
}));

function Marker(props) {
  const classes = useStyles();
  const { event, colors } = props;
  const { ptsEventID, EventID, Status } = event;
  const [dragging, setDragging] = useState(false);
  const [dragged, setDragged] = useState(null);
  const draggingTimeRef = useRef();
  const clickTime = useRef(0);
  const position = getCoords(event);

  const onClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const timestamp = new Date().getTime();
    if (timestamp - clickTime.current < 300) {
      props.editEvent(ptsEventID);
    }
    clickTime.current = timestamp;
  };

  const handleDragOver = (ev) => {
    ev.preventDefault();
    clearTimeout(draggingTimeRef.current);
    if (!dragging) {
      const draggedData = JSON.parse(localStorage.getItem('dragNdrop'));
      if (!draggedData) return;
      setDragging(true);
      if (areObjEqual(dragged, draggedData)) return;
      setDragged(draggedData);
    }
  };

  const handleDragLeave = (ev) => {
    clearTimeout(draggingTimeRef.current);
    draggingTimeRef.current = setTimeout(() => {
      setDragging(false);
    }, 50);
  };

  const handleDrop = (ev, event) => {
    if (!dragged) return;
    const { assignedUnits, ptsEventID } = event;
    clearTimeout(draggingTimeRef.current);
    setDragging(false);
    dragged.units.forEach((ptsUnitID) => {
      const unitExists = !!assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
      if (!unitExists) {
        props.assignUnitToEvent(ptsUnitID, ptsEventID);
      }
    });
    localStorage.removeItem('dragNdrop');
  };

  return (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      key={ptsEventID}
      position={position}
      getPixelPositionOffset={positionOverlayView}>
      <EventTooltip event={event}>
        <div
          onClick={(ev) => onClick(ev, ptsEventID)}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={(ev) => handleDrop(ev, event)}>
          <div className={classes.label}>{EventID}</div>
          <div className={classes.marker}>
            <LocationOnIcon className={dragging ? classes.iconBorderDrag : classes.iconBorder} />
            <LocationOnIcon
              style={{ color: colors[Status], fontSize: avatarSize, position: 'relative' }}
            />
            <div className={classes.icon}>
              <span style={{ backgroundColor: colors[Status] }} />
              <EventAvatar event={event} className={classes.evAvatar} color={'#28282B'} />
            </div>
          </div>
        </div>
      </EventTooltip>
    </OverlayView>
  );
}

function MarkersEvents(props) {
  const { events, colors, editEvent, assignUnitToEvent } = props;

  return (
    <>
      {events.map((event) => (
        <Marker
          key={event.ptsEventID}
          colors={colors}
          event={event}
          editEvent={editEvent}
          assignUnitToEvent={assignUnitToEvent}
        />
      ))}
    </>
  );
}

const mapStateToProps = (state) => ({
  events: state.events,
  colors: state.config.colorPalette.Events,
});

export default connect(mapStateToProps, {
  editEvent,
  assignUnitToEvent,
})(MarkersEvents);
