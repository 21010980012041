
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8explosion100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="m49.402 41.21-9.148-4.019 8.625-18.027a1.003 1.003 0 0 0-1.414-1.293l-16.766 9.895-5.55-18.063A.97.97 0 0 0 24.167 9c-.45.012-.836.324-.945.758l-4.11 16.566-12.855-8.387a1.004 1.004 0 0 0-1.172.06.997.997 0 0 0-.309 1.136l6.946 18.293L.699 40.902a1.002 1.002 0 0 0-.09 1.875l12.782 5.762 3.43 1.379a1.002 1.002 0 0 0 1.152-.277l.386-.586 2.246-3.317-5.59-2.793 6.493-.746-3.832-8.515 6.066 5.43L24.77 28.68l1.953 10.75 7.664-5.746-5.11 9.261 7.563.211-7.028 3.301.422.484c.008.004.02.012.024.016l1.785 2.074.02.024.539.625a1.01 1.01 0 0 0 1.003.28l4.618-1.62a1.07 1.07 0 0 0 .156-.059l11.05-5.25a1 1 0 0 0-.027-1.82Z"/></SvgIcon>
      );
    }
  
    export default icons8explosion100Icon;