import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import formStyles, { gridStyle } from 'utils/formStyles';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { getService } from 'reducers/service';
import XGrid3 from 'components/XGrid3';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import { showUnitRotation } from 'reducers/DialogsReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
  note: gridStyle(200, 2000),
  action: {
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const rotationColumns = ['Rotation', 'IsDeleted', 'Created', 'CreatedBy', 'Updated', 'UpdatedBy'];

function UnitRotation(props) {
  const classes = useStyles();
  const service = getService('cadrotationunits');
  const { ptsUnitID, dataUpdate } = props;
  const [columns, setColumns] = useState(rotationColumns);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    getUnitRotation();
  }, [ptsUnitID]);

  useEffect(() => {
    if (dataUpdate?.type === 'edit-unit-rotation') getUnitRotation();
  }, [dataUpdate]);

  const processData = (data) => data.map((item, idx) => ({ id: idx, ...item }));
  const getUnitRotation = async () => {
    const res = await service.find({ query: { ptsUnitID, IsDeleted: 0 } });
    if (res.data.length) {
      const processedData = processData(res.data);
      setData(processedData);
    }
    setLoaded(true);
  };

  const editRotation = () => {
    const item = data.find((a) => a.id === selection);
    if (!item) return;
    props.showUnitRotation(item);
  };

  const renderActions = () => {
    return (
      <div className={classes.action}>
        <Fab size="small" color="secondary" onClick={editRotation} disabled={selection == null}>
          <EditIcon />
        </Fab>
      </div>
    );
  };
  return (
    <div className="m-4">
      {renderActions()}
      <XGrid3
        name="UnitRotation"
        columns={columns}
        setColumns={setColumns}
        rows={data}
        setSelection={setSelection}
        loading={!loaded}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    zones: state.zones,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  notifyDataUpdate,
  notify,
  handleError,
  showUnitRotation,
})(UnitRotation);
