
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8enterhouse100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M.979 1.99A1 1 0 0 0 0 3.146V47a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1V18.682l1.375 1.1a1 1 0 1 0 1.25-1.563l-20-16a1 1 0 0 0-.646-.229zM11 4a1 1 0 0 0-1 1v1.36l6 4.798V5a1 1 0 0 0-1-1h-4zm22 7a4 4 0 0 0 0 8 4 4 0 0 0 0-8zm3.238 9.006c-.36.012-.726.08-1.084.213a3.5 3.5 0 0 0-2.06 4.5l2.992 8.049-3.777 5.267a2.257 2.257 0 0 0-.36.744l-1.867 6.35c-.253.862.014 1.822.729 2.365.452.344.953.5 1.435.5a2.25 2.25 0 0 0 2.158-1.615l1.733-5.887 1.097-1.226c.024-.035.034-.076.063-.108l4.848-5.41c.77-.861 1-2.067.597-3.15l-.584-1.57c-.003-.008 0-.013-.002-.02L40.98 25.84l1.63.474 3.295 4.723a2.248 2.248 0 0 0 3.133.559 2.25 2.25 0 0 0 .559-3.133l-3.75-5.375a2.252 2.252 0 0 0-1.217-.873l-7.332-2.082a3.54 3.54 0 0 0-1.059-.127zm-5.23 4.662-.848 1.088-4.26 3.193a2.251 2.251 0 0 0 2.7 3.602l4.123-3.094-1.502-4.039a5.428 5.428 0 0 1-.213-.75zM5 27h6a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V28a1 1 0 0 1 1-1zm38.73 7.96c-.034.04-.06.083-.095.122l-4.088 4.564.51 2.108c.099.432.319.81.619 1.103l4.482 4.485c.44.439 1.016.658 1.592.658a2.251 2.251 0 0 0 1.592-3.842l-3.955-3.953-.657-5.244z"/></SvgIcon>
      );
    }
  
    export default icons8enterhouse100Icon;