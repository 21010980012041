
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8lostandfound100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M7.688 0c-7 0-7.18 6.3-7.282 7.5h2c0-.898.094-5.813 5.094-5.813 4 0 4.5 3.711 4.5 4.313 0 3.898-5.5 3.602-5.5 11h2C8.102 10.602 14 12 14 6c0-1-.613-6-6.313-6Zm41.124 3.906c-.253 0-.523.086-.718.281l-2.438 2.438 1.407 1.406L49.5 5.594a.99.99 0 0 0 0-1.407.944.944 0 0 0-.688-.28ZM43.25 7.625a1.026 1.026 0 0 0-.313.094L4.25 27.812a1.04 1.04 0 0 0-.563.875.983.983 0 0 0 .5.875c.079.047 8.02 4.739 9.125 10.72.07.378.344.679.72.78 7.292 1.938 10 8.239 10.03 8.313.149.36.52.605.907.625H25c.371 0 .703-.2.875-.531L46 10.75a.987.987 0 0 0-.188-1.156l-1.718-1.688a.959.959 0 0 0-.844-.281ZM6 19v3h3v-3Zm-.5 20.094c-.254 0-.523.117-.719.312L3.188 41C2.417 41.77 2 42.8 2 43.906s.418 2.137 1.188 2.907A4.07 4.07 0 0 0 6.094 48 4.071 4.071 0 0 0 9 46.812l4-4a3.06 3.06 0 0 1-1.406-1.437l-4 4.031c-.782.782-2.219.782-3 0-.39-.39-.594-.93-.594-1.5s.203-1.11.594-1.5l1.625-1.593a.99.99 0 0 0 0-1.407 1.049 1.049 0 0 0-.719-.312Z"/></SvgIcon>
      );
    }
  
    export default icons8lostandfound100Icon;