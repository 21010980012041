
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8prisonbuilding100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M25 1a.996.996 0 0 0-.615.213l-23 18a.999.999 0 1 0 1.23 1.574L3.621 20H4v26a1 1 0 0 0 1 1h40a1 1 0 0 0 1-1V20h.379l1.006.787a.999.999 0 1 0 1.23-1.574l-23-18A.996.996 0 0 0 25 1zm-7 14a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-8 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm14.525.367 2.952 2.951c2.112-.449 4.328.087 5.886 1.645 2.534 2.536 2.377 6.819-.351 9.547-1.311 1.31-3.019 2.075-4.805 2.152a6.07 6.07 0 0 1-.3.008c-1.705 0-3.271-.636-4.442-1.807-1.24-1.239-1.88-2.925-1.8-4.744a6.99 6.99 0 0 1 .151-1.146l-2.949-2.95 5.658-5.656zM26 18a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-10 2a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm13.111.611a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5.805 1.55c-1.296 0-2.642.53-3.684 1.571-.956.956-1.515 2.19-1.57 3.475-.055 1.253.378 2.404 1.217 3.242.838.838 1.983 1.27 3.242 1.215 1.285-.056 2.52-.612 3.477-1.568 1.949-1.95 2.106-4.962.353-6.717-.817-.817-1.907-1.219-3.035-1.219zM17 23a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 3h8v4.174c1.811 1.176 3 3.123 3 5.326 0 3.584-3.141 6.5-7 6.5s-7-2.916-7-6.5c0-2.203 1.189-4.15 3-5.326V26zm4 1a1 1 0 0 0 0 2 1 1 0 0 0 0-2zm0 4a5 4.5 0 0 0 0 9 5 4.5 0 0 0 0-9z"/></SvgIcon>
      );
    }
  
    export default icons8prisonbuilding100Icon;