import store from '../config/configureStore';
import { saveUserSetting } from './UserSettingsReducer';

const SET_UNIT_GROUP_BY = 'SET_EVENT_GROUP_BY';
const SET_UNIT_SORT_BY = 'SET_UNIT_SORT_BY';
const SET_UNIT_ASC_DESC = 'SET_UNIT_ASC_DESC';
const SET_UNIT_FILTER = 'SET_UNIT_FILTER';
const SET_UNIT_SHOW_PINNED = 'SET_UNIT_SHOW_PINNED';
const SET_UNIT_AGENCY_FILTER = 'SET_UNIT_AGENCY_FILTER';
const SET_UNIT_GROUP_FOLDED = 'SET_UNIT_GROUP_FOLDED';
export const SET_UNIT_SORT_OPTS = 'SET_UNIT_SORT_OPTS';

const defaultSort = {
  folded: [], // shows which units are unfolded and open tab [{ ptsUnitID: 47889, tab: 'units' }]
  filter: '',
};

export const saveUnitSort = (sortObj) => (dispatch) => {
  const loaded = store.store.getState().userSettings.loaded;
  if (!loaded) return;
  const unitSort = store.store.getState().unitSort;
  const savedObj = { ...unitSort, ...sortObj, filter: '', showPinned: false };
  dispatch(saveUserSetting('unitSort', savedObj));
};

export const toggleExpandUnitGroup = (expand, groupKey) => (dispatch) => {
  const folded = store.store.getState().unitSort.folded;
  let newFolded;
  if (expand) {
    newFolded = folded.filter((key) => key !== groupKey);
  } else {
    newFolded = [...folded];
    newFolded.push(groupKey);
  }
  dispatch({ type: SET_UNIT_GROUP_FOLDED, payload: newFolded });
};

export const toggleUnitAgencyFilter = (AgencyID) => {
  const agencyFilter = store.store.getState().unitSort.agencyFilter;
  let newAgencyFilter;
  const present = agencyFilter.indexOf(AgencyID) !== -1;
  if (present) {
    newAgencyFilter = agencyFilter.filter((id) => id !== AgencyID);
  } else {
    newAgencyFilter = [...agencyFilter];
    newAgencyFilter.push(AgencyID);
  }
  return (dispatch) => {
    dispatch({ type: SET_UNIT_AGENCY_FILTER, payload: newAgencyFilter });
  };
};

export const setUnitGroupBy = (groupBy) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_GROUP_BY, payload: groupBy });
  };
};

export const setUnitSortBy = (sortBy) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_SORT_BY, payload: sortBy });
  };
};

export const setUnitAscDesc = (ascDesc) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_ASC_DESC, payload: ascDesc });
  };
};

export const setUnitFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_FILTER, payload: filter });
  };
};

export const setUnitShowPinned = (showPinned) => {
  return (dispatch) => {
    dispatch({ type: SET_UNIT_SHOW_PINNED, payload: showPinned });
  };
};

export default function reducer(state = defaultSort, action) {
  switch (action.type) {
    case SET_UNIT_GROUP_BY:
      return { ...state, groupBy: action.payload };
    case SET_UNIT_SORT_BY:
      return { ...state, sortBy: action.payload };
    case SET_UNIT_ASC_DESC:
      return { ...state, ascDesc: action.payload };
    case SET_UNIT_FILTER:
      return { ...state, filter: action.payload };
    case SET_UNIT_SHOW_PINNED:
      return { ...state, showPinned: action.payload };
    case SET_UNIT_AGENCY_FILTER:
      return { ...state, agencyFilter: action.payload };
    case SET_UNIT_GROUP_FOLDED:
      return { ...state, folded: action.payload };
    case SET_UNIT_SORT_OPTS:
      return { ...action.payload };
    default:
      return state;
  }
}
