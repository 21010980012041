
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8mentalhealth100BLACKANDWHITEIcon(props) {
      return (
        <SvgIcon {...props} width="48" height="48"><defs><clipPath id="b"><use /></clipPath><path d="M0 0h48v48H0V0Z" id="a"/></defs><path d="M0 0h48v48H0V0Z" fill="none"/><g clipPath="url(#b)" fillRule="evenodd"><path d="M37 29h3.895l-4.082-12.961C35.438 9.173 29.376 4 22.105 4c-8.284 0-15 6.716-15 15A14.96 14.96 0 0 0 12 30.085V44h16v-7h9v-8Z"/><path d="M12 19c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10Z" fill="#FDFEFE"/><path d="M24 25h-4V13h4v12Z"/><path d="M16 21v-4h12v4H16Z"/><path d="M20 17h4v4h-4v-4Z"/></g></SvgIcon>
      );
    }
  
    export default icons8mentalhealth100BLACKANDWHITEIcon;