import React from 'react';
import { makeStyles, Popover } from '@material-ui/core';
const useStyle = makeStyles((theme) => ({
  tooltip: {
    '& b': {
      fontWeight: 600,
    },
    '& table': {
      color: theme.content.color,
      background: theme.card.bg,
      width: '100%',
      marginBottom: 7,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    '& h4': {
      textAlign: 'center',
      fontSize: 14,
      color: theme.tooltip.header,
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 4,
    },
    '& th': {
      border: `1px solid ${theme.tooltip.tableBorder}`,
      padding: '2px 5px',
      textTransfrom: 'uppercase',
      whiteSpace: 'nowrap',
      background: theme.tooltip.tableHeaderBg,
    },
    '& td': {
      border: `1px solid ${theme.tooltip.tableBorder}`,
      padding: '2px 5px',
    },
  },
}));

const PopOver = (props) => {
  const { anchorEl, setAnchorEl } = props;
  const classes = useStyle();

  const handleClose = () => {
    setAnchorEl(false);
  };

  return (
    <Popover
      //key={Math.floor(Math.random() * 1000) + 1}
      className={classes.tooltip}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      {props.children}
    </Popover>
  );
};

export default PopOver;
