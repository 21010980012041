import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setUnitStatus, unitInitEvent } from 'reducers/UnitStatusReducer';
import { newUnitStatus } from 'reducers/DialogsReducer';

function UnitHotKeys(props) {
  const { ptsUnitID, ptsEventID, keyboard, options, setUnitStatus, newUnitStatus, unitInitEvent } = props;
  const { UnitActionCodes } = options;

  useEffect(() => {
    const kbdSubscription = keyboard.subject.subscribe(handleKbd);
    return () => {
      kbdSubscription.unsubscribe();
    }
    // eslint-disable-next-line
  }, []);

  const handleKbd = (keyCode) => {
    if (!ptsUnitID) return;
    switch (keyCode) {
      case 'F2':
        setUnitStatus(UnitActionCodes.Dispatch.status, ptsUnitID, ptsEventID);
        break;
      case 'F3':
        setUnitStatus(UnitActionCodes.Enroute.status, ptsUnitID, ptsEventID);
        break;
      case 'F4':
        setUnitStatus(UnitActionCodes.Arrived.status, ptsUnitID, ptsEventID);
        break;
      case 'F7':
        setUnitStatus(UnitActionCodes.Completed.status, ptsUnitID, ptsEventID);
        break;
      case 'F11':
        setUnitStatus(UnitActionCodes.Inservice.status, ptsUnitID, ptsEventID);
        break;
      case 'F12':
        setUnitStatus(UnitActionCodes.Outservice.status, ptsUnitID, ptsEventID);
        break;
      case 'F9':
        newUnitStatus(ptsUnitID, ptsEventID);
        break;
      case 'Ctrl+Alt+KeyN':
        unitInitEvent(ptsUnitID);
        break;
    }
  }

  return ''
}

const mapStateToProps = (state) => ({
  keyboard: state.keyboard,
  options: state.config.options,
})

export default connect(mapStateToProps, {
  setUnitStatus,
  newUnitStatus,
  unitInitEvent
})(UnitHotKeys);