import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ToolTip from '../../components/Tooltip';
import AgencyIcon from '../../components/AgencyIcon';

const useStyles = makeStyles((theme) => ({
  agencies: {
    display: 'flex',
    '& > span': {
      marginRight: 5,
      '&:last-child': {
        marginRight: 0,
      },
      '& > svg': {
        width: 20,
        height: 20,
        fill: theme.colors.grey3,
      },
      '& > span': {
        display: 'block',
        width: 20,
        height: '1em',
        textAlign: 'center',
      },
    },
  },
}));

export default function EventAgencies(props) {
  const classes = useStyles();
  const options = useSelector((state) => state.config.options);
  const { colorPalette, units, history } = props;
  const [agencies, setAgencies] = useState([]);
  const UnitColors = colorPalette.Units;

  useEffect(() => {
    if (!props.agencies) return;
    const agencies = [];
    props.agencies.forEach((agency) => {
      agencies.push(getAgencyInfo(agency.AgencyId));
    });
    setAgencies(agencies);
    // eslint-disable-next-line
  }, [props.agencies]);

  const getAgencyInfo = (AgencyID) => {
    const agency = { AgencyID };
    // No of units assigned to agency
    agency.count = units.reduce(
      (result, value) => (value.AgencyID === AgencyID ? result + 1 : result),
      0
    );
    // Calculate status
    const agencyUnits = [];
    history &&
      history.forEach((a) => {
        if (a.AgencyID === AgencyID) agencyUnits.push(a.UnitStatus);
      });
    const topStatusObj = agencyUnits.reduce(
      (topStatus, status) => {
        let result;
        Object.values(options.UnitActionCodes).forEach((obj) => {
          if (obj.status === status && obj.weight > topStatus.weight) result = obj;
        });
        return result ? result : topStatus;
      },
      { status: null, weight: -1 }
    );
    agency.status = topStatusObj.status;
    agency.color = UnitColors[agency.status];
    return agency;
  };

  const renderAgency = (agency) => {
    const { AgencyID, count, color } = agency;
    return (
      <ToolTip title={AgencyID} key={AgencyID}>
        <span>
          <AgencyIcon color={color} AgencyID={AgencyID} />
          <span>{count}</span>
        </span>
      </ToolTip>
    );
  };

  return (
    <div className={classes.agencies} onClick={props.onClick}>
      {agencies.map((agency) => renderAgency(agency))}
    </div>
  );
}
