import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const MultiSelectAutoComplete = (props) => {
  const { type, value, setValue, disabled, options, label, size = 'small' } = props;

  const getExtraOptions = () => {
    let extraOpts = {};
    switch (type) {
      case 'Zones':
        extraOpts = {
          getOptionSelected: (option, value) => option.ZoneCode === value.ZoneCode,
          getOptionLabel: (option) =>
            option.Description ? `${option.ZoneCode} - ${option.Description}` : option.ZoneCode,
          //   getOptionLabel: (option) => (option.ZoneCode ? `${option.ZoneCode}` : ''),
          renderOption: (option) => (
            <span>
              {option.Description ? `${option.ZoneCode} - ${option.Description}` : option.ZoneCode}
            </span>
          ),
        };
        break;
      default:
        extraOpts = {
          getOptionSelected: (option, value) => option.Code === value.Code,
          getOptionLabel: (option) =>
            option.Description ? `${option.Code} - ${option.Description}` : option.Code,
          //getOptionLabel: (option) => (option.Code ? `${option.Code}` : ''),
          renderOption: (option) => (
            <span>
              <strong>{option.Code}</strong>
              {!!option.Description && <> - {option.Description}</>}
            </span>
          ),
        };
        break;
    }

    return extraOpts;
  };
  if (!options) return <div style={{ color: 'red' }}>Invalid data</div>;
  return (
    <Autocomplete
      {...getExtraOptions()}
      multiple
      options={options}
      disableCloseOnSelect
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      onChange={(ev, val) => setValue(val)}
      size={size}
      value={value}
      disabled={disabled}
    />
  );
};

export default MultiSelectAutoComplete;
