import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 1.25rem',
    marginBottom: 0,
    borderBottom: '1px solid rgba(122, 123, 151, 0.3)',
    position: 'relative',
  },
  content: {
    overflow: 'auto',
    position: 'relative',
    minHeight: 200,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  loading: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
  },
}));

function SearchPanel(props) {
  const classes = useStyles();
  const { loading, error, action } = props;

  return (
    <Card className="card-box mb-4" variant="outlined">
      <div className={classes.header}>
        <div className="card-header--title py-2 font-size-lg">{props.title}</div>
        {loading && <LinearProgress className={classes.loading} />}
        {Boolean(action) && <>{action}</>}
      </div>
      <div className={clsx(classes.content, props.className)}>
        <CardContent className={classes.cardContent}>
          {Boolean(error) && <MuiAlert severity="error">{error}</MuiAlert>}
          {props.children}
        </CardContent>
      </div>
    </Card>
  );
}

export default SearchPanel;
