import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { displayDateTime } from 'reducers/TimeReducer';
import { connect } from 'react-redux';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import AddIcon from '@material-ui/icons/Add';
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { useDispatch } from 'react-redux';
import { getPeopleEmployments } from 'reducers/SearchReducer';
import { showPeopleEmployment } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));

const defaultColumns = [
  'PlaceName',
  'PlaceType',
  'DepartmentName',
  'Position',
  'Shift',
  'Occupation',
  'Pay',
  'Rate',
  'Rank',
  'Status',
  {
    field: 'Hired',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Terminated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'TerminationReason',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];
const Employment = (props) => {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;

  useEffect(() => {
    ptsPersonID && ptsPersonID !== true && getData();
    // eslint-disable-next-line
  }, [ptsPersonID]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'employment') return;
    getData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const getData = async () => {
    try {
      const data = await getPeopleEmployments(ptsPersonID);
      if (!mountedRef.current) return;
      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      const Place = row.Place && row.Place.length ? row.Place[0] : null;
      const PlaceName = Place ? Place.PlaceName : null;
      const PlaceType = Place ? Place.PlaceType : null;
      return {
        ...row,
        PlaceName,
        PlaceType,
        id: idx,
      };
    });
  };

  const addContact = () => {
    dispatch(showPeopleEmployment(ptsPersonID));
  };

  // const edit = () => {
  // const item = rows.find((row) => row.id === selection);
  // dispatch(showPeopleCredentials({ ...item }));
  // };

  // const deleteEmployment = async () => { };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Credential">
            <span>
              <Fab size="small" color="secondary" onClick={addContact} disabled={!canEditPerson}>
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>

          {selection !== null && (
            <>
              {/* <Tooltip title="Edit Credential">
                <Fab size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip> */}
              {/* <Tooltip title="View details">
                <Fab size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip> */}

              {/* <Tooltip title="Download Credential">
                <Fab size="small" color="secondary" onClick={deleteEmployment}>
                  <DeleteIcon />
                </Fab>
              </Tooltip> */}
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="PeopleEmployment"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate
});
export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(Employment);
