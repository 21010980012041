import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UpdateIcon from '@material-ui/icons/Update';
import SnoozeIcon from '@material-ui/icons/Snooze';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import Dialog from 'components/Dialog';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { closeOutserviceUnitDialog, newUnitStatus } from 'reducers/DialogsReducer';
import {
  getOutserviceUnits,
  getStandByUnits,
  INSERVICE,
  STANDBY,
  OUTSERVICE,
  updateMultiUnitStatus,
  getUnitStatusByCategory,
} from 'reducers/UnitStatusReducer';
import { getUnitsAsync } from 'reducers/UnitsReducer';
import { filterResults } from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DispatchMenu from 'pages/Units/DispatchMenu';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  filter: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const columnsOutService = [
  { field: 'Unit', headerName: 'Unit', width: 150, type: 'string' },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'Resources', headerName: 'Resources', width: 150 },
  { field: 'Division', headerName: 'Division', width: 150 },
];

const columnsInService = [
  { field: 'Unit', headerName: 'Unit', width: 150, type: 'string' },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'Code', headerName: 'Status', width: 150 },
  { field: 'UnitResources', headerName: 'Resources', width: 150 },
  { field: 'Division', headerName: 'Division', width: 150 },
];

function AddOutserviceUnitDialog(props) {
  const classes = useStyles();
  const [units, setUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filter, setFilter] = useState('');
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [tabVal, setTabVal] = useState(OUTSERVICE);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [dispatchMenuOpen, setDispatchMenuOpen] = useState(false);
  const dispatchRef = useRef();

  useEffect(() => {
    setLoaded(false);
    getData();
    // eslint-disable-next-line
  }, [tabVal]);

  useEffect(() => {
    if (props.dataUpdate?.type === 'unit-status') getData();
  }, [props.dataUpdate]);

  useEffect(() => {
    if (!filter) {
      setFilteredUnits(units);
    } else {
      const columns = [OUTSERVICE, STANDBY].includes(tabVal) ? columnsOutService : columnsInService;
      setFilteredUnits(filterResults(filter, units, columns));
    }
    // eslint-disable-next-line
  }, [filter, units]);

  const close = (openWindows) => {
    props.closeOutserviceUnitDialog(openWindows);
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const getData = () => {
    const statusStandby = getUnitStatusByCategory(STANDBY);
    let fn;
    switch (tabVal) {
      case OUTSERVICE:
        fn = getOutserviceUnits;
        break;
      case STANDBY:
        fn = getStandByUnits;
        break;
      default:
        fn = getUnitsAsync;
        break;
    }
    fn()
      .then((result) => {
        if (tabVal === INSERVICE) {
          setUnits(
            result
              .map((unit) => {
                return { ...unit, id: unit.ptsUnitID };
              })
              .filter((unit) => unit.UnitStatus !== statusStandby)
          );
        } else {
          setUnits(
            result.map((unit) => {
              return { ...unit, id: unit.ptsUnitID };
            })
          );
        }
      })
      .catch((err) => props.handleError(err, 'Error, Units not loaded.'))
      .finally(() => setLoaded(true));
  };

  const quickFilter = (ev) => {
    const val = ev.target.value;
    setFilter(val);
  };

  const handleClickDispatch = () => {
    const unitIds = selection.map((id) => parseInt(id));
    const selectedUnits = units.filter((unit) => unitIds.includes(unit.ptsUnitID));
    setSelectedUnits(selectedUnits);
    setDispatchMenuOpen(true);
  };

  const newStatus = () => {
    const ptsUnitID = parseInt(selection[0]);
    props.newUnitStatus(ptsUnitID);
  };

  const applyStatus = async (category) => {
    const ActionCode = getUnitStatusByCategory(category);
    props.showSpinner();
    try {
      await updateMultiUnitStatus(selection, ActionCode);
      props.notifyDataUpdate({ type: 'unit-status' });
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={newStatus}
            disabled={selection.length !== 1}>
            <UpdateIcon /> New Status
          </Button>
        </div>
        <div>
          {tabVal === INSERVICE && (
            <Button
              color="primary"
              variant="contained"
              autoFocus
              onClick={() => applyStatus(OUTSERVICE)}
              disabled={!selection.length}>
              <SnoozeIcon /> Out Service
            </Button>
          )}
          {(tabVal === OUTSERVICE || tabVal === STANDBY) && (
            <Button
              color="primary"
              variant="contained"
              autoFocus
              onClick={() => applyStatus(INSERVICE)}
              disabled={!selection.length}>
              <AddAlarmIcon /> In Service
            </Button>
          )}
          {tabVal === STANDBY && (
            <Button onClick={handleClickDispatch} disabled={!selection.length}>
              <HeadsetMicIcon className={classes.menuIcon} /> Dispatch
              <span style={{ marginLeft: 'auto' }}>
                <ArrowRightIcon ref={dispatchRef} />
              </span>
            </Button>
          )}
          <Button color="primary" onClick={close}>
            <CloseIcon /> Close
          </Button>
        </div>
        <DispatchMenu
          anchorEl={dispatchRef.current}
          open={dispatchMenuOpen}
          onClose={() => setDispatchMenuOpen(false)}
          units={selectedUnits}
        />
      </div>
    );
  };

  const handleTabChange = (ev, newValue) => {
    setTabVal(newValue);
  };

  const renderTabs = () => {
    return (
      <Tabs value={tabVal} onChange={handleTabChange} className={classes.tabs}>
        <Tab label="Out Of Service" value="outservice" />
        <Tab label="In Service" value="inservice" />
        <Tab label="On Stand By" value="standby" />
      </Tabs>
    );
  };

  const renderOutserviceUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsOutService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  const renderStandByUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsOutService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  const renderInserviceUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsInService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog onClose={close} title={'Update Unit Status'} actions={renderActions()}>
      {renderTabs()}
      {tabVal === OUTSERVICE && renderOutserviceUnits()}
      {tabVal === INSERVICE && renderInserviceUnits()}
      {tabVal === STANDBY && renderStandByUnits()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  closeOutserviceUnitDialog,
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  newUnitStatus,
  notifyDataUpdate,
})(AddOutserviceUnitDialog);
