import React from 'react';
import { connect } from 'react-redux';
import { getService } from 'reducers/service';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { getFullPermissions, getAccessPermission, getGroups, getProfile } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 20,
    '& label': {
      marginRight: 10,
      fontWeight: 500,
      display: 'inline-block',
      width: 250,
    },
  },
  defaultPermissions: {
    marginBottom: 20,
    '& button': {
      marginRight: 5,
    },
  },
  section: {
    marginBottom: 15,
    '& button': {
      marginRight: 10,
    },
  },
}));

function PermissionsTesting(props) {
  const classes = useStyles();
  const service = getService('permissions');
  const { permissions } = props;
  const defaultPermissions = permissions.default;
  const profile = getProfile();
  const groups = getGroups();
  const insertUpdate = groups.indexOf('InsertUpdate') !== -1;
  const execNCIC = getAccessPermission('globals', 'Execute NCIC Queries');
  const viewNCIC = getAccessPermission('globals', 'View NCIC Queries');
  const sendNotification = getAccessPermission('globals', 'Send General Page');
  const viewPagingHistory = getAccessPermission('globals', 'View Paging History');
  const viewPagingContact = getAccessPermission('globals', 'View Paging History Contact Information');
  const bulletinPermitions = getFullPermissions('cad', 'Alerts');
  const testingFullPermissions = JSON.stringify(getFullPermissions('cad', 'Events', 'POLICEDEP'));
  const testingAccessPermissions = getAccessPermission('cad', 'Edit Call Dates and Times', 'POLICEDEP');

  const toggleProfile = () => {
    if (profile === 'User') {
      service.get('to-admin');
    } else {
      service.get('to-user');
    }
  }

  const toggleUserInsertUpdate = () => {
    if (insertUpdate) {
      service.get('del-insert-update');
    } else {
      service.get('add-insert-update');
    }
  }

  const toggleExecNCIC = () => {
    if (execNCIC) {
      service.get('exec-ncic-off');
    } else {
      service.get('exec-ncic-on');
    }
  }

  const toggleViewNCIC = () => {
    if (viewNCIC) {
      service.get('view-ncic-off');
    } else {
      service.get('view-ncic-on');
    }
  }

  const toggleSendNotification = () => {
    if (sendNotification) {
      service.get('send-notification-off');
    } else {
      service.get('send-notification-on');
    }
  }

  const toggleViewPagingHistory = () => {
    if (viewPagingHistory) {
      service.get('view-paging-off');
    } else {
      service.get('view-paging-on');
    }
  }

  const toggleViewPagingContact = () => {
    if (viewPagingContact) {
      service.get('view-paging-contact-off');
    } else {
      service.get('view-paging-contact-on');
    }
  }

  const toggleReadBulletin = () => {
    if (bulletinPermitions.Read) {
      service.get('read-bulletin-off');
    } else {
      service.get('read-bulletin-on');
    }
  }

  const toggleCreateBulletin = () => {
    if (bulletinPermitions.Create) {
      service.get('create-bulletin-off');
    } else {
      service.get('create-bulletin-on');
    }
  }

  const toggleViewDeletedBulletin = () => {
    if (bulletinPermitions.ViewDeleted) {
      service.get('viewdeleted-bulletin-off');
    } else {
      service.get('viewdeleted-bulletin-on');
    }
  }

  const renderUser = () => {
    return (
      <div className={classes.section}>
        <label>ReadOnly Group Cities:</label>
        <Button variant="contained" color="primary" onClick={() => service.get('read-only')}>Only Read</Button>
        <Button variant="contained" color="primary" onClick={() => service.get('create')}>Create</Button>
        <Button variant="contained" color="primary" onClick={() => service.get('create-edit')}>Create/Edit</Button>
        <Button variant="contained" color="primary" onClick={() => service.get('create-edit-delete')}>Create/Edit/Delete</Button>
        <Button variant="contained" color="primary" onClick={() => service.get('all')}>All</Button>
      </div>
    );
  }

  const renderAdmin = () => {
    return (
      <>
        <div className={classes.section}>
          <label>Execute/View NCIC:</label>
          <Switch checked={execNCIC} onChange={toggleExecNCIC} />
          <Switch checked={viewNCIC} onChange={toggleViewNCIC} />
        </div>
        <div className={classes.section}>
          <label>Send generl notification:</label>
          <Switch checked={sendNotification} onChange={toggleSendNotification} />
        </div>
        <div className={classes.section}>
          <label>Paging histor/contact info:</label>
          <Switch checked={viewPagingHistory} onChange={toggleViewPagingHistory} />
          <Switch checked={viewPagingContact} onChange={toggleViewPagingContact} />
        </div>
        <div className={classes.section}>
          <label>Bulletin Read/Create/Full:</label>
          <Switch checked={bulletinPermitions.Read} onChange={toggleReadBulletin} />
          <Switch checked={bulletinPermitions.Create} onChange={toggleCreateBulletin} />
          <Switch checked={bulletinPermitions.ViewDeleted} onChange={toggleViewDeletedBulletin} />
        </div>
      </>
    );
  }

  return (
    <div className={classes.content}>
      <div style={{ marginBottom: 16 }}>
        <div className={classes.defaultPermissions}>
          Default permissions:
          <Checkbox checked={defaultPermissions.Read} inputProps={{ 'aria-label': 'Read' }} /> Read
          <Checkbox checked={defaultPermissions.Create} inputProps={{ 'aria-label': 'Create' }} /> Create
          <Checkbox checked={defaultPermissions.Edit} inputProps={{ 'aria-label': 'Edit' }} /> Edit
          <Checkbox checked={defaultPermissions.Delete} inputProps={{ 'aria-label': 'Delete' }} /> Delete
          <Checkbox checked={defaultPermissions.ViewDeleted} inputProps={{ 'aria-label': 'View Deleted' }} /> View Deleted
        </div>
        <div className={classes.section}>
          <label>My Profile: <span style={{ textDecoration: 'underline' }}>{profile}</span></label>
          <Switch checked={profile === 'Administrator'} onChange={toggleProfile} />
        </div>
        <div className={classes.section}>
          <label>My Groups: <span style={{ textDecoration: 'underline' }}>{groups.map((g, idx) => <span key={g}>{idx > 0 && <span>,</span>} {g}</span>)}</span></label>
          {profile === 'User' && <Switch checked={insertUpdate} onChange={toggleUserInsertUpdate} />}
        </div>
        <div className={classes.section}>
          <label>Testing full permissions:</label>
          {testingFullPermissions}
        </div>
        <div className={classes.section}>
          <label>Edit call date and time:</label>
          {profile === 'User' && <Switch checked={testingAccessPermissions} />}
        </div>
        {profile === 'User' && renderUser()}
        {profile === 'Administrator' && renderAdmin()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions,
  }
}

export default connect(mapStateToProps)(PermissionsTesting);