/** 
 * Custom Autocomplete component ver. 2
 * 
 * Component is designed to work with dictionaries provided as
 * options { Code, Description }. As a value there should be 
 * provided either null or Code value. e.g.:
 * 
    <CustomAutocomplete
      label="Method Type"
      options={dictionary.ContactMethodTypes}
      value={MethodType}
      onChange={newValue => setMethodType(newValue)}
      className={classes.w170x300}
      size="small"
    /> 
 */

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  autocomplete: {
    margin: '0 4px 8px',
  },
  option: {
    fontSize: 14,
  },
});

function ErrorElement() {
  return (
    <TextField
      label="Form Field Error!"
      variant="outlined"
      style={{ margin: '2em 8px' }}
      disabled
    />
  );
}

function CustomAutocomplete(props) {
  const classes = useStyles();
  const { value, onChange, label, options, disabled } = props;

  if (!options) return <ErrorElement />;

  return (
    <Autocomplete
      options={options}
      classes={{
        option: classes.option,
        root: classes.autocomplete,
      }}
      className={props.className}
      disabled={disabled}
      autoHighlight
      getOptionLabel={(option) => (option ? (option.Code ? option.Code : option) : null)}
      getOptionSelected={(option, value) => option.Code === value}
      renderOption={(option) => {
        const { Code, Description } = option;
        return (
          <span>
            <strong>{Code}</strong>
            {!!Description && <> - {Description}</>}
          </span>
        );
      }}
      onChange={(ev, newValue) => onChange(newValue ? newValue.Code : null)}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
