import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '5px 15px 10px',
    marginBottom: 15,
    '& th': {
      paddingLeft: 10,
    },
  },
  creator: {
    '& strong': {
      fontWeight: 500,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
}));

export default function EventCard(props) {
  const classes = useStyles();
  const { data, edit, edited, disableEdit } = props;
  const { CallType, CallMethod, RequestedAction, LocationDescription } = data;

  return (
    <Card className={classes.card} variant="outlined">
      <div className={classes.header}>
        <h4>Event</h4>
        <div>
          {!disableEdit && (
            <Fab color="secondary" aria-label="edit" onClick={edit} disabled={edited} size="small">
              <EditIcon />
            </Fab>
          )}
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <td>Event Type:</td>
            <th>{CallType}</th>
          </tr>
          <tr>
            <td>Call Method:</td>
            <th>{CallMethod}</th>
          </tr>
          <tr>
            <td>Requested Action:</td>
            <th>{RequestedAction}</th>
          </tr>
          <tr>
            <td>Location Description:</td>
            <th>{LocationDescription}</th>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}
