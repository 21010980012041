import Notifications from 'react-notification-system-redux';
import store from 'config/configureStore';

export const SNACKBAR_ENQUEUE = 'SNACKBAR/SNACKBAR_ENQUEUE';
export const SNACKBAR_CLOSE = 'SNACKBAR/SNACKBAR_CLOSE';
export const SNACKBAR_REMOVE = 'SNACKBAR/SNACKBAR_REMOVE';
export const CUSTOM_MSG_BOX = 'CUSTOM_MSG_BOX';
export const FORM_SEND_STATUS = 'FORM_SEND_STATUS';
export const SET_FORM_ORIGIN = 'SET_FORM_ORIGIN';

/** Shows snackbar notification, availabe options: info (default), success, error and warning,  */
export const notify = (message, variant = 'info') => {
  return (dispatch) => {
    dispatch(
      enqueueSnackbar({
        message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      })
    );
  };
};

export const notifyPanel = (options, variant) => (dispatch) => {
  const state = store.store.getState();
  const { autoHide, autoHideTime } = state.userSettings.notifications;
  const autoDismiss = autoHide ? autoHideTime : 0;
  const opts = {
    title: 'Computer Aided Dispatch',
    message: '',
    position: 'tc',
    ...options,
    autoDismiss,
  };
  dispatch(Notifications[variant](opts));
};

export const enqueueSnackbar = (notification) => {
  return (dispatch) => {
    const key = notification.options && notification.options.key;
    dispatch({
      type: SNACKBAR_ENQUEUE,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    });
  };
};

export const closeSnackbar = (key) => {
  return (dispatch) => {
    dispatch({
      type: SNACKBAR_CLOSE,
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    });
  };
};

export const removeSnackbar = (key) => {
  return (dispatch) => {
    dispatch({
      type: SNACKBAR_REMOVE,
      key,
    });
  };
};

/** Show custofm message box e.g. form validation errors */
export const showCustomMsgBox = (title, messages, type) => (dispatch) => {
  dispatch({ type: CUSTOM_MSG_BOX, payload: { title, messages, type } });
};

export const hideCustomMsgBox = () => (dispatch) => {
  dispatch({ type: CUSTOM_MSG_BOX, payload: { type: 'hide' } });
};

export const clearCustomMsgBox = () => (dispatch) => {
  dispatch({ type: CUSTOM_MSG_BOX, payload: null });
};

/** Form status, urs... */
export const setFormSendStatus = (status) => (dispatch) => {
  dispatch({ type: FORM_SEND_STATUS, payload: status });
};

export const setFormOrigin = (url) => (dispatch) => {
  dispatch({ type: SET_FORM_ORIGIN, payload: url });
};

const defaultState = {
  notifications: [],
  customMsgBox: null,
  formSendStatus: null,
  formOrigin: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    // Notifier
    case CUSTOM_MSG_BOX:
      return { ...state, customMsgBox: action.payload };
    case FORM_SEND_STATUS:
      return { ...state, formSendStatus: action.payload };
    case SNACKBAR_ENQUEUE:
      return {
        ...state,
        notifications: [...state.notifications, { key: action.key, ...action.notification }],
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };
    case SNACKBAR_REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };
    case SET_FORM_ORIGIN:
      return { ...state, formOrigin: action.payload };
    default:
      return state;
  }
}
