import { getService } from './service';
import store from '../config/configureStore';

export const upsertPlaceEmployment = async (data) => {
  const username = store.store.getState().user.userData.user.Username;
  if (!data.ptsPlaceID) throw new Error('Error saving place: no ptsPlaceID set');
  const service = getService('record-person-employment');
  const exist = await service.find({
    query: {
      ptsPersonID: data.ptsPersonID,
      ptsPlaceID: data.ptsPlaceID,
    },
  });
  let result;
  if (exist.total > 0) {
    result = await service.update(
      {
        ptsPersonID: data.ptsPersonID,
        ptsPlaceID: data.ptsPlaceID,
      },
      { ...data, UpdatedBy: username, IsDeleted: false }
    );
  } else {
    result = await service.create({ ...data, CreatedBy: username });
  }
  return result;
};

export const deletePlaceEmployment = async (data) => {
  const service = getService('record-person-employment');
  let query = {
    ptsPlaceID: Number.parseInt(data.ptsPlaceID),
    ptsPersonID: Number.parseInt(data.ptsPersonID),
    IsDeleted: false,
  };
  return service.remove(null, { query });
};

export const getPlaceEmployment = async (placeId) => {
  const service = getService('record-person-employment');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPlaceID: placeId,
  };

  const result = await service.find({
    query: q,
  });
  return result;
};
