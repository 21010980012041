import { handleError } from './ErrorReducer';
import { getService } from './service';

export const SET_EVENT_UNITS = 'SET_EVENT_UNITS';

export const getEventUnits = (ptsEventID) => {
  return async (dispatch) => {
    try {
      const service = getService('event-unit');
      await service.find({ query: { ptsEventID } });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

// ===========  REDUCERS  ======================

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_EVENT_UNITS:
      return action.events;
    default:
      break;
  }
  return state;
}
