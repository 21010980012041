import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getFormStyle } from '../../utils/functions';

const useStyles = makeStyles(() => ({
  formControl: {
    ...getFormStyle(170, 170),
  },
}));

function TextValue(props) {
  const classes = useStyles();
  const { value, setValue } = props;

  return (
    <TextField
      className={classes.formControl}
      label="Value"
      value={value}
      onChange={(ev) => setValue(ev.target.value)}
      size="small"
      error={value === ''}
    />
  );
}

export default TextValue;
