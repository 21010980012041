
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8spraypaint100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480" width="100" height="100"><path d="M203.5 0h-48c-17.673 0-32 14.327-32 32v42a8 8 0 0 0 8 8h96a8 8 0 0 0 8-8V32c0-17.673-14.327-32-32-32zm-7 52a8 8 0 0 1-16 0V42a8 8 0 0 1 16 0v10zm153-6h-9a8 8 0 0 1 0-16h9a8 8 0 0 1 0 16zm-60 0h-9a8 8 0 0 1 0-16h9a8 8 0 0 1 0 16zm120 0h-9a8 8 0 0 1 0-16h9a8 8 0 0 1 0 16zm-60 50h-9a8 8 0 0 1 0-16h9a8 8 0 0 1 0 16zm60 0h-9a8 8 0 0 1 0-16h9a8 8 0 0 1 0 16zm0 50h-9a8 8 0 0 1 0-16h9a8 8 0 0 1 0 16zm-178 143c0 28.673-23.327 52-52 52s-52-23.327-52-52 23.327-52 52-52 52 23.327 52 52zm33-191h-170c-17.64 0-32 14.35-32 32v318c0 17.65 14.36 32 32 32h170c17.64 0 32-14.35 32-32V130c0-17.65-14.36-32-32-32zm-85 259c-37.495 0-68-30.505-68-68s30.505-68 68-68 68 30.505 68 68-30.505 68-68 68z"/></SvgIcon>
      );
    }
  
    export default icons8spraypaint100Icon;