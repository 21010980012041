import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setUnitStatus, unitInitEvent } from 'reducers/UnitStatusReducer';
import { newUnitStatus } from 'reducers/DialogsReducer';

const allowedKeys = [
  'F2',
  'F3',
  'F4',
  'F5',
  'F7',
  'F9',
  'F11',
  'F12',
  'Ctrl+KeyP',
  'Ctrl+KeyL',
  'Ctrl+Alt+KeyN',
];

function UnitHotKeys(props) {
  const { ptsUnitID, options, setUnitStatus, newUnitStatus, unitInitEvent, keyboard } = props;
  const { UnitActionCodes } = options;
  const [kbdKey, setKbdKey] = useState(null);

  useEffect(() => {
    const kbdSubscription = keyboard.subject.subscribe((keyCode) => {
      allowedKeys.indexOf(keyCode) !== -1 && setKbdKey(keyCode);
    });
    return () => {
      kbdSubscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (kbdKey) handleKbdChange();
    // eslint-disable-next-line
  }, [kbdKey]);

  const handleKbdChange = () => {
    if (!ptsUnitID) return;
    switch (kbdKey) {
      case 'F2':
        setUnitStatus(UnitActionCodes.Dispatch.status, ptsUnitID);
        break;
      case 'F3':
        setUnitStatus(UnitActionCodes.Enroute.status, ptsUnitID);
        break;
      case 'F4':
        setUnitStatus(UnitActionCodes.Arrived.status, ptsUnitID);
        break;
      case 'F7':
        setUnitStatus(UnitActionCodes.Completed.status, ptsUnitID);
        break;
      case 'F11':
        setUnitStatus(UnitActionCodes.Inservice.status, ptsUnitID);
        break;
      case 'F12':
        setUnitStatus(UnitActionCodes.Outservice.status, ptsUnitID);
        break;
      case 'F9':
        newUnitStatus(ptsUnitID);
        break;
      case 'Ctrl+Alt+KeyN':
        unitInitEvent(ptsUnitID);
        break;
    }
  };

  return '';
}

const mapStateToProps = (state) => ({
  options: state.config.options,
  keyboard: state.keyboard,
});

export default connect(mapStateToProps, {
  setUnitStatus,
  newUnitStatus,
  unitInitEvent,
})(UnitHotKeys);
