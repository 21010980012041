
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8pickpocket100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M39.5 1C37 1 35 3 35 5.5s2 4.5 4.5 4.5S44 8 44 5.5 42 1 39.5 1Zm-20 2C17 3 15 5 15 7.5s2 4.5 4.5 4.5S24 10 24 7.5 22 3 19.5 3ZM38 12a3.106 3.106 0 0 0-3.094 3.094V47c0 1.102.899 2 2 2 1.102 0 2-.898 2-2V32.687l3 14.72c.2 1.1 1.309 1.792 2.407 1.593 1.101-.2 1.792-1.305 1.593-2.406L42.5 30.406l-.094-3L44 33.313c.3 1.101 1.398 1.675 2.5 1.374 1.102-.398 1.707-1.492 1.406-2.593l-5-18.282c-.3-1-1.492-1.812-2.593-1.812Zm-25.063.875a2.567 2.567 0 0 0-1.843.625l-6.5 5.188a4.208 4.208 0 0 0-1.407 2.406l-1.28 8c-.102 1.101.585 2.113 1.687 2.312 1.101.2 2.113-.492 2.312-1.593l1.094-7 3.813-3.125-2 7.312c-.301 1.102-.418 2.395-.22 3.594l1 6.406-5.28 8.5c-.7 1.102-.407 2.395.593 3.094C6.008 49.293 7.398 49 8 48l5.688-9.594c.3-.699.507-1.394.406-2.093l-.281-2.5 2.78 3.78 1.407 9.5c.2 1.2 1.3 2.106 2.5 1.907 1.2-.2 2.105-1.3 1.906-2.5l-1.312-10.313a4.983 4.983 0 0 0-.782-2L18.5 31.5l-1.594-2.406 2.094-7 1.313 2.593c.398.801.98 1.41 1.78 1.813l6.095 2.906 1.75-2.906.03-.063.032-.03c.031-.044.059-.087.094-.126l-6.407-3.375-2.593-6.718c-.301-.801-1.008-1.489-1.907-1.688L13.595 13a2.628 2.628 0 0 0-.656-.125ZM31.72 26.563c-.367-.051-.68.136-.907.437L29 30c-.3.398-.086.988.313 1.188l3.78 2.218a.956.956 0 0 0 .813.094v-5.781l-1.812-1.032a.967.967 0 0 0-.375-.125Z"/></SvgIcon>
      );
    }
  
    export default icons8pickpocket100Icon;