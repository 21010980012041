import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  eventCard: {
    marginBottom: 5,
    boxSizing: 'border-box',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '1px solid #a2a9d7',
    background: '#fff',
    '&:hover': {
      background: '#f4f5fd',
    },
  },
  evCardHead: {
    textAlign: 'center',
    padding: '8px 2px 5px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    color: theme.palette.text.primary,
    '& > div': {
      padding: '0 5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:nth-child(2)': {
        direction: 'rtl',
        textAlign: 'left',
      },
    },
  },
  avatar: {
    flex: '0 0 64px',
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dcdfed',
      borderRadius: '50%',
      width: 44,
      height: 44,
    },
  },
  content: {
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  details: {
    color: '#263055',
    overflow: 'hidden',
    marginRight: 5,
    '& h5': {
      fontSize: 15,
      marginBottom: 2,
      whiteSpace: 'nowrap',
      direction: 'rtl',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& p': {
      marginBottom: 0,
      fontSize: 12,
      lineHeight: 1.2,
      opacity: 0.75,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
    },
  },
}));

function EventList(props) {
  const classes = useStyles();
  const { statusColors, eventTypes } = props.config;
  const { data, activeEventIds, handleUnitClick } = props;

  return (
    <>
      {data.map((ev) => {
        const { EventId, Status, EventType, RespondingLocation } = ev;
        const bgColor = statusColors[Status].bgColor;
        const active = activeEventIds.indexOf(EventId) !== -1;
        const cardStyle = active
          ? { border: `3px solid ${bgColor}` }
          : { borderBottom: `3px solid ${bgColor}` };
        return (
          <Card
            className={classes.eventCard}
            key={EventId}
            style={cardStyle}
            onClick={(event) => handleUnitClick(event, ev)}>
            <div className={classes.content}>
              <div className={classes.avatar} title={EventType}>
                <div style={{ backgroundImage: `url(${eventTypes[EventType].icon})` }}></div>
              </div>
              <div className={classes.details}>
                <h5>{EventId}</h5>
                <p>{RespondingLocation}</p>
              </div>
            </div>
          </Card>
        );
      })}
    </>
  );
}

export default EventList;
