import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { lastNameLookup } from 'reducers/CallerReducer';
import { handleError } from 'reducers/ErrorReducer';
import TextFieldPlus from './TextFieldPlus';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: '0 4px 8px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  }
}));

function CallerLastNameLookup(props) {
  const classes = useStyles();
  const throttleRef = useRef(0);
  const [searchMatch, setSearchMatch] = useState([]);
  const [personData, setPersonData] = useState(null);
  const { label = 'Last Name', value, FirstName } = props;
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (personData && value === personData.LastName) return;
    setPersonData({ LastName: value || '' })
    // eslint-disable-next-line
  }, [value]);

  const onInputChange = (text) => {
    props.onChange && props.onChange({ LastName: text });
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      lastNameLookup(text, FirstName)
        .then((result) => {
          if (!mountedRef.current) return;
          setSearchMatch(result);
        })
        .catch((err) => props.handleError(err, 'Cannot contact API'));
    }, 500);
  };

  const onSearchSelect = (ev, selection) => {
    if (!selection) {
      setPersonData(null);
      props.onChange && props.onChange('', null);
      return;
    }
    setPersonData(selection);
    props.onChange && props.onChange(selection);
  };

  const getOptionSelected = (option, value) => {
    return option.ptsPersonID === value.ptsPersonID;
  };

  const renderOption = (option) => {
    const { FirstName, LastName, Info } = option;

    return (
      <div className={option.ActiveWarrant ? classes.activeWarrant : classes.option}>
        {LastName}, {FirstName} {!!Info && <>, <span style={{ whiteSpace: 'nowrap' }}>tel: {Info}</span></>}
      </div>
    );
  }

  return (
    <Autocomplete
      disabled={props.disabled}
      className={props.className}
      fullWidth
      autoHighlight
      options={searchMatch}
      value={personData}
      size="small"
      onChange={onSearchSelect}
      onInputChange={(ev, val) => onInputChange(val)}
      getOptionLabel={(option) => option.LastName}
      renderInput={(params) => {
        params.label = label;
        params.variant = 'outlined';
        return <TextFieldPlus {...params} />
      }}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
      freeSolo
    />
  );
}

export default connect(null, {
  handleError,
})(CallerLastNameLookup);
