
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8lawcourt100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="100" height="100"><path d="m26.69 11.533-.01-.01-.07-.07-1.6-1.3v-3.9c0-.55-.45-1-1-1h-1c-.55 0-1 .45-1 1v1.44l-6.32-5.16c-.19-.17-.43-.28-.68-.28-.26 0-.51.11-.7.3L3.4 11.463l-.07.07c-.2.18-.32.44-.32.72 0 .56.45 1 1 1h1v11c0 1.11.89 2 2 2h16a2 2 0 0 0 2-2v-11h1c.55 0 1-.44 1-1 0-.28-.12-.54-.32-.72zm-4.84 5.48-2.71 2.71c-.01 0-.01.01-.02.01-.21.21-.56.2-.76-.01a.55.55 0 0 1 .01-.77l.1-.1c-.62-.45-1.99-1.42-2.51-1.84-.78.89-4.84 5.54-5.1 5.8-.42.42-1.11.42-1.53 0-.42-.43-.42-1.11 0-1.54.26-.25 4.9-4.31 5.8-5.09-.41-.52-1.3-1.93-1.74-2.63l-.21.21c0 .01-.01.01-.01.02-.22.2-.56.2-.77-.02-.2-.21-.2-.56.02-.76l2.71-2.71c.09-.1.23-.16.37-.16a.5.5 0 0 1 .4.16c.21.21.21.56-.01.76l-.17.17c.85.56 2.63 1.75 3.04 2.16.42.42 1.65 2.19 2.2 2.98l.12-.12c.1-.1.23-.16.37-.16.15 0 .3.06.41.16.2.22.2.56-.01.77z"/></SvgIcon>
      );
    }
  
    export default icons8lawcourt100Icon;