import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
//Components
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import FilterGroup from './AddSOP/FilterGroup';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '../components/Dialog';
import CustomAutocomplete from 'components/CustomAutocomplete1';
import SelectList from 'components/SelectList';
//Services
import { getFormStyle } from '../utils/functions';

//Reducers
import { closeAutopageDialog } from 'reducers/DialogsReducer';
import { saveSopAutoPage } from 'reducers/SopsReducer';
import { notify } from 'reducers/NotifierReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import {
  updateTemplates,
  updateGroups,
  updateRecipients,
  updateGroupsRecipients,
} from 'reducers/PagingReducer';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: `calc(50% - ${theme.spacing(1)}px)`,
    },
  },
  dialogContent: {
    //width: 1000,
    maxWidth: '100%',
    minHeight: 500,
  },
  content: {
    margin: theme.spacing(2),
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  trigger: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '1em 0',
  },
}));

const AddEditAutoPage = (props) => {
  const { dictionary, paging } = props;
  const { recipients, groups, groupRecipients } = paging;
  const { op, data } = props.data;
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [editedValid, setEditedValid] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const recipientsRef = useRef([]);
  const groupsRef = useRef([]);

  useEffect(() => {
    props.updateTemplates();
    props.updateGroups();
    props.updateRecipients();
    props.updateGroupsRecipients();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (op === 'edit') {
      const { Label, Message, PageTemplate, groups, recipients, Source } = data;
      setTitle(Label);
      setMessage(Message);
      const template = paging.templates.find((t) => t.Code === PageTemplate);
      if (template) {
        setTemplate(template.Code);
        setSubject(template.Description);
      }

      if (groups) {
        const grp = JSON.parse(groups).map((group) => group.ROWGUID);
        groupsRef.current = grp;
      }

      if (recipients) {
        const rcpnts = JSON.parse(recipients).map((rec) => rec.ROWGUID);
        recipientsRef.current = rcpnts;
      }

      if (!Source) {
        props.notify(
          'Cannot edit this Autopage as it was created with previous version of CAD.',
          'warning'
        );
        return close();
      }
      const source = JSON.parse(Source);

      console.log('source ', source);
      setFilteredGroups(source);
    } else if (op === 'add') {
      const defaultTemplate = templates?.find((t) => t.IsDefault);
      if (!defaultTemplate) return;
      setTemplate(defaultTemplate.Code);
      setSubject(defaultTemplate.Description);
      setMessage(defaultTemplate.Template);
    }
  }, [op, data, paging.templates]);

  useEffect(() => {
    if (!paging.templates) return;
    const templates = paging.templates.filter((t) => t.IsActive === true);
    setTemplates(templates);
    // eslint-disable-next-line
  }, [paging.templates]);

  const save = async () => {
    setLoaded(true);
    const activeRecipients = recipientsRef.current
      .map((ROWGUID) => recipients.find((r) => r.ROWGUID === ROWGUID))
      .filter((rec) => !rec.IsDisabled)
      .map((rec) => rec.ptsRecipientID);

    const activeGroups = groupsRef.current
      .map((ROWGUID) => groups.find((r) => r.ROWGUID === ROWGUID))
      .filter((rec) => !rec.IsDisabled)
      .map((rec) => rec.ptsGroupID);

    const autoPageData = {
      ptsSOPID: data.ptsSOPID,
      ptsSOPAutopageID: op === 'edit' ? data.ptsSOPAutopageID : null,
      Label: title,
      Message: message,
      PageTemplate: template,
      TriggerOptions: filteredGroups,
      groups: activeGroups,
      recipients: activeRecipients,
    };
    await saveSopAutoPage(autoPageData);
    props.notifyDataUpdate({ type: 'sop-autopage', data: {} });
    setLoaded(false);
    close();
  };
  const close = () => {
    props.closeAutopageDialog();
  };

  const validate = () => {
    if (!subject || !message || !recipientsRef.current.length) {
      editedValid && setEditedValid(false);
    } else {
      !editedValid && setEditedValid(true);
    }
  };
  const handleTitleChange = (ev) => {
    const targetValue = ev.target.value;
    if (targetValue.length > 25) return;
    setTitle(targetValue);
  };
  const handleSubjectChange = (ev) => {
    const val = ev.target.value;
    if (val.length > 100) return;
    setSubject(val);
  };

  const handleMessageChange = (ev) => {
    const val = ev.target.value;
    setMessage(val);
  };
  const updateGroup = (no) => (group) => {
    const newGroups = [...filteredGroups];
    newGroups[no] = group;
    setFilteredGroups(newGroups);
  };

  const addGroup = () => {
    const newGroups = [...filteredGroups];
    const operator = filteredGroups.length > 0 ? filteredGroups[0].operator : 'And';
    newGroups.push({ operator, filters: [], logicOperator: 'And' });
    setFilteredGroups(newGroups);
  };

  const removeGroup = (no) => () => {
    const newGroups = [...filteredGroups];
    newGroups.splice(no, 1);
    setFilteredGroups(newGroups);
  };

  const updateGroupOperator = (operator) => {
    const newGroups = filteredGroups.map((data) => {
      return { ...data, operator };
    });
    setFilteredGroups(newGroups);
  };

  const selectGroups = (groups) => {
    const removedGroups = groupsRef.current.filter((id) => groups.indexOf(id) === -1);
    removedGroups.forEach((id) => {
      const rec = groupRecipients[id].Recipients;
      rec.forEach((recipient) => {
        const idx = recipientsRef.current.indexOf(recipient.ROWGUID);
        if (idx !== -1) {
          recipientsRef.current.splice(idx, 1);
        }
      });
    });
    groups.forEach((id) => {
      const rec = groupRecipients[id].Recipients;
      rec.forEach((recipient) => {
        if (recipientsRef.current.indexOf(recipient.ROWGUID) === -1) {
          recipientsRef.current.push(recipient.ROWGUID);
        }
      });
    });
    groupsRef.current = groups;
  };

  const selectRecipients = (recipients) => {
    recipientsRef.current = recipients;
    validate();
  };

  const renderTitleForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={classes.w100pr}
          label="Title"
          value={title}
          onChange={handleTitleChange}
          variant="outlined"
          size="small"
        />
      </div>
    );
  };

  const renderTriggerOptions = () => {
    return (
      <>
        <h5 className={classes.title}>Trigger options</h5>
        <div className={classes.triggers}>
          {filteredGroups.map((group, no) => (
            <FilterGroup
              key={no}
              no={no}
              data={group}
              removeGroup={removeGroup(no)}
              updateGroup={updateGroup(no)}
              dictionary={dictionary}
              updateGroupOperator={updateGroupOperator}
            />
          ))}
        </div>
        <Button onClick={addGroup}>
          <AddIcon /> Add Filter Group
        </Button>
      </>
    );
  };

  const renderForm = () => {
    const templateOptions =
      templates.length &&
      templates.map((t) => {
        return { Code: t.Code };
      });

    const onTemplateChange = (val) => {
      setTemplate(val);
      if (!val) {
        setSubject('');
        setMessage('');
      } else {
        const templ = templates.find((t) => t.Code === val);
        if (!templ) return;
        setSubject(templ.Description);
        setMessage(templ.Template);
      }
    };

    return (
      <div className={classes.form}>
        <CustomAutocomplete
          label="Template"
          options={templateOptions}
          value={template}
          onChange={onTemplateChange}
          className={classes.w100pr}
          size="small"
        />
        <TextField
          className={classes.w100pr}
          label="Subject"
          value={subject}
          onChange={handleSubjectChange}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.w100pr}
          label="Message"
          value={message}
          onChange={handleMessageChange}
          variant="outlined"
          size="small"
          multiline
          rows={10}
        />
      </div>
    );
  };

  const renderGroups = () => {
    return (
      <div className={classes.recipients}>
        <SelectList
          options={groups}
          setOptions={selectGroups}
          selected={groupsRef.current}
          keyID="ROWGUID"
          labelID="Label"
          title="Groups"
          height={400}
        />
      </div>
    );
  };

  const renderRecipients = () => {
    return (
      <div className={classes.recipients}>
        <SelectList
          options={recipients}
          selected={recipientsRef.current}
          setOptions={selectRecipients}
          keyID="ROWGUID"
          labelID="Recipient"
          title="Recipients"
          height={400}
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button variant="contained" color="primary" size="small" onClick={save} disabled={loaded}>
          <SaveIcon /> Save
        </Button>
        <Button onClick={close} color="primary" size="small">
          <CloseIcon /> Close
        </Button>
      </>
    );
  };
  return (
    <Dialog
      onClose={close}
      title={op === 'edit' ? 'Edit Autopage' : 'Add Autopage'}
      actions={renderActions()}
      fullScreen
      toolbar>
      <div className={classes.content}>
        {renderTitleForm()}
        <hr />
        {renderTriggerOptions()}
        <hr />
        {renderForm()}
        <div className={classes.columns}>
          {renderGroups()}
          {renderRecipients()}
        </div>
      </div>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    paging: state.paging,
  };
};

export default connect(mapStateToProps, {
  closeAutopageDialog,
  updateTemplates,
  updateGroups,
  updateRecipients,
  updateGroupsRecipients,
  notify,
  notifyDataUpdate,
})(AddEditAutoPage);
