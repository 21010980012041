import { getService } from './service';

export const getRotationUnits = (Rotation) => {
  const service = getService('cad-rotations');
  return service.find({ query: { Rotation } });
};

export const updateRotationUnits = (data) => {
  const service = getService('cad-rotations');
  return service.patch(data.Rotation, data);
};

export const getRotationHistory = (ptsRotationHistoryID) => {
  const service = getService('cad-rotation-history');
  return service.find({ query: { ptsRotationHistoryID } });
};

export const updateRotationHistory = ({ ActionReason, ptsRotationHistoryID }) => {
  //console.log('data ', data);
  const service = getService('cad-rotation-history');
  return service.patch(ptsRotationHistoryID, { ActionReason });
};
