import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CustomAutocomplete from 'components/CustomAutocomplete';
import { callerEmptyData, personEmptyData, placeEmptyData } from './emptyData';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import PersonLookup2 from 'components/PersonLookup2';

const useStyles = makeStyles((theme) => ({
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 8px',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
  search: {
    marginBottom: 10,
  },
  checkbox: {
    margin: '0 4px 4px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  personResult: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

function Person(props) {
  const { Relationships, data, persons } = props;
  const [filteredRelationships, setFilteredRelationships] = useState([]);
  const classes = useStyles();
  const { ptsPersonID } = data;

  useEffect(() => {
    const rel = persons.reduce((res, person) => {
      if (
        person.ptsPersonID !== ptsPersonID ||
        (person.Relationship && data?.Relationship?.Code === person.Relationship)
      )
        return res;
      return [...res, person.Relationship];
    }, []);
    const filteredRelationships = Relationships.filter((r) => rel.indexOf(r.Code) === -1);
    setFilteredRelationships(filteredRelationships);
    // eslint-disable-next-line
  }, [ptsPersonID]);

  const updatePartyType = (ev) => {
    switch (ev.target.value) {
      case 'caller':
        props.updateData(callerEmptyData);
        break;
      case 'person':
        props.updateData(personEmptyData);
        break;
      case 'place':
        props.updateData(placeEmptyData);
        break;
      default:
    }
  };

  const onAdvisoryTextChange = (ev) => props.updateData({ ...data, AdvisoryText: ev.target.value });
  const onRelationshipChange = (val) => props.updateData({ ...data, Relationship: val });
  const onPersonSelect = (val) => {
    val && props.updateData({ ...data, ...val });
  };

  return (
    <div className={classes.edit}>
      <div className={classes.form}>
        <FormControl
          className={clsx(classes.textField, classes.w180)}
          variant="outlined"
          size="small">
          <InputLabel>Party</InputLabel>
          <Select
            label="Party"
            value={data.party}
            onChange={updatePartyType}
            disabled={!!data.ROWGUID}>
            <MenuItem value="caller">Caller</MenuItem>
            <MenuItem value="person">Person</MenuItem>
            <MenuItem value="place">Place</MenuItem>
          </Select>
        </FormControl>
        <PersonLookup2
          className={clsx(classes.textField, classes.w180)}
          onPersonChange={onPersonSelect}
          ptsPersonID={data?.ptsPersonID}
          compact="true"
        />
      </div>
      <div className={classes.form}>
        <CustomAutocomplete
          className={clsx(classes.textField, classes.w180)}
          label="Party Relationship*"
          options={filteredRelationships}
          value={data.Relationship}
          onChange={onRelationshipChange}
        />
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Advisory Data"
          value={data.AdvisoryText}
          onChange={onAdvisoryTextChange}
          variant="outlined"
          size="small"
        />
      </div>
    </div>
  );
}

export default connect(null, { notify, handleError })(Person);
