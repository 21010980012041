import store from '../config/configureStore';
import { getService } from './service';
import { handleError } from './ErrorReducer';

const SET_UNIT_GROUPS = 'SET_UNIT_GROUPS';

export const getUnitGroups = () => {
  return async (dispatch) => {
    try {
      const service = getService('user-settings');
      const state = store.store.getState();
      const ptsUserID = state?.user?.userData?.user?.ptsUserID;
      const unitGroups = await service.find({
        query: {
          ptsUserID: ptsUserID,
          ApplicationName: 'CAD',
          IsDeleted: false
        }
      });
      const data = unitGroups.data.length > 0 ? JSON.parse(unitGroups.data[0].JsonValue) : {};
      dispatch({ type: SET_UNIT_GROUPS, data });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const updateUnitGroups = (saveObj = {}) => {
  return async (dispatch) => {
    const service = getService('user-settings');
    const state = store.store.getState();
    const ptsUserID = state?.user?.userData?.user?.ptsUserID;
    const data = JSON.stringify(saveObj);
    try {
      const exist = await service.find({
        query: {
          ptsUserID: ptsUserID,
          ApplicationName: 'CAD-unit-groups',
          IsDeleted: false
        }
      });
      if (exist?.data.length === 0) {
        await service.create({
          ptsUserID,
          ApplicationName: 'CAD-unit-groups',
          JsonValue: data
        });
      }
      else {
        await service.patch(exist.data[0].ptsUserSettingID, {
          JsonValue: data
        });
      }
      dispatch({ type: SET_UNIT_GROUPS, data: saveObj })
    }
    catch (error) {
      dispatch(handleError(error));
    }
  }
}


export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_UNIT_GROUPS:
      return action.data;
    default:
      break;
  }
  return state;
}