import React, { useEffect, useState, useRef } from 'react';
import { displayDateTime } from 'reducers/TimeReducer';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getTooltipStyles } from 'components/HtmlTooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getService } from 'reducers/service';
import { useDispatch } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  ...getTooltipStyles(theme),
  loader: {
    '& td': {
      position: 'relative',
      background: 'rgba(255, 255, 255, 0.4)',
      width: '100%',
      textAlign: 'center',
      '& svg': {
        color: '#0153a3',
      },
    },
  },
}));

const tooltipLabels = {
  Item: 'Item',
  UnitCapability: 'Unit Capability',
  VehicleCapability: 'Vehicle Roles',
  PersonalCapability: 'Personal Skill',
  ItemCapability: 'Item Capability',
};

function UnitTooltipInfo(props) {
  const { unit } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const resources = useSelector((state) => state.unitResources);
  const [loaded, setLoaded] = useState(false);
  const [coords, setCoords] = useState(null);
  const mountedRef = useRef(true);
  const { Unit, ptsUnitID } = unit;

  useEffect(() => {
    getUnitCoords();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const Resources = resources[unit.ptsUnitID] ? resources[unit.ptsUnitID] : [];
  const vehicles = Resources.filter((r) => r.ChildType === 'Vehicle');
  const personnel = Resources.filter((r) => r.ChildType === 'Person');
  const other = Resources.filter((r) => r.ChildType !== 'Person' && r.ChildType !== 'Vehicle');

  const getUnitCoords = async () => {
    try {
      const service = getService('unit-coordinates');
      const data = await service.get(ptsUnitID, { query: { detailed: true } });
      if (!mountedRef.current) return;
      if (data && data.lat && data.lng) {
        setCoords({
          lat: data.lat.toFixed(5),
          lng: data.lng.toFixed(5),
          Updated: data.Updated,
        });
      } else {
        setCoords(null);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoaded(true);
  };

  const renderLoader = () => (
    <tr className={classes.loader}>
      <td colSpan={2}>
        <CircularProgress />
      </td>
    </tr>
  );

  return (
    <div className={classes.tooltip}>
      <h4>{Unit}</h4>
      <table>
        <tbody>
          {Boolean(coords) && (
            <>
              <tr>
                <td colSpan={2} style={{ textAlign: 'center' }}>
                  {coords.lat}, {coords.lng}
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ textAlign: 'center' }}>
                  {displayDateTime(coords.Updated)}
                </td>
              </tr>
            </>
          )}
          {!loaded && renderLoader()}
          {vehicles.length > 0 && (
            <tr>
              <th>Vehicles</th>
              <td>
                {vehicles.map((v, idx) => (
                  <span key={v.ptsResourceID}>
                    {idx !== 0 && <br />}
                    {v.ComponentName}
                  </span>
                ))}
              </td>
            </tr>
          )}
          <tr>
            <th>Personnel</th>
            <td>
              {personnel.map((v, idx) => (
                <span key={v.ptsResourceID}>
                  {idx !== 0 && <br />}
                  {v.ComponentName}
                </span>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
      {other.length > 0 && (
        <>
          <h4>Other Resources</h4>
          <table>
            <tbody>
              {other.map((v, idx) => (
                <tr key={v.ptsResourceID}>
                  <th>{tooltipLabels[v.ChildType]}</th>
                  <td>{v.ComponentName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default UnitTooltipInfo;
