import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeAddressDialog } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { handleError } from 'reducers/ErrorReducer';
import AddressHistory from './AddressHistory';
import AddressSOPs from './AddressSOPs';
import AddressBulletins from './AddressBulletins';
import AddressParties from './AddressParties';
import AddressZones from './AddressZones';
import { getAddressDetails } from 'reducers/AddressReducer';
import AddressEdit from './AddressEdit';
import AddressCoordinates from './AddressCoordinates';
import AddressPrePlan from './AddressPrePlan';
import Attachments from 'components/Attachments';
import AddressPlace from './AddressPlace';
import AddressPerson from './AddressPerson';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: -theme.spacing(3),
  },
}));

function Address(props) {
  const classes = useStyles();
  const { ptsAddressID, copyAddress } = props;
  const [tab, setTab] = useState('edit');
  const [address, setAddress] = useState(null);
  const mountedRef = useRef(true);
  const newAddress = Boolean(!ptsAddressID || copyAddress);
  const [isAddressFormDirty, setIsAddressFormDirty] = useState(false);
  useEffect(() => {
    if (props.tab) setTab(props.tab);
    getAddressData();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [props.tab]);

  const getAddressData = async () => {
    try {
      const data = await getAddressDetails(ptsAddressID);
      if (!mountedRef.current) return;
      data?.data.length && setAddress(data.data[0]);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };
  const close = (data) => {
    if (isAddressFormDirty) {
      if (confirm('you have unsaved work. do you want to continue?')) {
        props.closeAddressDialog(data);
      }
    } else props.closeAddressDialog(data);
  };
  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={newAddress ? 'Add Address' : 'Edit Address'}
      actions={renderActions()}
      fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Edit Address" value="edit" />
            <Tab label="Attachments" value="attachments" disabled={newAddress} />
            <Tab label="Coordinates" value="coordinates" disabled={newAddress} />
            <Tab label="History" value="history" disabled={newAddress} />
            <Tab label="Parties" value="parties" disabled={newAddress} />
            <Tab label="Pre-Plan" value="pre-plan" disabled={newAddress} />
            <Tab label="SOPs" value="sops" disabled={newAddress} />
            <Tab label="Zones" value="zones" disabled={newAddress} />
            <Tab label="Bulletins" value="bulletins" disabled={newAddress} />
            <Tab label="Places" value="places" disabled={newAddress} />
            <Tab label="Persons" value="persons" disabled={newAddress} />
          </Tabs>
        </Paper>
        {tab === 'edit' && (
          <AddressEdit
            ptsAddressID={ptsAddressID}
            copyAddress={copyAddress}
            setIsAddressFormDirty={setIsAddressFormDirty}
          />
        )}
        {!newAddress && (
          <>
            {tab === 'history' && <AddressHistory ptsAddressID={ptsAddressID} />}
            {tab === 'sops' && <AddressSOPs ptsAddressID={ptsAddressID} />}
            {tab === 'parties' && <AddressParties ptsAddressID={ptsAddressID} />}
            {tab === 'zones' && <AddressZones ptsAddressID={ptsAddressID} address={address} />}
            {tab === 'attachments' && <Attachments type="Address" id={ptsAddressID} />}
            {tab === 'coordinates' && (
              <AddressCoordinates ptsAddressID={ptsAddressID} address={address} />
            )}
            {tab === 'pre-plan' && <AddressPrePlan ptsAddressID={ptsAddressID} />}
            {tab === 'bulletins' && (
              <AddressBulletins ptsAddressID={ptsAddressID} address={address} />
            )}
            {tab === 'places' && <AddressPlace ptsAddressID={ptsAddressID} />}
            {tab === 'persons' && <AddressPerson ptsAddressID={ptsAddressID} />}
          </>
        )}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeAddressDialog,
  handleError,
})(Address);
