export const eventDefaultColumns = [
  'EventID',
  'OriginalEventType',
  'EventType',
  'EventSubType',
  'Status',
  'CaseID',
  'FullAddress',
  'StreetName',
  'AgencyID',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'DeletedBy',
  'DeletedIP',
];

export const eventNoDeletedColumns = [
  'EventID',
  'OriginalEventType',
  'EventType',
  'EventSubType',
  'Status',
  'CaseID',
  'FullAddress',
  'StreetName',
  'AgencyID',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

export const advancedEventDefaultColumns = [
  'EventID',
  'OriginalEventType',
  'EventType',
  'EventSubType',
  'Status',
  'CaseID',
  'FullAddress',
  'StreetName',
  'AgencyID',
  'PartyName',
  'PartyRelationship',
  'Disposition',
  'Zone',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'DeletedBy',
  'DeletedIP',
];

export const advancedEventsNoDeletedColumns = [
  'EventID',
  'OriginalEventType',
  // { field: 'EventType', headerName: 'Event Type' },
  // { field: 'EventSubType', headerName: 'Event Sub Type' },
  'EventType',
  'EventSubType',
  'Status',
  'CaseID',
  'FullAddress',
  'StreetName',
  'AgencyID',
  'PartyName',
  'PartyRelationship',
  'Disposition',
  'Zone',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];
