/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Dialog from 'components/Dialog';
import { ColCard, gridStyle, Row, RowInner, useError } from 'utils/formStyles';
import { upsertPlaceEmployment } from 'reducers/PlacesReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { closePlaceEmployment } from 'reducers/DialogsReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import PersonLookup2 from 'components/PersonLookup2';
import TextField2 from 'components/TextField2';
import Dictionary from 'components/Dictionary';
import { formatSaveData } from 'utils/formStyles';

const useStyles = makeStyles((theme) => ({
  fileInfo: {
    minHeight: 35,
    marginBottom: 16,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8,
    },
  },
  item: gridStyle(250, 500),
  txtField: {
    minWidth: 350,
  },
}));

const emptyData = {
  ptsPlaceID: null,
  ptsPersonID: null,
  ptsSupervisorID: null,
  DepartmentName: null,
  MethodPositionType: null,
  Shift: null,
  Occupation: null,
  Rank: null,
  Position: null,
  Pay: null,
  Rate: null,
  Status: null,
  TerminationReason: null,
  Hired: null,
  Terminated: null,
};

function PlaceEmployeeDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const [formData, setFormData] = useState({});
  const [supervisor, setSupervisor] = useState(null);
  const [employer, setEmployer] = useState(null);
  const [errors, setErrors] = useState();
  const isEdit = props.ptsPlaceID;
  const { err, isValidAndChanged, formChanged } = useError(errors, setErrors);

  useEffect(() => {
    setFormData({ ...emptyData, ...data });
  }, []);

  const close = () => {
    props.closePlaceEmployment();
  };

  const save = async () => {
    const saveData = formatSaveData(formData);
    saveData.ptsPersonID = employer.ptsPersonID;
    if (supervisor) saveData.ptsSupervisorID = supervisor.ptsPersonID;
    saveData.ptsPlaceID = data.ptsPlaceID;
    props.showSpinner();
    upsertPlaceEmployment(saveData).then(close).catch(props.handleError).finally(props.hideSpinner);
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          disabled={!isValidAndChanged()}
          autoFocus
          onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const handleFormChange = (newValue, field) => {
    setFormData((formState) => ({ ...formState, [field]: newValue }));
    formChanged();
  };

  const renderEmployee = () => {
    const onChange = (val) => {
      setEmployer(val);
      formChanged();
    };
    const error = err(employer, 'person');
    return (
      <PersonLookup2
        error={error}
        className={classes.item}
        onPersonChange={onChange}
        label="Employee"
        ptsPersonID={data.ptsPersonID}
        disabled={Boolean(data.ptsPersonID)}
        compact="true"
      />
    );
  };

  const renderSupervisor = () => {
    const onChange = (val) => {
      setSupervisor(val);
      formChanged();
    };
    return (
      <PersonLookup2
        className={classes.item}
        onPersonChange={onChange}
        label="Supervisor"
        ptsPersonID={data.ptsSupervisorID}
        compact="true"
      />
    );
  };

  const renderDepartmentName = () => {
    return (
      <TextField2
        className={classes.item}
        value={formData.DepartmentName}
        label="Department Name"
        onChange={(ev, val) => handleFormChange(val, 'DepartmentName')}
        compact="true"
      />
    );
  };

  const renderPosition = () => {
    return (
      <Dictionary
        className={classes.item}
        options="Positions"
        value={formData.Position}
        onChange={(ev, val) => handleFormChange(val, 'Position')}
        label="Position"
        compact="true"
      />
    );
  };

  const renderShift = () => {
    return (
      <TextField2
        className={classes.item}
        value={formData.Shift}
        label="Shift"
        onChange={(ev, val) => handleFormChange(val, 'Shift')}
        compact="true"
      />
    );
  };

  const renderOccupation = () => {
    return (
      <Dictionary
        className={classes.item}
        options="Occupations"
        value={formData.Occupation}
        onChange={(ev, val) => handleFormChange(val, 'Occupation')}
        label="Occupation"
        compact="true"
      />
    );
  };

  const renderPay = () => {
    return (
      <TextField2
        className={classes.item}
        value={formData.Pay}
        label="Pay"
        onChange={(ev, val) => handleFormChange(val, 'Pay')}
        compact="true"
      />
    );
  };

  const renderPayRate = () => {
    return (
      <Dictionary
        className={classes.item}
        options="PayRates"
        value={formData.Rate}
        onChange={(ev, val) => handleFormChange(val, 'Rate')}
        label="Rate"
        compact="true"
      />
    );
  };

  const renderRank = () => {
    return (
      <Dictionary
        className={classes.item}
        options="Ranks"
        value={formData.Rank}
        onChange={(ev, val) => handleFormChange(val, 'Rank')}
        label="Rank"
        compact="true"
      />
    );
  };

  const renderEmploymentStatus = () => {
    return (
      <Dictionary
        className={classes.item}
        options="EmploymentStatuses"
        value={formData.Status}
        onChange={(ev, val) => handleFormChange(val, 'Status')}
        label="Status"
        compact="true"
      />
    );
  };

  const renderTerminiationReasons = () => {
    return (
      <Dictionary
        className={classes.item}
        options="TerminationReasons"
        value={formData.TerminationReason}
        onChange={(ev, val) => handleFormChange(val, 'TerminationReason')}
        label="TerminationReason"
        compact="true"
      />
    );
  };

  const renderHired = () => {
    return (
      <KeyboardDatePicker
        className={classes.item}
        size="small"
        inputVariant="outlined"
        fullWidth
        showTodayButton={true}
        variant="dialog"
        format="MM/dd/yyyy"
        id="date-picker-inline-from"
        label="Hired"
        value={formData?.Hired ? formData.Hired : null}
        onChange={(date) => setFormData((state) => ({ ...state, Hired: date }))}
      />
    );
  };

  const renderTerminated = () => {
    return (
      <KeyboardDatePicker
        className={classes.item}
        size="small"
        inputVariant="outlined"
        fullWidth
        showTodayButton={true}
        variant="dialog"
        format="MM/dd/yyyy"
        id="date-picker-inline-from"
        label="Terminated"
        value={formData?.Terminated ? formData.Terminated : null}
        onChange={(date) => setFormData((state) => ({ ...state, Terminated: date }))}
      />
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={isEdit ? 'Edit Employee' : 'Add Employee'}
      actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            {renderEmployee()}
            {renderSupervisor()}
          </RowInner>
          <RowInner>
            {renderDepartmentName()}
            {renderPosition()}
            {renderShift()}
          </RowInner>
          <RowInner>
            {renderOccupation()}
            {renderPay()}
            {renderPayRate()}
          </RowInner>
          <RowInner>
            {renderRank()}
            {renderEmploymentStatus()}
            {renderTerminiationReasons()}
          </RowInner>
          <RowInner>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {renderHired()}
              {renderTerminated()}
            </MuiPickersUtilsProvider>
          </RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, {
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
  closePlaceEmployment,
})(PlaceEmployeeDialog);
