import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from 'components/CustomAutocomplete';
import PlaceLookup from 'components/PlaceLookup';
import { callerEmptyData, personEmptyData, placeEmptyData } from './emptyData';
import { getPlaceDetails } from 'reducers/EventsReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';

const useStyles = makeStyles((theme) => ({
  formWrap: {
    padding: theme.spacing(2),
    paddingBottom: 12,
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 8px',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
  w240: {
    maxWidth: '100%',
    flexBasis: 240,
    minWidth: 240,
    flexGrow: 1,
  },
  w320: {
    maxWidth: '100%',
    flexBasis: 320,
    minWidth: 320,
    flexGrow: 1,
  },
  w120x180: {
    maxWidth: 180,
    flexBasis: 120,
    minWidth: 120,
    flexGrow: 1,
  },
  search: {
    marginBottom: 10,
  },
  checkbox: {
    margin: '0 4px 4px',
  },
  AddressLookup: {
    margin: '0 4px 8px',
  },
}));

function Place(props) {
  const { ptsEventID, Relationships, data, places } = props;
  const [filteredRelationships, setFilteredRelationships] = useState([]);
  const [newPlace, setNewPlace] = useState(null);
  const classes = useStyles();
  const { ptsPlaceID } = data;

  useEffect(() => {
    // Get place details if edit party
    if (ptsPlaceID) {
      getPlaceDetails(ptsPlaceID)
        .then((result) => {
          setNewPlace({ ...data, ...result });
        })
        .catch((error) => props.handleError(error, 'Error, address details not received'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const rel = places.reduce((res, place) => {
      if (place.ptsPlaceID !== ptsPlaceID || (place.Relationship && data?.Relationship?.Code === place.Relationship)) return res;
      return [...res, place.Relationship];
    }, []);
    const filteredRelationships = Relationships.filter(r => rel.indexOf(r.Code) === -1);
    setFilteredRelationships(filteredRelationships);
    // eslint-disable-next-line
  }, [data]);

  const renderPartyType = () => {
    const onChange = (ev) => {
      switch (ev.target.value) {
        case 'caller':
          props.updateData(callerEmptyData);
          break;
        case 'person':
          props.updateData(personEmptyData);
          break;
        case 'place':
          props.updateData(placeEmptyData);
          break;
        default:
      }
    };
    return (
      <FormControl
        className={clsx(classes.textField, classes.w180)}
        variant="outlined"
        size="small">
        <InputLabel id={`party-type-${ptsEventID}`}>Party</InputLabel>
        <Select
          label="Party"
          labelId="party-type"
          id="party-type"
          value={data.party}
          onChange={onChange}
          disabled={!!data.ROWGUID}>
          <MenuItem value="caller">Caller</MenuItem>
          <MenuItem value="person">Person</MenuItem>
          <MenuItem value="place">Place</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const renderPlaceLookup = () => {
    const onChange = (place) => {
      if (!place) {
        setNewPlace(null);
      } else {
        const { ptsPlaceID, AddressID, PlaceName } = place;
        props.updateData({ ...data, ptsPlaceID, AddressID, PlaceName });
      }
    };

    return (
      <FormControl fullWidth className={clsx(classes.textField, classes.w180)}>
        <PlaceLookup
          onChange={onChange}
          ptsPlaceID={newPlace ? newPlace.ptsPlaceID : null}
          label="Search Place"
        />
      </FormControl>
    );
  }

  const renderPartyRelationship = () => {
    const onChange = (val) => {
      props.updateData({ ...data, Relationship: val });
    };

    return (
      <Autocomplete
        className={clsx(classes.textField, classes.w180)}
        label="Party Relationship*"
        options={filteredRelationships}
        value={data.Relationship}
        onChange={onChange}
      />
    );
  }

  const renderAdvisoryData = () => {
    const onChange = (ev) => {
      props.updateData({ ...data, AdvisoryText: ev.target.value });
    };
    return (
      <TextField
        className={clsx(classes.textField, classes.w180)}
        label="Advisory Data"
        value={data.AdvisoryText}
        onChange={onChange}
        variant="outlined"
        size="small"
      />
    );
  }

  return (
    <div className={classes.formWrap}>
      <div className={classes.form}>
        {renderPartyType()}
        {renderPlaceLookup()}
      </div>
      <div className={classes.form}>
        {renderPartyRelationship()}
        {renderAdvisoryData()}
      </div>
    </div>
  );
}

export default connect(null, { notify, handleError })(Place);
