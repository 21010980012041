import React from 'react';

export default function Police() {
  return (
    <svg
      width="100%"
      height="100%"
      enableBackground="new 0 0 225 225"
      version="1.1"
      viewBox="0 0 225 225"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m225 163v13c-2.54 12.723-37.786 16.896-55 21-19.441 4.635-36.323 12.538-56 13-18.418 0.433-39.417-8.387-58-13-12.235-3.037-53.196-7.82-56-21v-13c2.894-8.773 16.276-7.058 28-7-5.505-62.505 9.049-104.95 50-121-5.423-23.548 14.179-20 35-20 24.427 0 39.608-2.745 35 21 41.146 15.188 53.923 58.744 49 120 11.437 0.23 25.575-2.242 28 7zm-113-93c-6.66 8.007-16.236 13.097-28 16 0.633 27.7 10.318 46.349 29 56 17.486-10.514 27.54-28.46 28-56-12.523-2.477-20.23-9.77-29-16z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
}
