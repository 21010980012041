import React, { useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeBulletinDetails } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import BulletinContent from './BulletinContent';
import { printRef } from 'utils/functions';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import CheckIcon from '@material-ui/icons/Check';
import { markBulletinRead } from 'reducers/BulletinReducer';

const useStyles = makeStyles(() => ({
  pageWrap: {
    width: 800,
    maxWidth: '100%',
  },
  iframe: {
    border: 'none',
    overflow: 'hidden',
    width: 1,
    height: 1,
  },
}));

function BulletinDetails(props) {
  const classes = useStyles();
  const [ref, setRef] = useState(null);
  const { data } = props;
  const { ptsAlertID, Read } = data;
  let title = `Bulletin Details`
  if (data.AlertID) title += `- ${data.AlertID}`;
  const [btnDisabled, setBtnDisabled] = useState(false);

  const close = () => {
    props.closeBulletinDetails();
  };

  const print = () => {
    printRef(ref, title)
  }

  const markRead = () => {
    props.markBulletinRead(ptsAlertID);
    setBtnDisabled(true);
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" onClick={markRead} disabled={btnDisabled || Read}>
          <CheckIcon /> Read
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={print}>
          <LocalPrintshopIcon /> Print
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog maxWidth="xs" toolbar onClose={close} title={title} actions={renderActions()}>
      <div className={classes.pageWrap}>
        <BulletinContent data={data} title={title} setRef={setRef} />
        <iframe id="print-iframe" className={classes.iframe}></iframe>
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeBulletinDetails,
  markBulletinRead,
})(BulletinDetails);
