import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeSettings } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getFormStyle } from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import ChangeUserPass from 'components/ChangeUserPass';
import {
  getLocalSettings,
  saveLocalSettings,
  changeAppMode,
  clearAppModeData,
  defaultLocalSettings,
} from 'reducers/ConfigReducer';
import { saveUserSetting } from 'reducers/UserSettingsReducer';
import Checkbox2 from 'components/Checkbox2';
import formStyles, { gridStyle, RowInner } from 'utils/formStyles';
import TextField2 from 'components/TextField2';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(150, 150),
  content: {
    width: 800,
    height: 500,
    maxWidth: '100%',
    display: 'flex',
    '& h4': {
      marginBottom: theme.spacing(3),
    },
  },
  settingsWrap: {
    flex: 1,
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(2),
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  w150x250: {
    ...getFormStyle(150, 250),
  },
  tabsWrap: {
    width: 200,
    flex: 0,
    marginLeft: theme.spacing(-3),
  },
}));

function Settings(props) {
  const classes = useStyles();
  const { mode, eventDataPresent, mapSettings, notifications } = props;
  const [changed, setChanged] = useState(false);
  const [workstationID, setWorkstationID] = useState('');
  const [tab, setTab] = useState('e911');
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [zoom, setZoom] = useState(14);
  const [enableE911, setEnableE911] = useState(props.enableE911);
  const [e911MaxTime, setE911MaxTime] = useState(props.e911MaxTime);
  const [logE911Events, setLogE911Events] = useState(props.logE911Events);
  const [settings, setSettings] = useState({ ...defaultLocalSettings });
  const [autoHideNotifications, setAutoHideNotifications] = useState(notifications.autoHide);
  const [autoHideNotTime, setAutoHideNotTime] = useState(notifications.autoHideTime);
  const [showSafetyChecks, setShowSafetyChecks] = useState(props.showSafetyChecks);

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  const getSettings = () => {
    const settings = getLocalSettings();
    if (!settings) return;
    const { workstationID } = settings;
    const { mapLat, mapLng, mapZoom } = mapSettings;
    setWorkstationID(workstationID);
    setLat(String(mapLat));
    setLng(String(mapLng));
    setZoom(String(mapZoom));
    setWorkstationID(workstationID);
  };

  const close = () => {
    props.closeSettings();
  };

  const save = () => {
    const settings = getLocalSettings();
    if (!settings) return;
    if (settings.workstationID !== workstationID) {
      saveLocalSettings({ workstationID });
    }

    if (
      parseFloat(lat) !== mapSettings.mapLat ||
      parseFloat(lng) !== mapSettings.mapLng ||
      parseFloat(zoom) !== mapSettings.mapZoom
    ) {
      props.saveUserSetting('mapSettings', {
        mapLat: parseFloat(lat),
        mapLng: parseFloat(lng),
        mapZoom: parseFloat(zoom),
      });
    }

    if (
      notifications.autoHide !== autoHideNotifications ||
      notifications.autoHideTime !== parseInt(autoHideNotTime)
    ) {
      props.saveUserSetting('notifications', {
        ...notifications,
        autoHide: autoHideNotifications,
        autoHideTime: parseInt(autoHideNotTime),
      });
    }

    if (showSafetyChecks !== props.showSafetyChecks) {
      props.saveUserSetting('showSafetyChecks', showSafetyChecks);
    }
    if (props.e911MaxTime !== e911MaxTime) props.saveUserSetting('e911MaxTime', e911MaxTime);
    if (props.logE911Events !== logE911Events)
      props.saveUserSetting('logE911Events', logE911Events);
    if (props.enableE911 !== enableE911) props.saveUserSetting('enableE911', enableE911);

    props.closeSettings();
  };

  const handleWorkstationIDChange = (ev) => {
    const val = ev.target.value;
    if (val.length > 10) return;
    const newSettings = { ...settings };
    newSettings.workstationID = val;
    setSettings(newSettings);
    setWorkstationID(val);
  };

  const isLatValid = () => {
    if (lat === '') return false;
    const val = parseFloat(lat);
    return val > -85.05112878 && val < 85.05112878;
  };

  const isLngValid = () => {
    if (lng === '') return false;
    const val = parseFloat(lng);
    return val > -180 && val < 180;
  };

  const isZoomValid = () => {
    if (zoom === '') return false;
    const val = parseFloat(zoom);
    return val >= 3 && val <= 21;
  };

  const settingsValid = () => {
    return isLatValid() && isLngValid() && isZoomValid();
  };

  const onLatChange = (ev) => {
    !changed && setChanged(true);
    setLat(ev.target.value);
  };

  const onLngChange = (ev) => {
    !changed && setChanged(true);
    setLng(ev.target.value);
  };

  const onZoomChange = (ev) => {
    !changed && setChanged(true);
    setZoom(ev.target.value);
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={save}
          disabled={!settingsValid()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const handleTabChange = (ev, value) => {
    setTab(value);
  };

  const renderMultimonitorSupport = () => {
    const onClick = (mode) => () => {
      props.changeAppMode(mode);
    };
    const removeData = () => {
      if (
        window.confirm(
          'Are you sure you want to remove stored data? It sould be used in emergency cases only when old data is preventing from entering master mode.'
        )
      ) {
        props.clearAppModeData();
      }
    };
    return (
      <div>
        <Button onClick={onClick('standalone')} disabled={mode === 'standalone'}>
          Standalone
        </Button>
        <Button
          onClick={onClick('master')}
          disabled={mode === 'slave' || mode === 'master' || eventDataPresent}>
          Primary
        </Button>
        <Button
          onClick={onClick('slave')}
          disabled={mode === 'slave' || mode === 'master' || !eventDataPresent}>
          Secondary
        </Button>
        <Button onClick={removeData} disabled={mode !== 'slave'}>
          Clear Data
        </Button>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className={classes.tabsWrap}>
        <Tabs orientation="vertical" variant="scrollable" value={tab} onChange={handleTabChange}>
          <Tab label="e911" value="e911" size="small" />
          <Tab label="Notifications" value="Notifications" size="small" />
          <Tab label="Map" value="Map" size="small" />
          <Tab label="Password" value="Password" size="small" />
          <Tab label="Multi Screen" value="Multi Screen" size="small" />
        </Tabs>
      </div>
    );
  };

  const renderWrokstationSettings = () => {
    return (
      <>
        <h4>E911 settings</h4>
        <RowInner>
          <TextField
            label="Workstation ID"
            variant="outlined"
            size="small"
            value={workstationID}
            onChange={handleWorkstationIDChange}
            className={classes.w150x250}
          />
        </RowInner>
        <RowInner>
          <TextField
            label="Cache expiration time [minutes]"
            variant="outlined"
            size="small"
            value={e911MaxTime}
            onChange={(ev) => setE911MaxTime(parseInt(ev.target.value))}
            className={classes.w150x250}
            type="number"
          />
        </RowInner>
        <RowInner>
          <Checkbox2
            checked={enableE911}
            onChange={(ev, val) => setEnableE911(val)}
            label="Enable popup"
          />
        </RowInner>
        <RowInner>
          <Checkbox2
            checked={logE911Events}
            onChange={(ev, val) => setLogE911Events(val)}
            label="Log entries"
          />
        </RowInner>
      </>
    );
  };

  const renderMapSettings = () => {
    return (
      <>
        <h4>Map Settings</h4>
        <div className={classes.form}>
          <TextField
            className={classes.w150x250}
            label="Latitude"
            variant="outlined"
            value={lat}
            onChange={onLatChange}
            size="small"
            type="number"
            error={!isLatValid()}
          />
          <TextField
            className={classes.w150x250}
            label="Longitude"
            variant="outlined"
            value={lng}
            onChange={onLngChange}
            size="small"
            type="number"
            error={!isLngValid()}
          />
        </div>
        <div className={classes.form}>
          <TextField
            className={classes.w150x250}
            label="Zoom Level"
            variant="outlined"
            value={zoom}
            onChange={onZoomChange}
            size="small"
            type="number"
            error={!isZoomValid()}
          />
        </div>
      </>
    );
  };

  const renderNotificationSettings = () => {
    return (
      <>
        <h4>Notification Settings</h4>
        <RowInner>
          <Checkbox2
            checked={autoHideNotifications}
            onChange={(ev, val) => setAutoHideNotifications(val)}
            label="Auto-hide"
          />
          <TextField2
            className={classes.item}
            label="Auto-hide time [sec]"
            value={autoHideNotTime}
            onChange={(ev, val) => setAutoHideNotTime(parseInt(val))}
            disabled={!autoHideNotifications}
            type="number"
            min={1}
          />
        </RowInner>
        <RowInner>
          <Checkbox2
            checked={showSafetyChecks}
            onChange={(ev, val) => setShowSafetyChecks(val)}
            label="Show safety check notifications"
          />
        </RowInner>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title="User Preferences"
      actions={renderActions()}
      className={classes.dialog}>
      <div className={classes.content}>
        {renderTabs()}
        <div className={classes.settingsWrap}>
          {tab === 'e911' && renderWrokstationSettings()}
          {tab === 'Map' && renderMapSettings()}
          {tab === 'Password' && <ChangeUserPass />}
          {tab === 'Multi Screen' && renderMultimonitorSupport()}
          {tab === 'Notifications' && renderNotificationSettings()}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  mode: state.config.mode,
  eventDataPresent: state.config.eventDataPresent,
  mapSettings: state.userSettings.mapSettings,
  notifications: state.userSettings.notifications,
  showSafetyChecks: state.userSettings.showSafetyChecks,
  e911MaxTime: state.userSettings.e911MaxTime,
  logE911Events: state.userSettings.logE911Events,
  enableE911: state.userSettings.enableE911,
});

export default connect(mapStateToProps, {
  closeSettings,
  notify,
  handleError,
  changeAppMode,
  clearAppModeData,
  saveUserSetting,
})(Settings);
