import { getService } from './service';

export const firstNameLookup = (search) => {
  if (!search) return Promise.resolve([]);
  const service = getService('caller-lookup');
  return service.find({ query: { search, type: 'FirstName' } });
}

export const lastNameLookup = (search, FirstName) => {
  if (!search) return Promise.resolve([]);
  const service = getService('caller-lookup');
  return service.find({ query: { search, type: 'LastName', FirstName } });
}

export const phoneLookup = (search) => {
  if (!search) return Promise.resolve([]);
  const service = getService('caller-lookup');
  return service.find({ query: { search, type: 'Phone', } });
}