import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ShootingIcon(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 30 30"
      {...props}>
      <g id="surface40427499">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 5.625 7.5 L 11.25 11.25 L 15 7.5 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 11.25 13.125 C 10.875 13.125 10.5 12.9375 10.125 12.75 L 4.5 9 C 3.75 8.4375 3.5625 7.3125 4.125 6.375 C 4.6875 5.4375 5.8125 5.25 6.75 5.8125 L 11.0625 8.625 L 13.6875 6 C 14.4375 5.25 15.5625 5.25 16.3125 6 C 17.0625 6.75 17.0625 7.875 16.3125 8.625 L 12.5625 12.375 C 12.1875 12.9375 11.8125 13.125 11.25 13.125 Z M 11.25 13.125 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 13.125 30 C 12.375 30 11.4375 29.4375 11.25 28.5 L 9.5625 21.5625 L 2.4375 14.4375 C 1.6875 13.6875 1.6875 12.5625 2.4375 11.8125 C 3.1875 11.0625 4.3125 11.0625 5.0625 11.8125 L 12.5625 19.3125 C 12.75 19.5 12.9375 19.875 13.125 20.25 L 15 27.75 C 15.1875 28.6875 14.625 29.8125 13.6875 30 C 13.5 30 13.3125 30 13.125 30 Z M 13.125 30 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 3.75 29.0625 L 3.5625 29.0625 C 3.375 29.0625 3.1875 28.875 3 28.6875 C 2.8125 28.5 2.8125 28.125 2.8125 27.9375 L 4.6875 20.8125 L 4.6875 19.875 L 2.8125 14.8125 L 2.8125 14.0625 L 6.5625 14.0625 L 6.5625 21 L 4.6875 28.3125 C 4.5 28.6875 4.125 29.0625 3.75 29.0625 Z M 3.75 29.0625 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 5.625 15 L 5.625 20.8125 L 5.625 19.6875 L 5.4375 19.3125 L 3.9375 15 L 5.625 15 M 7.5 13.125 L 1.875 13.125 L 1.875 15 L 3.75 20.0625 L 3.75 20.625 L 1.875 27.75 C 1.6875 28.6875 2.25 29.8125 3.1875 30 C 3.375 30 3.5625 30 3.5625 30 C 4.3125 30 5.25 29.4375 5.4375 28.5 L 7.3125 21.1875 C 7.3125 21 7.3125 20.8125 7.3125 20.8125 L 7.3125 13.125 Z M 7.5 13.125 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 2.8125 14.0625 L 2.8125 13.125 C 3.375 9 4.6875 6.375 6.5625 5.0625 L 6.75 4.875 C 6.9375 4.875 7.5 4.6875 8.0625 4.6875 C 9 4.6875 9.9375 5.0625 10.125 5.4375 C 10.875 6.375 10.3125 6.9375 9.9375 7.3125 C 7.6875 8.0625 6.75 10.875 6.5625 12.9375 C 6.5625 12.9375 6.5625 12.9375 6.5625 14.0625 Z M 2.8125 14.0625 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 8.0625 5.625 C 8.8125 5.625 9.1875 5.8125 9.375 6 C 9.5625 6.1875 9.5625 6.375 9.5625 6.375 C 9.5625 6.375 9.5625 6.375 9.5625 6.5625 C 7.3125 7.3125 6 9.5625 5.625 12.9375 L 5.625 13.125 L 3.75 13.125 C 4.125 10.5 5.0625 7.125 6.9375 6 C 7.125 6 7.125 5.8125 7.125 5.8125 C 7.3125 5.8125 7.5 5.625 8.0625 5.625 M 8.0625 3.75 C 7.3125 3.75 6.5625 3.9375 6.1875 4.3125 C 5.625 4.5 2.8125 5.8125 1.875 13.125 L 1.875 15 L 7.5 15 L 7.5 13.125 C 8.0625 8.625 10.3125 8.0625 10.5 8.0625 C 11.625 7.125 11.8125 6 10.875 4.875 C 10.3125 4.125 9.1875 3.75 8.0625 3.75 Z M 8.0625 3.75 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 13.125 5.625 L 22.5 5.625 L 22.5 7.5 L 11.25 7.5 Z M 13.125 5.625 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 9.375 3.75 L 28.125 3.75 L 28.125 5.625 L 9.375 5.625 Z M 9.375 3.75 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 7.5 8.4375 C 6.9375 8.4375 6.5625 8.0625 6.5625 7.5 C 6.5625 6.9375 6.9375 6.5625 7.5 6.5625 L 15 6.5625 C 15.5625 6.5625 15.9375 6.9375 15.9375 7.5 C 15.9375 8.0625 15.5625 8.4375 15 8.4375 Z M 7.5 8.4375 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 15 5.625 L 7.5 5.625 C 6.5625 5.625 5.625 6.5625 5.625 7.5 C 5.625 8.4375 6.5625 9.375 7.5 9.375 L 15 9.375 C 16.125 9.375 16.875 8.625 16.875 7.5 C 16.875 6.375 16.125 5.625 15 5.625 Z M 15 5.625 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero' /* , fill: 'rgb(0%,0%,0%)' */,
            fillOpacity: 1,
          }}
          d="M 10.3125 0.9375 C 9.277344 0.9375 8.4375 1.777344 8.4375 2.8125 C 8.4375 3.847656 9.277344 4.6875 10.3125 4.6875 C 11.347656 4.6875 12.1875 3.847656 12.1875 2.8125 C 12.1875 1.777344 11.347656 0.9375 10.3125 0.9375 Z M 10.3125 0.9375 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            /* fill: 'rgb(0%,0%,0%)', */ fillOpacity: 1,
          }}
          d="M 10.3125 1.875 C 10.875 1.875 11.25 2.25 11.25 2.8125 C 11.25 3.375 10.875 3.75 10.3125 3.75 C 9.75 3.75 9.375 3.375 9.375 2.8125 C 9.375 2.25 9.75 1.875 10.3125 1.875 M 10.3125 0 C 8.8125 0 7.5 1.3125 7.5 2.8125 C 7.5 4.3125 8.8125 5.625 10.3125 5.625 C 11.8125 5.625 13.125 4.3125 13.125 2.8125 C 13.125 1.3125 11.8125 0 10.3125 0 Z M 10.3125 0 "
        />
      </g>
    </SvgIcon>
  );
}

export default ShootingIcon;
