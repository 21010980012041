// https://material-ui-pickers.dev/ - in package.json "@material-ui/pickers": "3.3.10",
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getService } from 'reducers/service';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import {
  displayDateTime,
  displayTime,
  displayDate,
  displayLocalTime,
  displayAgencyTime,
  toggleTimeFormat,
  dateTimePicker,
  getFormat24,
  formatSaveDateFrom,
  formatSaveDateTo,
  formatSaveDate,
  getCurrentDate,
  setDateTime,
  getDateTimeFormat,
  setTimeOffset,
  toggleAgencyTime,
  getApiTimeOffset,
} from 'reducers/TimeReducer';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDateTimePicker,
  TimePicker,
  DatePicker,
} from '@material-ui/pickers';
import TimePicker2 from 'components/TimePicker2';
import DatePicker2 from 'components/DatePicker2';
import TextField2 from 'components/TextField2';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 16,
    '& code': {
      fontFamily: "'Courier New', Courier, monospace",
    },
    '& button': {
      marginLeft: 8,
    },
  },
  displayTime: {
    display: 'flex',
    '& > div:first-child': {
      width: '65%',
    },
    '& > div:last-child': {
      paddingLeft: 16,
      width: '35%',
    },
  },
  settings: {
    display: 'flex',
  },
  timePicker: {
    margin: '8px 8px 8px 0',
    width: 220,
  },
  dateTime: {
    marginTop: 20,
  },
  label: {
    display: 'inline-block',
    width: 50,
  },
  actions: {
    display: 'inline-block',
    paddingTop: 8,
    '& > button': {
      marginRight: 8,
    },
  },
  table: {
    marginBottom: 8,
    display: 'inline-block',
    '& th': {
      padding: '2px 8px',
      textAlign: 'center',
      background: '#eee',
      border: '1px solid #bbb',
      '&:first-child': {
        textAlign: 'left',
      },
    },
    '& td': {
      padding: '2px 8px',
      border: '1px solid #bbb',
    },
    '&>thead th:first-child': {
      textAlign: 'center',
      background: '#fff',
      padding: 4,
    },
  },
}));

const zeroTime = { jsonTime: null, seqTime: null, sqlTime: null };
function TimeTesting2(props) {
  const classes = useStyles();
  const [dateTime, setDateTime] = useState(dateTimePicker('2022-07-07T11:59:55.500Z'));
  const [ptsPersonID, setPtsPersonID] = useState(1);
  const [times, setTimes] = useState(zeroTime);
  const [timeOffset, setTimeOffset] = useState(0);
  const [dob, setDob] = useState(null);
  const [picker1, setPicker1] = useState(null);
  const [calcTimeOffset, setCalcTimeOffset] = useState(0);
  const format24 = getFormat24();
  const { time } = props;
  const apiTimeZone = time.apiTimeZone;
  const browserTimeZone = moment.tz.guess();

  useEffect(() => {
    setTimeOffset(time.timeOffset);
    getDob();
    // eslint-disable-next-line
  }, []);

  useEffect(async () => {
    setCalcTimeOffset(await getApiTimeOffset(dateTime));
  }, [dateTime]);

  const getDobTime = async () => {
    const testService = getService();
    const test = await testService.get({ type: 'cad-testing', data: ptsPersonID });
    setTimes(test);
  };

  const saveDobTime = async () => {
    const testService = getService();
    const Born = formatSaveDate(dateTime);
    await testService.patch(ptsPersonID, {
      type: 'cad-testing',
      data: { type: 'sql', Born },
    });
    return getDobTime();
  };

  const getDob = async () => {
    const testingService = getService('ptsperson');
    const person = await testingService.find({ query: { ptsPersonID: parseInt(ptsPersonID) } });
    if (!person?.data?.length) return;
    const dob = person.data[0].Born;
    setDob(dob);
    setPicker1(dateTimePicker(dob));
  };

  const renderTimeSettings = () => {
    const { format24h, localTime, dbTZoffset, localTZoffset } = time;
    const onChange = (ev, val) => {
      setTimeOffset(val);
      if (isNaN(val)) return;
      const timeOffset = parseInt(val || 0);
      props.setTimeOffset(timeOffset);
    };
    return (
      <div className={classes.settings}>
        <div>
          <h5>Time Settings</h5>
          <table className={classes.table} style={{ marginRight: 16, marginBottom: 0 }}>
            <tbody>
              <tr>
                <th>DB Time Zone Offset</th>
                <td>{dbTZoffset}</td>
              </tr>
              <tr>
                <th>Local Time Zone Offset</th>
                <td>{localTZoffset}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          Time (PM/24): <Switch checked={format24h} onChange={props.toggleTimeFormat} />
        </div>
        <div>
          Agency/Local: <Switch checked={localTime} onChange={props.toggleAgencyTime} />
        </div>
        <div>
          <TextField2
            value={timeOffset}
            style={{ width: 130 }}
            label="Time offset [min]"
            onChange={onChange}
            compact="true"
          />
        </div>
      </div>
    );
  };

  const saveDobSequelize = async () => {
    const testingService = getService();
    const Born = picker1;
    await testingService.patch(ptsPersonID, {
      type: 'cad-testing',
      data: { type: 'seqelize', Born },
    });
    getDob();
  };

  const saveDobSql = async () => {
    const testingService = getService('cad');
    const Born = formatSaveDate(picker1);
    await testingService.patch(ptsPersonID, { type: 'cad-testing', data: { type: 'sql', Born } });
    getDob();
  };

  const renderTestPickers = () => {
    const getCurrentTime = () => {
      const currentDate = getCurrentDate();
      setPicker1(currentDate);
    };

    return (
      <div>
        <div>
          <h5>Save Time/Date testing</h5>
          ptsPersonID:{' '}
          <TextField2
            value={ptsPersonID}
            onChange={(e, v) => setPtsPersonID(parseInt(v))}
            type-="number"
            compact="true"
            style={{ width: 60 }}
          />
          <Button variant="contained" color="primary" onClick={getDob}>
            Get DOB
          </Button>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              ampm={!format24}
              className={classes.timePicker}
              label="Time picker"
              value={picker1}
              onChange={(val) => setPicker1(val)}
              inputVariant="outlined"
              size="small"
              style={{ width: 100, margin: '0 8px 0 8px' }}
            />
          </MuiPickersUtilsProvider>
          <Button variant="contained" color="primary" onClick={getCurrentTime}>
            Get current time
          </Button>
          <Button variant="contained" color="primary" onClick={saveDobSequelize}>
            Update DOB - Sequelize
          </Button>
          <Button variant="contained" color="primary" onClick={saveDobSql}>
            Update DOB - SQL
          </Button>
          <br />
          DOB: {displayDateTime(dob)}
        </div>
      </div>
    );
  };

  const renderDisplayTimeTable = () => {
    const { jsonTime, seqTime, sqlTime } = times;
    return (
      <div className={classes.displayTime}>
        <div>
          <h5>Testing DOB in ptsPerson table for specified ptsPersonID</h5>
          <table className={classes.table}>
            <thead>
              <tr>
                <th colSpan={4}>Get time using different methods</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th></th>
                <th>raw data</th>
                <th>displayDateTime</th>
                <th>displayTime</th>
              </tr>
              <tr>
                <th>SQL</th>
                <td>{sqlTime}</td>
                <td>{displayDateTime(sqlTime)}</td>
                <td>{displayTime(sqlTime)}</td>
              </tr>
              <tr>
                <th>JSON SQL</th>
                <td>{jsonTime}</td>
                <td>{displayDateTime(jsonTime)}</td>
                <td>{displayTime(jsonTime)}</td>
              </tr>
              <tr>
                <th>Seq</th>
                <td>{seqTime}</td>
                <td>{displayDateTime(seqTime)}</td>
                <td>{displayTime(seqTime)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div>
            ptsPersonID:{' '}
            <TextField2
              value={ptsPersonID}
              onChange={(e, v) => setPtsPersonID(v)}
              type-="number"
              compact="true"
              style={{ width: 60 }}
            />
            <Button variant="contained" color="primary" onClick={getDobTime}>
              Get Time
            </Button>
          </div>
          <div style={{ marginTop: 20 }}>
            When you click on update you should receive current time (local or agency) in all 4 rows
          </div>
          <div className={classes.dateTime}>
            <DatePicker2
              label="Date picker"
              value={dateTime}
              onChange={(ev, val) => setDateTime(val)}
              style={{ width: 200, margin: '0 8px 0 8px' }}
            />
            <TimePicker2
              label="Time picker"
              value={dateTime}
              onChange={(ev, val) => setDateTime(val)}
              style={{ width: 200, margin: '0 8px 0 8px' }}
            />
            <TextField2 value={calcTimeOffset} style={{ width: 100 }} compact="true" />
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDateTime(getCurrentDate())}>
              Set Current Time
            </Button>
            <Button variant="contained" color="primary" onClick={saveDobTime}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={classes.container}>
      <div style={{ marginBottom: 20 }}>
        Time Zone: browser: {browserTimeZone}, API: {apiTimeZone}
      </div>
      {renderTimeSettings()}
      <hr />
      {renderDisplayTimeTable()}
      <hr />
      {renderTestPickers()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    time: state.time,
  };
};

export default connect(mapStateToProps, {
  displayLocalTime,
  displayAgencyTime,
  toggleTimeFormat,
  showSpinner,
  hideSpinner,
  handleError,
  setTimeOffset,
  toggleAgencyTime,
})(TimeTesting2);
