import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mapNight, mapDay } from 'config/configureMap';
import { defaultCenterRef, defaultMenuPos } from 'utils/mapFunctions';
import { GoogleMap, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import HomeIcon from '@material-ui/icons/Home';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { saveUserSetting } from 'reducers/UserSettingsReducer';
import { makeStyles, Menu, MenuItem, Paper, TextField } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import Tooltip from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    bottom: 0,
    top: 0,
  },
  searchBox: {
    left: 0,
    right: 0,
    width: 400,
    height: 35,
    marginTop: 20,
    marginLeft: 20,
    position: 'absolute',
    alignItems: 'center',
    '& > div': {
      padding: '4px 8px 4px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
}));

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const currentMapOptions = {
  mapTypeControlOptions: { style: 1, position: 11 },
  zoomControlOptions: { position: 11 },
};

export default function AddressCoordsMap(props) {
  const { lat, setLat, lng, setLng, FullAddressText } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [coords, setCoords] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [menuPos, setMenuPos] = useState(defaultMenuPos);
  const [searchText, setSearchText] = useState('');
  const [addEventAnchor, setAddEventAnchor] = useState(null);
  const themeMode = useSelector((state) => state.theme.mode);
  const mapActions = useSelector((state) => state.map.action);
  const userSettings = useSelector((state) => state.userSettings);
  const mapRef = useRef();
  const zoomRef = useRef(8);
  const centerRef = useRef(defaultCenterRef);
  const menuRef = useRef(null);
  const textBoxRef = useRef(null);
  const mapOptionsRef = useRef(
    useSelector((state) => ({
      ...state.map.options,
      ...currentMapOptions,
      styles: themeMode === 'day' ? mapDay : mapNight,
    }))
  );

  const userSettingsRef = useRef(userSettings);
  const markerCoords = lat && lng ? { lat, lng } : null;

  useEffect(() => {
    if (lat && lng) return;
    centerMap();
    // elint-disable-next-line
  }, []);

  useEffect(() => {
    if (!mapActions) return;
    const { type, zoom = 15, lat, lng } = mapActions;
    if (type === 'zoom-and-center') {
      zoomRef.current = zoom;
      centerRef.current = { lat, lng };
      setCoords(centerRef.current);
    }
    // eslint-disable-next-line
  }, [mapActions]);

  useEffect(() => {
    userSettingsRef.current = userSettings;
    // eslint-disable-next-line
  }, [userSettings]);

  useEffect(() => {
    if (!lat || !lng || !mapRef.current) return;
    const currentZoom = mapRef.current.zoom;
    if (currentZoom < 16) mapRef.current.setZoom(16);
    mapRef.current.setCenter({ lat, lng });
    // eslint-disable-next-line
  }, [lat, lng]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    mapRef.current.setZoom(zoomRef.current);
    mapRef.current.setMapTypeId(userSettingsRef.current.mapSettings.mapTypeId);
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(<MapSettings />, controlButtonDiv);
    map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(controlButtonDiv);
  }, []);

  const centerMap = () => {
    const { mapZoom, mapLat, mapLng } = userSettingsRef.current.mapSettings;
    zoomRef.current = mapZoom;
    centerRef.current = { lat: mapLat, lng: mapLng };
    setCoords(centerRef.current);
  };

  const saveMapSettings = () => {
    const zoom = mapRef.current.getZoom();
    const center = mapRef.current.getCenter().toJSON();
    const mapTypeId = mapRef.current.getMapTypeId();
    dispatch(
      saveUserSetting('mapSettings', {
        mapZoom: zoom,
        mapLat: center.lat,
        mapLng: center.lng,
        mapTypeId,
      })
    );
  };

  const useCoords = () => {
    closeMenu();
    setLat(coords.lat);
    setLng(coords.lng);
  };

  const closeMenu = () => {
    setAddEventAnchor(null);
  };

  const renderAddEventMenu = () => (
    <>
      <div style={{ position: 'absolute', top: menuPos.y, left: menuPos.x }} ref={menuRef}></div>
      <Menu
        id="simple-menu"
        anchorEl={addEventAnchor}
        keepMounted
        open={Boolean(addEventAnchor)}
        onClose={closeMenu}>
        <MenuItem onClick={useCoords}>Use this location</MenuItem>
        <MenuItem onClick={closeMenu}>Close</MenuItem>
      </Menu>
    </>
  );

  const onMapClick = (ev) => {
    if (!ev?.pixel?.x) return;
    const screen = {
      x: ev.pixel.x,
      y: ev.pixel.y,
    };
    const coords = {
      lat: ev.latLng.lat(),
      lng: ev.latLng.lng(),
    };
    setMenuPos(screen);
    setCoords(coords);
    setAddEventAnchor(menuRef.current);
  };

  const onZoomChanged = () => {
    if (!mapRef.current) return;
    zoomRef.current = mapRef.current.getZoom();
  };

  const onCenterChanged = () => {
    if (!mapRef.current) return;
    centerRef.current = mapRef.current.getCenter().toJSON();
  };

  const MapSettings = () => (
    <>
      <IconButton onClick={centerMap} className="map-icon-button">
        <HomeIcon />
      </IconButton>
      <IconButton onClick={saveMapSettings} className="map-icon-button">
        <SaveIcon />
      </IconButton>
    </>
  );

  // ------------ Search box -----------
  const onLoadSearchBox = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => {
    const place = searchBox.getPlaces();
    if (!place || !place.length) return;
    const lat = parseFloat(place[0].geometry.location.lat());
    const lng = parseFloat(place[0].geometry.location.lng());
    setLat(lat);
    setLng(lng);
  };

  const useCurrentAddress = () => {
    setSearchText(FullAddressText);
  };

  const showCurrentLocation = () => {
    centerRef.current = { lat, lng };
    setCoords(centerRef.current);
  };

  const renderSearchBox = () => (
    <StandaloneSearchBox onLoad={onLoadSearchBox} onPlacesChanged={onPlacesChanged}>
      <div className={classes.searchBox}>
        <Paper elevation={3}>
          <Tooltip title="Use current address">
            <span>
              <IconButton disabled={!FullAddressText} onClick={useCurrentAddress}>
                <ArrowForwardIcon />
              </IconButton>
            </span>
          </Tooltip>
          <TextField
            size="small"
            label=""
            variant="outlined"
            value={searchText}
            onChange={(ev) => setSearchText(ev.target.value)}
            placeholder="Address Search"
            ref={textBoxRef}
          />
          <Tooltip title="Show current location">
            <span>
              <IconButton disabled={!markerCoords} onClick={showCurrentLocation}>
                <MyLocationIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Paper>
      </div>
    </StandaloneSearchBox>
  );

  return (
    <div className={classes.map}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={zoomRef.current}
        center={centerRef.current}
        options={mapOptionsRef.current}
        key={themeMode}
        onLoad={onMapLoad}
        onZoomChanged={onZoomChanged}
        onCenterChanged={onCenterChanged}
        className={classes.map}
        onClick={onMapClick}>
        {renderSearchBox()}
        {markerCoords && <Marker position={markerCoords} />}
        {renderAddEventMenu()}
      </GoogleMap>
    </div>
  );
}
