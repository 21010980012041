import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { showReport } from 'reducers/DialogsReducer';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { formatSaveDate, getCurrentDate } from 'reducers/TimeReducer';
import formStyles, { gridStyle, Row } from 'utils/formStyles';
import DatePicker2 from 'components/DatePicker2';
import TimePicker2 from 'components/TimePicker2';
import PrintIcon from '@material-ui/icons/Print';
import { getService } from 'reducers/service';
import Autocomplete2 from 'components/Autocomplete2';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(250, '100%'),
}));

function DateAndTimeDialog(props) {
  const { close, time } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [Shift, setShift] = useState(null);
  const [Shifts, setShifts] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setDatePickers();
    getShifts();
    // eslint-disable-next-line
  }, []);

  const setDatePickers = () => {
    const startDate = getCurrentDate();
    const endDate = getCurrentDate();
    const { localTZoffset } = time;
    startDate.subtract(1, 'day').hours(7).minutes(0).seconds(0).add(localTZoffset, 'minutes');
    endDate.hours(19).minutes(0).seconds(0).add(localTZoffset, 'minutes');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getShifts = async () => {
    const service = getService('ptsshift');
    const result = await service.find({
      query: {
        $limit: 1000,
      },
    });
    let shifts = result && result.data ? result.data : [];
    shifts = shifts.map((shift) => ({
      Code: shift.Designation,
      Description: shift.AgencyID,
    }));
    setShifts(shifts);
  };

  const viewShiftReport = () => {
    const StartDateStr = formatSaveDate(startDate);
    const EndDateStr = formatSaveDate(endDate);
    props.showReport({
      id: 238,
      data: { StartDateStr, EndDateStr, Shift: Shift?.Code || '' },
      title: 'Shift Report',
    });
    close();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={viewShiftReport}>
          <PrintIcon /> View Report
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Shift report" actions={renderActions()}>
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Row>
            <DatePicker2
              label="Start Date"
              className={classes.item}
              value={startDate}
              onChange={(ev, val) => setStartDate(val)}
            />
            <TimePicker2
              className={classes.item}
              label="Start Time"
              value={startDate}
              onChange={(ev, val) => setStartDate(val)}
            />
          </Row>
          <Row>
            <DatePicker2
              label="End Date"
              className={classes.item}
              value={endDate}
              onChange={(ev, val) => setEndDate(val)}
            />
            <TimePicker2
              className={classes.item}
              label="End Time"
              value={endDate}
              onChange={(ev, val) => setEndDate(val)}
            />
          </Row>
          <Row>
            <Autocomplete2
              options={Shifts}
              className={classes.item}
              onChange={(ev, val) => setShift(val)}
              value={Shift}
              label="Shift"
              compact="true"
            />
          </Row>
        </MuiPickersUtilsProvider>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  time: state.time,
});

export default connect(mapStateToProps, { showReport })(DateAndTimeDialog);
