import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import MessageIcon from '@material-ui/icons/Message';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  notify: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    padding: '8px 0',
    whiteSpace: 'pre-wrap',
  },
  questions: {
    width: '100%',
  },
  checkbox: {
    '& label': {
      cursor: 'pointer',
    },
    '& > td': {
      '& > span': {
        marginLeft: -theme.spacing(1.5),
      },
    },
  },
  title: {
    '& span': {
      fontWeight: 400,
    },
  },
}));

function Sops(props) {
  const classes = useStyles();
  const { no, update, sop, fromClosedEvent } = props;
  const { DispatcherQuestions, DispatcherMessage, SOPID, ParentType, ParentName, ptsSOPID } = sop;
  const perms = getFullPermissions('globals', 'SOPs');
  const { Edit } = perms;
  const updateQuestion = (idx) => (ev) => {
    const value = ev.target.value;
    const newSop = { ...sop };
    newSop.DispatcherQuestions[idx] = {
      ...newSop.DispatcherQuestions[idx],
      value,
    };
    update(newSop);
  };

  const updateCheckbox = (idx) => (ev) => {
    const value = ev.target.checked;
    const newSop = { ...sop };
    newSop.DispatcherQuestions[idx] = {
      ...newSop.DispatcherQuestions[idx],
      value,
    };
    update(newSop);
  };

  const renderText = (text) => {
    return (
      <tr key={text.name}>
        <td colSpan={2} className={classes.text}>
          <MessageIcon /> &nbsp;{text.text}
        </td>
      </tr>
    );
  };

  const renderQuestion = (question, idx) => {
    return (
      <tr key={question.name}>
        <td>
          <QuestionAnswerIcon /> &nbsp;{question.text}
        </td>
        <td>
          {
            <TextField
              variant="outlined"
              size="small"
              style={{ width: '100%', minWidth: 250 }}
              value={question.value}
              onChange={updateQuestion(idx)}
              disabled={fromClosedEvent && !Edit}
            />
          }
        </td>
      </tr>
    );
  };

  const renderCheckbox = (checkbox, idx) => {
    const value = checkbox.value === true || checkbox.value === 'True';
    const id = `sop-checkbox-${ptsSOPID}-${idx}`;
    return (
      <tr key={checkbox.name} className={classes.checkbox}>
        <td>
          <label htmlFor={id}>{checkbox.text}</label>
        </td>
        <td>
          <Checkbox
            checked={value}
            onChange={updateCheckbox(idx)}
            id={id}
            disabled={fromClosedEvent && !Edit}
          />
        </td>
      </tr>
    );
  };

  if (!sop) return '';

  return (
    <div>
      {no > 0 && <hr />}
      <h5 className={classes.title}>
        {SOPID}{' '}
        <span>
          ({ParentType} - {ParentName})
        </span>
      </h5>
      <p>
        <strong>Notification text:</strong> {DispatcherMessage}
      </p>
      <h5>Actions</h5>
      <table className={classes.questions}>
        <tbody>
          {DispatcherQuestions.map((q, idx) => {
            switch (q.type) {
              case 'Text':
                return renderText(q);
              case 'Question':
                return renderQuestion(q, idx);
              case 'CheckBox':
                return renderCheckbox(q, idx);
              default:
                return '';
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Sops;
