import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeUnitResource } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import PersonLookup2 from 'components/PersonLookup2';
import VehicleLookup from 'components/VehicleLookup';
import { getFormStyle, getRowStyle, get100prStyle } from 'utils/functions';
import { gridStyle } from 'utils/formStyles';
import {
  getFormat24,
  getDateTimeFormat,
  dateTimePicker,
  formatSaveDate,
} from 'reducers/TimeReducer';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  saveUnitPerson,
  saveUnitVehicle,
  editUnitResource,
  getUnitResource,
} from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import Dictionary from 'components/Dictionary';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  item: {
    ...gridStyle(200, 920),
  },
  date: getFormStyle(200, 300),
  action: {
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  row: getRowStyle(),
  w100pr: get100prStyle(),
}));

function AddEditUnitResource(props) {
  const classes = useStyles();
  const { type, ptsResourceID, ptsUnitID } = props.data;
  const [person, setPerson] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [ending, setEnding] = useState(null);
  const [beginning, setBeginning] = useState(null);
  const [capabilityType, setCapabilityType] = useState(null);
  const [CapabilityTypeQuantity, setCapabilityQuantity] = useState('');
  const [CapabilityDetails, setCapabilityDetail] = useState('');
  const [personId, setPersonId] = useState(null);
  const [vehicleId, setVehicleId] = useState(null);
  const [componentName, setComponentName] = useState('');
  const format24 = getFormat24();

  useEffect(() => {
    if (ptsResourceID) search();
  }, []);

  const search = async () => {
    const result = await getUnitResource(ptsResourceID);
    initData(result);
  };

  const initData = (data) => {
    const {
      Beginning,
      Ending,
      CapabilityType,
      CapabilityDetails,
      CapabilityTypeQuantity,
      ptsChildID,
      ComponentName,
    } = data;
    setBeginning(dateTimePicker(Beginning));
    setEnding(dateTimePicker(Ending));
    CapabilityType && setCapabilityType({ Code: CapabilityType });
    setCapabilityDetail(CapabilityDetails || '');
    setCapabilityQuantity(CapabilityTypeQuantity || '');
    setComponentName(ComponentName);
    type === 'Person' ? setPersonId(ptsChildID) : setVehicleId(ptsChildID);
  };

  const close = () => {
    props.closeUnitResource();
  };

  const save = () => {
    ptsResourceID ? saveEditedRes() : type === 'Person' ? savePerson() : saveVehicle();
  };

  const savePerson = async () => {
    const ComponentName = person.FullName;
    const ptsChildID = person.ptsPersonID;
    const selectedBeginningDate = beginning;
    const selectedEndingDate = ending;
    const CapabilityType = capabilityType?.Code || null;
    props.showSpinner();
    try {
      await saveUnitPerson({
        ptsUnitID,
        ComponentName,
        ptsChildID,
        selectedBeginningDate,
        selectedEndingDate,
        CapabilityType,
        CapabilityTypeQuantity,
        CapabilityDetails,
      });
      props.notifyDataUpdate({ type: 'unit-resource' });
      close();
    } catch (err) {
      props.handleError(err, 'Error saving resource');
    }
    props.hideSpinner();
  };

  const saveVehicle = async () => {
    const ComponentName = componentName;
    const ptsChildID = vehicle.ptsVehicleID;
    const selectedBeginningDate = beginning;
    const selectedEndingDate = ending;
    const CapabilityType = capabilityType?.Code || null;
    props.showSpinner();
    try {
      await saveUnitVehicle({
        ptsUnitID,
        ComponentName,
        ptsChildID,
        selectedBeginningDate,
        selectedEndingDate,
        CapabilityType,
        CapabilityTypeQuantity,
        CapabilityDetails,
      });
      props.notifyDataUpdate({ type: 'unit-resource' });
      close();
    } catch (err) {
      props.handleError(err, 'Error saving resource');
    }
    props.hideSpinner();
  };

  const saveEditedRes = async () => {
    const CapabilityType = capabilityType?.Code || null;
    try {
      await editUnitResource(ptsResourceID, {
        Beginning: formatSaveDate(beginning),
        Ending: formatSaveDate(ending),
        CapabilityType,
        CapabilityTypeQuantity,
        CapabilityDetails,
        ComponentName: componentName,
      });
      props.notifyDataUpdate({ type: 'unit-resource' });
      close();
    } catch (err) {
      props.handleError(err, 'Error saving resource');
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderPersonLookup = () => {
    return (
      <div className={classes.row}>
        <PersonLookup2
          className={classes.item}
          onPersonChange={setPerson}
          ptsPersonID={personId}
          compact="true"
          disabled={Boolean(ptsResourceID)}
        />
      </div>
    );
  };

  const renderVehicleLookup = () => {
    const onChange = (vehicle) => {
      setVehicle(vehicle);
      ////// updating component name to property ID on add/////
      if (!ptsResourceID) {
        if (vehicle) setComponentName(vehicle.PropertyID || '');
        else setComponentName('');
      }
    };
    return (
      <div className={classes.row}>
        <VehicleLookup
          className={classes.item}
          onChange={onChange}
          label="Search Vehicle"
          ptsVehicleID={vehicleId}
          disabled={Boolean(ptsResourceID)}
        />
      </div>
    );
  };

  const renderBeginningTime = () => {
    return (
      <div className={classes.row}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            ampm={!format24}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format={getDateTimeFormat()}
            margin="normal"
            label="Date From"
            value={beginning}
            onChange={setBeginning}
            maxDate={ending}
            autoOk
            size="small"
            className={classes.item}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const renderEndingTime = () => {
    return (
      <div className={classes.row}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            ampm={!format24}
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            label="Date To"
            value={ending}
            onChange={setEnding}
            minDate={beginning}
            autoOk
            size="small"
            className={classes.item}
            format={getDateTimeFormat()}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const renderCapabilityType = () => {
    const onChange = (ev, val) => setCapabilityType(val);
    return (
      <div className={classes.row}>
        <Dictionary
          options="CapabilityTypes"
          className={classes.item}
          onChange={onChange}
          value={capabilityType}
          label="Capability Type"
          compact="true"
        />
      </div>
    );
  };

  const renderComponentName = () => {
    const onChange = (ev, val) => setComponentName(ev.target.value);
    return (
      <div className={classes.row}>
        <TextField
          className={classes.w100pr}
          label="Component Name"
          value={componentName}
          onChange={onChange}
          type="text"
          variant="outlined"
          size="small"
        />
      </div>
    );
  };

  const renderCapabilityTypeQuantity = () => {
    const onChange = (ev, val) => setCapabilityQuantity(ev.target.value);
    return (
      <div className={classes.row}>
        <TextField
          className={classes.w100pr}
          label="Capability Type Quantity"
          value={CapabilityTypeQuantity}
          onChange={onChange}
          type="number"
          min={0}
          max={1000000000}
          variant="outlined"
          size="small"
        />
      </div>
    );
  };

  const renderCapabilityDetail = () => {
    const onChange = (ev) => setCapabilityDetail(ev.target.value);
    return (
      <div className={classes.row}>
        <TextField
          className={classes.w100pr}
          label="Capability Detail"
          value={CapabilityDetails}
          onChange={onChange}
          multiline
          rows={4}
          variant="outlined"
          size="small"
        />
      </div>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={ptsResourceID ? 'Edit Resource' : 'Add Resource'}
      actions={renderActions()}>
      {type === 'Person' && renderPersonLookup()}
      {type === 'Vehicle' && renderVehicleLookup()}
      {type === 'Vehicle' && renderComponentName()}
      {renderBeginningTime()}
      {renderEndingTime()}
      {renderCapabilityType()}
      {renderCapabilityTypeQuantity()}
      {renderCapabilityDetail()}
    </Dialog>
  );
}

export default connect(null, {
  closeUnitResource,
  notifyDataUpdate,
  showSpinner,
  hideSpinner,
  handleError,
})(AddEditUnitResource);
