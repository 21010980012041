import React from 'react';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ModifiersFilter = (props) => {
  const { className, UnitActionModifiers, onChange, setSelectedModifier } = props;

  const handleFilterChange = (event, values) => {
    if (values) {
      setSelectedModifier && setSelectedModifier(values);
      onChange && onChange(event, values);
    }
  };

  return (
    <Autocomplete
      className={className}
      labelid="modifiers"
      multiple
      filterSelectedOptions={true}
      value={props.selectedModifiers}
      onChange={handleFilterChange}
      options={UnitActionModifiers}
      getOptionLabel={(option) => option.Code}
      getOptionSelected={(option, value) => {
        return option.Code === value.Code;
      }}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Modifiers" variant="outlined" size="small" />
      )}
      disabled={props.disabled}
    />
  );
};

const mapStateToProps = (state) => ({
  UnitActionModifiers: state.dictionary.UnitActionModifiers,
});

export default connect(mapStateToProps)(ModifiersFilter);
