import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import Query from './Query';
import { getAgenciesAccessPermission } from 'reducers/PermissionsReducer';
function QueriesSearch(props) {
  const canAccessAgencies = getAgenciesAccessPermission('globals', 'View NCIC Queries');
  if (!canAccessAgencies.length) return <Redirect to="/" />;

  return (
    <SearchMenu agencyList={canAccessAgencies}>
      <FilterConsumer>{(AgencyFilter) => <Query filter={AgencyFilter} />}</FilterConsumer>
    </SearchMenu>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
});

export default connect(mapStateToProps)(QueriesSearch);
