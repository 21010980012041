import React from 'react';
import PoliceIco from './PoliceIco';
import FiremanIco from './FiremanIco';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

const useStyles = makeStyles((theme) => ({
  unit: {
    padding: '10px 10px 5px',
    color: '#263055',
  },
  icon: {
    flex: '0 0 24px',
    height: 24,
    marginRight: 10,
  },
  header: {
    display: 'flex',
    marginBottom: 5,
    '& h5': {
      fontSize: '1rem',
      marginBottom: 0,
    },
    '& p': {
      fontWeight: 500,
      opacity: 0.7,
      marginBottom: 0,
      fontSize: '0.8rem',
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 10px',
      '& > span': {
        marginRight: 10,
      },
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      color: '#fff',
      fontSize: 12,
      fontStyle: 'normal',
    },
    '& svg': {
      color: '#535b7c',
      fontSize: 20,
    },
  },
  dateTime: {
    textAlign: 'right',
    fontWeight: 500,
    fontSize: '0.9rem',
    opacity: 0.75,
  },
}));

function UnitPanel(props) {
  const classes = useStyles();
  const { unit } = props;
  const { AgencyId, Mobile, Unit, UnitStatus } = unit;
  const { unitStatusColors } = props.config;
  const unitData = {};

  Object.entries(unit).forEach(([key, value]) => {
    switch (value) {
      case false:
        unitData[key] = 'NO';
        break;
      case true:
        unitData[key] = 'YES';
        break;
      case '':
        break;
      case null:
        break;
      default:
        unitData[key] = value;
    }
  });
  const Icon = AgencyId === 'POLICEDEP' ? PoliceIco : FiremanIco;
  const bgColor = unitStatusColors[UnitStatus].bgColor;

  return (
    <Card className={classes.unit} draggable>
      <div className={classes.status}>
        <div title="Unit">
          <div className={classes.icon} style={{ fill: bgColor }}>
            <Icon />
          </div>
          <span>{Unit}</span> {Mobile && <DriveEtaIcon />}
        </div>
        <i style={{ background: bgColor }}>{UnitStatus}</i>
      </div>
    </Card>
  );
}

export default UnitPanel;
