import store from '../config/configureStore';
import { getService } from './service';
import { handleError } from './ErrorReducer';
import { notify } from './NotifierReducer';
import { addEvent } from './DialogsReducer';
import { getWorkstationID, setSettingsVal } from './ConfigReducer';
import { parseStreet } from '../utils/mapFunctions';

const ADD_TO_911_QUEUE = 'ADD_TO_QUEUE';
const SET_911_STATE = 'SET_911_STATE';
const UPDATE_911_STATUS = 'UPDATE_911_STATUS';
export const CLEAR_911_STATUS = 'CLEAR_911_STATUS';

export const get911Log = () => {
  const service = getService('cade911log');
  return service.find({ query: { $limit: 1000 } });
};

export const do911Test = (workstationID, testNo) => {
  const service = getService();
  return service.create({ type: '911-test', data: { workstationID, testNo } });
};

export const getE911 = (id) => {
  const service = getService('cad-e911');
  return service.get(id);
};

let e911service;
let e911interval = 0;
export const subscribeE911 = () => {
  const state = store.store.getState();
  const authenticated = state.user.isAuthenticated;
  const client = state.websocket.websocket;
  return async (dispatch) => {
    if (!client || !authenticated) return console.log('Error, no client. Subscription failed.');
    try {
      e911service = client.service('cad-e911');
      e911service.on('created', (data) => {
        const state = store.store.getState();
        if (state.userSettings.enableE911)
          handleNew911Event({ ...data, action: 'created' }, dispatch);
      });
      e911service.on('updated', (data) => {
        const state = store.store.getState();
        if (state.userSettings.enableE911)
          handleNew911Event({ ...data, action: 'updated' }, dispatch);
      });
      e911service.on('error', () => console.log('Error listener'));
      e911service.on('unhandledRejection', (reason, p) => {
        console.log('EventReducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
    // Clean e911 data
    e911interval = setInterval(() => {
      cleanOutdated911Calls(dispatch);
    }, 1000 * 30);
  };
};

export const clearE911 = () => (dispatch) => {
  dispatch({ type: CLEAR_911_STATUS });
};

export const unsubscribeE911 = () => {
  clearInterval(e911interval);
  if (e911service) {
    try {
      e911service.off('created');
      e911service.off('error');
      e911service.off('unhandledRejection');
      e911service = false;
    } catch (error) {
      console.log('EventsReducer/unsubscribeEvents: error: ', error, error.code);
    }
  }
  return () => {};
};

const cleanOutdated911Calls = (dispatch) => {
  const currentTime = getTimestamp();
  const state = store.store.getState();
  const maxTime = state.userSettings.e911MaxTime * 1000 * 60;
  const addEvDialog = state.dialogs.addEvent;
  const addEvCallerNo = addEvDialog && addEvDialog.Event ? addEvDialog.Event.CallerPhone : null;
  const e911 = state.e911;
  const phonesToRemove = [];
  Object.entries(e911).forEach(([phoneNo, val]) => {
    if (currentTime - val.time > maxTime && phoneNo !== addEvCallerNo) {
      phonesToRemove.push(phoneNo);
    }
  });
  if (!phonesToRemove.length) return;
  const newE911State = { ...e911 };
  phonesToRemove.forEach((phoneNo) => {
    delete newE911State[phoneNo];
  });
  dispatch({ type: SET_911_STATE, payload: newE911State });
  const e911EntriesNo = Object.keys(newE911State).length;
  if (e911EntriesNo === 0) dispatch(setSettingsVal('show911', 1));
};

export default function reducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case ADD_TO_911_QUEUE:
      const data = action.data;
      const phoneNo = data.AreaCode + data.Telephone;
      newState = { ...state };
      const time = getTimestamp();
      if (state[phoneNo]) {
        newState[phoneNo].data.push({ ...data, time });
        newState[phoneNo].time = time;
      } else {
        newState[phoneNo] = {
          startTime: time,
          time,
          status: 'new',
          data: [{ ...data, time: getTimestamp() }],
        };
      }
      return newState;
    case UPDATE_911_STATUS:
      newState = { ...state };
      newState[action.phoneNo].status = action.status;
      return newState;
    case CLEAR_911_STATUS:
      return {};
    case SET_911_STATE:
      return action.payload;
    default:
      return state;
  }
}

function handleNew911Event(data, dispatch) {
  const state = store.store.getState();
  const workstationID = getWorkstationID();
  const phoneNo = (data.AreaCode ? data.AreaCode : '') + data.Telephone;
  if (state.userSettings.logE911Events) {
    const addEvDialog = state.dialogs.addEvent;
    const addEvCallerNo = addEvDialog && addEvDialog.Event ? addEvDialog.Event.CallerPhone : null;
    const isMatch = parseInt(workstationID) === parseInt(data.StationID);
    const inHistory = state.e911[phoneNo];
    const eventCallerNoMatch = addEvCallerNo !== null && addEvCallerNo === phoneNo;
    const enabled = Boolean(state.userSettings.enableE911);
    const shouldOpenNewEvent = isMatch && !inHistory && enabled && !addEvDialog;
    console.log(
      '911 ' +
        data.action +
        ', tel: ' +
        phoneNo +
        ' (StationID/workstationID) : (' +
        data.StationID +
        ', ' +
        workstationID +
        '), match: ' +
        (isMatch ? 'Yes' : 'No') +
        ', in history: ' +
        (inHistory ? 'Yes' : 'No') +
        ', enabled: ' +
        (enabled ? 'Yes' : 'No') +
        ', "Add Event" opened: ' +
        (addEvDialog ? 'Yes' : 'No') +
        ', Event caller phone match: ' +
        (eventCallerNoMatch ? 'Yes' : 'No') +
        ', should open popup: ' +
        (shouldOpenNewEvent ? 'Yes' : 'No')
    );
  }

  if (!workstationID || !data.StationID) return;
  if (parseInt(workstationID) !== parseInt(data.StationID)) return;
  const formattedData = {
    ...data,
    XCoordinate: parseFloat(data.XCoordinate) + '',
    YCoordinate: parseFloat(data.YCoordinate) + '',
  };
  dispatch({ type: ADD_TO_911_QUEUE, data: formattedData });
  const { e911 } = state;

  if (!e911[phoneNo]) {
    createEvent(formattedData, phoneNo, dispatch);
  }
}

function getAddressFromData(data) {
  if (!data) return;
  const {
    AddressNumber,
    AddressPostDirectional,
    AddressPreDirectional,
    AddressStreet,
    AddressUnitNumber,
    Community,
    State,
    // BldgIdentifier,  ContactNotes, FireAgency, FirstName, FloorIdentifier, LastName, Notes,  StationID, AddressSuffix,
  } = data;
  let address = '';
  if (AddressNumber) address += AddressNumber + ' ';
  if (AddressPreDirectional) address += AddressPreDirectional + ' ';
  if (AddressStreet) address += AddressStreet + ' ';
  if (AddressPostDirectional) address += AddressPostDirectional + ' ';
  if (AddressUnitNumber) address += 'UNIT ' + AddressUnitNumber + ' ';
  if (Community) address += Community + ' ';
  if (State) address += State + ' ';
  return address.trim().replace(/\s+/g, ' ');
}

function createEvent(rawData, phoneNo, dispatch) {
  const state = store.store.getState();
  if (!state.dialogs.addEvent) {
    const data = format911Data(rawData, dispatch);
    dispatch(addEvent(data));
    dispatch({ type: UPDATE_911_STATUS, phoneNo, status: 'processed' });
  } else {
    dispatch(notify('Add Event window is already open.', 'warning'));
  }
}

export function format911Data(rawData) {
  const state = store.store.getState();
  const CallMethod = validateCallMethod(rawData.CallMethod) ? rawData.CallMethod : null;
  const {
    AddressNumber,
    AddressPostDirectional,
    AddressPreDirectional,
    AddressStreet,
    Community,
    State,
    ContactNotes,
    FirstName,
    FloorIdentifier,
    LastName,
    Notes,
    AreaCode,
    Telephone,
    AddressUnitNumber,
    // XCoordinate, YCoordinate,
  } = rawData;

  const PreDirection = AddressPreDirectional ? AddressPreDirectional.trim() : null;
  const PostDirection = AddressPostDirectional ? AddressPostDirectional.trim() : null;
  // const lat = XCoordinate ? XCoordinate : null;
  // const lng = YCoordinate ? YCoordinate : null;
  const UnitIdentifier = AddressUnitNumber ? AddressUnitNumber.trim() : null;

  const { Cities } = state.dictionary;
  let ptsCityID;
  const City = Cities.find((c) => c.Code === Community);
  if (City) {
    ptsCityID = City.ptsCityID;
  }
  const Info = `(${AreaCode}) ${Telephone.substr(0, 3)}-${Telephone.substr(3)}`;
  const { StreetName, StreetType } = parseStreet(AddressStreet);
  const phoneNo = rawData.AreaCode + rawData.Telephone;
  const data = {
    Event: {
      CallType: null,
      CallMethod,
      RequestedAction: null,
      Description: null,
      lat: null,
      lng: null,
      CallerPhone: phoneNo,
    },
    Locations: [
      {
        AddressNumber,
        PreDirection,
        StreetName,
        StreetType,
        PostDirection,
        ptsCityID: 1,
        State,
        PostalCode: null,
        IsPrimary: true,
        ptsAddressID: null,
        ptsPlaceID: null,
        FloorIdentifier,
        UnitIdentifier,
      },
    ],
    Callers: [
      {
        CallerLocation: getAddressFromData(rawData),
        FirstName,
        LastName,
        Info,
      },
    ],
    Notes: [],
    type: '911',
  };
  if (Notes) data.Notes.push({ Comment: Notes });
  if (ContactNotes) data.Notes.push({ Comment: 'Contact Notes: ' + ContactNotes });
  if (FloorIdentifier) data.Notes.push({ Comment: 'Floor Identifier: ' + FloorIdentifier });
  data.Notes.push({ Comment: '' });
  return data;
}

function validateCallMethod(CallMethod) {
  const state = store.store.getState();
  const { CallMethods = [] } = state.dictionary;
  const exists = !!CallMethods.find((c) => c.Code === CallMethod);
  return exists;
}

function getTimestamp() {
  return new Date().getTime();
}
