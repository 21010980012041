import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//Components
import Dialog from '../../components/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import FilterGroup from './FilterGroup';
import AddIcon from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SOPActions from './SOPActions';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autopages from './Autopages';
import { makeStyles } from '@material-ui/core/styles';
//Services
import { getFormStyle } from '../../utils/functions';
//Reducers
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';

import { saveSop } from '../../reducers/SopsReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { getSops, getSop, decodeDispatcherQuestions } from '../../reducers/SopsReducer';
import { closeEditSop } from '../../reducers/DialogsReducer';
import { notify } from '../../reducers/NotifierReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { savePeopleSOP } from 'reducers/PersonReducer';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  dialogContent: {
    //width: 1000,
    maxWidth: '100%',
    minHeight: 500,
  },
  content: {
    margin: theme.spacing(2),
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  trigger: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '1em 0',
  },
}));

function AddSOP(props) {
  const classes = useStyles();
  const { dictionary, data } = props;
  const [title, setTitle] = useState('');
  const [groups, setGroups] = useState([]);
  const [valid, setValid] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [dispatcherMessage, setDispatcherMessage] = useState('');
  const [actions, setActions] = useState([]);
  const [tab, setTab] = useState('sop');
  const [ptsSOPID, setPtsSOPID] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data !== true && data.hasOwnProperty('ptsSOPID')) {
      setPtsSOPID(data.ptsSOPID);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (ptsSOPID) loadSop(ptsSOPID);
    // eslint-disable-next-line
  }, [ptsSOPID]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [groups, title, showNotification, dispatcherMessage, actions]);

  const close = () => {
    props.closeEditSop();
  };

  const save = () => {
    setLoading(true);
    const sopData = {
      ptsSOPID: ptsSOPID || true,
      SOPID: title,
      TriggerOptions: groups,
      ShowToast: showNotification,
      DispatcherMessage: dispatcherMessage,
      DispatcherQuestions: actions,
    };
    props.showSpinner();
    saveSop(sopData)
      .then(async (response) => {
        const { ptsSOPID } = response;
        setPtsSOPID(ptsSOPID);
        if (data !== true && data.hasOwnProperty('IntCode')) {
          /* adding sops to zone */
          await savePeopleSOP({
            ptsParentID: data.IntCode,
            ParentType: 'Zone',
            ptsSOPID: ptsSOPID,
          });
        }
      })
      .then(() => props.notifyDataUpdate({ type: 'update-sop', data: { ptsSOPID } }))
      .catch((err) => props.handleError(err, 'Error, SOP not saved.'))
      .finally(() => props.hideSpinner());
    setLoading(false);
  };

  const loadSop = (ptsSOPID) => {
    props.showSpinner();
    getSop(ptsSOPID)
      .then(parseData)
      .catch((err) => props.handleError(err, 'Error, SOP not loaded.'))
      .finally(() => props.hideSpinner());
  };

  const parseData = (rawData) => {
    if (!rawData.Source) {
      props.notify(
        'Cannot edit this SOP as it was created with previous version of CAD.',
        'warning'
      );
      return props.closeEditSop();
    }
    const source = JSON.parse(rawData.Source);
    setTitle(rawData.SOPID);
    setGroups(source);
    setDispatcherMessage(rawData.DispatcherMessage);
    setShowNotification(rawData.ShowToast);
    setActions(decodeDispatcherQuestions(rawData.DispatcherQuestions));
  };

  const updateGroup = (no) => (group) => {
    const newGroups = [...groups];
    newGroups[no] = group;
    setGroups(newGroups);
  };

  const addGroup = () => {
    const newGroups = [...groups];
    const operator = groups.length > 0 ? groups[0].operator : 'And';
    newGroups.push({ operator, filters: [], logicOperator: 'And' });
    setGroups(newGroups);
  };

  const removeGroup = (no) => () => {
    const newGroups = [...groups];
    newGroups.splice(no, 1);
    setGroups(newGroups);
  };

  const updateGroupOperator = (operator) => {
    const newGroups = groups.map((data) => {
      return { ...data, operator };
    });
    setGroups(newGroups);
  };

  const validate = () => {
    let valid = true;
    if (!title) valid = false;
    groups.forEach((group) => {
      if (group.filters.length === 0) valid = false;
      group.filters.forEach((filter) => {
        const val = filter.actionValue;
        if (!val || val.length === 0) valid = false;
        if (Array.isArray(val)) {
          val.forEach((v) => {
            if (v === '') valid = false;
          });
        }
      });
    });
    if (showNotification && !dispatcherMessage) valid = false;
    actions.forEach((action) => {
      if (!action.text) valid = false;
    });
    setValid(valid);
  };

  const renderActions = () => {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={save}
          disabled={!valid || loading}>
          <SaveIcon /> Save
        </Button>
        <Button onClick={close} color="primary" size="small">
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const handleTitleChange = (ev) => {
    const targetValue = ev.target.value;
    if (targetValue.length > 25) return;
    setTitle(targetValue);
  };

  const renderTitleForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={classes.w100pr}
          label="Title"
          value={title}
          onChange={handleTitleChange}
          variant="outlined"
          size="small"
        />
      </div>
    );
  };

  const renderTriggerOptions = () => {
    return (
      <>
        <h5 className={classes.title}>Trigger options</h5>
        <div className={classes.triggers}>
          {groups.map((group, no) => (
            <FilterGroup
              key={no}
              no={no}
              data={group}
              removeGroup={removeGroup(no)}
              updateGroup={updateGroup(no)}
              dictionary={dictionary}
              updateGroupOperator={updateGroupOperator}
            />
          ))}
        </div>
        <Button onClick={addGroup}>
          <AddIcon /> Add Filter Group
        </Button>
      </>
    );
  };

  const renderNotificationOptions = () => {
    return (
      <>
        <h5 className={classes.title}>Notification Text</h5>
        <FormControlLabel
          size="small"
          control={
            <Checkbox
              checked={showNotification}
              onChange={(ev) => setShowNotification(ev.target.checked)}
              color="primary"
            />
          }
          label="Shop Popup Notification"
        />
        <div className={classes.form}>
          <TextField
            label="Dispatcher Message"
            variant="outlined"
            value={dispatcherMessage}
            onChange={(ev) => setDispatcherMessage(ev.target.value)}
            error={showNotification && !dispatcherMessage}
            disabled={!showNotification}
            className={classes.w100pr}
            size="small"
          />
        </div>
      </>
    );
  };
  const renderSOPForm = () => {
    return (
      <div className={classes.content}>
        {renderTitleForm()}
        <hr />
        {renderTriggerOptions()}
        <hr />
        {renderNotificationOptions()}
        <hr />
        <SOPActions actions={actions} setActions={setActions} />
      </div>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={ptsSOPID ? 'Edit SOP' : 'Add SOP'}
      actions={renderActions()}
      fullScreen
      toolbar>
      <div className={classes.dialogContent}>
        <Paper square>
          <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="SOP" value="sop" />
            <Tab disabled={!ptsSOPID} label="Autopage" value="autopage" />
          </Tabs>
        </Paper>
        {tab === 'sop' && renderSOPForm()}
        {tab === 'autopage' && <Autopages ptsSOPID={ptsSOPID} />}
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  getSops,
  closeEditSop,
  notify,
  notifyDataUpdate,
})(AddSOP);
