import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from 'components/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  newCaseID,
  getEventLocatins,
  removeEventLocation,
  saveLocation,
} from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import LocationForm from 'Dialogs/AddEvent/LocationForm';
import { locationEmptyData } from 'Dialogs/AddEvent/eventEmptyData';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  row: {
    padding: '5px 15px 5px',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& p': {
      fontSize: 13,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  rowActions: {
    textAlign: 'right',
    opacity: 0,
    transition: 'opacity 0.3s',
    '& label': {
      marginBottom: 0,
    },
  },
  loading: {
    textAlign: 'center',
  },
}));

function LocationsTab(props) {
  const classes = useStyles();
  const { sortOption, dictionary, ptsEventID, disableEdit } = props;
  const [locations, setLocations] = useState([]);
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const newLocationData = useRef({ ...locationEmptyData });

  const perms = getFullPermissions('cad', 'Events', 'any');
  const { Create, Edit, Delete } = perms;

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line
  }, []);

  const getLocations = async () => {
    setLoading(true);
    try {
      const locations = await getEventLocatins(ptsEventID);
      setLocations(sortObjArr(locations, 'FullAddressText', sortOption));
    } catch (err) {
      props.handleError(err, 'Error, cannot get event locations');
    }
    setLoading(false);
  };

  const onDiscard = () => {
    setEdited(false);
  };

  const onAddLocation = () => {
    setEdited(true);
  };

  const onEditLocation = (location) => {
    setEdited(location);
  };

  const onSave = async () => {
    props.showSpinner();
    try {
      await saveLocation(newLocationData.current, ptsEventID, dictionary);
      setEdited(null);
      await getLocations();
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const addPlace = (place) => {
    setEdited({
      ...place,
      IsPrimary: newLocationData.current.IsPrimary,
    });
  };

  const updateNewLocation = (data) => {
    const { ptsAddressID, AddressNumber, StreetName, ptsCityID } = data;
    newLocationData.current = data;
    let valid = false;
    if (ptsAddressID) valid = true;
    if (AddressNumber && StreetName && ptsCityID) valid = true;
    if (valid && !formValid) setFormValid(true);
    if (!valid && formValid) setFormValid(false);
  };

  const renderAddLocation = () => {
    return (
      <LocationForm
        dictionary={dictionary}
        data={edited}
        title={edited.ptsLocationID ? 'Edit Location' : 'New Location'}
        addPlace={addPlace}
        updateData={updateNewLocation}
        editingForm={edited.ptsLocationID ? true : false}
      />
    );
  };

  const remove = async (location) => {
    if (!window.confirm('Are you sure you want to remove this location?')) return;
    try {
      await removeEventLocation(location.ptsLocationAddressID);
      await getLocations();
    } catch (err) {
      props.handleError(err, 'Error removing the location');
    }
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!edited && (
          <Tooltip title="Add dispositions">
            <span>
              <IconButton color="primary" size="small" onClick={onAddLocation} disabled={!Edit}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {edited && formValid && (
          <Tooltip title="Save changes">
            <IconButton color="primary" size="small" onClick={onSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}
        {edited && !formValid && (
          <IconButton color="primary" size="small" disabled>
            <SaveIcon />
          </IconButton>
        )}

        {edited && (
          <Tooltip title="Discard changes">
            <IconButton color="primary" size="small" onClick={onDiscard}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderLocations = () => {
    return (
      <>
        {locations.map((location) => (
          <div key={location.ptsLocationAddressID} className={classes.row}>
            <div className={classes.header}>
              <div className={classes.left}>
                <h4>
                  {location.FullAddressText} {location.IsPrimary && <strong>(primary)</strong>}
                </h4>
              </div>
              {!disableEdit && (
                <div className={classes.rowActions}>
                  <Tooltip title="Edit location">
                    <span>
                      <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        disabled={!Edit}
                        onClick={() => onEditLocation(location)}>
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete location">
                    <span>
                      <IconButton
                        color="primary"
                        size="small"
                        component="span"
                        disabled={!Delete || location.IsPrimary}
                        onClick={() => remove(location)}>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={classes.wrap}>
      <PerfectScrollbar>
        {!disableEdit && renderActions()}
        {!disableEdit && edited && renderAddLocation()}
        {!edited && renderLocations()}
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </PerfectScrollbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  newCaseID,
  handleError,
  notify,
  showSpinner,
  hideSpinner,
})(LocationsTab);
