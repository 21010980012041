const data = [
  {
    EventId: 'EVT00000000018',
    EventType: 'BURG',
    Status: 'Completed',
    RoutingRecommendation: ['POLICEDEP'],
    SOPs: 0,
    RespondingLocation: '100 North Blvd Baton Rouge, LA 70801',
    Created: '6/30/2020 5:40:10 PM',
    CreatedBy: 'PTS',
    Updated: '7/14/2020 1:20:54 PM',
    UpdatedBy: 'MMCNEIL',
    units: [
      {
        Mobile: true,
        Unit: 'SALSA',
        Names: 'Mike McNeil',
        UnitStatus: 'ARRIVED',
        OccurredData: '7/16/2020',
        OccurredTime: '4:37:26 AM',
        AgencyId: 'POLICEDEP',
      },
      {
        Mobile: true,
        Unit: '4711',
        Names: 'David Chamberlain',
        UnitStatus: 'ARRIVED',
        OccurredData: '7/16/2020',
        OccurredTime: '4:32:44 AM',
        AgencyId: 'POLICEDEP',
      },
    ],
  },
  {
    EventId: 'EVT00000000020',
    EventType: 'ROBBERY-A',
    Status: 'Enroute',
    RoutingRecommendation: ['FIREDEP', 'POLICEDEP'],
    SOPs: 0,
    RespondingLocation: '100 North Blvd Baton Rouge, LA 70801',
    Created: '7/9/2020 9:35:09 PM',
    CreatedBy: 'MMCNEIL',
    Updated: '7/16/2020 4:38:33 PM',
    UpdatedBy: 'PTS',
    units: [
      {
        Mobile: true,
        Unit: 'SALSA',
        Names: 'Mike McNeil',
        UnitStatus: 'ARRIVED',
        OccurredData: '7/14/2020',
        OccurredTime: '5:37:26 AM',
        AgencyId: 'POLICEDEP',
      },
      {
        Mobile: true,
        Unit: '4711',
        Names: 'David Chamberlain',
        UnitStatus: 'ENROUTE',
        OccurredData: '7/14/2020',
        OccurredTime: '5:32:44 AM',
        AgencyId: 'FIREDEP',
      },
    ],
  },
  {
    EventId: 'EVT00000000024',
    EventType: 'SHOTSFIRED',
    Status: 'Arrived',
    RoutingRecommendation: ['POLICEDEP'],
    SOPs: 0,
    RespondingLocation: '8011 Merle Gustafson Dr Baton Rouge, LA 70807',
    Created: '6/30/2020 5:41:07 PM',
    CreatedBy: 'PTS',
    Updated: '7/16/2020 4:38:33 PM',
    UpdatedBy: 'GRZEGORZ',
    units: [
      {
        Mobile: true,
        Unit: 'CHA-CHA',
        Names: 'Greg Sarzynski',
        UnitStatus: 'ENROUTE',
        OccurredData: '7/16/2020',
        OccurredTime: '6:32:44 AM',
        AgencyId: 'POLICEDEP',
      },
    ],
  },
  {
    EventId: 'EVT00000000025',
    EventType: 'SUSP-P',
    Status: 'Dispatched',
    RoutingRecommendation: ['POLICEDEP'],
    SOPs: 0,
    RespondingLocation: '23 Jackson Street ST Baton Rouge, AL 23456-5743',
    Created: '7/15/2020 8:11:03 PM',
    CreatedBy: 'GRZEGORZ',
    Updated: '7/16/2020 4:38:40 PM',
    UpdatedBy: 'GRZEGORZ',
    units: [
      {
        Mobile: true,
        Unit: 'MAMBO',
        Names: 'Chris Rutherford',
        UnitStatus: 'DISPATCH',
        OccurredData: '7/16/2020',
        OccurredTime: '4:55:24 AM',
        AgencyId: 'POLICEDEP',
        Location: null,
        Modifiers: null,
        Destination: null,
        TripNumber: null,
      },
    ],
  },
];

export default data;
