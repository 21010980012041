import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { GrMap } from 'react-icons/gr';
import { GrMapLocation } from 'react-icons/gr';
import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { getAddressDetails } from 'reducers/AddressReducer';
import { addCoordsToLocation } from 'utils/mapFunctions';
import { IconButton } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import { showAddressDialog } from 'reducers/DialogsReducer';
import { findBulletinsData, findBulletins } from 'reducers/BulletinReducer';
import Tooltip from 'components/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0,
};

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
      opacity: 0.2,
      transition: 'all 0.1s',
    },
    '&:hover, & .Mui-focused': {
      '& $bulletinInfo': {
        opacity: 1,
      },
      '& .MuiAutocomplete-clearIndicator': {
        opacity: 1,
      },
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  bulletinInfo: {
    position: 'absolute',
    right: 60,
    top: 5,
    opacity: 0.2,
    transition: 'all 0.1s',
  },
  iconButton: {
    color: theme.palette.error.main,
  },
}));

export const getPlace = (ptsPlaceID) => {
  const service = getService('cad');
  return service.get({ type: 'get-place', data: { ptsPlaceID } });
};

function AddressLookup(props) {
  const {
    wsClient,
    ptsPlaces,
    ptsPlaceID,
    ptsAddresses,
    map,
    onReset,
    setPlace,
    label = 'search',
    error,
    compact,
    dataUpdate,
    dialogs,
    inputRef,
    ptsAddressID,
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]); // list of search results
  const [extAddressUpdated, setExtAddressUpdated] = useState(false);
  const [bulletinExists, setBulletinExists] = useState(false);
  const [address, setAddress] = useState(null);
  const activeRef = useRef(false);
  const throttleRef2 = useRef(0);
  const addEnabled = dialogs.address === null;

  useEffect(async () => {
    if (!ptsAddressID) return;
    const address = await getAddress(ptsAddressID);
    if (!address) return;
    handlePlaceSet(address);
    // eslint-disable-next-line
  }, [ptsAddressID]);

  useEffect(() => {
    clearTimeout(throttleRef2.current);
    throttleRef2.current = setTimeout(() => {
      if (inputValue.length > 2) {
        search();
      } else {
        setAutocompleteOptions([]);
      }
    }, 500);
    // eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Address') return;
    handlePlaceSet(dataUpdate.data);
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    handlePlaceSet();
    // eslint-disable-next-line
  }, [setPlace]);

  useEffect(() => {
    if (!ptsPlaceID) return;
    setPlaceByID(ptsPlaceID);
    // eslint-disable-next-line
  }, [ptsPlaceID]);

  useEffect(() => {
    activeRef.current = true;
    if (inputValue === '') return setAutocompleteOptions(value ? [value] : []);
    return () => {
      activeRef.current = false;
    };
    // eslint-disable-next-line
  }, [value, inputValue, wsClient.websocket, ptsAddresses, map]);

  const search = () => {
    if (!inputValue) {
      setAutocompleteOptions([]);
      return;
    }
    findAddress(inputValue);
  };

  const setPlaceByID = async (ptsPlaceID) => {
    try {
      const place = await getPlace(ptsPlaceID);
      handlePlaceSet(place);
    } catch (err) {
      props.handleError(err);
    }
  };

  const findAddress = async (searchTerm) => {
    if (ptsAddresses && ptsPlaces && extAddressUpdated) return setExtAddressUpdated(false);
    try {
      const service = getService('address-lookup');
      let addresses = await service.find({ query: { FullAddressText: searchTerm } });
      if (addresses[0].length > 0) {
        setAutocompleteOptions(addresses[0]);
      }
    } catch (error) {
      props.handleError(error);
    }
  };

  // If place set by parent component
  const handlePlaceSet = (place = setPlace) => {
    if (!place) {
      if (value || inputValue) {
        setAutocompleteOptions([]);
        setValue(null);
        setExtAddressUpdated(true);
      }
      return;
    } else if (!place.ptsAddressID) return;
    const autocompleteOptions = [{ ...place }];
    setAutocompleteOptions(autocompleteOptions);
    setValue(autocompleteOptions[0]);
    setExtAddressUpdated(true);
    if (place !== setPlace) {
      props.onAddressValueSet(place);
    }
  };

  const getOptionLabel = (option) => {
    if (option.FullAddressText) {
      if (option.PlaceName) {
        return `${option.PlaceName} - ${option.FullAddressText}`;
      } else {
        return option.FullAddressText;
      }
    }
  };

  const onAddressSelect = async (ev, newValue) => {
    setAutocompleteOptions(newValue ? [newValue, ...autocompleteOptions] : autocompleteOptions);
    setValue(newValue);
    setBulletinExists(false);
    if (!newValue) return onReset && onReset();
    const addressDetails = await getAddress(newValue.ptsAddressID);
    const address = { ...newValue, ...addressDetails };
    findAddressBulletins(address);
    setAddress(address);
    if (address.ptsPlaceID !== null) {
      props.onPlaceValueSet && props.onPlaceValueSet(address);
    } else {
      props.onAddressValueSet && props.onAddressValueSet(address);
    }
  };

  const findAddressBulletins = async (address) => {
    if (!address) return;
    try {
      const bulletins = await findBulletinsData(address);
      setBulletinExists(Boolean(bulletins.length));
    } catch (err) {
      props.handleError(err);
    }
  };

  const renderBulletinInfo = () => {
    const onClick = () => {
      props.findBulletins(address);
    };

    return (
      <div className={classes.bulletinInfo}>
        <Tooltip title="Bulletin">
          <span>
            <IconButton
              color="secondary"
              size="small"
              onClick={onClick}
              className={classes.iconButton}>
              <ErrorIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  const getAddress = async (ptsAddressID) => {
    let address = null;
    try {
      const result = await getAddressDetails(ptsAddressID);
      if (!result?.data?.length) return null;
      address = result.data[0];
    } catch (err) {
      props.handleError(err);
    }
    try {
      const addressWithCoords = await addCoordsToLocation(address);
      if (addressWithCoords) address = addressWithCoords;
    } catch (err) {
      console.log('Error attaching coordinates to an address');
    }
    return address;
  };

  const onInputChange = (ev, newInputValue) => {
    setInputValue(newInputValue);
  };

  const renderOption = (option) => {
    if (option.PlaceName === null) {
      return (
        <Grid container alignItems="center">
          <Grid item>
            <GrMap className={classes.icon} style={{ color: 'green', fontSize: '24px' }} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 400 }}>{option.FullAddressText}</span>
            <Typography variant="body2" color="textSecondary">
              {option.FullName}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container alignItems="center">
          <Grid item>
            <GrMapLocation className={classes.icon} style={{ color: 'red', fontSize: '24px' }} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 400 }}>{option.FullAddressText}</span>
            <Typography variant="body2" color="textSecondary">
              {option.PlaceName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {option.FullName}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const getOptionSelected = (option, value) => {
    return (
      option.ptsLocationAddressID === value.ptsLocationAddressID ||
      option.ptsAddressID === value.ptsAddressID
    );
  };

  const onAdd = () => {
    props.showAddressDialog();
  };

  const helperText = compact ? undefined : typeof error === 'string' ? error : ' ';
  const startAdornment = addEnabled ? (
    <IconButton style={btnStyle} onClick={onAdd}>
      <AddIcon fontSize="small" />
    </IconButton>
  ) : undefined;

  return (
    <div className={clsx(classes.wrap, props.className)}>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        options={autocompleteOptions}
        autoComplete
        includeInputInList
        filterSelectedOptions
        className={classes.autocomplete}
        style={props.style}
        value={value}
        onChange={onAddressSelect}
        onInputChange={onInputChange}
        renderOption={renderOption}
        getOptionSelected={getOptionSelected}
        filterOptions={(option) => option}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              error={!!error}
              helperText={helperText}
              label={label}
              variant="outlined"
              size="small"
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                autoComplete: 'new-password',
                startAdornment,
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
            {bulletinExists && renderBulletinInfo()}
          </>
        )}
      />
    </div>
  );
}

AddressLookup.defaultProps = {
  ptsPlaces: false,
  ptsAddresses: true,
  googleAddresses: false,
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  map: state.map.map,
  dictionary: state.dictionary,
  dataUpdate: state.dataUpdate,
  dialogs: state.dialogs,
});

export default connect(mapStateToProps, {
  handleError,
  showAddressDialog,
  findBulletins,
})(AddressLookup);
