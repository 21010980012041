import { getService, joinChannel, leaveChannel } from './service';
import { handleError } from './ErrorReducer';
import store from 'config/configureStore';
import {
  saveEventsToStorage,
  getEventsFromStorage,
  updateEventsData,
  removeEventsFromStorage,
} from './EventsReducer';
import {
  saveUnitsToStorage,
  getUnitsFromStorage,
  updateUnitsData,
  removeUnitsFromStorage,
} from './UnitsReducer';

export const GET_SETTINGS = 'CONFIG/GET_SETTINGS';
export const CLEAR_SETTINGS = 'CONFIG/CLEAR_SETTINGS';
export const SET_TIME_DIFF = 'SET_TIME_DIFF';
export const SET_SETTINGS_VAL = 'SET_SETTINGS_VAL';

const multipleScreens = process.env.REACT_APP_MULTIPLE_SCREENS === 'true';

// ================  ACTIONS  ======================

export const getSettings = () => {
  return async (dispatch) => {
    try {
      const service = getService();
      const settings = await service.find({ query: { type: 'settings' } });
      dispatch({ type: GET_SETTINGS, settings });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const clearSettings = () => (dispatch) => {
  dispatch({ type: CLEAR_SETTINGS });
};

export const setSettingsVal = (key, value) => (dispatch) => {
  dispatch({ type: SET_SETTINGS_VAL, key, value });
};

export const saveSettings = (type, data) => {
  const service = getService();
  return service.patch(type, { type: 'settings', data });
};

export const setMasterMode = () => {
  window.localStorage.setItem('masterMode', 'true');
};

export const removeMasterMode = () => {
  window.localStorage.removeItem('masterMode');
};

export const isMasterMode = () => {
  return Boolean(window.localStorage.getItem('masterMode'));
};

/** Check if master instance really exists if not clean the data and reload */
const pingToMaster = () => {
  const pingNo = '' + Math.floor(Math.random() * 9999999999);
  let restartTimeout = 0;
  const pingMasterSubscriber = (ev) => {
    const { key, newValue } = ev;
    if (key !== 'ping-master') return;
    if (!newValue) {
      // Got response from primary instance
      clearTimeout(restartTimeout);
      window.removeEventListener('storage', pingMasterSubscriber);
    }
  };
  window.addEventListener('storage', pingMasterSubscriber);
  window.localStorage.setItem('ping-master', pingNo);
  restartTimeout = setTimeout(() => {
    // No primary instance found inspite of data present - clean + restart
    removeMasterMode();
    removeUnitsFromStorage();
    removeEventsFromStorage();
    window.location.reload();
  }, 1000);
};

export const checkStorageData = () => async (dispatch) => {
  const changeModeSubscriber = (ev) => {
    const state = store.store.getState();
    const { mode } = state.config;
    const { key, newValue } = ev;
    if (key === 'ping-master' && mode === 'master') {
      if (newValue) window.localStorage.removeItem('ping-master');
    }
    if (key !== 'masterMode') return;
    if (!isMasterMode()) {
      if (mode !== 'standalone') tryMaster(dispatch);
    }
  };

  if (isMasterMode()) {
    pingToMaster();
    // Start secondary mode
    dispatch(setSettingsVal('eventDataPresent', true));
    dispatch(setSettingsVal('unitDataPresent', true));
    dispatch(changeAppMode('slave'));
    try {
      await leaveChannel('events');
      await leaveChannel('units');
    } catch (err) {
      dispatch(handleError(err));
    }
  } else {
    if (multipleScreens) {
      // Start primary mode
      setMasterMode();
      dispatch(changeAppMode('master'));
    } else {
      dispatch(changeAppMode('standalone'));
    }
  }
  window.addEventListener('storage', changeModeSubscriber);
};

export const clearAppModeData = () => (dispatch) => {
  removeEventsFromStorage();
  removeUnitsFromStorage();
  removeMasterMode();
  dispatch(setSettingsVal('eventDataPresent', false));
  dispatch(setSettingsVal('unitDataPresent', false));
  const state = store.store.getState();
  const { mode } = state.config;
  if (mode === 'slave') dispatch(changeAppMode('standalone'));
};

export const changeAppMode = (newMode) => async (dispatch) => {
  const removeDataFromStorage = () => {
    removeEventsFromStorage();
    removeUnitsFromStorage();
    removeMasterMode();
  };
  const waitForEvents = () => {
    setTimeout(async () => {
      const events = await getEventsFromStorage();
      if (events) {
        updateEventsData(events, dispatch);
        await leaveChannel('events');
      } else {
        waitForEvents();
      }
    }, 100);
  };
  const waitForUnits = () => {
    setTimeout(async () => {
      const units = await getUnitsFromStorage();
      if (units) {
        updateUnitsData(units, dispatch);
        await leaveChannel('units');
      } else {
        waitForUnits();
      }
    }, 100);
  };
  const state = store.store.getState();
  const prevMode = state.config.mode;
  if (prevMode === 'master') {
    removeEventsFromStorage();
    removeUnitsFromStorage();
    removeMasterMode();
    window.removeEventListener('beforeunload', removeDataFromStorage);
    dispatch(setSettingsVal('eventDataPresent', false));
    dispatch(setSettingsVal('unitDataPresent', false));
  }
  if (prevMode === 'slave') {
    try {
      await joinChannel('events');
      await joinChannel('units');
    } catch (err) {
      dispatch(handleError(err));
    }
  }
  let error = null;
  if (newMode === 'standalone') {
  } else if (newMode === 'master') {
    const { events, units } = state;
    saveEventsToStorage(events);
    saveUnitsToStorage(units);
    setMasterMode();
    window.addEventListener('beforeunload', removeDataFromStorage);
    dispatch(setSettingsVal('eventDataPresent', true));
    dispatch(setSettingsVal('unitDataPresent', true));
  } else if (newMode === 'slave') {
    const events = await getEventsFromStorage();
    const units = await getUnitsFromStorage();
    if (events) {
      updateEventsData(events, dispatch);
      await leaveChannel('events');
    } else {
      waitForEvents();
    }
    if (units) {
      updateUnitsData(units, dispatch);
      await leaveChannel('units');
    } else {
      waitForUnits();
    }
  }
  if (error) {
  } else {
    dispatch({ type: SET_SETTINGS_VAL, key: 'mode', value: newMode });
  }
};

let testMasterTimeout = 0;
export const tryMaster = (dispatch) => {
  if (isMasterMode()) return;
  setMasterMode();
  clearTimeout(testMasterTimeout);
  const no = '' + Math.floor(Math.random() * 9999999999999999);
  window.localStorage.setItem('masterNo', no);
  testMasterTimeout = setTimeout(() => {
    const savedNo = window.localStorage.getItem('masterNo');
    if (savedNo === no) {
      dispatch(changeAppMode('master'));
      window.localStorage.removeItem('masterNo');
    }
  }, 100);
};

const testingLevel = parseInt(process.env.REACT_APP_TESTING_LEVEL) || 0;

// ==================  REDUCERS  ===============

// show911 :
// 0 - reject calls on this screen (grey)
// 1 - show - accepts all calls (blue)
// 2 - accept calls - ommit recent callers (red)

const defaultConfig = {
  loaded: false,
  options: {},
  colorPalette: {
    events: {},
    units: {},
  },
  timeDiff: 0,
  testingLevel,
  show911: 1,
  mode: 'standalone', // standalone, master, slave
  eventDataPresent: false, // is event data present in local storage
  unitDataPresent: false, // is event data present in local storage
};

export default function reducer(state = defaultConfig, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state, ...action.settings, loaded: true };
    case CLEAR_SETTINGS:
      return { ...defaultConfig };
    case SET_TIME_DIFF:
      return { ...state, timeDiff: action.timeDiff };
    case SET_SETTINGS_VAL:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
}

export function getWorkstationID() {
  const settings = getLocalSettings();
  return settings.workstationID;
}

export function saveWorkstationID(workstationID) {
  const settings = getLocalSettings();
  settings.workstationID = workstationID;
  saveLocalSettings(settings);
}

export function createWorkstationID() {
  return String(Math.random()).substr(2, 10);
}

export const defaultLocalSettings = {
  workstationID: '',
  mapLat: 38.8937091,
  mapLng: -77.0846157,
  mapZoom: 14,
  mapEventOpen: false,
  mapEventWidth: 580,
  mapUnitOpen: false,
  mapUnitWidth: 580,
  changePassReminder: {},
};

export function getLocalSettings(setting) {
  const savedSettings = localStorage.getItem('localSettings');
  let settings;
  if (savedSettings) {
    const parsedSettings = JSON.parse(savedSettings);
    settings = { ...defaultLocalSettings, ...parsedSettings };
  } else {
    const newSettings = { ...defaultLocalSettings };
    newSettings.workstationID = createWorkstationID();
    localStorage.setItem('localSettings', JSON.stringify(newSettings));
    settings = newSettings;
  }
  if (setting) return settings[setting];
  return settings;
}

export function saveLocalSettings(settings) {
  const currentSettings = getLocalSettings();
  const newSettings = { ...currentSettings, ...settings };
  localStorage.setItem('localSettings', JSON.stringify(newSettings));
}
