import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { displayDateTime, getTimeFromDate } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import { searchPhoneNumbers } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import { showEditPerson, showEditPlace, editEvent } from 'reducers/DialogsReducer';
import { notify } from 'reducers/NotifierReducer';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
import TextField2 from 'components/TextField2';
import SearchDatePicker from '../components/SearchDatePicker2';
import SearchTimePicker from 'Search/components/SearchTimePicker';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'PartyType',
  'ParentName',
  'ParentID',
  'ContactMethod',
  'MethodType',
  'PhoneNumber',
  {
    field: 'IsDefault',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Created',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'CreatedBy',
  {
    field: 'Updated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'UpdatedBy',
  {
    field: 'IsDeleted',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Deleted',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'DeletedBy',
  'DeletedIP',
  {
    field: 'ParentIsDeleted',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'ParentDeleted',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'ParentDeletedBy',
  'ParentDeletedIP',
];

function PhoneNumber(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [phoneNumberColumns, setPhoneNumberColumns] = useState(defaultColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const personPermissions = getFullPermissions('globals', 'People', 'any');
  const placePermissions = getFullPermissions('globals', 'Places', 'any');
  const eventPermissions = getFullPermissions('cad', 'Events');
  const Read = personPermissions.Read || placePermissions.Read;
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [text, setText] = useState('');
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = async (offset = 0, mergeRows = false) => {
    const rowLimit = offset >= 1000 ? limit + offset : 1000;
    if (offset !== 0) offset += 1;

    setLoaded(false);
    const startTime = timeFrom ? getTimeFromDate(timeFrom) : timeFrom;
    const endTime = timeFrom ? getTimeFromDate(timeTo) : timeTo;
    searchPhoneNumbers(text, dateFrom, dateTo, startTime, endTime, offset, rowLimit)
      .then((result) => {
        const nextStartingIndex = rows.length;
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `Phone number Details - ${item.ParentName}`,
    });
  };

  const filterData = (rawData) => {
    const {
      PartyType,
      ParentName,
      ParentID,
      ContactMethod,
      MethodType,
      PhoneNumber,
      IsDefault,
      Notes,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = rawData;
    return {
      PartyType,
      ParentName,
      ParentID,
      ContactMethod,
      MethodType,
      PhoneNumber,
      IsDefault,
      Notes,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    };
  };

  const showEdit = () => {
    if (selection === null) return false;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsParentID, PartyType } = item;
    if (PartyType === 'Place') {
      if (placePermissions.Edit && ptsParentID) return true;
    } else if (PartyType === 'Event') {
      if (eventPermissions.Edit && ptsParentID) return true;
    } else {
      if (personPermissions.Edit && ptsParentID) return true;
    }
    return false;
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsParentID, PartyType } = item;
    if (PartyType === 'Place') {
      if (placePermissions.Edit) {
        props.showEditPlace(ptsParentID);
      } else {
        props.notify("You not' have permissions to view this record");
      }
    } else if (PartyType === 'Event') {
      if (eventPermissions.Edit) {
        props.editEvent(ptsParentID);
      } else {
        props.notify("You not' have permissions to view this record");
      }
    } else {
      if (personPermissions.Edit) {
        props.showEditPerson({ ptsPersonID: ptsParentID });
      } else {
        props.notify("You not' have permissions to view this record");
      }
    }
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };
  const actions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <Tooltip title="View details">
            <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
              <InfoIcon />
            </Fab>
          </Tooltip>
        )}
        {showEdit() && (
          <Tooltip title="Edit person">
            <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    );
  };
  if (!Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Phone Numbers</h5>
      {renderSearch()}
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={actions()}
        loaded={loaded}
      />
      {/* Implement time search here */}
      <SearchTimePicker
        timeFrom={timeFrom}
        timeTo={timeTo}
        setTimeFrom={setTimeFrom}
        setTimeTo={setTimeTo}
      />
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Phone Numbers"
        columns={phoneNumberColumns}
        setColumns={setPhoneNumberColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showDisplayDataWindow,
  showEditPerson,
  showEditPlace,
  editEvent,
  notify,
})(PhoneNumber);
