
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8peacesymbol100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100" height="100"><path d="M23.977 3.979a1.5 1.5 0 0 0-.409.064C12.743 4.278 3.998 13.119 3.998 24c0 10.88 8.743 19.72 19.566 19.957a1.5 1.5 0 0 0 .864 0c10.825-.235 19.57-9.076 19.57-19.957 0-10.879-8.742-19.717-19.564-19.955a1.5 1.5 0 0 0-.457-.066zM22.498 7.15v14.23l-12.38 12.38A16.76 16.76 0 0 1 6.997 24c0-8.892 6.811-16.081 15.5-16.85zm3 0c8.69.769 15.5 7.957 15.5 16.85a16.76 16.76 0 0 1-3.12 9.758l-12.38-12.381V7.15zm0 18.47 10.424 10.425a17.022 17.022 0 0 1-10.424 4.805V25.62zm-3 .003V40.85a17.025 17.025 0 0 1-10.424-4.803l10.424-10.424z"/></SvgIcon>
      );
    }
  
    export default icons8peacesymbol100Icon;