import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import Unit from './Unit';
import { Redirect } from 'react-router-dom';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
function UnitIndex() {
  const canReadAgencies = getAgenciesFullPermission('cad', 'Units', 'Read');
  if (!canReadAgencies.length) return <Redirect to="/" />;
  return (
    <SearchMenu agencyList={canReadAgencies}>
      <FilterConsumer>{(AgencyFilter) => <Unit filter={AgencyFilter} />}</FilterConsumer>
    </SearchMenu>
  );
}

export default UnitIndex;
