import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Autocomplete from './Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ContainerCard from './ContainerCard';
import { processInputPhoneNo } from '../../utils/functions';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  textField: {
    margin: '0 4px 8px',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
  w120x180: {
    maxWidth: 180,
    flexBasis: 120,
    minWidth: 120,
    flexGrow: 1,
  },
  search: {
    marginBottom: 10,
  },
  checkbox: {
    margin: '0 4px 4px',
  },
  actions: {
    padding: theme.spacing(1.5, 0),
    '& button': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));

export default function ContactForm(props) {
  const classes = useStyles();
  const { title, no, updateData, data } = props;
  const { ptsCallerID } = data;
  const [CallerLocation, setCallerLocation] = useState('');
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [PhoneNo, setPhoneNo] = useState('');
  const [validForm, setValidForm] = useState(false);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    formatInputData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    formatOutputData();
    if (validateForm() && !validForm) setValidForm(true);
    if (!validateForm() && validForm) setValidForm(false);
    // eslint-disable-next-line
  }, [CallerLocation, FirstName, LastName, PhoneNo]);

  const formatInputData = () => {
    const { CallerLocation, FirstName, LastName, Info } = data;
    setCallerLocation(CallerLocation ? CallerLocation : '');
    setFirstName(FirstName ? FirstName : '');
    setLastName(LastName ? LastName : '');
    setPhoneNo(Info ? Info : '');
  };

  const formatOutputData = () => {
    if (!updateData) return;
    updateData(
      {
        CallerLocation: CallerLocation ? CallerLocation : null,
        FirstName: FirstName ? FirstName : null,
        LastName: LastName ? LastName : null,
        Info: PhoneNo ? PhoneNo : null,
        ptsCallerID,
      },
      no
    );
  };

  const onFirstNameChange = (ev) => {
    const value = ev.target.value;
    if (value === '' || value.length < 100) setFirstName(value);
    !changed && setChanged(true);
  };

  const onLastNameChange = (ev) => {
    const value = ev.target.value;
    if (value === '' || value.length < 100) setLastName(value);
    !changed && setChanged(true);
  };

  const onLocationChange = (ev) => {
    const value = ev.target.value;
    if (value === '' || value.length < 2000) setCallerLocation(value);
    !changed && setChanged(true);
  };

  const onPhoneNoChange = (ev) => {
    const inputVal = ev.target.value;
    const outputVal = processInputPhoneNo(inputVal, PhoneNo);
    if (outputVal !== false) setPhoneNo(outputVal);
    !changed && setChanged(true);
  };

  const clear = (ev) => {
    ev.stopPropagation();
    props.clear();
    setChanged(false);
  };

  const save = (ev) => {
    ev.stopPropagation();
    props.save();
    setChanged(false);
  };

  const validateForm = () => {
    if (CallerLocation || FirstName || LastName || PhoneNo) return true;
    return false;
  };

  const renderActions = () => {
    const closePresent = !!props.clear;
    const savePresent = !!props.save;
    const disabled = !validForm || !changed;
    return (
      <div className={classes.actions}>
        {savePresent && (
          <Fab color="secondary" aria-label="save" onClick={save} disabled={disabled} size="small">
            <SaveIcon />
          </Fab>
        )}
        {closePresent && (
          <Fab color="secondary" aria-label="close" onClick={clear} size="small">
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  return (
    <ContainerCard title={title} actions={renderActions()}>
      <div className={classes.form}>
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="First Name"
          value={FirstName}
          onChange={onFirstNameChange}
          variant="outlined"
          size="small"
          inputProps={{ autoComplete: 'new-password', form: { autoComplete: 'off' } }}
        />
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Last Name"
          value={LastName}
          onChange={onLastNameChange}
          variant="outlined"
          size="small"
          inputProps={{ autoComplete: 'new-password', form: { autoComplete: 'off' } }}
        />
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Phone"
          value={PhoneNo}
          onChange={onPhoneNoChange}
          variant="outlined"
          size="small"
        />
        {/* <FormControlLabel
          className={clsx(classes.checkbox, classes.w120x180)}
          control={<Checkbox
            checked={Unknown}
            onChange={ev => setValue('Unknown', ev.target.checked)}
          />}
          label="Unknown"
        />
        <FormControlLabel
          className={clsx(classes.checkbox, classes.w120x180)}
          control={<Checkbox
            checked={Refused}
            onChange={ev => setValue('Refused', ev.target.checked)}
          />}
          label="Refused"
        />
      </div>
      Address
      <div className={classes.form}>
        
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Number"
          value={StreetNumber}
          onChange={ev => setValue('StreetNumber', ev.target.value)}
          variant="outlined"
          size="small"
        />
        <Autocomplete 
          className={classes.w180}
          label="Predirectional"
          options={dictionary.StreetDirections}
          value={PreDirectional}
          onChange={val => setValue('PreDirectional', val)}
        />
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Street Name"
          value={StreetName}
          onChange={ev => setValue('StreetName', ev.target.value)}
          variant="outlined"
          size="small"
        />
        <Autocomplete 
          className={classes.w180}
          label="Type"
          options={dictionary.StreetTypes}
          value={StreetType}
          onChange={val => setValue('StreetType', val)}
        />
        <Autocomplete 
          className={classes.w180}
          label="Postdirectional"
          options={dictionary.StreetDirections}
          value={PostDirectional}
          onChange={val => setValue('PostDirectional', val)}
        />
        <Autocomplete 
          className={classes.w180}
          label="City"
          options={dictionary.Cities}
          value={City}
          onChange={val => setValue('City', val)}
        />
        <Autocomplete 
          className={classes.w180}
          label="State"
          options={dictionary.States}
          value={State}
          onChange={val => setValue('State', val)}
        />
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Postal Code"
          variant="outlined"
          value={PostalCode}
          onChange={ev => setValue('PostalCode', ev.target.value)}
          size="small"
        /> */}
      </div>
      <div className={classes.form}>
        <TextField
          className={classes.textField}
          style={{ width: '100%' }}
          label="Caller Location"
          value={CallerLocation}
          onChange={onLocationChange}
          variant="outlined"
          size="small"
        />
      </div>
    </ContainerCard>
  );
}
