import { getService } from './service';

export const getPersonWarrantCount = async (ptsPersonID) => {
  const service = getService('ptswarrant');
  const result = await service.find({ query: { ptsPersonID, IsActive: 1, IsDeleted: 0 } });
  return result?.data.length || 0;
}

export const getPersonWarrant = (ptsPersonID) => {
  const service = getService('cad-search-warrants');
  return service.get(ptsPersonID);
};