import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from 'components/CustomAutocomplete';
import { callerEmptyData, personEmptyData, placeEmptyData } from './emptyData';
import PersonLookup2 from 'components/PersonLookup2';
import CallerFirstNameLookup from 'components/CallerFirstNameLookup';
import CallerLastNameLookup from 'components/CallerLastNameLookup';
import CallerPhoneLookup from 'components/CallerPhoneLookup';

const useStyles = makeStyles((theme) => ({
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
  },
  textField: {
    margin: '0 4px 8px',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
  w240: {
    maxWidth: '100%',
    flexBasis: 240,
    minWidth: 240,
    flexGrow: 1,
  },
  w320: {
    maxWidth: '100%',
    flexBasis: 320,
    minWidth: 320,
    flexGrow: 1,
  },
  w120x180: {
    maxWidth: 180,
    flexBasis: 120,
    minWidth: 120,
    flexGrow: 1,
  },
  search: {
    marginBottom: 10,
  },
  checkbox: {
    margin: '0 4px 4px',
  },
}));

export default function Caller(props) {
  const { ptsEventID, Relationships, data, options } = props;
  const classes = useStyles();

  useEffect(() => {
    // Add default relationship
    if (data.Relationship) return;
    const relationship = options.DefaultRelationshipCaller;
    const relObj = Relationships.find((rel) => rel.Code === relationship);
    const description = relObj ? relObj.Description : '';
    props.updateData({
      ...data,
      Relationship: {
        Code: relationship,
        Description: description,
      },
    });
    // eslint-disable-next-line
  }, [data.Relationship]);

  const updatePartyType = (ev) => {
    switch (ev.target.value) {
      case 'caller':
        props.updateData(callerEmptyData);
        break;
      case 'person':
        props.updateData(personEmptyData);
        break;
      case 'place':
        props.updateData(placeEmptyData);
        break;
      default:
    }
  };

  const onFirstNameChange = (FirstName) => {
    props.updateData({ ...data, FirstName });
  };

  const onLastNameChange = (person) => {
    const { LastName, FirstName, Info } = person;
    const newData = { ...data, LastName };
    if (FirstName) newData.FirstName = FirstName;
    if (Info) newData.Info = Info;
    props.updateData(newData);
  };

  const onInfoChange = (infoData) => {
    const { LastName, FirstName, Info } = infoData;
    const newData = { ...data, Info };
    if (FirstName) newData.FirstName = FirstName;
    if (LastName) newData.LastName = LastName;
    props.updateData(newData);
  };

  const onLocationChange = (ev) => {
    props.updateData({ ...data, CallerLocation: ev.target.value });
  };

  const onAdvisoryTextChange = (ev) => {
    props.updateData({ ...data, AdvisoryText: ev.target.value });
  };

  const onRelationshipChange = (val) => {
    props.updateData({ ...data, Relationship: val });
  };

  const onPersonSelect = (person) => {
    console.log('p caller ', person);
    if (!person)
      return props.updateData({
        ...data,
        FirstName: '',
        LastName: '',
        Info: '',
        ptsPersonID: null,
      });
    const { FirstName, LastName, ContactInfo, ptsPersonID, MiddleName, Prefix, Suffix } = person;
    const phones = ContactInfo.filter((c) => c.ContactMethod === 'Phone');
    const Info = phones.length ? phones[0].Info : '';
    props.updateData({
      ...data,
      FirstName,
      LastName,
      Info,
      ptsPersonID,
      MiddleName,
      Prefix,
      Suffix,
    });
  };

  return (
    <div className={classes.edit}>
      <div className={classes.form}>
        <FormControl
          className={clsx(classes.textField, classes.w180)}
          variant="outlined"
          size="small">
          <InputLabel id={`party-type-${ptsEventID}`}>Party</InputLabel>
          <Select
            label="Party"
            labelId="party-type"
            id="party-type"
            value={data.party}
            onChange={updatePartyType}
            disabled={!!data.ROWGUID}>
            <MenuItem value="caller">Caller</MenuItem>
            <MenuItem value="person">Person</MenuItem>
            <MenuItem value="place">Place</MenuItem>
          </Select>
        </FormControl>
        <PersonLookup2
          className={clsx(classes.textField, classes.w180)}
          onPersonChange={onPersonSelect}
          compact="true"
        />
      </div>
      <div className={classes.form}>
        <CallerFirstNameLookup
          className={clsx(classes.textField, classes.w180)}
          value={data.FirstName}
          onChange={onFirstNameChange}
        />
        <CallerLastNameLookup
          className={clsx(classes.textField, classes.w180)}
          FirstName={data.FirstName}
          value={data.LastName}
          onChange={onLastNameChange}
        />
        <CallerPhoneLookup
          className={clsx(classes.textField, classes.w180)}
          value={data.Info}
          onChange={onInfoChange}
        />
      </div>
      <div className={classes.form}>
        <Autocomplete
          className={classes.w180}
          label="Party Relationship*"
          options={Relationships}
          value={data.Relationship}
          onChange={onRelationshipChange}
        />
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Advisory Data"
          value={data.AdvisoryText}
          onChange={onAdvisoryTextChange}
          variant="outlined"
          size="small"
        />
        <TextField
          className={clsx(classes.textField, classes.w180)}
          label="Caller Location"
          value={data.CallerLocation}
          onChange={onLocationChange}
          variant="outlined"
          size="small"
        />
      </div>
    </div>
  );
}
