import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  autocomplete: {
    margin: '0 4px 8px',
  },
  option: {
    fontSize: 14,
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
});

function ErrorElement() {
  return (
    <TextField
      label="Form Field Error!"
      variant="outlined"
      style={{ margin: '2em 8px' }}
      disabled
    />
  );
}

function CustomAutocomplete(props) {
  const classes = useStyles();
  const { value, onChange, label, options, disabled, error } = props;

  if (!options) return <ErrorElement />;

  return (
    <Autocomplete
      options={options}
      classes={{
        option: classes.option,
        root: classes.autocomplete,
      }}
      className={props.className}
      disabled={disabled}
      autoHighlight
      getOptionLabel={(option) => (option.Code ? option.Code : '')}
      getOptionSelected={(option, value) => option.Code === value.Code}
      renderOption={(option) => {
        const { Code, Description } = option;
        return (
          <span>
            <strong>{Code}</strong>
            {!!Description && <> - {Description}</>}
          </span>
        );
      }}
      onChange={(ev, newValue) => onChange(newValue)}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
