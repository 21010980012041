import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import settings from '../../config/settings';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Place from './Place';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: `calc(100vh - ${settings.mainNavHeight}px)`,
    paddingTop: theme.spacing(1),
  },
  panel: {
    height: '100%',
    overflow: 'auto',
    display: 'block',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
}));

function Panel(props) {
  const classes = useStyles();
  const { children, ...other } = props;
  return (
    <PerfectScrollbar className={classes.panel} {...other}>
      <Box p={3}>{children}</Box>
    </PerfectScrollbar>
  );
}

function PlacesSearch(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Panel>
        <Place />
      </Panel>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps)(PlacesSearch);
