import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { sortObjArr, areObjEqual } from 'utils/functions';
import { displayDateTime } from 'reducers/TimeReducer';
import { addDispositions, removeDisposition } from 'reducers/EventsReducer';
import settings from 'config/settings';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getRequiredDispositions } from 'reducers/EventsReducer';
import Chip from '@material-ui/core/Chip';
import { Alert } from '@material-ui/lab';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme) => ({
  dispositions: {
    textAlign: 'left',
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  disposition: {
    padding: '10px 15px 5px',
    position: 'relative',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    '&:hover': {
      '& $dispAction': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  reqDispositions: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > div': {
      marginRight: 4,
    },
  },
  addDispositions: {
    padding: '5px 15px',
    background: '#eee',
  },
  dispAction: {
    position: 'absolute',
    right: 10,
    top: 25,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  autocomplete: {
    padding: theme.spacing(1, 2),
  },
  reqAgencies: {
    padding: '16px 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  reqMergedDisp: {
    padding: '16px 20px 0',
    display: 'flex',
  },
  chip: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  label: {
    minWidth: 120,
    display: 'inline-block',
    marginRight: 16,
  },
}));

function Disposition(props) {
  const classes = useStyles();
  const { ptsEventID, disposition, perms, disableEdit } = props;
  const dispDictionary = props.dictionary.Dispositions;
  const { CreatedBy, Disposition, Created, AgencyID } = disposition;

  const getDescription = () => {
    const obj = dispDictionary.find((disp) => disp.Code === Disposition);
    return obj ? ` - ${obj.Description}` : '';
  };

  const del = async () => {
    if (!window.confirm(`Are you sure you want to delete "${disposition.Disposition}?"`)) return;
    try {
      await removeDisposition(ptsEventID, disposition);
      props.notifyDataUpdate({ type: 'event', data: ptsEventID });
    } catch (err) {
      props.handleError(err);
    }
  };

  return (
    <div className={classes.disposition}>
      <div className={classes.header}>
        <h4>{CreatedBy}</h4>
        <span>{displayDateTime(Created)}</span>
      </div>
      <p>
        {Disposition} {getDescription()} ({AgencyID})
      </p>
      {!disableEdit && (
        <div className={classes.dispAction}>
          {perms.Delete && (
            <Tooltip title="Delete disposition">
              <IconButton size="small" component="span" color="primary" onClick={del}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function DispositionsTab(props) {
  const perms = getFullPermissions('cad', 'Events', 'any');
  const classes = useStyles();
  const {
    dispositions,
    dictionary,
    ptsEventID,
    units,
    sortOption,
    EventRouting,
    dispositionWarning,
    disableEdit,
  } = props;

  const dispDictionary = dictionary.Dispositions;
  const [edited, setEdited] = useState(true);
  const [availDispositions, setAvailDispositions] = useState([]);
  const [selectedDispositions, setSelectedDispositions] = useState([]);
  const [sortedDispositions, setSortedDispositions] = useState([]);
  const [reqDispAgencies, setReqDispAgencies] = useState([]);
  const [reqMergedDisp, setReqMergedDisp] = useState([]);
  const [agencyFilter, setAgencyFilter] = useState([]);
  const [multiselect, setMultiselect] = useState(true);

  useEffect(() => {
    getAvailDispositions();
    const { reqDispAgencies, reqMergedDisp } = getRequiredDispositions(ptsEventID);
    setReqDispAgencies(reqDispAgencies);
    setReqMergedDisp(reqMergedDisp);
    // eslint-disable-next-line
  }, [EventRouting, sortOption, units, agencyFilter]);

  useEffect(() => {
    if (!dispositions) return;
    setSortedDispositions(sortObjArr(dispositions, 'Disposition', sortOption));
    // eslint-disable-next-line
  }, [dispositions, sortOption]);

  const getAvailDispositions = () => {
    // Find current AgencyID's
    let agencies = [];
    EventRouting &&
      EventRouting.forEach((routing) => {
        if (!agencies.find((agency) => agency === routing.AgencyId)) {
          agencies.push(routing.AgencyId);
        }
      });
    // Custom agency filtering
    if (agencyFilter.length) {
      agencies = agencyFilter;
    }
    // Get all dispositions related to AgencyID's
    let matchDisp = [];
    agencies.forEach((AgencyID) => {
      matchDisp = [...matchDisp, ...dispDictionary.filter((dict) => dict.AgencyID === AgencyID)];
    });
    // Filter already set dispositions
    dispositions.forEach((disp) => {
      matchDisp = matchDisp.filter((d) => {
        return d.Code !== disp.Disposition || d.AgencyID !== disp.AgencyID;
      });
    });
    // Sort dispositions
    const sortedAvailDispositions = sortObjArr(matchDisp, 'Code', sortOption);
    // Set dispositions if changed
    if (!areObjEqual(sortedAvailDispositions, availDispositions)) {
      setAvailDispositions(sortedAvailDispositions);
    }

    let defaultOption = [];

    defaultOption = sortedAvailDispositions.filter((item) => item.IsDefault || item.isDefault);
    if (defaultOption.length) {
      //props.onChange(null, defaultOption[0]);
      setSelectedDispositions([defaultOption[0]]);
    }
  };

  const onAddDispositions = () => {
    setEdited(true);
  };

  const onSave = () => {
    const dispToAdd = selectedDispositions.map(({ Code, AgencyID }) => ({
      Disposition: Code,
      AgencyID,
      ptsEventID,
    }));
    props.addDispositions(dispToAdd);
    setEdited(true);
    setSelectedDispositions([]);
  };

  const onDiscard = () => {
    setSelectedDispositions([]);
    setEdited(false);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!edited && availDispositions.length > 0 && perms.Edit && (
          <IconButton color="primary" size="small" component="span" onClick={onAddDispositions}>
            <AddIcon />
          </IconButton>
        )}
        {!edited && availDispositions.length === 0 && (
          <IconButton color="primary" size="small" component="span" disabled>
            <AddIcon />
          </IconButton>
        )}
        {edited && (
          <>
            <IconButton
              color="primary"
              size="small"
              component="span"
              onClick={onSave}
              disabled={!selectedDispositions.length || !perms.Edit}>
              <SaveIcon />
            </IconButton>
            <IconButton color="primary" size="small" component="span" onClick={onDiscard}>
              <CloseIcon />
            </IconButton>
          </>
        )}
      </div>
    );
  };

  const getOptionLabel = (option) => {
    const { Code, Description, AgencyID } = option;
    return Code === Description
      ? `${Description} (${AgencyID})`
      : `${Code} ${Description} (${AgencyID})`;
  };

  const renderAddDispSingle = () => {
    return (
      <Autocomplete
        options={availDispositions}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        onChange={(ev, val) => setSelectedDispositions([val])}
        size="small"
        className={classes.autocomplete}
        value={selectedDispositions.length ? selectedDispositions[0] : null}
        disabled={!perms.Edit}
      />
    );
  };

  const renderAddDispMulti = () => {
    return (
      <Autocomplete
        multiple
        options={availDispositions}
        disableCloseOnSelect
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {getOptionLabel(option)}
          </React.Fragment>
        )}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        onChange={(ev, val) => setSelectedDispositions(val)}
        size="small"
        className={classes.autocomplete}
        value={selectedDispositions}
        disabled={!perms.Edit}
      />
    );
  };

  const renderDispositions = () => {
    return (
      <>
        {sortedDispositions.map((disposition, idx) => (
          <Disposition
            key={idx}
            disposition={disposition}
            dictionary={dictionary}
            no={idx}
            ptsEventID={ptsEventID}
            handleError={props.handleError}
            notifyDataUpdate={props.notifyDataUpdate}
            perms={perms}
            disableEdit={disableEdit}
          />
        ))}
      </>
    );
  };

  const addReqDisposition = (disp) => {
    const Disposition = { Disposition: disp.Code, AgencyID: disp.AgencyID, ptsEventID };
    props.addDispositions([Disposition]);
  };

  const toggleFilter = (AgencyID) => {
    if (agencyFilter.indexOf(AgencyID) === -1) {
      setAgencyFilter([...agencyFilter, AgencyID]);
    } else {
      setAgencyFilter(agencyFilter.filter((a) => a !== AgencyID));
    }
  };

  const renderReqDispositions = () => {
    const toggleMultiselect = (ev, val) => {
      setMultiselect(val);
      if (!val && selectedDispositions.length > 1) {
        setSelectedDispositions([selectedDispositions[0]]);
      }
    };
    const showAlert =
      dispositionWarning && (reqDispAgencies.length > 0 || reqMergedDisp.length > 0);
    return (
      <>
        {showAlert && (
          <Alert severity="warning" style={{ margin: '10px 20px 0' }}>
            Cannot close the event. Event disposition requirements are not met.
          </Alert>
        )}
        {
          <div className={classes.reqAgencies}>
            <div className={classes.reqDispositions}>
              {reqDispAgencies.length > 0 && (
                <>
                  <span className={classes.label}>Disp. required from:</span>
                  {reqDispAgencies.map((AgencyID) => {
                    const active = agencyFilter.indexOf(AgencyID) != -1;
                    return (
                      <Tooltip title="Toggle filter" key={AgencyID}>
                        <Chip
                          variant={active ? undefined : 'outlined'}
                          size="small"
                          label={AgencyID}
                          onClick={() => toggleFilter(AgencyID)}
                          color={active ? 'primary' : undefined}
                        />
                      </Tooltip>
                    );
                  })}
                </>
              )}
            </div>
            {!disableEdit && (
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    style={{ padding: 0 }}
                    checked={multiselect}
                    onChange={toggleMultiselect}
                    name="multiselect"
                    color="primary"
                    size="small"
                  />
                }
                label="Multi-select"
              />
            )}
          </div>
        }
        {!disableEdit && reqMergedDisp.length > 0 && (
          <div className={classes.reqMergedDisp}>
            <span className={classes.label}>Req. dispositions: </span>
            {reqMergedDisp.map((d) => (
              <Chip
                key={d.AgencyID}
                variant="outlined"
                color="primary"
                size="small"
                className={classes.chip}
                label={`${d.AgencyID} | ${d.Description}`}
                onClick={() => (perms.Create ? addReqDisposition(d) : undefined)}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.dispositions}>
      {!disableEdit && perms.Create && renderActions()}
      {renderReqDispositions()}
      {!perms.Create && <hr style={{ marginTop: 4, marginBottom: 0 }} />}
      {!disableEdit &&
        perms.Create &&
        edited &&
        (multiselect ? renderAddDispMulti() : renderAddDispSingle())}
      {renderDispositions()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  addDispositions,
  handleError,
  notifyDataUpdate,
})(DispositionsTab);
