import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import { getEventVehicles } from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from 'components/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { XGrid } from '@material-ui/x-grid';
import VehicleLookup from 'components/VehicleLookup';
import { addVehicleToEvent, removeVehicleFromEvent } from 'reducers/EventsReducer';
import { showEditVehicle } from 'reducers/DialogsReducer';
import EditIcon from '@material-ui/icons/Edit';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import RvHookupIcon from '@material-ui/icons/RvHookup';
import { showTowingDialog } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const panelHeight = 300;
const useStyles = makeStyles((theme) => ({
  Vehicles: {
    textAlign: 'left',
    maxHeight: panelHeight,
    overflowY: 'auto',
  },
  vehicleSelect: {
    padding: '0 16px 8px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  textField: {
    margin: `0 0 ${theme.spacing(1)}px 0`,
  },
  rowActions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  editNote: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
  },
  xgrid: {
    height: panelHeight,
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'VehicleID', headerName: 'Vehicle ID', width: 150 },
  { field: 'registrations', headerName: 'Registration', width: 150 },
  { field: 'VIN', headerName: 'VIN', width: 100 },
  { field: 'Year', headerName: 'Year', width: 100 },
  { field: 'Make', headerName: 'Make', width: 100 },
  { field: 'Model', headerName: 'Model', width: 100 },
  { field: 'Style', headerName: 'Style', width: 100 },
  { field: 'PrimaryColor', headerName: 'Primary Color', width: 150 },
  {
    field: 'IsWanted',
    headerName: 'Is Wanted',
    width: 100,
    format: 'bool',
    renderCell: bool,
  },
];

function VehiclesTab(props) {
  const { sortOption, ptsEventID, VehicleCount, units, disableEdit } = props;
  const classes = useStyles();
  const [Vehicles, setVehicles] = useState([]);
  const [sortedVehicles, setSortedVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [edited, setEdited] = useState(false);
  const mountedRef = useRef(true);
  const perms = getFullPermissions('cad', 'Events', 'any');
  useEffect(() => {
    getVehicles();
    // eslint-disable-next-line
  }, [VehicleCount]);

  useEffect(() => {
    setSortedVehicles(sortObjArr(Vehicles, 'VehicleID', sortOption));
    // eslint-disable-next-line
  }, [Vehicles, sortOption]);

  const getVehicles = async () => {
    setLoading(true);
    try {
      const Vehicles = await getEventVehicles(ptsEventID);
      if (!mountedRef.current) return;
      setVehicles(Vehicles.map((v) => ({ ...v, id: v.ptsVehicleID })));
    } catch (err) {
      props.handleError(err);
    }
    setLoading(false);
    setSelection(null);
  };

  const onSave = async () => {
    const exists = Vehicles.find((v) => v.ptsVehicleID === vehicle.ptsVehicleID);
    if (exists) {
      props.notify('Error - vehicle already added to event', 'error');
      return;
    }
    try {
      await addVehicleToEvent(ptsEventID, vehicle.ptsVehicleID);
      setEdited(false);
      props.notifyDataUpdate({ type: 'event', data: ptsEventID });
    } catch (err) {
      props.handleError(err);
    }
    setSelection(null);
  };

  const onEditClick = () => {
    const vehicle = Vehicles.find((v) => v.id === selection);
    props.showEditVehicle(vehicle);
  };

  const onAddTowing = () => {
    const vehicle = Vehicles.find((v) => v.id === selection);
    const ptsVehicleID = vehicle?.ptsVehicleID || null;
    const ptsUnitIDS = units.length ? units.map((u) => u.ptsUnitID) : null;
    const data = { ptsVehicleID, ptsUnitIDS, ptsPlaceID: null, ptsEventID };
    props.showTowingDialog(data);
  };

  const removeVehicle = async () => {
    if (!window.confirm('Are you sure you want to remove this vehicle?')) return;
    const vehicle = Vehicles.find((v) => v.id === selection);
    try {
      await removeVehicleFromEvent(ptsEventID, vehicle.ptsVehicleID);
      props.notifyDataUpdate({ type: 'event', data: ptsEventID });
    } catch (err) {
      props.handleError(err);
    }
    setSelection(null);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!edited && (
          <Tooltip title="Add vehicle to the event">
            <IconButton
              color="primary"
              size="small"
              onClick={() => setEdited(true)}
              disabled={!perms.Edit}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        {edited && vehicle !== null && (
          <Tooltip title="Save changes">
            <IconButton color="primary" size="small" onClick={onSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}
        {edited && !vehicle && (
          <IconButton color="primary" size="small" disabled>
            <SaveIcon />
          </IconButton>
        )}

        {edited && (
          <Tooltip title="Discard changes">
            <IconButton color="primary" size="small" onClick={() => setEdited(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}

        {selection !== null && !edited && (
          <>
            <Tooltip title="Towing">
              <IconButton color="primary" size="small" onClick={onAddTowing}>
                <RvHookupIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit vehicle">
              <IconButton color="primary" size="small" onClick={onEditClick} disabled={!perms.Edit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove vehicle from event">
              <IconButton
                color="primary"
                size="small"
                onClick={removeVehicle}
                disabled={!perms.Edit}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.wrap}>
      {!disableEdit && renderActions()}
      {edited && !disableEdit && (
        <div className={classes.vehicleSelect}>
          <VehicleLookup onChange={setVehicle} label="Add Vehicle" />
        </div>
      )}
      <div className={classes.xgrid}>
        {!edited && (
          <XGrid
            columns={columns}
            rows={sortedVehicles}
            loading={loading}
            rowHeight={38}
            disableMultipleSelection={true}
            showToolbar
            disableColumnFilter
            onSelectionModelChange={(newSelection) => {
              setSelection(newSelection.selectionModel[0]);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default connect(null, {
  notify,
  handleError,
  showEditVehicle,
  notifyDataUpdate,
  showTowingDialog,
})(VehiclesTab);
