
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8whyquest100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M35.19.07c-6.579 0-11.93 4.681-11.93 10.432 0 3.153 1.648 6.146 4.44 8.117-.109.575-.413 1.632-1.27 2.848l-1.028 1.457 1.784.008h.029c2.65 0 4.514-1.496 5.295-2.264.875.175 1.773.264 2.68.264 6.577 0 11.93-4.679 11.93-10.43C47.12 4.751 41.767.07 35.19.07zm.529 5.203c2.09 0 3.484 1.204 3.484 2.891.001 1.166-.578 1.984-1.615 2.602-1 .587-1.281.976-1.281 1.73v.438h-1.772l-.008-.522c-.068-1.145.375-1.83 1.428-2.447.946-.571 1.268-.979 1.268-1.733 0-.793-.643-1.365-1.604-1.365-.984 0-1.618.596-1.68 1.526h-1.793c.062-1.808 1.307-3.12 3.573-3.12zM15.5 12.5c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm20.006 1.639c.778 0 1.265.48 1.265 1.22 0 .732-.487 1.213-1.265 1.213-.763 0-1.268-.48-1.268-1.213 0-.74.505-1.22 1.268-1.22zM11.127 27.5a5.632 5.632 0 0 0-5.625 5.627V47a.5.5 0 0 0 .5.5H9.5V37a.5.5 0 0 1 1 0l-.002 10.5h12.5a.5.5 0 0 0 .5-.5l.002-8.793 2.838 2.836c.918.917 2.406.974 3.459.062l4.422-5.533c1.017-1.394 1.88-1.805 2.574-2.135.843-.398 1.57-.744 1.705-2.396A.5.5 0 0 0 38 30.5h-4.559a3.49 3.49 0 0 0-2.734 1.313l-2.69 3.365-4.744-6.485a3.497 3.497 0 0 0-2.632-1.193h-9.514z"/></SvgIcon>
      );
    }
  
    export default icons8whyquest100Icon;