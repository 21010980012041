import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Badge, Menu, List, ListItem } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { handleError } from 'reducers/ErrorReducer';
import { showBulletinDetails } from 'reducers/DialogsReducer';
import { getExcerpt } from 'utils/functions';
import { displayDateTime } from 'reducers/TimeReducer';
import { markBulletinRead } from 'reducers/BulletinReducer';

const useStyles = makeStyles((theme) => ({
  lstItem: {
    borderBottom: "1px solid grey"
  },
  menuInner: {
    width: 300,
  },
  list: {
    padding: 0,
    marginTop: -4,
    marginBottom: -4,
    fontSize: 13,
  },
  menu: {
    //marginLeft: -80,
  },
  table: {
    width: '100%',
    '& td:first-child button': {
      marginLeft: -4,
      marginBottom: -4,
    },
    '& td:nth-child(2)': {
      textAlign: 'right',
      '& button': {
        marginRight: -4,
        marginBottom: -4,
      },
    },
  },
}));

function UnreadAlerts(props) {
  const { unreadBulletins } = props;
  const classes = useStyles();
  const [bellAnchorEl, setBellAnchorEl] = useState(null);

  const handleBellClick = (event) => {
    setBellAnchorEl(event.currentTarget);
  }

  const handleBellMenuClose = () => {
    setBellAnchorEl(null);
  }

  const renderBellFab = () => {
    return (
      <Fab size="small" className="mr-4" onClick={handleBellClick} disabled={!unreadBulletins?.length}>
        <Badge badgeContent={unreadBulletins?.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </Fab>
    );
  }

  const renderBulletinList = () => {
    return (
      <Menu
        anchorEl={bellAnchorEl}
        keepMounted
        open={Boolean(bellAnchorEl) && unreadBulletins.length > 0}
        onClose={handleBellMenuClose}
        className={classes.menu}
        PaperProps={{
          className: classes.menuInner
        }}
      >
        <List className={classes.list}>
          {unreadBulletins.length > 10 && <ListItem className={classes.lstItem} style={{ justifyContent: 'flex-end' }}>
            <Button
              size="small"
              color="primary"
              onClick={() => props.markBulletinRead('all')}
            >Mark All as Read</Button>
          </ListItem>}

          {unreadBulletins.map(bulletin => {
            const {
              ptsAlertID, AlertID, AlertType, Notification, OLN, OLNState, Plate, PlateState,
              Priority, Ranges, Created, Person
            } = bulletin;
            return (
              <ListItem key={bulletin.ptsAlertID} className={classes.lstItem}>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td><strong>{AlertID}</strong></td>
                      <td style={{ whiteSpace: 'nowrap' }}>{displayDateTime(Created)}</td>
                    </tr>
                    {(Boolean(AlertType) || Boolean(Priority)) && <tr><th>{AlertType}</th><td>Priority: {Priority}</td></tr>}
                    {Boolean(Notification) && <tr><td colSpan={2}>{getExcerpt(Notification, 80)}</td></tr>}
                    {Boolean(Person) && <tr><th>Person:</th><td>{Person}</td></tr>}
                    {Boolean(OLN) && <tr><th>OLN:</th><td>{OLN} {OLNState}</td></tr>}
                    {Boolean(Plate) && <tr><th>Plate:</th><td>{Plate} {PlateState}</td></tr>}
                    {Boolean(Ranges) && <tr><th>Range:</th><td>{Ranges}</td></tr>}
                    <tr>
                      <td><Button size="small" color="primary" onClick={() => props.showBulletinDetails(bulletin)}>Show Details</Button></td>
                      <td>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => props.markBulletinRead(ptsAlertID)}
                        >Mark as Read</Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ListItem>
            )
          })}
        </List>
      </Menu >
    )
  }

  return (
    <div>
      {renderBellFab()}
      {Boolean(bellAnchorEl) && renderBulletinList()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    unreadBulletins: state.unreadBulletins,
  };
}

export default connect(mapStateToProps, {
  showBulletinDetails,
  handleError,
  markBulletinRead,
})(UnreadAlerts);
