
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8briefcase100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M20 3c-1.645 0-3 1.355-3 3v3H3c-1.652 0-3 1.348-3 3v13c0 1.652 1.348 3 3 3h44c1.652 0 3-1.348 3-3V12c0-1.652-1.348-3-3-3H33V6c0-1.645-1.355-3-3-3Zm0 2h10c.563 0 1 .438 1 1v3H19V6c0-.563.438-1 1-1Zm5 17a1.999 1.999 0 1 1 0 4 1.999 1.999 0 1 1 0-4ZM0 27v17c0 1.652 1.348 3 3 3h44c1.652 0 3-1.348 3-3V27c0 1.652-1.348 3-3 3H3c-1.652 0-3-1.348-3-3Z"/></SvgIcon>
      );
    }
  
    export default icons8briefcase100Icon;