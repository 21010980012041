import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getService } from 'reducers/service';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Spinner from 'components/Spinner';
import Dialog from 'components/Dialog';
import { displayDateTime } from 'reducers/TimeReducer';
import { closeBulletinReadHistory } from 'reducers/DialogsReducer';

const useStyles = makeStyles((theme) => ({
  contentWrap: {
    minWidth: 800,
    minHeight: 600,
  },
  gridContainer: {
    '& .MuiDataGrid-root': {
      boxSizing: 'content-box',
    },
  },
  tableRowStyle: {
    color: theme.colors.text1 + '!important',
    borderBottom: '1px solid ' + theme.colors.borderColorList + '!important',
  },
}));

function BulletinReadHistory(props) {
  const { data } = props;
  const { ptsAlertID } = data;
  const classes = useStyles();
  const [readHistory, setReadHistory] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getStatusHistory = async () => {
      setShowSpinner(true);
      const data = await getBulletinReadHistory(ptsAlertID);
      setReadHistory(data);
      setShowSpinner(false);
    };
    getStatusHistory();
  }, [ptsAlertID]);

  const getBulletinReadHistory = (ptsAlertID) => {
    const service = getService('cad-user-alerts-read');
    return service.get(ptsAlertID);
  };

  const onClose = () => {
    dispatch(closeBulletinReadHistory());
  };

  return (
    <Dialog toolbar onClose={onClose} title="Bulletin Read History">
      <div className={clsx(classes.contentWrap, classes.gridContainer)}>
        {showSpinner && <Spinner />}
        <TableContainer>
          <Table aria-label="bulletin read history table" className={classes.tableStyle}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableRowStyle}>Read By</TableCell>
                <TableCell className={classes.tableRowStyle}>Read Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {readHistory.map((row) => (
                <TableRow key={row.id}>
                  <TableCell className={classes.tableRowStyle}>{row.ReadBy}</TableCell>
                  <TableCell className={classes.tableRowStyle}>
                    {' '}
                    {displayDateTime(row.ReadDate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Dialog>
  );
}

export default BulletinReadHistory;
