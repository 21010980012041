import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeUnitRotation } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { getFormStyle, getRowStyle, get100prStyle } from 'utils/functions';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import TextField from '@material-ui/core/TextField';
import { getService } from 'reducers/service';
import { gridStyle, Row } from 'utils/formStyles';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  item: {
    ...gridStyle(500, 920),
  },
}));

function EditUnitRotation(props) {
  const classes = useStyles();
  const { Rotation, ptsUnitID } = props.data;
  const service = getService('cad-rotations');
  const [contactInfo, setContactInfo] = useState(props.data.ContactInfo);
  const close = () => props.closeUnitRotation();
  const save = async () => {
    try {
      await service.update(null, { ContactInfo: contactInfo, Rotation, ptsUnitID });
      props.notifyDataUpdate({ type: 'edit-unit-rotation', data: { ptsUnitID } });
      props.notify('Unit rotation updated!', 'success');
      close();
    } catch (err) {
      props.handleError(err);
    }
  };
  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };
  return (
    <Dialog toolbar onClose={close} title={'Edit Unit Rotation'} actions={renderActions()}>
      <div>
        <Row>
          <TextField
            className={classes.item}
            label="Contact Info"
            value={contactInfo}
            onChange={(ev) => setContactInfo(ev.target.value)}
            max={30}
            variant="outlined"
            size="large"
          />
        </Row>
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeUnitRotation,
  notifyDataUpdate,
  handleError,
  notify,
})(EditUnitRotation);
