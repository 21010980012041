import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import LogicOperator from './LogicOperator';
import ActionType from './ActionType';
import Operator from './Operator';
import ActionValue from './ActionValue';

const useStyles = makeStyles((theme) => ({
  group: {
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& hr': {
      marginBottom: 0,
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  row: {
    padding: theme.spacing(1, 1, 0),
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    margin: `${theme.spacing(1)}px -4px 0`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const defaultValues = {
  equal: '',
  notEqual: '',
  greater: '',
  greaterEqual: '',
  less: '',
  lessEqual: '',
  between: ['', ''],
  contains: '',
  notContains: '',
  beginsWith: '',
  endsEith: '',
  anyOf: [],
  noneOf: [],
};

function FilterRow(props) {
  const classes = useStyles();
  const {
    data,
    clearRow,
    updateData,
    disabled,
    logicOperator,
    updateLogicOperator,
    dictionary,
  } = props;
  const { actionType, operator, actionValue } = data;

  const update = (val, name) => {
    const newData = { actionType, operator, actionValue };
    newData[name] = val;
    if (name === 'actionType') {
      newData.actionValue = defaultValues[operator];
    }
    updateData(newData);
  };

  const updateOperator = (val) => {
    const newData = { actionType, operator, actionValue };
    newData.operator = val;
    newData.actionValue = defaultValues[val];
    updateData(newData);
  };

  return (
    <>
      <div className={classes.row}>
        <IconButton size="small" onClick={clearRow}>
          <ClearIcon fontSize="inherit" />
        </IconButton>
        <LogicOperator value={logicOperator} setValue={updateLogicOperator} disabled={disabled} />
        <ActionType value={actionType} setValue={(val) => update(val, 'actionType')} />
        <Operator value={operator} setValue={updateOperator} />
        <ActionValue
          value={actionValue}
          setValue={(val) => update(val, 'actionValue')}
          actionType={actionType}
          operator={operator}
          dictionary={dictionary}
        />
      </div>
    </>
  );
}

function FilterGroup(props) {
  const classes = useStyles();
  const { no, data, removeGroup, updateGroup, dictionary, updateGroupOperator } = props;
  const { operator, filters, logicOperator } = data;

  const setGroupOperator = (val) => {
    updateGroupOperator(val);
  };

  const addFilter = () => {
    const newData = { ...data };
    newData.filters.push({
      actionType: 'Agency',
      operator: 'equal',
      actionValue: '',
    });
    updateGroup(newData);
  };

  const clearRow = (no) => () => {
    const newData = { ...data };
    newData.filters.splice(no, 1);
    updateGroup(newData);
  };

  const updateRow = (no) => (row) => {
    const newData = { ...data };
    newData.filters[no] = row;
    updateGroup(newData);
  };

  const updateLogicOperator = (val) => {
    const newData = { ...data };
    newData.logicOperator = val;
    updateGroup(newData);
  };

  return (
    <>
      {no > 0 && (
        <div className={classes.form}>
          <LogicOperator
            value={operator}
            setValue={setGroupOperator}
            noLabel
            variant="outlined"
            disabled={no > 1}
          />
        </div>
      )}
      <Card variant="outlined" className={classes.group}>
        <div>
          {filters.map((row, idx) => (
            <FilterRow
              key={idx}
              disabled={idx > 0}
              data={row}
              clearRow={clearRow(idx)}
              updateData={updateRow(idx)}
              logicOperator={logicOperator}
              updateLogicOperator={updateLogicOperator}
              dictionary={dictionary}
            />
          ))}
        </div>
        <div>
          <hr />
          <div className={classes.action}>
            <div>
              <Button onClick={addFilter}>
                <AddIcon /> Add Filter
              </Button>
            </div>
            <div>
              <Button
                onClick={removeGroup}
                className={filters.length === 0 ? classes.error : undefined}>
                <ClearIcon /> Remove Group
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default FilterGroup;
