export const SET_SELECTED_EVENT_UNIT = 'SET_SELECTED_EVENT_UNIT';
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';
export const SET_SELECTED_UNIT = 'SET_SELECTED_UNIT';
export const setSelectedUnitFromEvent = (
  selection = {
    ptsUnitID: null,
    ptsEventID: null,
  }
) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_EVENT_UNIT, payload: selection });
  };
};

export const setSelectedEvents = (selection = []) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_EVENT, payload: selection });
  };
};

export const setSelectedUnits = (selection = []) => {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_UNIT, payload: selection });
  };
};

// ===========  REDUCERS  ======================

export default function reducer(
  state = {
    eventUnitSelection: {
      ptsUnitID: null,
      ptsEventID: null,
    },
    eventSelection: [],
    unitSelection: [],
  },
  action
) {
  switch (action.type) {
    case SET_SELECTED_EVENT_UNIT:
      return { ...state, eventUnitSelection: action.payload };
    case SET_SELECTED_EVENT:
      return { ...state, eventSelection: action.payload };
    case SET_SELECTED_UNIT:
      return { ...state, unitSelection: action.payload };
    default:
      break;
  }
  return state;
}
