import React from 'react';
import Units from 'pages/Units';

const style = { padding: 16 };

function UnitsPage() {
  return (
    <div style={style}>
      <Units />
    </div>
  );
}

export default UnitsPage;
