import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from 'components/Dialog';
import { Button } from '@material-ui/core';
import { getUnitStatus, setNotify } from 'reducers/UnitStatusReducer';
import EventFilter from 'components/EventFilter';
import { closeAddNewNote } from 'reducers/DialogsReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showBulletinDetails } from 'reducers/DialogsReducer';
import { bulletinFound } from 'reducers/BulletinReducer';
import { findBulletins } from 'reducers/BulletinReducer';
import { dateTimePicker, getDateTimeDisplay } from 'reducers/TimeReducer';
import Dictionary from 'components/Dictionary';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import formStyles, { gridStyle, Row } from 'utils/formStyles';
import DatePicker2 from 'components/DatePicker2';
import TimePicker2 from 'components/TimePicker2';
import TextField2 from 'components/TextField2';
import { formatSaveData } from 'utils/formStyles';
import { saveUnitAction } from 'reducers/SearchReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(250, '100%'),
  notes: {
    ...gridStyle(250, '100%'),
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
    },
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: `0 ${theme.spacing(2)}px`,
    '& button': {
      marginRight: theme.spacing(1),
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

function getDefaultEvent(events, ptsUnitID) {
  let output = '';
  events.forEach((event) => {
    const result = event.assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (result) output = event.ptsEventID;
  });
  return output;
}

const emptyFormData = {
  UnitStatus: null,
  Location: null,
  Mileage: null,
  Notes: null,
  OLN: null,
  OLNState: null,
  Plate: null,
  PlateState: null,
  Modifiers: [],
  ptsActionID: null,
  ptsDestinationID: null,
  Occurred: null,
  ptsEventID: null,
};

function DialogNote(props) {
  const classes = useStyles();
  const { events, units, data, options } = props;
  const [unit, setUnit] = useState(null);
  const [ptsUnitID, setPtsUnitID] = useState(null);
  const [formData, setFormData] = useState({ ...emptyFormData });
  const [title, setTitle] = useState('');
  const formDataCopy = useRef({ ...emptyFormData });
  const addDateToNote = options.UnitStatusNotesDate;
  const unitStatusRef = useRef(null);
  const mountedRef = useRef(true);

  useEffect(() => {
    const { ptsUnitID, ptsEventID } = data;
    getEditStatus(ptsUnitID, ptsEventID);
    setPtsUnitID(ptsUnitID);
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unit = units.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (unit) setUnit(unit);
    // eslint-disable-next-line
  }, [units]);

  const clearForm = () => {
    const newFormData = { ...emptyFormData };
    delete newFormData.Occurred;
    formDataCopy.current = { ...formData, ...newFormData };
    setFormData(formDataCopy.current);
  };

  const getEditStatus = async (ptsUnitID, ptsEventID) => {
    try {
      const result = await getUnitStatus(ptsUnitID, ptsEventID);
      processData(result);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (result) => {
    const {
      Location,
      Mileage,
      Notes,
      OLN,
      OLNState,
      Plate,
      PlateState,
      ptsActionID,
      ptsDestinationID,
      EventID,
      Action,
      Modifiers,
    } = result;

    const Occurred = dateTimePicker(result.Occurred);
    const formDataFromResult = {
      Location,
      Mileage,
      Notes,
      OLN,
      OLNState,
      Plate,
      PlateState,
      ptsActionID,
      ptsDestinationID,
      Occurred,
      EventID,
      status: Action,
      Modifiers: Modifiers ? JSON.parse(Modifiers) : [],
      UnitStatus: Action,
    };
    formDataFromResult.ptsEventID = data.ptsEventID
      ? data.ptsEventID
      : getDefaultEvent(events, data.ptsUnitID);
    formDataCopy.current = { ...emptyFormData, ...formDataFromResult };
    setFormData(formDataCopy.current);

    // set title
    let title = data.title;
    if (ptsActionID && result.Unit) {
      title += ` - ${result.Unit}`;
    } else {
      title += unit && unit.Unit ? ' - ' + unit.Unit : ' ';
    }
    setTitle(title || ' ');
  };

  const handleClose = () => {
    props.closeAddNewNote();
  };

  const save = () => {
    const data = formatSaveData(formData);
    data.ptsUnitID = ptsUnitID;
    updateUnitAction(data);
  };

  const updateUnitAction = (data) => {
    props.showSpinner();
    saveUnitAction(data)
      .then((result) => {
        props.notifyDataUpdate({ type: 'unit-status' });
        handleClose();
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            onClick={clearForm}>
            <CloseIcon /> Clear
          </Button>
          <Button variant="contained" color="primary" type="submit" size="small" onClick={save}>
            <SaveIcon /> Save
          </Button>
          <Button onClick={handleClose} color="primary" size="small">
            <CloseIcon /> Close
          </Button>
        </div>
      </div>
    );
  };

  const onChange = (name) => (ev, val) => {
    formDataCopy.current = { ...formDataCopy.current, [name]: val };
    setFormData((prevState) => ({ ...prevState, ...formDataCopy.current }));
  };

  const renderStatus = () => {
    return (
      <Dictionary
        disabled={true}
        options="UnitActions"
        className={classes.item}
        onChange={onChange('UnitStatus')}
        value={formData.UnitStatus}
        label="Status"
        inputRef={unitStatusRef}
        compact="true"
      />
    );
  };

  const renderStatusDateTime = () => {
    return (
      <>
        <DatePicker2
          disabled={true}
          label="Edit Status Date"
          className={classes.item}
          value={formData.Occurred}
          onChange={onChange('Occurred')}
        />
        <TimePicker2
          disabled={true}
          className={classes.item}
          label="Edit Status Time"
          value={formData.Occurred}
          onChange={onChange('Occurred')}
        />
      </>
    );
  };

  const renderEvent = () => {
    return (
      <EventFilter
        disabled={true}
        className={classes.item}
        ptsEventID={formData.ptsEventID}
        onChange={onChange('ptsEventID')}
      />
    );
  };

  const renderNotes = () => {
    const getUserData = () => {
      if (!addDateToNote) return '';
      const userName = props.user?.userData?.user?.Username;
      const created = getDateTimeDisplay() + (userName ? `, ${userName}` : '') + ': ';
      return created;
    };
    const onClick = () => {
      if (!formData.Notes) {
        const userData = getUserData();
        userData && onChange('Notes')(null, userData);
      }
    };
    const handleChange = (ev, val) => {
      const ch = val.charCodeAt(val.length - 1);
      if (ch === 10) return onChange('Notes')(ev, val + getUserData());
      onChange('Notes')(ev, val);
    };
    return (
      <TextField2
        label="Notes"
        className={classes.notes}
        onChange={handleChange}
        value={formData.Notes}
        onClick={onClick}
        multiline
        rows={10}
        compact="true"
      />
    );
  };

  return (
    <Dialog onClose={handleClose} title={title} actions={renderActions()}>
      <Row>{renderStatusDateTime()}</Row>
      <Row>{renderEvent()}</Row>
      <Row>{renderStatus()}</Row>

      <Row>{renderNotes()}</Row>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  events: state.events,
  units: state.units,
  user: state.user,
  notify: state.unitStatus.notify,
  notification: state.unitStatus.notification,
  permissions: state.permissions,
  options: state.config.options,
});

export default connect(mapStateToProps, {
  closeAddNewNote,
  showSpinner,
  hideSpinner,
  handleError,
  setNotify,
  showBulletinDetails,
  bulletinFound,
  findBulletins,
  notifyDataUpdate,
})(DialogNote);
