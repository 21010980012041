import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Subject } from 'rxjs';
import Tooltip from 'components/Tooltip';
import Toolbar from 'components/ToolBar';
import EventList from './EventList';
import IconButton from '@material-ui/core/IconButton';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { setFormOrigin } from 'reducers/NotifierReducer';
import settings from 'config/settings';
import { addEvent, editEvent } from 'reducers/DialogsReducer';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FilterListIcon from '@material-ui/icons/FilterList';
import { setEventFilter } from 'reducers/EventSortingReducer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  getFullPermissions,
  getAccessPermission,
  getAgenciesAccessPermission,
} from 'reducers/PermissionsReducer';
import { saveUserSetting } from 'reducers/UserSettingsReducer';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  eventsWrap: {
    display: 'flex',
    height: `calc(100vh - ${settings.mainNavHeight + 90}px)`,
    margin: -5,
    position: 'relative',
  },
  actions: {
    marginRight: theme.spacing(2),
  },
  scrollbar: {
    padding: 5,
    width: '100%',
    overflowY: 'auto',
  },
  select: {
    marginTop: 5,
    marginRight: 10,
  },
  toolbar: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& h3': {
      marginLeft: theme.spacing(1),
    },
  },
  filter: {
    width: 250,
    marginTop: 5,
    marginRight: 10,
  },
  switch: {
    marginLeft: theme.spacing(1),
    marginBottom: 0,
  },
  checkboxControl: {
    margin: 0,
  },
  checkbox: {
    padding: ' 0 8px 0 0',
  },
  iconBadge: {
    '& .MuiBadge-badge': {
      marginTop: 5,
      marginLeft: 5,
    },
  },
  mnuItem: {
    padding: 0,
    '& .MuiFormControlLabel-root': {
      padding: '8px 12px',
    },
  },
  scrollToTop: {
    position: 'absolute',
    bottom: 0,
    left: 15,
    opacity: 0.5,
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1,
    },
  },
}));

function Events(props) {
  const classes = useStyles();
  const {
    eventSort,
    eventSortBy,
    eventSortOrder,
    eventGroupBy,
    eventAgencyFilter,
    setEventFilter,
    Agencies,
    eventShowPinned,
    events,
  } = props;
  const { filter } = eventSort;
  const [unfold, setUnfold] = useState(false);
  const [unfoldSubject, setUnfoldSubject] = useState(null);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [filteredAgencies, setFilteredAgencies] = useState([]);
  const scrollRef = useRef(null);
  const visibleRef = useRef(false);
  const selectAll = useRef(false);
  const deselectAll = useRef(false);
  const permissions = getFullPermissions('cad', 'Events', 'any');

  useEffect(() => {
    setUnfoldSubject(new Subject());
    scrollRef.current.addEventListener('scroll', addOnScrollHandler);
  }, []);

  const addOnScrollHandler = () => {
    if (!visibleRef.current && scrollRef.current.scrollTop && scrollRef.current.scrollTop > 10) {
      visibleRef.current = true;
      setScrollTopVisible(true);
    }
    if (visibleRef.current && scrollRef.current.scrollTop < 2) {
      visibleRef.current = false;
      setScrollTopVisible(false);
    }
  };

  const scrollToTop = () => {
    scrollRef.current.scroll({ top: 0, behavior: 'smooth' });
  };

  const toggleFold = () => {
    unfoldSubject && unfoldSubject.next(!unfold);
    setUnfold(!unfold);
  };

  const addEvent = () => props.addEvent(true);

  const editEvent = (EventID) => props.editEvent(EventID);

  const renderAgencyFilter = () => {
    const toggleFilter = (AgencyID) => {
      let newAgencyFilter;

      if (AgencyID === 'selectAll') {
        newAgencyFilter = [];
        selectAll.current = !selectAll.current;
        deselectAll.current = false;
      } else if (AgencyID === 'deselectAll') {
        const allAgencies = Agencies.filter((agency) => {
          const canReadAgencies = getAgenciesFullPermission('cad', 'Events', 'Read');
          return canReadAgencies.indexOf(agency.AgencyID) !== -1;
        }).map(({ AgencyID }) => AgencyID);
        newAgencyFilter = [...allAgencies];
        deselectAll.current = !deselectAll.current;
        selectAll.current = false;
      } else {
        const present = eventAgencyFilter.indexOf(AgencyID) !== -1;
        if (present) {
          newAgencyFilter = eventAgencyFilter.filter((id) => id !== AgencyID);
        } else {
          newAgencyFilter = [...eventAgencyFilter];
          newAgencyFilter.push(AgencyID);
        }
        selectAll.current = false;
        deselectAll.current = false;
      }

      props.saveUserSetting('eventAgencyFilter', newAgencyFilter);
    };
    const toggleShowFilter = (ev) => setFilterAnchor(filterAnchor ? null : ev.currentTarget);
    //showAll = eventAgencyFilter.indexOf('All') !== -1;
    const noFiltered = eventAgencyFilter.filter((a) => a !== 'All').length;

    return (
      <>
        <Tooltip title="Filter units by agencies">
          <span onClick={toggleShowFilter}>
            {noFiltered && !selectAll.current ? (
              <Badge badgeContent={noFiltered} color="secondary" className={classes.iconBadge}>
                <IconButton size="small">
                  <FilterListIcon />
                </IconButton>
              </Badge>
            ) : (
              <IconButton size="small">
                <FilterListIcon />
              </IconButton>
            )}
          </span>
        </Tooltip>
        <Menu
          anchorEl={filterAnchor}
          keepMounted
          open={Boolean(filterAnchor)}
          onClose={toggleShowFilter}>
          <MenuItem value="filter" size="small" onKeyDown={(e) => e.stopPropagation()}>
            <QuickFilterSearch
              rows={Agencies}
              columns={['AgencyID']}
              setFilteredRows={setFilteredAgencies}
            />
          </MenuItem>
          <MenuItem value="Select All" size="small" className={classes.mnuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll.current}
                  onChange={() => toggleFilter('selectAll')}
                  className={classes.checkbox}
                />
              }
              label="Select All"
              className={classes.checkboxControl}
            />
          </MenuItem>
          <MenuItem value="Unselect All" size="small" className={classes.mnuItem}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={deselectAll.current}
                  onChange={() => toggleFilter('deselectAll')}
                  className={classes.checkbox}
                />
              }
              label="Deselect All"
              className={classes.checkboxControl}
            />
          </MenuItem>
          <hr style={{ margin: 0 }} />
          {filteredAgencies
            .filter((agency) => {
              const canReadAgencies = getAgenciesFullPermission('cad', 'Events', 'Read');
              return canReadAgencies.indexOf(agency.AgencyID) !== -1;
            })
            .map(({ AgencyID }) => {
              const checked = eventAgencyFilter.indexOf(AgencyID) === -1;
              const checkbox = (
                <Checkbox
                  checked={checked}
                  onChange={() => toggleFilter(AgencyID)}
                  className={classes.checkbox}
                  //disabled={selectAll.current || deselectAll.current}
                />
              );
              return (
                <MenuItem key={AgencyID} value={AgencyID} size="small" className={classes.mnuItem}>
                  <FormControlLabel
                    control={checkbox}
                    label={AgencyID}
                    className={classes.checkboxControl}
                  />
                </MenuItem>
              );
            })}
        </Menu>
      </>
    );
  };

  const renderToolbar = () => {
    const setEventSortBy = (ev) => {
      const val = ev.target.value;
      props.saveUserSetting('eventSortBy', val);
    };

    const setEventSortOrder = (ev) => {
      const val = ev.target.value;
      props.saveUserSetting('eventSortOrder', val);
    };

    const setEventGroupBy = (ev) => {
      const val = ev.target.value;
      props.saveUserSetting('eventGroupBy', val);
    };

    const setEventShowPinned = (ev, val) => {
      props.saveUserSetting('eventShowPinned', val);
    };

    return (
      <Toolbar className={classes.toolbar}>
        <div>
          <IconButton onClick={toggleFold}>
            {unfold && (
              <Tooltip title="Collapse all">
                <UnfoldLessIcon />
              </Tooltip>
            )}
            {!unfold && (
              <Tooltip title="Expand all">
                <UnfoldMoreIcon />
              </Tooltip>
            )}
          </IconButton>
          <div className={classes.actions}>
            <Tooltip title="Add Event">
              <span>
                <Fab
                  color="secondary"
                  onClick={addEvent}
                  size="small"
                  disabled={!permissions.Create}>
                  <AddIcon />
                </Fab>
              </span>
            </Tooltip>
          </div>
          <FormControl variant="outlined" size="small" className={classes.select}>
            <InputLabel htmlFor="unit-sort-options" className={classes.selectLabel}>
              Sort By
            </InputLabel>
            <Select
              native
              value={eventSortBy}
              onChange={setEventSortBy}
              label="Sort By"
              inputProps={{ id: 'unit-sort-options' }}>
              <option value="EventID">Event ID</option>
              <option value="Status">Status</option>
              <option value="CallType">Call Type</option>
              <option value="EventChangeDate">Status Time</option>
              <option value="ReceiveDate">Received Date/Time</option>
              <option value="Priority">Priority</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" className={classes.select}>
            <InputLabel htmlFor="unit-sort-options" className={classes.selectLabel}>
              Order
            </InputLabel>
            <Select
              native
              value={eventSortOrder}
              onChange={setEventSortOrder}
              label="Order"
              inputProps={{ id: 'unit-sort-options' }}>
              <option value="ASC">ASC</option>
              <option value="DESC">DESC</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" className={classes.select}>
            <InputLabel htmlFor="unit-group-by" className={classes.selectLabel}>
              Group By
            </InputLabel>
            <Select
              native
              value={eventGroupBy}
              onChange={setEventGroupBy}
              label="Group By"
              inputProps={{ id: 'unit-group-by' }}>
              <option value="none">None</option>
              <option value="Status">Status</option>
              <option value="CallType">Type</option>
              <option value="Priority">Priority</option>
            </Select>
          </FormControl>
          <TextField
            label="Filter"
            type="search"
            className={classes.filter}
            variant="outlined"
            size="small"
            onChange={(ev) => setEventFilter(ev.target.value)}
            value={filter}
          />
          <Tooltip title="Show pinned only">
            <FormControlLabel
              control={
                <Switch
                  checked={eventShowPinned}
                  onChange={setEventShowPinned}
                  name="eventShowPinned"
                />
              }
              className={classes.switch}
            />
          </Tooltip>
          {renderAgencyFilter()}
          <Box style={{ marginLeft: 30 }}>
            <Tooltip title={'Number of active events'}>
              <Badge badgeContent={events.length} color="secondary" />
            </Tooltip>
          </Box>
        </div>
      </Toolbar>
    );
  };

  return (
    <Fragment>
      {renderToolbar()}
      <div className={classes.eventsWrap}>
        <div className={classes.scrollbar} ref={scrollRef}>
          <EventList unfoldSubject={unfoldSubject} editEvent={editEvent} />
        </div>
        {scrollTopVisible && (
          <Tooltip title="Scroll to top" className={classes.scrollToTop}>
            <Fab onClick={scrollToTop} size="small" color="secondary">
              <KeyboardArrowUpIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    eventSort: state.eventSort,
    Agencies: state.dictionary.Agencies,
    eventSortBy: state.userSettings.eventSortBy,
    eventSortOrder: state.userSettings.eventSortOrder,
    eventGroupBy: state.userSettings.eventGroupBy,
    eventAgencyFilter: state.userSettings.eventAgencyFilter,
    eventShowPinned: state.userSettings.eventShowPinned,
    events: state.events,
  };
};

export default connect(mapStateToProps, {
  setFormOrigin,
  addEvent,
  editEvent,
  setEventFilter,
  saveUserSetting,
})(Events);
