import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { showReport, showEditPerson } from 'reducers/DialogsReducer';
import { searchCredentials } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EditIcon from '@material-ui/icons/Edit';
import { showEditCredential } from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import QuickSearch from '../components/QuickSearch';
import PersonIcon from '@material-ui/icons/Person';
import { Alert } from '@material-ui/lab';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'PersonID',
  'FullName',
  'IDNumber',
  'Type',
  'IssuedBy',
  'Description',
  'Class',
  'State',
  {
    field: 'IsCommercial',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Issued',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'ExpirationDate',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  { field: 'Age', headerName: 'Age', width: 150, hide: false },
  {
    field: 'BirthDate',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'Race',
  'Height',
  'Weight',
  'FirstName',
  'LastName',
  'NickName',
  {
    field: 'Dangerous',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'IsDeleted',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Created',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'CreatedBy',
  {
    field: 'Updated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'UpdatedBy',
];

const searchCols = ['FullName', 'Type', 'State', 'IDNumber'];
let lastSearchTerm = '';

function Credential(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rawRows, setRawRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [credentialColumns, setCredentialColumns] = useState(defaultColumns);
  const permissions = getFullPermissions('globals', 'People', 'any');
  const [printFlag, setPrintFlag] = useState(false);
  //const showAlert = rows.length >= 1000;
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'edit-credential') getCredentialData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = async (text = lastSearchTerm, offset = 0, mergeRows = false) => {
    lastSearchTerm = text;
    if (text.length < 3) return;
    setLoaded(false);
    searchCredentials(text, offset, limit)
      .then((result) => {
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);
        const nextStartingIndex = rows.length;
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 45,
      data: { ptsPersonID: item.ptsPersonID },
      title: 'Credential Details',
    });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showAddAttachment({ id: ptsPersonID, type: 'Person' });
  };

  const editCred = () => {
    let item = rows.find((row) => row.id === selection);
    props.showEditCredential(item);
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const handlePersonClick = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsPersonID } = item;
    props.showEditPerson(ptsPersonID);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(lastSearchTerm, offset, mergingRows);
    setPage(page + 1);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  return (
    <div>
      <h5>Credentials History</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickSearch
            search={search}
            rows={rawRows}
            setRows={setRows}
            cols={searchCols}
            loaded={loaded}
          />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              {permissions.Edit && (
                <Tooltip title="Edit Credential">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editCred}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              )}
              <Tooltip title="View details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="View Person">
                <Fab className="ml-2" size="small" color="secondary" onClick={handlePersonClick}>
                  <PersonIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Credentials"
        columns={credentialColumns}
        setColumns={setCredentialColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddAttachment,
  showEditCredential,
  showEditPerson,
})(Credential);
