import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { XGrid } from '@material-ui/x-grid';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import UpdateIcon from '@material-ui/icons/Update';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import Dialog from 'components/Dialog';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import {
  closeOutserviceUnitDialog,
  closeMultiSelectableUnitDialog,
  newUnitStatus,
  multipleUnitStatusChangeDialog,
} from 'reducers/DialogsReducer';
import {
  getOutserviceUnits,
  getStandByUnits,
  updateMultiUnitStatus,
  INSERVICE,
  STANDBY,
  OUTSERVICE,
  getUnitStatusByCategory,
} from 'reducers/UnitStatusReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { filterResults } from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DispatchMenu from 'pages/Units/DispatchMenu';
import CloseIcon from '@material-ui/icons/Close';
import { getUnitsAsync } from 'reducers/UnitsReducer';
import SnoozeIcon from '@material-ui/icons/Snooze';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  filter: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const columnsOutService = [
  { field: 'Unit', headerName: 'Unit', width: 150, type: 'string' },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'Resources', headerName: 'Resources', width: 150 },
  { field: 'Division', headerName: 'Division', width: 150 },
];

const columnsInService = [
  { field: 'Unit', headerName: 'Unit', width: 150, type: 'string' },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'Code', headerName: 'Status', width: 150 },
  { field: 'UnitResources', headerName: 'Resources', width: 150 },
  { field: 'Division', headerName: 'Division', width: 150 },
];

function AddMultiSelectableUnitDialog(props) {
  const classes = useStyles();
  const { data, UnitActions } = props;
  const { tabVal, agencyId, tabLabel, filterText = '' } = data;
  const [units, setUnits] = useState([]);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filter, setFilter] = useState(filterText);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [dispatchMenuOpen, setDispatchMenuOpen] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const dispatchRef = useRef();
  const statusStandby = getUnitCodeByCategory(STANDBY);

  useEffect(() => {
    setLoaded(false);
    getData();
    // eslint-disable-next-line
  }, [tabVal]);

  useEffect(() => {
    if (props.dataUpdate?.type === 'unit-status') getData();
    setSelection([]);
  }, [props.dataUpdate]);

  useEffect(() => {
    if (!filter) {
      setFilteredUnits(units);
    } else {
      const columns = [OUTSERVICE, STANDBY].includes(tabVal) ? columnsOutService : columnsInService;
      setFilteredUnits(filterResults(filter, units, columns));
    }
    // eslint-disable-next-line
  }, [filter, units]);

  function getUnitCodeByCategory(category) {
    const action = UnitActions.filter(
      (action) => action.Category && action.Category.toLowerCase() === category
    );
    return action.length && action[0].Code;
  }

  const getData = () => {
    let fn;
    switch (tabVal) {
      case OUTSERVICE:
        fn = getOutserviceUnits;
        break;
      case STANDBY:
        fn = getStandByUnits;
        break;
      default:
        fn = getUnitsAsync;
        break;
    }
    fn()
      .then((result) => {
        if (tabVal === INSERVICE) {
          setUnits(
            result
              .map((unit) => {
                return { ...unit, id: unit.ptsUnitID };
              })
              .filter((unit) => unit.AgencyID === agencyId && unit.UnitStatus !== statusStandby)
          );
        } else {
          setUnits(
            result
              .map((unit) => {
                return { ...unit, id: unit.ptsUnitID };
              })
              .filter((unit) => unit.AgencyID === agencyId)
          );
        }
      })
      .catch((err) => props.handleError(err, 'Error, Units not loaded.'))
      .finally(() => setLoaded(true));
  };

  const close = (openWindows) => {
    props.closeMultiSelectableUnitDialog(openWindows);
  };

  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const applyStatus = async (category) => {
    const ActionCode = getUnitStatusByCategory(category);
    props.showSpinner();
    try {
      await updateMultiUnitStatus(selection, ActionCode);
      props.notifyDataUpdate({ type: 'unit-status' });
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const newStatus = () => {
    const unitsData = selection.map((id) => {
      const unit = units.find((unit) => parseInt(id) === unit.ptsUnitID);
      unit.status = unit.UnitStatus;
      return unit;
    });
    props.multipleUnitStatusChangeDialog(unitsData);
    close();
  };

  const quickFilter = (ev) => {
    const val = ev.target.value;
    setFilter(val);
  };
  const handleClickDispatch = () => {
    const unitIds = selection.map((id) => parseInt(id));
    const selectedUnits = units.filter((unit) => unitIds.includes(unit.ptsUnitID));
    setSelectedUnits(selectedUnits);
    setDispatchMenuOpen(true);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div>
          <Button
            color="primary"
            variant="contained"
            autoFocus
            onClick={newStatus}
            disabled={!selection.length || selection.length > 5}>
            <UpdateIcon /> New Status
          </Button>
          {tabVal === INSERVICE && (
            <Button
              color="primary"
              variant="contained"
              autoFocus
              onClick={() => applyStatus(OUTSERVICE)}
              disabled={!selection.length}>
              <SnoozeIcon /> Out Service
            </Button>
          )}
          {(tabVal === OUTSERVICE || tabVal === STANDBY) && (
            <Button
              color="primary"
              variant="contained"
              autoFocus
              onClick={() => applyStatus(INSERVICE)}
              disabled={!selection.length}>
              <AddAlarmIcon /> In Service
            </Button>
          )}
          {[STANDBY].includes(tabVal) && (
            <Button onClick={handleClickDispatch} disabled={!selection.length}>
              <HeadsetMicIcon className={classes.menuIcon} /> Dispatch
              <span style={{ marginLeft: 'auto' }}>
                <ArrowRightIcon ref={dispatchRef} />
              </span>
            </Button>
          )}
        </div>

        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
        <DispatchMenu
          anchorEl={dispatchRef.current}
          open={dispatchMenuOpen}
          onClose={() => setDispatchMenuOpen(false)}
          units={selectedUnits}
        />
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <Tabs value={tabVal} className={classes.tabs}>
        <Tab label={tabLabel} value={tabVal} />
      </Tabs>
    );
  };

  const renderOutserviceUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsOutService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  const renderStandByUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsOutService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  const renderInserviceUnits = () => {
    return (
      <div className={classes.card}>
        <TextField
          label="Quick filter"
          type="search"
          className={classes.filter}
          variant="outlined"
          size="small"
          onChange={quickFilter}
          value={filter}
        />
        <div style={{ height: 520, width: '100%' }}>
          <XGrid
            columns={columnsInService}
            rows={filteredUnits}
            loading={!loaded}
            rowHeight={38}
            checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog onClose={close} title={'Add Units'} actions={renderActions()}>
      {renderTabs()}
      {tabVal === OUTSERVICE && renderOutserviceUnits()}
      {tabVal === STANDBY && renderStandByUnits()}
      {tabVal === INSERVICE && renderInserviceUnits()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    UnitActionCodes: state.config.options.UnitActionCodes,
    dataUpdate: state.dataUpdate,
    UnitActions: state.dictionary.UnitActions,
  };
};

export default connect(mapStateToProps, {
  closeOutserviceUnitDialog,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
  notify,
  newUnitStatus,
  closeMultiSelectableUnitDialog,
  multipleUnitStatusChangeDialog,
})(AddMultiSelectableUnitDialog);
