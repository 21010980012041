import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import SearchDatePicker from '../components/SearchDatePicker2';
import { showReport } from 'reducers/DialogsReducer';
import { searchNotes } from 'reducers/SearchReducer';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import TextField2 from 'components/TextField2';
import { Alert } from '@material-ui/lab';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const defaultColumns = [
  'EventID',
  'CaseID',
  'AgencyID',
  'Note',
  {
    field: 'EventDateTime',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'EventIsDeleted',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'DeletedBy',
  'DeletedIP',
];

function NotesHistory(props) {
  const classes = useStyles();
  const { filter } = props;
  const [rows, setRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [loaded, setLoaded] = useState(true);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [text, setText] = useState('');
  //const showAlert = rows.length >= 1000;
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const processData = (data, nextStatingIndex = 0) => {
    return data.map((row, idx) => {
      return { ...row, id: idx + nextStatingIndex };
    });
  };

  const search = (offset = 0, mergeRows = false) => {
    setLoaded(false);
    searchNotes(text, dateFrom, dateTo, filter, offset, limit)
      .then((result) => {
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);

        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        const nextStartingIndex = rows.length;
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewDetails = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 24, data: { ptsEventID: item.ptsEventID }, title: 'Event Details' });
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        {!!selection && (
          <>
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  return (
    <div>
      <h5>Notes History</h5>
      {renderSearch()}
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={renderActions()}
        loaded={loaded}
      />
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Notes"
        columns={columns}
        setColumns={setColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showReport,
})(NotesHistory);
