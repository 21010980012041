import React, { useState, useEffect, useRef } from 'react';
import { getService } from 'reducers/service';
import Button from '@material-ui/core/Button';
import { useSelector, connect, useDispatch } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import TextField2 from 'components/TextField2';
import styled from '@emotion/styled';
import { newEvent, subscribeEvents, unsubscribeEvents } from 'reducers/EventsReducer';
import { asyncForEach } from 'utils/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { sleep } from 'utils/functions';
import { addUnit } from 'reducers/SearchReducer';
import { updateUnitStatusPromise } from 'reducers/UnitStatusReducer';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import { leaveChannel, joinChannel } from 'reducers/service';
import { subscribeUnits, unsubscribeUnits } from 'reducers/UnitsReducer';
import { getAddressDetails } from 'reducers/AddressReducer';
import { addCoordsToLocation } from 'utils/mapFunctions';
import { getUnits } from 'reducers/UnitsReducer';
import moment from 'moment';

let minMaxCoords = {
  latMin: 30, // less go down
  latMax: 31,
  lngMin: -91.8, // less - go left
  lngMax: -90.5,
};

const storedCoords = window.localStorage.getItem('testCoords');
if (storedCoords) {
  minMaxCoords = JSON.parse(storedCoords);
}

let unitsCoords = {};

const coordStep = 0.005;

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Checkbox = styled.div`
  align-self: end;
  margin-left: 8px;
  cursor: pointer;
  input {
    margin-right: 8px;
  }
`;

const Console = styled.div`
  position: relative;
  div {
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    height: 400px;
    font-size: 14px;
    background: #252526;
    color: #cccccc;
    border: 1px solid #000;
    border-radius: 4px;
    white-space: pre-line;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 12px;
  }
  button {
    position: absolute;
    top: 2px;
    right: 5px;
    color: #f6f2f2;
    background: #333;
    transition: all 0.3s;
    border-radius: 3px;
    &:hover {
      background: #555;
      color: #fff;
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;

const Row = styled.div`
  margin: 0 0 16px;
  display: flex;
  button {
    margin: 0 8px;
  }
`;

const Content = styled.div`
  padding: 20px;
  label {
    margin-right: 10px;
    font-weight: 500;
    display: inline-block;
    width: 250px;
  }
`;

let eventTypeIdx = 0;
const waitTime = 500;

const callSigns = [
  'alpha',
  'bravo',
  'charlie',
  'delta',
  'echo',
  'foxtrot',
  'golf',
  'hotel',
  'india',
  'juliet',
  'kilo',
  'lima',
  'mike',
  'november',
  'oscar',
  'papa',
  'quebec',
  'romeo',
  'sierra',
  'tango',
  'uniform',
  'victor',
  'whiskey',
  'xray',
  'yankee',
  'zulu',
  'alfred',
  'benjamin',
  'charles',
  'david',
  'edward',
  'frederick',
  'george',
  'harry',
  'isaac',
  'jack',
  'king',
  'london',
  'mary',
  'nellie',
  'oliver',
  'peter',
  'queen',
  'robert',
  'samuel',
  'tommy',
  'uncle',
  'victor',
  'william',
  'xray',
  'yellow',
  'zebra',
  'amsterdam',
  'baltimore',
  'casablanca',
  'denmark',
  'edison',
  'florida',
  'gallipoli',
  'havana',
  'italia',
  'jerusalem',
  'kilogramme',
  'liverpool',
  'madagascar',
  'new york',
  'oslo',
  'paris',
  'quebec',
  'roma',
  'santiago',
  'tripoli',
  'uppsala',
  'valencia',
  'washington',
  'xantippe',
  'yokohama',
  'zurich',
  'army',
  'brother',
  'cinema',
  'doctor',
  'english',
  'father',
  'gold',
  'hotel',
  'india',
  'jam',
  'king',
  'lady',
  'mother',
  'navy',
  'orange',
  'paper',
  'queen',
  'raja',
  'sister',
  'table',
  'uncle',
  'victory',
  'water',
  'ray',
  'yellow',
  'zero',
  'africa',
  'bombay',
  'charlie',
  'durban',
  'england',
  'freddie',
  'george',
  'harry',
  'india',
  'japan',
  'kenya',
  'london',
  'mombasa',
  'nairobi',
  'orange',
  'peter',
  'queen',
  'robert',
  'sugar',
  'tanga',
  'uganda',
  'victory',
  'william',
  'ray',
  'yellow',
  'zanzibar',
  'arthur',
  'betty',
  'charlie',
  'david',
  'edward',
  'frederick',
  'george',
  'harry',
  'isaac',
  'jane',
  'kate',
  'lucy',
  'mary',
  'nellie',
  'olive',
  'peter',
  'queen',
  'robert',
  'simon',
  'thomas',
  'union',
  'violet',
  'william',
  'ray',
  'york',
  'zero',
  'australia',
  'bombay',
  'china',
  'denmark',
  'england',
  'fiji',
  'ghana',
  'hongkong',
  'india',
  'japan',
  'kedah',
  'london',
  'malacca',
  'norway',
  'osaka',
  'penang',
  'queensland',
  'russia',
  'singapore',
  'turkey',
  'uganda',
  'victoria',
  'wales',
  "x'ray",
  'yokohama',
  'zanzibar',
  'adam',
  'boy',
  'charlie',
  'david',
  'edward',
  'frank',
  'george',
  'henry',
  'ida',
  'john',
  'king',
  'lincoln',
  'mary',
  'nora',
  'ocean',
  'peter',
  'queen',
  'robert',
  'sam',
  'tom',
  'union',
  'victor',
  'william',
  'ray',
  'young',
  'zebra',
  'adam',
  'boston',
  'chicago',
  'denver',
  'edward',
  'frank',
  'george',
  'henry',
  'ida',
  'john',
  'king',
  'lincoln',
  'mary',
  'nancy',
  'ocean',
  'peter',
  'queen',
  'robert',
  'sam',
  'thomas',
  'union',
  'victor',
  'william',
  'ray',
  'young',
  'zebra',
];

function generateString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const uCoords = {};
let evEnabled = true;
let unEnabled = true;
let evSubscribed = true;
let unSubscribed = true;

let statusesTimer = 0;
let coordTimer = 0;

function PerformanceTesting(props) {
  const dispatch = useDispatch();
  const CallTypes = useSelector((state) => state.dictionary.CallTypes);
  const events = useSelector((state) => state.events);
  const origUnits = useSelector((state) => state.units);
  const dictionary = useSelector((state) => state.dictionary);
  const [newEventsNo, setNewEventsNo] = useState(1);
  const [newUnitsNo, setNewUnitsNo] = useState(1);
  const [busy, setBusy] = useState(false);
  const [units, setUnits] = useState(false);
  const [progress, setProgress] = useState(0);
  const [statusesTime, setStatusesTime] = useState(5);
  const [statusesRunning, setStatusesRunning] = useState(false);
  const [unitRefresh, setUnitRefresh] = useState({ time: 0 });
  const [lockedUnits, setLockedUnits] = useState([]);
  const [coordTime, setCoordTime] = useState(1);
  const [coordPercent, setCoordPercent] = useState(50);
  const [coordsRunning, setCoordsRunning] = useState(false);
  const [coordsRefresh, setCoordsRefresh] = useState(null);
  const [consoleText, setConsoleText] = useState('');
  const [logCoords, setLogCoords] = useState(false);
  const [logStatus, setLogStatus] = useState(false);
  const [eventsEnabled, setEventsEnabled] = useState(evEnabled);
  const [unitsEnabled, setUnitsEnabled] = useState(unEnabled);
  const [eventsSubscribed, setEventsSubscribed] = useState(evSubscribed);
  const [unitsSubscribed, setUnitsSubscribed] = useState(unSubscribed);
  const [search, setSearch] = useState('%stre%');
  const [addressCount, setAddressCount] = useState(0);
  const [addressIds, setAddressIds] = useState([]);
  const [addAddress, setAddAddress] = useState(true);
  const [coordLimits, setCoordLimits] = useState(minMaxCoords);
  const { UnitActions, Division } = dictionary;
  const stopClicked = useRef(false);
  const coordsBlocked = useRef(false);
  const clearRef = useRef(false);
  const consoleRef = useRef('');
  const pingRef = useRef(false);
  const Agencies = getAgenciesFullPermission('cad', 'Units', 'Read');

  useEffect(() => {
    getAddressCount();
    getAddressIDs();
    return () => {
      clearInterval(statusesTimer);
      clearInterval(coordTimer);
    };
  }, []);

  useEffect(() => {
    updateUnits();
  }, [origUnits]);

  useEffect(() => {
    if (statusesRunning) {
      changeNextStatus();
    }
  }, [unitRefresh]);

  useEffect(() => {
    if (!coordsRefresh) return;
    nextCoords();
  }, [coordsRefresh]);

  const getAddressCount = async () => {
    const service = getService();
    try {
      const result = await service.get({
        type: 'cad-testing',
        data: { type: 'address-count' },
      });
      setAddressCount(result[0].AddressCount);
    } catch (err) {
      props.handleError(err);
    }
  };

  const getAddressIDs = async () => {
    const service = getService();
    setBusy(true);
    try {
      const result = await service.get({
        type: 'cad-testing',
        data: { type: 'address-ids' },
      });
      const ids = result.map((e) => e.id);
      setAddressIds(ids);
    } catch (err) {
      props.handleError(err);
    }
    setBusy(false);
  };

  const getLocation = async () => {
    const idx = Math.round(Math.random() * addressIds.length) - 1;
    const ptsAddressID = addressIds[idx];
    if (!ptsAddressID) {
      return null;
    }
    let address = null;
    try {
      const result = await getAddressDetails(ptsAddressID);
      if (!result?.data?.length) return null;
      address = result.data[0];
    } catch (err) {
      props.handleError(err);
    }
    try {
      const addressWithCoords = await addCoordsToLocation(address);
      if (addressWithCoords) address = addressWithCoords;
    } catch (err) {
      console.log('Error attaching coordinates to an address');
    }
    return address;
  };

  const closeAllEvents = async () => {
    if (busy) return;
    setBusy(true);
    setProgress(0);
    const no = events.length;
    let i = 0;
    await asyncForEach(events, async (event) => {
      if (!stopClicked.current) {
        const { ptsEventID } = event;
        try {
          await closeEvent(ptsEventID);
        } catch (err) {
          dispatch(handleError(err));
        }
        const progress = parseInt(((i++ + 1) * 100) / no);
        setProgress(progress);
        await sleep(waitTime);
      }
    });
    if (stopClicked.current) stopClicked.current = false;
    setBusy(false);
  };

  const closeEvent = async (ptsEventID) => {
    const service = getService('event-status-change');
    try {
      const time1 = new Date().getTime();
      await service.update(ptsEventID, { ptsEventID, Status: 'Closed' });
      const time2 = new Date().getTime();
      if (logStatus) dbg(`Close event ptsEventID: ${ptsEventID}, time: ${time2 - time1}`);
    } catch (err) {
      if (logStatus) dbg(`Failed to close new event ptsEventID: ${ptsEventID}`);
      dispatch(handleError(err));
    }
  };

  const createEvents = async () => {
    if (busy) return;
    const no = parseInt(newEventsNo);
    setProgress(0);
    setBusy(true);
    for (let i = 0; i < no; i++) {
      try {
        await createEvent();
      } catch (err) {
        dispatch(handleError(err));
      }
      const progress = parseInt(((i + 1) * 100) / no);
      setProgress(progress);
      await sleep(waitTime);
      if (stopClicked.current) {
        stopClicked.current = false;
        break;
      }
    }
    setBusy(false);
  };

  const createEvent = async () => {
    let location = null;
    if (addAddress) location = await getLocation();
    const typeLen = CallTypes.length;
    const idx = eventTypeIdx % typeLen;
    const CallType = CallTypes[idx].Code;
    const LocationDescription = 'Some Location ' + eventTypeIdx++;
    const data = {
      Event: {
        CallType,
        CallSubType: null,
        CallMethod: null,
        RequestedAction: null,
        LocationDescription,
        EventDescription: null,
        lat: null,
        lng: null,
        AddressHistory: 0,
      },
      Locations: [],
      Callers: [],
    };
    if (location) {
      data.Locations[0] = location;
      data.Event.LocationDescription = null;
    }
    try {
      const time1 = new Date().getTime();
      await newEvent(data);
      const time2 = new Date().getTime();
      if (logStatus) dbg(`Create new event ${CallType}, time: ${time2 - time1}`);
    } catch (err) {
      if (logStatus) dbg(`Failed to create new event`);
      dispatch(handleError(err));
    }
  };

  const renderAddEvents = () => {
    const joinEvents = () => {
      joinChannel('events');
      setEventsEnabled(true);
      evEnabled = true;
    };
    const leaveEvents = () => {
      leaveChannel('events');
      setEventsEnabled(false);
      evEnabled = false;
    };
    const subscrEvents = () => {
      props.subscribeEvents();
      setEventsSubscribed(true);
      evSubscribed = true;
    };
    const unsubscrEvents = () => {
      unsubscribeEvents();
      setEventsSubscribed(false);
      evSubscribed = false;
    };
    const onAddAddress = () => {
      setAddAddress(!addAddress);
      if (!addressIds.length) getAddressIDs();
    };
    return (
      <Row>
        <TextField2
          label="No to add"
          value={newEventsNo}
          onChange={(ev, val) => setNewEventsNo(val)}
          style={{ width: 100 }}
          compact="true"
        />
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={createEvents}
          disabled={busy}>
          Add events
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={closeAllEvents}
          disabled={busy}>
          Close all events
        </Button>
        {eventsEnabled && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={leaveEvents}
            disabled={busy}>
            Leave Channel Events
          </Button>
        )}
        {!eventsEnabled && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={joinEvents}
            disabled={busy}>
            Join Channel Events
          </Button>
        )}
        {eventsSubscribed && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={unsubscrEvents}
            disabled={busy}>
            Unsubscribe Events
          </Button>
        )}
        {!eventsSubscribed && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={subscrEvents}
            disabled={busy}>
            Subscribe Events
          </Button>
        )}
        <Checkbox onClick={onAddAddress}>
          <input type="checkbox" checked={addAddress} onChange={() => {}} />
          <span>Add Address</span>
        </Checkbox>
      </Row>
    );
  };

  const updateUnits = async () => {
    const units = origUnits
      .map((u) => {
        const { UnitStatus } = u;
        const action = UnitActions.find((s) => s.Code === UnitStatus);
        let actionCategory = action ? action.Category : null;
        if (typeof actionCategory === 'string') actionCategory = actionCategory.toLowerCase();
        if (UnitStatus === 'Multiple Calls') actionCategory = 'multiple';
        return { ...u, actionCategory };
      })
      .filter((u) => u.actionCategory != 'standby');
    setUnits(units);
  };

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getRandomRecord = (records) => {
    return records[getRandomInt(0, records.length - 1)];
  };

  const capitalize = (string) => {
    const firstLetter = string[0].toUpperCase();
    return firstLetter + string.substring(1, string.length);
  };

  const createTestUnits = async () => {
    const currentUnits = units.map((u) => u.Unit);
    const no = parseInt(newUnitsNo);
    setBusy(true);
    for (let i = 0; i < no; i++) {
      const AgencyID = getRandomRecord(Agencies);
      const division = getRandomRecord(Division);
      const callSignBase = getRandomRecord(callSigns);
      let callSign = capitalize(callSignBase) + '_T';
      let j = 0;
      while (currentUnits.find((u) => u === callSign)) {
        callSign = capitalize(callSignBase) + ++j + '_T';
      }
      currentUnits.push(callSign);
      const data = {
        Unit: callSign,
        AgencyID,
        Division: division.Code,
        Zone: null,
        ContactType: null,
        ptsContactID: null,
        Notes: null,
        IsActive: true,
      };
      if (stopClicked.current) {
        stopClicked.current = false;
        break;
      }
      try {
        await addUnit(data);
      } catch (err) {
        dispatch(handleError(err));
      }
      const progress = parseInt(((i + 1) * 100) / no);
      setProgress(progress);
      await sleep(waitTime);
    }
    setBusy(false);
  };

  const removeTestUnits = async () => {
    setBusy(true);
    const service = getService();
    const toBeRemoved = units
      .filter((u) => {
        const { Unit } = u;
        const len = Unit.length;
        const ending = Unit.substring(len - 2, len);
        return ending === '_T';
      })
      .map((u) => u.ptsUnitID);
    const no = toBeRemoved.length;
    for (let i = 0; i < no; i++) {
      if (stopClicked.current) {
        stopClicked.current = false;
        break;
      }
      const ptsUnitID = toBeRemoved[i];
      try {
        await service.get({ type: 'cad-testing', data: { type: 'remove-unit', ptsUnitID } });
        const progress = parseInt(((i + 1) * 100) / no);
        setProgress(progress);
        await sleep(waitTime);
      } catch (err) {
        dispatch(handleError(err));
      }
    }
    setBusy(false);
  };

  const renderAddUnits = () => {
    const joinUnits = () => {
      joinChannel('units');
      setUnitsEnabled(true);
      unEnabled = true;
    };
    const leaveUnits = () => {
      leaveChannel('units');
      setUnitsEnabled(false);
      unEnabled = false;
    };
    const subscrUnits = () => {
      props.subscribeUnits();
      setUnitsSubscribed(true);
      unSubscribed = true;
    };
    const unsubscrUnits = () => {
      unsubscribeUnits();
      setUnitsSubscribed(false);
      unSubscribed = false;
    };
    const updateCoordLimits = (key, val) => {
      const newCoordsLimits = { ...coordLimits, [key]: parseFloat(val) };
      unitsCoords = {};
      setCoordLimits(newCoordsLimits);
      window.localStorage.setItem('testCoords', JSON.stringify(newCoordsLimits));
    };
    return (
      <>
        <Row>
          <TextField2
            label="No to add"
            value={newUnitsNo}
            onChange={(ev, val) => setNewUnitsNo(val)}
            style={{ width: 100 }}
            compact="true"
          />
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={createTestUnits}
            disabled={busy}>
            Add Test Units
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={removeTestUnits}
            disabled={busy}>
            Remove Test Units
          </Button>
          {unitsEnabled && (
            <Button
              variant="contained"
              style={{ marginRight: 8 }}
              color="primary"
              onClick={leaveUnits}
              disabled={busy}>
              Leave Channel Units
            </Button>
          )}
          {!unitsEnabled && (
            <Button
              variant="contained"
              style={{ marginRight: 8 }}
              color="primary"
              onClick={joinUnits}
              disabled={busy}>
              Join Channel Units
            </Button>
          )}
          {unitsSubscribed && (
            <Button
              variant="contained"
              style={{ marginRight: 8 }}
              color="primary"
              onClick={unsubscrUnits}
              disabled={busy}>
              Unsubscribe Units
            </Button>
          )}
          {!unitsSubscribed && (
            <Button
              variant="contained"
              style={{ marginRight: 8 }}
              color="primary"
              onClick={subscrUnits}
              disabled={busy}>
              Subscribe Units
            </Button>
          )}
        </Row>
        <Row>
          <TextField2
            label="Min Lat"
            value={coordLimits.latMin}
            onChange={(ev, val) => updateCoordLimits('latMin', val)}
            style={{ width: 70 }}
            compact="true"
          />
          &nbsp;
          <TextField2
            label="Max Lat"
            value={coordLimits.latMax}
            onChange={(ev, val) => updateCoordLimits('latMax', val)}
            style={{ width: 70 }}
            compact="true"
          />
          &nbsp;
          <TextField2
            label="Min Lng"
            value={coordLimits.lngMin}
            onChange={(ev, val) => updateCoordLimits('lngMin', val)}
            style={{ width: 70 }}
            compact="true"
          />
          &nbsp;
          <TextField2
            label="Max Lng"
            value={coordLimits.lngMax}
            onChange={(ev, val) => updateCoordLimits('lngMax', val)}
            style={{ width: 70 }}
            compact="true"
          />{' '}
          &nbsp;{' '}
          <div>
            lat less - down
            <br /> lng less - left
          </div>
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={() => {
              dispatch(getUnits());
            }}
            disabled={busy}>
            Update Units
          </Button>
        </Row>
      </>
    );
  };

  const getUnitStatusCode = (unit) => {
    const status = unit.UnitStatus;
    if (status === 'Multiple Calls') return 'multiple';
    if (!status) return 'inservice';
    const action = UnitActions.find((a) => a.Code === status);
    return action && action.Category ? action.Category.toLowerCase() : 'inservice';
  };

  const changeUnitStatus = async (unit, category, ptsEventID = null) => {
    const { ptsUnitID } = unit;
    const status = UnitActions.find((u) => u.Category && u.Category.toLowerCase() === category);
    const UnitStatus = status.Code;
    const data = {
      Location: null,
      Mileage: null,
      Modifiers: null,
      Notes: null,
      OLN: null,
      Occurred: null,
      Plate: null,
      UnitStatus,
      ptsActionID: null,
      ptsEventID,
      ptsUnitID,
    };
    setLockedUnits([...lockedUnits, ptsUnitID]);
    let time1, time2;
    try {
      time1 = new Date().getTime();
      await updateUnitStatusPromise(data, ptsEventID);
      time2 = new Date().getTime();
      if (logStatus)
        dbg(`Change status for unit ${unit.Unit} - ${UnitStatus} time: ${time2 - time1}ms`);
    } catch (err) {
      if (logStatus) dbg(`Failed unit status change for unit ${unit.Unit}`);
      dispatch(handleError(err));
    }
    setLockedUnits(lockedUnits.filter((u) => u.ptsUnitID !== ptsUnitID));
  };

  const pickEvent = () => {
    let event = null;
    let noOfUnits = 9999;
    events.forEach((ev) => {
      const { assignedUnits } = ev;
      const unitCount = assignedUnits.length;
      if (unitCount < noOfUnits) {
        noOfUnits = unitCount;
        event = ev;
      }
    });
    return event;
  };

  const completeEvent = async (event) => {
    await closeEvent(event.ptsEventID);
    await createEvent();
  };

  const dispatchUnit = (unit) => {
    const event = pickEvent();
    changeUnitStatus(unit, 'dispatch', event.ptsEventID);
  };

  const getEventUnits = (event) => {
    return event.assignedUnits.map((u) => {
      const { UnitStatus } = u;
      const action = UnitActions.find((s) => s.Code === UnitStatus);
      let actionCategory = action ? action.Category : null;
      if (typeof actionCategory === 'string') actionCategory = actionCategory.toLowerCase();
      return { ...u, actionCategory };
    });
  };

  const handleCompleted = (unit) => {
    const event = events.find((ev) => {
      const { assignedUnits } = ev;
      const found = assignedUnits.find((u) => u.ptsUnitID === unit.ptsUnitID);
      return Boolean(found);
    });
    if (!event) {
      changeUnitStatus(unit, 'inservice');
      return;
    }
    const eventUnits = getEventUnits(event);
    const allComplete = eventUnits.reduce(
      (res, val) => (val.actionCategory !== 'completed' ? false : res),
      true
    );
    if (allComplete) {
      completeEvent(event);
    } else {
      changeUnitStatus(unit, 'inservice');
    }
  };

  const unblockEvent = async (unit) => {
    const unitEvents = events.filter((ev) => {
      const { assignedUnits } = ev;
      const found = assignedUnits.find((u) => u.ptsUnitID === unit.ptsUnitID);
      return Boolean(found);
    });
    for (let i = 0; i < unitEvents.length; i++) {
      const ev = unitEvents[i];
      const u = ev.assignedUnits.find((u) => u.ptsUnitID === unit.ptsUnitID);
      if (u) {
        await changeUnitStatus(u, 'inservice', ev.ptsEventID);
      }
    }
  };

  const changeNextStatus = () => {
    const unit = getRandomRecord(units);
    const { ptsUnitID } = unit;
    if (lockedUnits.length > 10) {
      if (logStatus) dbg('Unit statuss change blocked - too many waiting requests');
      return;
    }
    if (lockedUnits.find((u) => u.ptsUnitID === ptsUnitID)) {
      if (logStatus) dbg(`Unit ${unit.Unit} is locked - cancel changing status`);
      return;
    }
    const statusCode = getUnitStatusCode(unit);
    switch (statusCode) {
      case 'inservice':
        dispatchUnit(unit);
        break;
      case 'dispatch':
        changeUnitStatus(unit, 'enroute');
        break;
      case 'enroute':
        changeUnitStatus(unit, 'arrived');
        break;
      case 'arrived':
        changeUnitStatus(unit, 'completed');
        break;
      case 'completed':
        handleCompleted(unit);
        break;
      case 'multiple':
        unblockEvent(unit);
        break;
    }
  };

  const startStatuses = () => {
    setStatusesRunning(true);
    statusesTimer = setInterval(() => {
      setUnitRefresh({ i: 0 });
    }, parseInt(statusesTime) * 1000);
  };

  const stopStatuses = () => {
    clearInterval(statusesTimer);
    setStatusesRunning(false);
  };

  const allInservice = async () => {
    const notInservice = units.filter((u) => u.actionCategory !== 'inservice');
    const no = notInservice.length;
    if (!no) return;
    setBusy(true);
    for (let i = 0; i < no; i++) {
      if (stopClicked.current) {
        stopClicked.current = false;
        break;
      }
      await changeUnitStatus(notInservice[i], 'inservice');
      const progress = parseInt(((i + 1) * 100) / no);
      setProgress(progress);
      await sleep(waitTime);
    }
    setBusy(false);
  };

  const renderStatuses = () => {
    return (
      <Row>
        <TextField2
          label="Every sec"
          value={statusesTime}
          onChange={(ev, val) => setStatusesTime(val)}
          style={{ width: 100 }}
          compact="true"
        />
        {!statusesRunning && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={startStatuses}>
            Start Statuses
          </Button>
        )}
        {statusesRunning && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={stopStatuses}>
            Stop Statuses
          </Button>
        )}
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={allInservice}
          disabled={statusesRunning}>
          All Inservice
        </Button>
        <Checkbox onClick={() => setLogStatus(!logStatus)}>
          <input type="checkbox" checked={logStatus} onChange={() => {}} />
          <span>Log</span>
        </Checkbox>
      </Row>
    );
  };

  const startCoords = () => {
    setCoordsRunning(true);
    coordTimer = setInterval(() => {
      setCoordsRefresh({ t: 0 });
    }, parseInt(coordTime) * 1000);
  };

  const stopCoords = () => {
    setCoordsRunning(false);
    clearInterval(coordTimer);
  };

  function getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  const nextCoords = async () => {
    if (coordsBlocked.current) {
      if (logCoords) dbg('Previous coords request is still in progress - new request cancelled');
      return;
    }
    const time1 = new Date().getTime();
    const perCent = parseInt(coordPercent) / 100;
    coordsBlocked.current = true;
    const service = getService();
    const { latMin, latMax, lngMin, lngMax } = coordLimits;
    const dataToBeSend = [];
    for (let i = 0; i < units.length; i++) {
      const rnd = Math.random();
      if (rnd > perCent) continue;
      const unit = units[i];
      const coords = unitsCoords[unit.ptsUnitID];
      const lat = coords ? coords.lat : getRandom(latMin, latMax);
      const lng = coords ? coords.lng : getRandom(lngMin, lngMax);
      const xCoords = uCoords[unit.ptsUnitID];
      const randLat = xCoords ? xCoords.lat : Math.random() * coordStep - 0.5 * coordStep;
      const randLng = xCoords ? xCoords.lng : Math.random() * coordStep - 0.5 * coordStep;
      uCoords[unit.ptsUnitID] = { lat: randLat, lng: randLng };
      let newLat = lat + randLat;
      if (newLat < latMin) {
        newLat = latMin - randLat;
        uCoords[unit.ptsUnitID].lat = -randLat;
        // console.log('Lat over the limit');
      }
      if (newLat > latMax) {
        newLat = latMax - randLat;
        uCoords[unit.ptsUnitID].lat = -randLat;
        // console.log('Lat over the limit');
      }
      let newLng = lng + randLng;
      if (newLng < lngMin) {
        newLng = lngMin - randLng;
        // console.log('Lng over the limit', newLng);
        uCoords[unit.ptsUnitID].lng = -randLng;
      }
      if (newLng > lngMax) {
        newLng = lngMax - randLng;
        // console.log('Lng over the limit', newLng);
        uCoords[unit.ptsUnitID].lng = -randLng;
      }
      // console.log(uCoords[unit.ptsUnitID]);
      const newCoords = {
        lat: newLat,
        lng: newLng,
      };
      unitsCoords[unit.ptsUnitID] = newCoords;
      dataToBeSend.push({
        ptsUnitID: unit.ptsUnitID,
        lat: newLat,
        lng: newLng,
      });
    }
    try {
      await service.get({
        type: 'cad-testing',
        data: {
          type: 'update-unit-coords',
          data: dataToBeSend,
        },
      });
    } catch (err) {
      dispatch(handleError(err));
    }
    if (logCoords)
      dbg(
        'Updated ' +
          dataToBeSend.length +
          ' unit coords in ' +
          (new Date().getTime() - time1) +
          ' ms'
      );
    coordsBlocked.current = false;
  };

  const renderCoords = () => {
    return (
      <Row>
        <TextField2
          label="Every sec"
          value={coordTime}
          onChange={(ev, val) => setCoordTime(val)}
          style={{ width: 100 }}
          compact="true"
        />
        &nbsp;
        <TextField2
          label="Unit %"
          value={coordPercent}
          onChange={(ev, val) => setCoordPercent(val)}
          style={{ width: 100 }}
          compact="true"
        />
        {!coordsRunning && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={startCoords}>
            Start
          </Button>
        )}
        {coordsRunning && (
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={stopCoords}>
            Stop
          </Button>
        )}
        <Checkbox onClick={() => setLogCoords(!logCoords)}>
          <input type="checkbox" checked={logCoords} onChange={() => {}} />
          <span>Log</span>
        </Checkbox>
      </Row>
    );
  };

  const pingApi = async (size = 0) => {
    if (pingRef.current) {
      dbg('Ping cancelled as previous request is still in progress');
    }
    let sizeStr = size + 'B';
    if (size > 1024) sizeStr = parseInt(size / 1024) + 'kB';
    const data = generateString(size);
    pingRef.current = true;
    const service = getService();
    try {
      const time1 = new Date().getTime();
      await service.get({ type: 'cad-testing', data: { type: 'ping-api', data } });
      const time2 = new Date().getTime();
      dbg(`Ping (${sizeStr}), time: ${time2 - time1}ms`);
    } catch (err) {
      dispatch(handleError(err));
    }
    pingRef.current = false;
  };

  const pingDB = async () => {
    if (pingRef.current) {
      dbg('Request cancelled as previous request is still in progress');
    }
    pingRef.current = true;
    const service = getService();
    let result, time1, time2;
    try {
      time1 = new Date().getTime();
      result = await service.get({
        type: 'cad-testing',
        data: { type: 'ping-db' },
      });
      time2 = new Date().getTime();
    } catch (err) {
      dispatch(handleError(err));
    }
    if (result)
      dbg(
        `Ping DB, API time: ${time2 - time1 - result.time}ms, DB time: ${result.time}ms, total: ${
          time2 - time1
        }ms`
      );
    pingRef.current = false;
  };

  const searchAddr = async () => {
    if (pingRef.current) {
      dbg('Request cancelled as previous request is still in progress');
    }
    pingRef.current = true;
    const service = getService();
    let result, time1, time2;
    try {
      time1 = new Date().getTime();
      result = await service.get({
        type: 'cad-testing',
        data: { type: 'search-address', data: search },
      });
      time2 = new Date().getTime();
    } catch (err) {
      dispatch(handleError(err));
    }
    if (result)
      dbg(
        `Search for ${search}, API time: ${time2 - time1 - result.time}ms, DB time: ${
          result.time
        }ms, total: ${time2 - time1}ms`
      );
    pingRef.current = false;
  };

  const renderRequests = () => {
    return (
      <>
        <Row>
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={() => pingApi(0)}>
            API Ping
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={() => pingApi(1024)}>
            API Ping 1kB
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={() => pingApi(1024 * 10)}>
            API Ping 10kB
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={() => pingApi(1024 * 100)}>
            API Ping 100kB
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={() => pingApi(1024 * 1024)}>
            API Ping 1MB
          </Button>
          <Button variant="contained" style={{ marginRight: 8 }} color="primary" onClick={pingDB}>
            Ping DB
          </Button>
        </Row>
        <Row>
          <TextField2
            label="Search"
            value={search}
            onChange={(ev, val) => setSearch(val)}
            style={{ width: 200 }}
            compact="true"
          />
          <Button
            variant="contained"
            style={{ marginRight: 8 }}
            color="primary"
            onClick={searchAddr}>
            Search
          </Button>
        </Row>
      </>
    );
  };

  const dbg = (text) => {
    let prevText = consoleRef.current;
    if (clearRef.current) {
      clearRef.current = false;
      prevText = '';
    }
    const t = new Date().getTime();
    const time = moment(t).format('HH:mm:ss');
    const out = prevText + '\n' + time + ': ' + text;
    consoleRef.current = out;
    setConsoleText(out);
  };

  const renderConsole = () => {
    const clearConsole = () => {
      clearRef.current = true;
      setConsoleText('');
    };
    return (
      <Console>
        <h5>Console</h5>
        <button onClick={clearConsole}>Clear</button>
        <div>{consoleText}</div>
      </Console>
    );
  };

  return (
    <Content>
      <hr />
      <h5>Events</h5>
      <Row>
        Total events: {events.length}, available addresses: {addressCount}
      </Row>
      {renderAddEvents()}
      <hr />
      <h5>Units</h5>
      <Row>Total units: {units.length}</Row>
      {renderAddUnits()}
      {busy && (
        <Loader>
          <CircularProgressWithLabel value={progress} />
          <Button onClick={() => (stopClicked.current = true)}>Stop</Button>
        </Loader>
      )}
      <hr />
      <h5>Statuses</h5>
      {renderStatuses()}
      <hr />
      <h5>Unit Coords</h5>
      {renderCoords()}
      <hr />
      <h5>Requests</h5>
      {renderRequests()}
      <hr />
      {renderConsole()}
    </Content>
  );
}

export default connect(null, {
  subscribeEvents,
  subscribeUnits,
})(PerformanceTesting);
