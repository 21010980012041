import store from '../config/configureStore';
import settings from 'config/settings';
import { getService } from './service';
import { handleError } from './ErrorReducer';
import { setSettingsVal } from './ConfigReducer';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import { sortObjArr } from 'utils/functions';
import { openDB } from 'idb';
export const SET_UNITS = 'UNITS/SET_UNITS';

let unitsService = false;
let unitUpdatesCounter = 0;
let getUnitsTimeout = 0;
let dbInitiated = false;
const throttling = {
  maxTime: 1000,
  minTime: 250,
  time: 0,
  timeout: 0,
};

openDB('cad', 1)
  .then((db) => {
    eventStore = db;
    dbInitiated = true;
  })
  .catch((err) => console.log(err));

const getTimestamp = () => new Date().getTime();
let eventStore = null;
export const saveUnitsToStorage = async (units) => {
  const doSave = async () => {
    const tx = eventStore.transaction('data', 'readwrite');
    const store = tx.objectStore('data');
    await store.put(units, 'units');
    tx.commit();
    window.localStorage.setItem('units', getTimestamp());
    throttling.time = 0;
  };
  if (!dbInitiated) return;
  const { time, minTime, maxTime, timeout } = throttling;
  clearTimeout(timeout);
  if (time !== 0 && getTimestamp() - time > maxTime) {
    doSave();
    return;
  }
  if (time === 0) throttling.time = getTimestamp();
  throttling.timeout = setTimeout(doSave, minTime);
};

export const getUnitsFromStorage = async () => {
  if (!dbInitiated) return;
  const tx = eventStore.transaction('data', 'readwrite');
  const store = tx.objectStore('data');
  const units = await store.get('units');
  tx.commit();
  return units;
};

export const removeUnitsFromStorage = async () => {
  window.localStorage.removeItem('units');
  const tx = eventStore.transaction('data', 'readwrite');
  const store = tx.objectStore('data');
  await store.delete('units');
  tx.commit();
};

// export const setUnits = (units) => {
//   return async (dispatch) => dispatch({ type: SET_UNITS, units });
// };

/** Save ALL units data in Redux and local store if mode is master */
export const updateUnitsData = (newUnits, dispatch) => {
  const state = store.store.getState();
  const currentUnits = state.units;
  // Update only units that had changed - prevent rerender
  const units = [];
  newUnits.forEach((newUnit) => {
    const currentUnit = currentUnits.find((u) => u.ptsUnitID === newUnit.ptsUnitID);
    if (currentUnit && JSON.stringify(currentUnit) === JSON.stringify(newUnit)) {
      units.push(currentUnit);
    } else {
      units.push(newUnit);
    }
  });
  const { mode } = state.config;
  const sortedUnits = sortObjArr(units, 'Unit');
  if (mode === 'master') saveUnitsToStorage(sortedUnits);
  dispatch({ type: SET_UNITS, units: sortedUnits });
};

/** Save in Redux only units that have changed for use in slave mode */
export const updateChangedUnitsData = (newUnits, dispatch) => {
  const state = store.store.getState();
  const oldUnits = state.units;
  // 1. New and modified units
  const modifiedAndNewUnits = newUnits.map((newUnit) => {
    const oldUnit = oldUnits
      ? oldUnits.find((oldUnit) => oldUnit.ptsUnitID === newUnit.ptsUnitID)
      : null;
    if (!oldUnit) return newUnit; // this is a new unit
    return JSON.stringify(newUnit) === JSON.stringify(oldUnit) ? oldUnit : newUnit;
  });
  // 2. Old unmodified units without deleted ones
  const unmodifiedUnits = oldUnits.filter((oldUnit) => {
    return (
      !modifiedAndNewUnits.find((newUnit) => newUnit.ptsUnitID === oldUnit.ptsUnitID) &&
      newUnits.find((newUnit) => newUnit.ptsUnitID === oldUnit.ptsUnitID)
    );
  });
  const units = [...unmodifiedUnits, ...modifiedAndNewUnits];
  updateUnitsData(units, dispatch);
};

export const getUnits = () => {
  return async (dispatch) => {
    clearTimeout(getUnitsTimeout);
    getUnitsTimeout = setTimeout(() => {
      const service = getService('cmplx-units');
      service
        .find()
        .then((units) => updateUnitsData(processUnits(units), dispatch))
        .catch((error) => dispatch(handleError(error)));
    }, settings.reqThrottlingTime);
  };
};

export const updateUnits = () => async (dispatch) => {
  if (settings.synchronizeData) {
    dispatch(getUnits());
  }
};

export const clearUnits = () => (dispatch) => {
  updateUnitsData(false, dispatch);
};

export const getUnitData = (ptsUnitID) => {
  const service = getService();
  return service.get({ type: 'unit-data', data: ptsUnitID });
};

export const updateUnitData = (ptsUnitID, data) => {
  const service = getService();
  return service.patch(ptsUnitID, { type: 'update-unit-data', data });
};

export const setUnitZone = (ptsUnitID, Zone) => {
  const service = getService();
  return service.patch(ptsUnitID, { type: 'set-unit-zone', data: Zone });
};

export const subscribeUnits = () => {
  return async (dispatch) => {
    unitsService = getService('cmplx-units');
    dispatch(getUnits());
    unitsService.on('newUnitsData', (units) => {
      // probably need to check if still logged in - leaving it for now
      const newUnits = processUnits(units);
      updateUnitsData(newUnits, dispatch);
    });
    unitsService.on('unitChange', (unit) => {
      // probably need to check if still logged in - leaving it for now
      const state = store.store.getState();
      if (!state.units) return;
      //if data is not sysc
      if (unit.unitUpdatesCounter !== unitUpdatesCounter + 1) {
        unitUpdatesCounter = unit.unitUpdatesCounter;
        dispatch(getUnits());
        return;
      }
      // if data in sync
      unitUpdatesCounter = unit.unitUpdatesCounter;
      const action = unit.unitActionType;
      // if (action !== 'modify' && action !== 'remove') return;
      let newUnits;
      if (action === 'remove') {
        const { removedUnits } = unit;
        newUnits = state.units.filter((u) => removedUnits.indexOf(u.ptsUnitID) === -1);
      }
      if (action === 'modify') {
        newUnits = [...state.units];
        for (let i = 0; i < unit.unitsToPush.length; i++) {
          const processedUnit = processUnits(unit.unitsToPush)[i];
          if (!processedUnit) return;
          const { ptsUnitID } = processedUnit;
          const idx = state.units.findIndex((u) => u.ptsUnitID === ptsUnitID);
          if (idx !== -1) {
            // swap unit data
            newUnits[idx] = processedUnit;
          } else {
            // add data
            newUnits = [...newUnits, processedUnit];
          }
        }
      }
      updateUnitsData(newUnits, dispatch);
    });
    unitsService.on('unhandledRejection', (reason, p) => {
      console.log('Units Reducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
    });
  };
};

export const unsubscribeUnits = () => {
  if (unitsService) {
    unitsService.off('newUnitsData');
    unitsService.off('unitChange');
    unitsService = false;
  }
  return () => {};
};

let handleStorageUnitsChange = null;
export const subscribeUnitsfromStorage = () => (dispatch) => {
  handleStorageUnitsChange = async (ev) => {
    if (ev.key !== 'units') return;
    const state = store.store.getState();
    const { mode } = state.config;
    const units = await getUnitsFromStorage();
    if (units) {
      if (mode === 'slave') {
        updateChangedUnitsData(units, dispatch);
      }
      !state.config.unitDataPresent && dispatch(setSettingsVal('unitDataPresent', true));
    } else {
      state.config.unitDataPresent && dispatch(setSettingsVal('unitDataPresent', false));
    }
  };
  window.addEventListener('storage', handleStorageUnitsChange);
};

export const unsubscribeUnitsfromStorage = () => (dispatch) => {
  window.removeEventListener('storage', handleStorageUnitsChange);
};
// ===========  REDUCERS  ======================

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_UNITS:
      return action.units;
    default:
      break;
  }
  return state;
}

// ===============  HELPER fUNCTIONS  ==============

function processUnits(units) {
  const canReadAgencies = getAgenciesFullPermission('cad', 'Units', 'Read');
  return units
    .filter((unit) => canReadAgencies.indexOf(unit.AgencyID) !== -1)
    .map((unit) => {
      const UnitStatuses = unit.UnitStatuses ? JSON.parse(unit.UnitStatuses) : '';
      const Resources = unit.Resources ? JSON.parse(unit.Resources) : null;
      return { ...unit, UnitStatuses, Resources };
    });
}

export const getUnitsAsync = () => {
  const service = getService('cmplx-units');
  return new Promise((resolve, reject) => {
    service
      .find()
      .then((units) => {
        resolve(processUnits(units));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
