import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  eventCard: {
    marginBottom: 10,
    boxSizing: 'border-box',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '1px solid #a2a9d7',
    background: '#fff',
    '&:hover': {
      background: '#f4f5fd',
    },
  },
  evCardHead: {
    textAlign: 'center',
    padding: '8px 2px 5px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    color: theme.palette.text.primary,
    '& > div': {
      padding: '0 5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:nth-child(2)': {
        direction: 'rtl',
        textAlign: 'left',
      },
    },
  },
  evCardContent: {
    padding: '0 6px 6px',
    position: 'relative',
  },
  department: {
    boxSizing: 'border-box',
    background: '#fff',
    marginRight: 2,
    display: 'block',
    width: 28,
    height: 28,
    padding: 2,
    borderRadius: '50%',
    '& svg': {
      marginBottom: 2,
    },
  },
  location: {
    display: 'flex',
    '& > div': {
      fontSize: 12,
      lineHeight: 1.1,
      height: '2.25em',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
      marginBottom: 2,
    },
    '& img': {
      height: 36,
      width: 'auto',
      borderRadius: '50%',
      padding: 1,
      background: '#fff',
      marginRight: 8,
    },
  },
  evBottom: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      color: '#fff',
      fontSize: 12,
    },
  },
  dispatch: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

export default useStyles;
