
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8suicidefullbody100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M26.533 3a4.5 4.5 0 0 0 0 9 4.5 4.5 0 0 0 0-9zM20 4l-1 1h-6v2h.666l-.059.176a1.994 1.994 0 0 0-1.302 1.437l-1.125 4.873c-.04.173-.377 2.024-.377 2.024-.255 1.516.861 2.944 2.763 2.691l6.438-.947.03 27.047c0 1.564.836 2.699 2.4 2.699 1.382 0 2.447-1.01 2.584-2.393.004-.006.011-.01.015-.015V30.457a.5.5 0 0 1 1 0v13.844c0 1.539 1.12 2.699 2.602 2.699 1.565 0 2.398-1.135 2.398-2.7V20.47c0-.27.23-.5.5-.5.271 0 .5.23.5.5V31c0 1.104.897 2 2 2s2-.896 2-2V18.5c0-3.033-2.467-5.5-5.5-5.5h-7c-.554 0-1.056.035-1.535.09l.008-.022-6.84.932 1.035-4.486a1.97 1.97 0 0 0-.037-1.008L16.666 7H21V4h-1z"/></SvgIcon>
      );
    }
  
    export default icons8suicidefullbody100Icon;