export default [
  {
    Mobile: true,
    Unit: 'MAMBO',
    Names: 'Chris Rutherford',
    Resources: 'Chris Rutherford',
    UnitStatus: 'DISPATCH',
    OccurredData: '7/16/2020',
    OccurredTime: '4:55:24 AM',
    AgencyId: 'FIREDEP',
    Location: null,
    Modifiers: null,
    Destination: null,
    TripNumber: null,
    Zone: null,
  },
  {
    Mobile: true,
    Unit: 'SALSA',
    Names: 'Mike McNeil',
    Resources: 'Mike McNeil',
    UnitStatus: 'ARRIVED',
    OccurredData: '7/16/2020',
    OccurredTime: '4:37:26 AM',
    AgencyId: 'POLICEDEP',
    Location: null,
    Modifiers: null,
    Destination: null,
    TripNumber: null,
    Zone: null,
  },
  {
    Mobile: true,
    Unit: '4711',
    Names: 'David Chamberlain',
    Resources: '',
    UnitStatus: 'ARRIVED',
    OccurredData: '7/16/2020',
    OccurredTime: '4:32:44 AM',
    AgencyId: 'POLICEDEP',
    Location: null,
    Modifiers: null,
    Destination: null,
    TripNumber: null,
    Zone: null,
  },
  {
    Mobile: true,
    Unit: 'SALSA',
    Names: 'Mike McNeil',
    Resources: '',
    UnitStatus: 'ARRIVED',
    OccurredData: '7/14/2020',
    OccurredTime: '5:37:26 AM',
    AgencyId: 'POLICEDEP',
    Location: null,
    Modifiers: null,
    Destination: null,
    TripNumber: null,
    Zone: null,
  },
  {
    Mobile: true,
    Unit: '4711',
    Names: 'David Chamberlain',
    Resources: '',
    UnitStatus: 'ENROUTE',
    OccurredData: '7/14/2020',
    OccurredTime: '5:32:44 AM',
    AgencyId: 'FIREDEP',
    Location: null,
    Modifiers: null,
    Destination: null,
    TripNumber: null,
    Zone: null,
  },
  {
    Mobile: true,
    Unit: 'CHA-CHA',
    Names: 'Greg Sarzynski',
    Resources: '',
    UnitStatus: 'ENROUTE',
    OccurredData: '7/16/2020',
    OccurredTime: '6:32:44 AM',
    AgencyId: 'POLICEDEP',
    Location: null,
    Modifiers: null,
    Destination: null,
    TripNumber: null,
    Zone: null,
  },
];
