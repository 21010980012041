import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import { handleError } from 'reducers/ErrorReducer';
import PrintsSearch from 'Search/components/PrintsSearch';
import { XGrid } from '@material-ui/x-grid';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';
import { geocodeAddress } from 'utils/mapFunctions';
import { getAddressZones } from 'reducers/ZonesReducer';
import Autocomplete2 from 'components/Autocomplete2';
import { Button, Grid, Box } from '@material-ui/core';
import { getService } from 'reducers/service';
import GeofenceIcon from '@material-ui/icons/Room';
import NoGeofenceIcon from '@material-ui/icons/LocationOff';
import DeleteIcon from '@material-ui/icons/Delete';

const columns = [
  {
    field: 'ZoneCode',
    headerName: 'Zone',
    width: 150,
    renderCell: ({ row }) => (
      <span>
        {Boolean(row.ptsGeofenceID) && <GeofenceIcon />}
        {!row.ptsGeofenceID && <NoGeofenceIcon />} {row.ZoneCode}
      </span>
    ),
  },
  { field: 'Description', headerName: 'Description', width: 150 },
  { field: 'AgencyID', headerName: 'AgencyID', width: 150 },
];

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  box: {
    marginTop: theme.spacing(2),
  },
}));
const rowType = {
  ptsGeofenceID: null,
  ZoneCode: '',
  Description: '',
  AgencyID: '',
  ROWGUID: '',
  ptsZoneInfoID: null,
};

function AddressZones(props) {
  const { address } = props;
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [data, setData] = useState([]);
  const [zoneAgency, setZoneAgency] = useState('');
  const [zoneAgencies, setZoneAgencies] = useState('');
  const [zone, setZone] = useState(null);
  const [zonesByAgency, setZonesByAgency] = useState([]);
  const [selectedRow, setSelectedRow] = useState(rowType);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => (mountedRef.current = false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!address?.ptsAddressID) return;
    getZones();
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    const zoneAgencies = props.zones.map((zone) => zone.AgencyID);
    setZoneAgencies([...new Set(zoneAgencies)]);
  }, [props.zones]);

  useEffect(() => {
    if (zoneAgency) handleSetZonesByAgency();
  }, [zoneAgency, data]);

  const getZones = async () => {
    const { ptsAddressID, ptsCoordinateID } = address;
    try {
      let coords = null;
      if (!ptsCoordinateID) {
        coords = await geocodeAddress(address.FullAddressText);
      }
      const zones = await getAddressZones({ ptsAddressID, coords, ptsCoordinateID });

      setData(
        zones.map((zone, idx) => {
          return { ...zone, id: idx };
        })
      );
    } catch (err) {
      props.handleError(err);
    }
    setLoaded(true);
  };

  const handleSelection = (newSelection) => {
    setSelection(newSelection.selectionModel[0]);
    const selectedZone = newSelection.selectionModel[0];
    const selectedZoneObject = data.find((item) => item.id === selectedZone);
    setSelectedRow(selectedZoneObject);
  };
  const save = async () => {
    try {
      setLoaded(false);
      const { ptsAddressID } = address;
      const { ZoneCode } = zone;
      const zoneObj = {
        ptsParentID: ptsAddressID,
        ParentType: 'Address',
        ZoneCode: ZoneCode,
        IsDeleted: 0,
      };
      const service = getService('ptszoneinfo');
      // const res = await service.find({ query: zoneObj });
      // const { data } = res;
      // if (data.length) {
      //   props.handleError(null, 'Zone already exist!');
      //   return;
      // }
      await service.create(zoneObj);
      await getZones();
      setZone(null);
      setLoaded(true);
    } catch (error) {
      props.handleError(error);
    }
  };
  const viewDetails = () => {
    const item = data.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `${item.ZoneCode} - ${item.Description}`,
    });
  };

  const filterData = (rawData) => {
    const { ZoneCode, Description, AgencyID } = rawData;
    return { ZoneCode, Description, AgencyID };
  };

  const handleDelete = async () => {
    setLoaded(false);
    const { ptsZoneInfoID } = selectedRow;
    if (!ptsZoneInfoID) return;
    if (!window.confirm('Are you sure you want to delete selected zone?')) return;
    const service = getService('ptszoneinfo');
    await service.remove(ptsZoneInfoID);
    setLoaded(true);
    await getZones();
  };

  const handleSetZonesByAgency = () => {
    const zonesByAgency = props.zones
      .filter(
        (zone) =>
          zone.AgencyID === zoneAgency &&
          !data.find((item) => item.ZoneCode === zone.ZoneCode && item.AgencyID === zoneAgency) //filtering zone which are already in grid
      )
      .map((zone) => ({ Code: zone.ZoneCode, ...zone }));
    setZonesByAgency(zonesByAgency);
  };

  const renderZoneAgency = () => {
    const onChange = (ev, val) => {
      setZoneAgency(val);
      setZonesByAgency([]);
      setZone(null);
      //handleSetZonesByAgency()
    };
    return (
      <Autocomplete2
        options={zoneAgencies}
        className={classes.item}
        onChange={onChange}
        value={zoneAgency}
        label="Zone Agencies"
        getOptionSelected={(option, value) => option}
        getOptionLabel={(option) => option}
        renderOption={(option) => option}
      />
    );
  };
  const renderZone = () => {
    const onChange = (ev, val) => setZone(val);
    return (
      <Autocomplete2
        options={zonesByAgency}
        className={classes.item}
        onChange={onChange}
        value={zone}
        label="Zone"
        getOptionSelected={(option, value) => option.Code === value.Code}
        getOptionLabel={(option) => (option.Code ? option.Code : '')}
        renderOption={(option) => (
          <span>
            {Boolean(option.ptsGeofenceID) && <GeofenceIcon />}
            {!option.ptsGeofenceID && <NoGeofenceIcon />} {option.Code} - {option.Description} -{' '}
            {option.AgencyID}
          </span>
        )}
      />
    );
  };
  return (
    <div>
      <Box className={classes.box}>
        <Grid container>
          <Grid container item xs={10} spacing={2}>
            <Grid item xs={4}>
              {renderZoneAgency()}
            </Grid>
            <Grid item xs={4}>
              {renderZone()}
            </Grid>
            <Grid item xs={4}>
              <Button disabled={!zone || !loaded} variant="outlined" onClick={save}>
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            {' '}
          </Grid>
        </Grid>
      </Box>
      <div className={classes.actions}>
        <PrintsSearch title="SOP Records" data={data} cols={columns} hiddenCols={hiddenColumns} />
        {selection !== null && (
          <>
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Remove Zone">
              <Fab
                className="ml-2"
                size="small"
                color="secondary"
                onClick={handleDelete}
                disabled={selectedRow.ptsGeofenceID}>
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={handleSelection}
          onColumnVisibilityChange={(col) => setHiddenColumns([...hiddenColumns, col.field])}
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  zones: state.zones,
});
export default connect(mapStateToProps, {
  handleError,
  showDisplayDataWindow,
})(AddressZones);
