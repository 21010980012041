import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, Link, useRouteMatch } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { notifyPanel, notify } from '../reducers/NotifierReducer';

let no = 1;

const notificationOptsSuccess = {
  // uid: 'once-please', // you can specify your own uid if required
  title: "Hey, it's good to see you!",
  message: 'Now you can see how easy it is to use notifications in React!',
  position: 'tr',
  autoDismiss: 0,
  action: {
    label: 'Click me!!',
    callback: () => alert('Success clicked!'),
  },
};

const notificationOptsInfo = {
  // uid: 'once-please', // you can specify your own uid if required
  title: "Hey, it's good to see you!",
  message: 'Now you can see how easy it is to use notifications in React!',
  position: 'tr',
  autoDismiss: 0,
  action: {
    label: 'Click me!!',
    callback: () => alert('Info clicked!'),
  },
};

const notificationOptsWarning = {
  // uid: 'once-please', // you can specify your own uid if required
  title: "Hey, it's good to see you!",
  message: 'Now you can see how easy it is to use notifications in React!',
  position: 'tr',
  autoDismiss: 0,
  action: {
    label: 'Click me!!',
    callback: () => alert('Warning clicked!'),
  },
};

const notificationOptsError = {
  // uid: 'once-please', // you can specify your own uid if required
  title: "Hey, it's good to see you!",
  message: 'Now you can see how easy it is to use notifications in React!',
  position: 'tr',
  autoDismiss: 0,
  action: {
    label: 'Click me!!',
    callback: () => alert('Error clicked!'),
  },
};

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    minHeight: '100%',
  },
}));

function Page2(props) {
  const { notifyPanel, notify } = props;
  const params = useParams();
  const classes = useStyles();
  const match = useRouteMatch();
  const topUrl = match.path.substr(0, match.path.indexOf(':') - 1);

  return (
    <div className={classes.pageWrap}>
      <h3>Notification Panel</h3>
      <p>
        <Button onClick={() => notifyPanel(notificationOptsSuccess, 'success')}>Success</Button>
        <Button onClick={() => notifyPanel(notificationOptsInfo, 'info')}>Info</Button>
        <Button onClick={() => notifyPanel(notificationOptsWarning, 'warning')}>Warning</Button>
        <Button onClick={() => notifyPanel(notificationOptsError, 'error')}>Error</Button>
      </p>
      <h3>Snackbars</h3>
      <p>
        <Button onClick={() => notify('Some success message', 'success')}>Success</Button>
        <Button onClick={() => notify('Some info message', 'info')}>Info</Button>
        <Button onClick={() => notify('Some warning message', 'warning')}>Warning</Button>
        <Button onClick={() => notify('Some error message', 'error')}>Error</Button>
      </p>

      <h1>Sub-routing</h1>
      <p>
        <Link to={`${topUrl}/hello1`}>
          <Button>Hello 1</Button>
        </Link>
        <Link to={`${topUrl}/hello2`}>
          <Button>Hello 1</Button>
        </Link>
        <Link to={`${topUrl}/hello3`}>
          <Button>Hello 1</Button>
        </Link>
      </p>
      <h1>route: {params.tab}</h1>
    </div>
  );
}

export default connect(null, { notifyPanel, notify })(Page2);
