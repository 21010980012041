import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import BulletinSearchResults from './BulletinSearchResults';
import { Redirect } from 'react-router-dom';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
function AlertsSearch() {
  const canReadAgencies = getAgenciesFullPermission('cad', 'Alerts', 'Read');
  if (!canReadAgencies.length) return <Redirect to="/" />;
  return (
    <SearchMenu agencyList={canReadAgencies}>
      <FilterConsumer>
        {(AgencyFilter) => <BulletinSearchResults filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default AlertsSearch;
