import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { closeSetUnitZone } from '../reducers/DialogsReducer';
import Dialog from '../components/Dialog';
import { setUnitZone } from '../reducers/UnitsReducer';
import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.25em',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 12px`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function UnitZone(props) {
  const classes = useStyles();
  const { ptsUnitID, zones, units } = props;
  const [Zone, setZone] = useState(null);
  const [Unit, setUnit] = useState('');
  const [AgencyZones, setAgencyZones] = useState([]);

  useEffect(() => {
    const unit = units.find((u) => u.ptsUnitID === ptsUnitID);
    if (!unit) {
      props.notify('Error, Unit information not found.');
      return;
    }
    const { AgencyID, Unit } = unit;
    setUnit(Unit);
    const AgencyZones = zones.filter((z) => z.AgencyID === AgencyID);
    setAgencyZones(AgencyZones);
  }, []);

  const close = () => {
    props.closeSetUnitZone();
  };

  const save = () => {
    const zone = Zone ? Zone.ZoneCode : null;
    props.showSpinner();
    setUnitZone(ptsUnitID, zone)
      .then(() => close())
      .catch((err) => props.handleError(err, 'Error, problems with saving Zone data'))
      .finally(() => props.hideSpinner());
  };

  const renderForm = () => {
    return (
      <div className={classes.form}>
        <Autocomplete
          options={AgencyZones}
          getOptionLabel={(option) => option.ZoneCode}
          value={Zone}
          className={classes.w100pr}
          renderInput={(params) => (
            <TextField {...params} label="Zone" variant="outlined" size="small" />
          )}
          onChange={(event, newValue) => setZone(newValue)}
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Cancel
        </Button>
      </>
    );
  };

  return (
    <Dialog open={true} onClose={close} title={'Unit Zone'} actions={renderActions()} toolbar>
      <h4 className={classes.title}>{Unit}</h4>
      {renderForm()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    zones: state.zones,
    units: state.units,
  };
};

export default connect(mapStateToProps, {
  closeSetUnitZone,
  handleError,
  showSpinner,
  hideSpinner,
  notify,
})(UnitZone);
