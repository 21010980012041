import { getService } from './service';
import { handleError } from './ErrorReducer';
const UNITS_RESOURCES = 'UNITS_RESOURCES';

export const updateUnitsResources = () => {
  return async (dispatch) => {
    try {
      const service = getService('unit-resources');
      const data = await service.find();
      dispatch({ type: UNITS_RESOURCES, data });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

// ===========  REDUCERS  ======================
export default function reducer(state = {}, action) {
  switch (action.type) {
    case UNITS_RESOURCES:
      return action.data;
    default:
      break;
  }
  return state;
}
