import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { genColHeaderName, sortObjArr } from 'utils/functions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import { handleError } from 'reducers/ErrorReducer';
import { getPrePlanDetails } from 'reducers/AddressReducer';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    border: `1px solid ${theme.colors.grey1}`,
    '& th': {
      padding: '2px 15px',
      '&:nth-child(1)': {
        width: '20%',
      },
      '&:nth-child(2)': {
        width: '30%',
      },
      '&:nth-child(3)': {
        width: '30%',
      },
    },
    '& td': {
      padding: '2px 15px',
      height: 33,
      '&:nth-child(1)': {
        width: '20%',
      },
      '&:nth-child(2)': {
        width: '30%',
      },
      '&:nth-child(3)': {
        width: '30%',
      },
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 16,
    forntSize: 16,
  },
  tableAction: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  loading: {
    paddingTop: 12,
    marginTop: -16,
    width: '100%',
    height: 16,
  },
}));

const RadioSmall = withStyles((theme) => ({
  root: {
    padding: 2,
    marginRight: 8,
    color: theme.palette.secondary.main,
    '&$checked': {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const dataColumns = [
  'AddressCategory',
  'AddressNumber',
  'AddressNumberPrefix',
  'AddressNumberSuffix',
  'AltitudeMeasure',
  'Area',
  'BldgIdentifier',
  'BldgType',
  'CLERYLocationType',
  'City',
  'Country',
  'County',
  'CrossStreet',
  'DepthMeasure',
  'Description',
  'ESN',
  'FloorIdentifier',
  'FloorType',
  'GeographicElevation',
  'Highway',
  'LandmarkText',
  'Locale',
  'MGRSCoordinate',
  'MapLocation',
  'NonValidatedAddress',
  'Notes',
  'POBox',
  'Parolee',
  'PostDirection',
  'PostalCode',
  'PostalCodeExtension',
  'PreDirection',
  'Probation',
  'RangeDescription',
  'RelativeLocation',
  'SexOffender',
  'ShowAlert',
  'ShowInEventLookup',
  'State',
  'StreetName',
  'StreetType',
  'SurroundingArea',
  'TwoDGeographicCoordinate',
  'UTMCoordinate',
  'UnitIdentifier',
  'UnitType',
];

const prePlanColumns = [
  'AvailableFlow',
  'BuildingCapacity',
  'ConstructionType',
  'ElectricalCutoffLocation',
  'ElectricalService',
  'Firewalls',
  'GasCutoffLocation',
  'GasService',
  'Notes',
  'NumberFloors',
  'NumberOfExits',
  'OccupancyType',
  'OrigPrePlanDate',
  'RequiredFlow',
  'RoofType',
  'Sprinkler',
  'SprinklerFDConnectionLocation',
  'SprinklerShutoffLocation',
  'StandPipeFDConnectionLocation',
  'Standpipe',
  'StandpipeShutoffLocation',
];

const defaultPrePlan = {
  AvailableFlow: null,
  BuildingCapacity: null,
  ConstructionType: null,
  ElectricalCutoffLocation: null,
  ElectricalService: false,
  Firewalls: false,
  GasCutoffLocation: null,
  GasService: false,
  Notes: null,
  NumberFloors: null,
  NumberOfExits: null,
  OccupancyType: null,
  OrigPrePlanDate: null,
  RequiredFlow: null,
  RoofType: null,
  Sprinkler: false,
  SprinklerFDConnectionLocation: null,
  SprinklerShutoffLocation: null,
  StandPipeFDConnectionLocation: null,
  Standpipe: false,
  StandpipeShutoffLocation: null,
  ptsAddressPrePlanID: 0,
}

function valToStr(val) {
  if (val === null) return 'null';
  if (typeof val === 'string' || typeof val === 'number') return '' + val;
  if (val === false) return 'false';
  if (val === true) return 'true';
  return 'unknown format!';
}

function AddressMerge(props) {
  const {
    rows,
    source,
    setSource,
    target,
    setTarget,
    data,
    setData,
    sourcePrePlan,
    setSourcePrePlan,
    targetPrePlan,
    setTargetPrePlan,
    prePlan,
    setPrePlan,
    loading,
    setLoading,
  } = props;
  const classes = useStyles();
  const [show, setShow] = useState('not-null'); // diff, not-null, all
  const mountedRef = useRef(true);

  useEffect(() => {
    if (!data.length) getData();
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    setLoading(true);
    let source = rows.find((row) => row.id === props.sourceID);
    let target = rows.find((row) => row.id === props.targetID);
    setSource(source);
    setTarget(target);
    let data = processAddressData(source, target);
    data = sortObjArr(data, 'field');
    setData(data);
    try {
      let sourcePrePlan = await getPrePlanDetails(source.ptsAddressID);
      if (!mountedRef.current) return;
      let targetPrePlan = await getPrePlanDetails(target.ptsAddressID);
      if (!mountedRef.current) return;
      if (!sourcePrePlan && !targetPrePlan) return setLoading(false)
      if (!sourcePrePlan) sourcePrePlan = { ...defaultPrePlan };
      if (!targetPrePlan) targetPrePlan = { ...defaultPrePlan };
      setSourcePrePlan(sourcePrePlan);
      setTargetPrePlan(targetPrePlan);
      const prePlan = sortObjArr(processPrePlanData(sourcePrePlan, targetPrePlan));
      setPrePlan(prePlan);
    } catch (err) {
      props.handleError(err);
    }
    setLoading(false);
  }

  const processAddressData = (source, target) => {
    const data = dataColumns.map((field) => {
      const sourceVal = source[field];
      const targetVal = target[field];
      const obj = {
        field,
        isTargetVal: !(sourceVal && !targetVal),
        headerName: genColHeaderName(field),
        sourceVal,
        targetVal,
        sourceStrVal: valToStr(sourceVal),
        targetStrVal: valToStr(targetVal),
      };
      return obj;
    });
    return data;
  }

  const processPrePlanData = (source, target) => {
    const data = prePlanColumns.map((field) => {
      const sourceVal = source[field];
      const targetVal = target[field];
      const obj = {
        field,
        isTargetVal: !(sourceVal && !targetVal),
        headerName: genColHeaderName(field),
        sourceVal,
        targetVal,
        sourceStrVal: valToStr(sourceVal),
        targetStrVal: valToStr(targetVal),
      };
      return obj;
    });
    return data;
  }

  const swapSourceAndTarget = () => {
    const newSource = { ...target };
    const newTarget = { ...source };
    const newData = swapData(data);
    setSource(newSource);
    setTarget(newTarget);
    setData(newData);
    if (!prePlan) return;
    const newSourcePrePlan = { ...targetPrePlan };
    const newTargetPrePlan = { ...sourcePrePlan };
    const newPrePlan = swapData(prePlan);
    setSourcePrePlan(newSourcePrePlan);
    setTargetPrePlan(newTargetPrePlan);
    setPrePlan(newPrePlan);
  }

  const swapData = (data) => {
    return data.map((row) => {
      const { field, isTargetVal, headerName, sourceVal, targetVal, sourceStrVal, targetStrVal } = row;
      return {
        field,
        isTargetVal: !isTargetVal,
        headerName,
        sourceVal: targetVal,
        targetVal: sourceVal,
        sourceStrVal: targetStrVal,
        targetStrVal: sourceStrVal,
      }
    });
  }

  const onAddressClick = (row, isSource = true) => {
    const { field } = row;
    const idx = data.findIndex((row) => row.field === field);
    const newData = [...data];
    newData[idx].isTargetVal = isSource ? false : true;
    setData(newData);
  }

  const onPrePlanClick = (row, isSource = true) => {
    const { field } = row;
    const idx = prePlan.findIndex((row) => row.field === field);
    const newData = [...prePlan];
    newData[idx].isTargetVal = isSource ? false : true;
    setPrePlan(newData);
  }

  const renderFilters = () => {
    return (
      <RadioGroup
        name="filter"
        value={show}
        onChange={(ev, val) => setShow(val)}
        className={classes.radioGroup}
      >
        <FormControlLabel value="all" control={<Radio />} label="All" />
        <FormControlLabel value="diff" control={<Radio />} label="Difference" />
        <FormControlLabel value="not-null" control={<Radio />} label="Not Null" />
      </RadioGroup>
    );
  }

  const selectAll = (isSource) => () => {
    const newData = [...data].map((row) => ({ ...row, isTargetVal: !isSource }));
    setData(newData);
    if (!prePlan) return;
    const newPrePlan = [...prePlan].map((row) => ({ ...row, isTargetVal: !isSource }));
    setPrePlan(newPrePlan);
  }

  const renderAddressRows = () => {
    let addressData;
    switch (show) {
      case 'diff':
        addressData = data.filter((row) => row.sourceVal !== row.targetVal);
        break;
      case 'not-null':
        addressData = data.filter((row) => row.sourceVal !== null || row.targetVal !== null);
        break;
      default:
        addressData = data;
    }
    return addressData.map((row) => (
      <TableRow key={row.field}>
        <TableCell>{row.headerName}</TableCell>
        <TableCell>
          <RadioSmall
            checked={!row.isTargetVal}
            onClick={() => onAddressClick(row, true)}
            id={'source-' + row.field}
          />
          <label htmlFor={'source-' + row.field}>{row.sourceStrVal}</label>
        </TableCell>
        <TableCell>
          <RadioSmall
            checked={row.isTargetVal}
            onClick={() => onAddressClick(row, false)}
            id={'target-' + row.field}
          />
          <label htmlFor={'target-' + row.field}>{row.targetStrVal}</label>
        </TableCell>
      </TableRow>
    ))
  }

  const renderPrePlanRows = () => {
    let prePlanData;
    switch (show) {
      case 'diff':
        prePlanData = prePlan.filter((row) => row.sourceVal !== row.targetVal);
        break;
      case 'not-null':
        prePlanData = prePlan.filter((row) => row.sourceVal !== null || row.targetVal !== null);
        break;
      default:
        prePlanData = prePlan;
    }
    return (
      prePlanData.map((row) => (
        <TableRow key={row.field}>
          <TableCell>{row.headerName}</TableCell>
          <TableCell>
            <RadioSmall
              checked={!row.isTargetVal}
              onClick={() => onPrePlanClick(row, true)}
              id={'source-' + row.field}
            />
            <label htmlFor={'source-' + row.field}>{row.sourceStrVal}</label>
          </TableCell>
          <TableCell>
            <RadioSmall
              checked={row.isTargetVal}
              onClick={() => onPrePlanClick(row, false)}
              id={'target-' + row.field}
            />
            <label htmlFor={'target-' + row.field}>{row.targetStrVal}</label>
          </TableCell>
        </TableRow>
      ))
    );
  }

  const renderTable = () => {
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Address Record</TableCell>
            <TableCell>
              <div className={classes.tableAction}>
                <div>Source <br /><small>(will be deleted)</small></div>
                <div>
                  <Tooltip title="Merge address">
                    <span>
                      <Fab
                        color="secondary"
                        size="small"
                        onClick={swapSourceAndTarget}
                        disabled={source.IsDeleted}
                      >
                        <SwapHorizontalCircleIcon />
                      </Fab>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </TableCell>
            <TableCell>Target <br /><small>(will be updated)</small></TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          <TableRow>
            <TableCell>Address ID</TableCell>
            <TableCell>{source.AddressID}</TableCell>
            <TableCell>{target.AddressID}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bulk Selection</TableCell>
            <TableCell>
              <Button
                variant="outlined"
                onClick={selectAll(true)}
              >Select All Fields In This Section
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                onClick={selectAll(false)}
              >Select All Fields In This Section
              </Button>
            </TableCell>
          </TableRow>
          {renderAddressRows()}
          {Boolean(prePlan) && <TableRow>
            <TableCell colSpan={3} style={{ textAlign: 'center' }}>
              Address Pre-Plan
            </TableCell>
          </TableRow>}
          {Boolean(prePlan) && renderPrePlanRows()}
        </tbody>
      </Table>
    );
  }

  return (
    <div>
      {renderFilters()}
      <div className={classes.loading}>
        {loading && <LinearProgress />}
      </div>
      {Boolean(source && target && !loading) && renderTable()}
    </div>
  );
}

export default connect(null, { handleError })(AddressMerge);
