import { getService } from './service';
import store from '../config/configureStore';
import { notify } from './NotifierReducer';
import { handleError } from './ErrorReducer';

export const getReport = (reportId, data) => {
  const service = getService('ssrs');
  service.timeout = 90000;
  return service.get(reportId, {
    query: { data },
  });
};

/**
 * @brief - this function checking the permission of export for agency
 * @param {object} data -  event object
 * @param {string} permissionType - property of agency object. param example: 'FireHouseEnabled', 'LawEnforcementRMSExport'
 * @param {string} eventType - is permission checking for single agency or multiple agencies. param example: 'single', 'multiple'
 * @returns {boolean} return true or false, whether the particular permission is enable/disable for a agency.
 */
export const isEligibleForExport = (data, permissionType, eventType) => {
  const state = store.store.getState();
  const { AgencyID } = data;
  const Agencies = state?.dictionary?.Agencies;
  if (!AgencyID || !Agencies || !Agencies.length) return false;
  let agency = false;
  if (eventType === 'basic') {
    agency = Agencies.find((a) =>
      AgencyID.split(', ').some((agency) => a.AgencyID === agency && a[permissionType])
    );
  } else {
    agency = Agencies.find((a) => a.AgencyID === AgencyID && a[permissionType]);
  }

  if (!agency) return false;
  return agency[permissionType];
};

export const sendRipAndRun = (ptsEventID) => {
  const service = getService('rip-and-run');
  service.timeout = 90000;
  return service.create({
    ptsEventID,
  });
};

export const handleMailEventReport = (type, rows, selection) => async (dispatch) => {
  const item = rows.find((row) => row.id === selection);
  if (!item) return;
  try {
    const { ptsEventID } = item;
    const res = getAgenciesForEmailReport(type, item);
    if (!res.length) return;
    const service = getService('mail-event-report-to-agency');
    await service.create({ ptsEventID, AgencyIDs: res });
    dispatch(notify('Report sent', 'success'));
  } catch (error) {
    console.log('error ', error);
    dispatch(handleError(error));
  }
};

export const handleEventExport = (rows, selection) => async (dispatch) => {
  const item = rows.find((row) => row.id === selection);
  if (!item) return;
  const { ptsEventID } = item;
  const service = getService('event-export-global');

  try {
    await service.create({ ptsEventID });
    dispatch(notify('Event exported', 'success'));
  } catch (err) {
    console.log(err);
    dispatch(handleError(err));
  }
};

export const handleEventExportToLegacy = (rows, selection) => async (dispatch) => {
  const item = rows.find((row) => row.id === selection);
  if (!item) return;
  const { ptsEventID } = item;

  const service = getService('event-export-legacy-rms');
  try {
    await service.create({ ptsEventID });
    dispatch(notify('Event exported', 'success'));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fireRMSexport = (ptsEventID, AgencyID, realTimeExport = false) => async (dispatch) => {
  console.log('fire rms');
  const service = getService('cad-fire-rms-report');
  try {
    await service.create({ ptsEventID, AgencyID, realTimeExport });
    //notify('Event exported', 'success');
  } catch (err) {
    dispatch(handleError(err));
  }
};

// export const realTimeFireRmsExport = async (ptsEventID, eventRouting) => async (dispatch) => {
//   {
//     const state = store.store.getState();
//     const Agencies = state.dictionary.Agencies;
//     await asyncForEach(eventRouting, async (routedAgency) => {
//       const realTimeEnabled = Agencies.find(
//         (agency) => agency.AgencyID === routedAgency.AgencyId && agency?.FireHouseExportOnRealTime
//       );
//       console.log('agency ', realTimeEnabled);
//       if (realTimeEnabled) dispatch(fireRMSexport(ptsEventID, routedAgency.AgencyId, true));
//     });
//   }
// };

const getAgenciesForEmailReport = (type, item) => {
  const state = store.store.getState();
  const Agencies = state?.dictionary?.Agencies;
  let result = [];
  if (type === 'basic') {
    const routedAgencies = JSON.parse(item.AgencyIDs).map((agency) => agency.AgencyID);

    result = Agencies.filter(
      (agency) => routedAgencies.includes(agency.AgencyID) && agency.EmailReportOnEventClose
    ).map((it) => it.AgencyID);
  } else {
    result = Agencies.filter((a) => a.AgencyID === item.AgencyID && a.EmailReportOnEventClose).map(
      (item) => item.AgencyID
    );
  }
  return result;
};
