import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import { showReport, editEvent } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyEvent, pushEvToLegacyRMS } from 'reducers/EventsReducer';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { getFullPermissions, getAccessPermission } from 'reducers/PermissionsReducer';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { reinstateEvent } from 'reducers/EventsReducer';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import DateAndTime from 'Search/components/DateAndTime';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { sendRipAndRun } from 'reducers/ReportsReducer';
import {
  isEligibleForExport,
  handleMailEventReport,
  handleEventExport,
  handleEventExportToLegacy,
  fireRMSexport,
} from 'reducers/ReportsReducer';
import SendIcon from '@mui/icons-material/Send';
import IosShareIcon from '@mui/icons-material/IosShare';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function Actions(props) {
  const { rows, selection, selectionStatus, setPrintFlag, type } = props;
  const dispatch = useDispatch();
  const BATCH = 'batch';
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const classes = useStyles();
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [shiftRepShown, setShiftRepShown] = useState(false);
  const [fireRMSActive, setFireRMSActive] = useState(false);
  const [eventExportToLegacyRMS, setEventExportToLegacyRMS] = useState(false);
  const [pushToLegacyPermission, setPushToLegacyPermission] = useState(false);
  const [mailEventReport, setMailEventReport] = useState(false);
  const isEventExportEnabled = useSelector((state) => state.config.options.isEventExportEnabled);
  const editClosedEvents = getAccessPermission('cad', 'Edit Closed Events', 'any');
  const canEdit = permissions.Edit && (selectionStatus !== 'closed' || editClosedEvents);
  const canReinstate = permissions.Edit && selectionStatus === 'closed' && editClosedEvents;

  useEffect(() => {
    checkFireRMS();
    checkMailEventReport();
    checkEventExportToLegacyRMS();
    checkPushToLegacyRMS();
    // eslint-disable-next-line
  }, [selection]);
  //////////////////// HANDLER //////////////////

  const showDetailsMenu = (ev) => {
    setViewMenuAnchor(ev.currentTarget);
  };

  const closeDetailsMenu = () => {
    setViewMenuAnchor(null);
  };

  const ripAndRun = async () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    try {
      await sendRipAndRun(item.ptsEventID);
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const viewReport = async (type) => {
    const data = generateReportData(type);
    closeDetailsMenu();
    if (!data) return;
    const reportObject = generateReportObject(type, data);
    dispatch(showReport(reportObject));
  };

  const viewPrintReport = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
    closeDetailsMenu();
  };

  const viewShiftReportDialog = () => {
    closeDetailsMenu();
    setShiftRepShown(true);
  };

  const generateReportObject = (type, data) => {
    const { ptsEventID } = data;
    switch (type) {
      case 'internal':
        return { id: 24, data: { ptsEventID }, title: 'Event Details' };
      case 'external':
        return { id: 72, data: { ptsEventID }, title: 'Event Details' };
      case 'batch':
        return { id: 119, data: { data }, title: 'Event Lists' };
      default:
        break;
    }
  };

  const generateReportData = (type) => {
    if (type === BATCH)
      return rows
        .map((item) => item.ptsEventID)
        .filter((item) => item)
        .join(',');

    return rows.find((row) => row.id === selection);
  };

  const pushToLegacy = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID } = item;
    dispatch(pushEvToLegacyRMS(ptsEventID, true));
    //setPushToLegacyActive(false);
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsEventID } = item;
    dispatch(showAddAttachment({ id: ptsEventID, type: 'Event' }));
  };

  const checkFireRMS = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'FireHouseEnabled';
    //const eventType = 'single';
    setFireRMSActive(isEligibleForExport(item, permissionType, type));
  };

  const checkMailEventReport = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'EmailReportOnEventClose';
    // const agencyType = 'single';
    setMailEventReport(isEligibleForExport(item, permissionType, type));
  };

  const checkEventExportToLegacyRMS = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'LawEnforcementRMSExport';
    // const agencyType = 'single';
    setEventExportToLegacyRMS(isEligibleForExport(item, permissionType, type));
  };

  const checkPushToLegacyRMS = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'PushToLegacyRMS';
    //  const agencyType = 'single';
    setPushToLegacyPermission(isEligibleForExport(item, permissionType, type));
  };

  const handleCopyEvent = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    try {
      await copyEvent(item.ptsEventID);
    } catch (err) {
      props.handleError(err);
    }
  };

  const reinstate = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID } = item;
    dispatch(reinstateEvent(ptsEventID));
  };

  const handleEditEvent = () => {
    const item = rows.find((row) => row.id === selection);
    dispatch(editEvent(item.ptsEventID));
  };

  const handleFireRmsExport = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID, AgencyID } = item;
    dispatch(fireRMSexport(ptsEventID, AgencyID));
  };

  return (
    <>
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <span>
            <Fab onClick={showDetailsMenu} size="small" color="secondary">
              <LocalPrintshopRoundedIcon />
            </Fab>
          </span>
        </Tooltip>
        {selection !== null && (
          <>
            {canEdit && (
              <Tooltip title="Edit Event">
                <Fab size="small" color="secondary" onClick={handleEditEvent}>
                  <EditIcon />
                </Fab>
              </Tooltip>
            )}
            {canReinstate && (
              <Tooltip title="Reinstate Event">
                <Fab size="small" color="secondary" onClick={reinstate}>
                  <OpenInBrowserIcon />
                </Fab>
              </Tooltip>
            )}
            <Tooltip title="Copy Event">
              <Fab size="small" color="secondary" onClick={handleCopyEvent}>
                <FileCopyIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Add attachment">
              <Fab color="secondary" size="small" onClick={addAttachment}>
                <AttachFileIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Fire RMS export">
              <span>
                <Fab
                  color="secondary"
                  size="small"
                  onClick={handleFireRmsExport}
                  disabled={!fireRMSActive}>
                  <WhatshotIcon />
                </Fab>
              </span>
            </Tooltip>

            <Tooltip title="Law Enforcement RMS Export">
              <span>
                <Fab
                  disabled={!eventExportToLegacyRMS}
                  color="secondary"
                  size="small"
                  onClick={() => dispatch(handleEventExportToLegacy(rows, selection))}>
                  <IosShareIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Export Event Data">
              <span>
                <Fab
                  disabled={!isEventExportEnabled}
                  color="secondary"
                  size="small"
                  onClick={() => dispatch(handleEventExport(rows, selection))}>
                  <SystemUpdateAltIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Push to legacy RMS">
              <span>
                <Fab
                  color="secondary"
                  size="small"
                  onClick={pushToLegacy}
                  disabled={!pushToLegacyPermission}>
                  <DeviceHubIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="email report">
              <span>
                <Fab
                  disabled={selectionStatus !== 'closed' || !mailEventReport}
                  color="secondary"
                  size="small"
                  onClick={() => dispatch(handleMailEventReport(type, rows, selection))}>
                  <SendIcon />
                </Fab>
              </span>
            </Tooltip>
          </>
        )}
        <Menu
          anchorEl={viewMenuAnchor}
          keepMounted
          open={Boolean(viewMenuAnchor)}
          onClose={closeDetailsMenu}>
          {typeof selection === 'number' && (
            <MenuItem onClick={() => viewReport('internal')}>Internal</MenuItem>
          )}
          {typeof selection === 'number' && (
            <MenuItem onClick={() => viewReport('external')}>External</MenuItem>
          )}
          {typeof selection === 'number' && <MenuItem onClick={ripAndRun}>Rip and Run</MenuItem>}
          <MenuItem onClick={viewPrintReport}>List</MenuItem>
          <MenuItem onClick={viewShiftReportDialog}>Shift Report</MenuItem>
          <MenuItem onClick={() => viewReport(BATCH)}>Batch Report</MenuItem>
        </Menu>
      </div>
      {shiftRepShown && <DateAndTime close={() => setShiftRepShown(false)} />}
    </>
  );
}

export default Actions;
