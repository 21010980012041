import React from 'react';
import NotificationsSearchResults from './NotificationsSearchResults';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import { Redirect } from 'react-router-dom';
import { getAgenciesAccessPermission } from 'reducers/PermissionsReducer';
function NotificationsSearch(props) {
  const canAccessAgencies = getAgenciesAccessPermission('globals', 'View Paging History');
  if (!canAccessAgencies.length) return <Redirect to="/" />;
  return (
    <SearchMenu agencyList={canAccessAgencies}>
      <FilterConsumer>
        {(AgencyFilter) => <NotificationsSearchResults filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default NotificationsSearch;
