import { getService } from './service';
import { handleError } from './ErrorReducer';
import store from 'config/configureStore';
import { setSettingsVal } from './ConfigReducer';
import { DICTIONARY_PERMISSIONS } from './PermissionsReducer'

const GET_DICTIONARY = 'GET_DICTIONARY';

let dictionaryService;
export const subscribeDictionary = () => {
  return async (dispatch) => {
    try {
      dictionaryService = getService('dictionary');
      dictionaryService.on('updated', (models) => {
        const state = store.store.getState();
        const { dictionary } = state;
        Object.keys(dictionary).forEach((dict) => {
          if (models.indexOf(dict.toLocaleLowerCase()) !== -1) {
            dispatch(getDictionaries([dict]));
          }
        });
      });
      dictionaryService.on('error', () => console.log('Error in dictionary service listener'));
      dictionaryService.on('unhandledRejection', (reason, p) => {
        console.log('DectionaryReducer Unhandled Rejection at: Promise ', p, ' reason: ', reason);
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribeDictionary = () => {
  if (dictionaryService) {
    try {
      dictionaryService.off('created');
      dictionaryService.off('error');
      dictionaryService.off('unhandledRejection');
      dictionaryService = false;
    } catch (error) {
      console.log('DectionaryReducer/unsubscribeDictionary: error: ', error, error.code);
    }
  }
  return () => { };
};

export const getDictionary = (dictionary) => {
  const service = getService('dictionary');
  return service.get(dictionary);
};

export const getDictionaries = (options = 'cad') => {
  return async (dispatch) => {
    try {
      const service = getService('dictionary');
      const data = await service.find({
        query: { options }
      });
      dispatch({ type: GET_DICTIONARY, payload: data.dictionary });
      dispatch({ type: DICTIONARY_PERMISSIONS, payload: data.permissions });
      dispatch(setSettingsVal('dictionaryLoaded', true));
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const saveDictionary = (dictionary, data) => {
  const service = getService('dictionary');
  if (data.ROWGUID) {
    return service.patch(dictionary, data);
  } else {
    delete data.ROWGUID;
    return service.create({ data, dictionary });
  }
}

export const deleteDictionary = (dictionary, ROWGUID) => {
  const service = getService('dictionary');
  return service.remove(dictionary, { query: { ROWGUID } });
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case GET_DICTIONARY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}