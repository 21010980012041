
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8mediumrisk100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M48.593 41.397 27.637 4.542C27.088 3.577 26.103 3 25 3s-2.088.577-2.637 1.542L1.407 41.397a3.067 3.067 0 0 0 .016 3.088A3.005 3.005 0 0 0 4.044 46h41.912c1.09 0 2.069-.566 2.621-1.515a3.067 3.067 0 0 0 .016-3.088zM27 39c0 .552-.446 1-.998 1h-2.004A.999.999 0 0 1 23 39v-2c0-.552.446-1 .998-1h2.003c.553 0 .999.448.999 1v2zm0-7a1 1 0 0 1-1 1h-2.003A.997.997 0 0 1 23 32V18c0-.552.446-1 .998-1h2.003c.553 0 .999.448.999 1v14z"/></SvgIcon>
      );
    }
  
    export default icons8mediumrisk100Icon;