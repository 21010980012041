import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function RobberyIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 -64 512 512" {...props}>
      <path d="M80.3 181.2h374.5c17.2 8.7 22.2 18.6 18.1 37.9-2.3 10.6-4.8 21.3-8.4 31.5-9.4 26.4-23 50.1-47.2 65.8-28.7 18.7-58.6 22.6-88.7 3.2-40.4-26-80.6-26.4-121.2-.6-6.3 4-13.2 7.9-20.4 9.8-34.4 9.2-62.9-2.5-86.5-27.1-23.2-24.1-32.2-55.1-38.1-87-2.9-15.5 4.1-26.6 17.9-33.5zM186.2 295c28-.3 47.3-12.9 55.9-35 3.5-9.1.8-14.3-7.5-16.4-31.5-8-63-15.9-94.7-22.9-10.6-2.4-16.7 3.9-15.7 14.7 2.6 30.3 30.3 61.8 62 59.6zm162.5 0c30.9.4 61.2-28.5 62.3-59.5.4-10.3-4.8-17.1-13.2-15.2-32.7 7.6-65.3 15.9-97.9 24-8.9 2.2-9.6 7.5-6.8 15.5 8.4 24 30.2 34.8 55.6 35.2z" />
    </SvgIcon>
  );
}

export default RobberyIcon;