////// COMPONENTS //////
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { getFormat24 } from 'reducers/TimeReducer';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import PersonLookup2 from 'components/PersonLookup2';
import PlaceLookup from 'components/PlaceLookup';
import { Autocomplete } from '@material-ui/lab';
import MultiSelectAutoComplete from 'components/MultiSelectAutoComplete';
import CallerLookup from 'components/CallerLookup';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
    '& > button': {
      marginTop: 7,
    },
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  autocomplete: {
    margin: '0 4px',
    maxWidth: '100%',
    flexBasis: 200,
    minWidth: 220,
    flexGrow: 1,
  },
  datetime: {
    width: '100%',
  },
  marginTop: {
    marginTop: 20,
  },
}));

const defaultStartDate = new Date();
defaultStartDate.setDate(defaultStartDate.getDate() - 30);

function EventFilters(props) {
  const classes = useStyles();
  const PARTY_NAME = 'partyName';
  const PARTY_RELATIONSHIP = 'partyRelationship';
  const PLACE = 'Place';
  const PERSON = 'Person';
  const CALLER = 'Caller';
  const { filters, onChange, agency } = props;
  const {
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    unit,
    zone,
    disposition,
    partyType,
    partyRelationship,
    partyName,
  } = filters;

  const units = useSelector((state) => state.units);
  const dictionary = useSelector((state) => state.dictionary);
  const format24 = getFormat24();
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [filteredDispositions, setFilteredDispositions] = useState([]);

  useEffect(() => {
    const filteredData = units
      .filter((unit) => agency.includes(unit.AgencyID))
      .map((unit) => ({
        Code: unit.Unit,
        Description: unit.AgencyID + (unit.IsDeleted ? ' (DEL)' : ''),
      }));

    setFilteredUnits(filteredData);
    setFilteredZones(filterByAgency(dictionary['Zones']));
    setFilteredDispositions(filterByAgency(dictionary['Dispositions']));
  }, [units, agency, dictionary]);

  useEffect(() => {
    if (partyType !== PERSON) onChange(PARTY_RELATIONSHIP, '');
  }, [partyType]);

  /* resetting the agency specific options on agency change */
  useEffect(() => {
    onChange('unit', []);
    onChange('zone', []);
    onChange('disposition', []);
  }, [agency]);

  const filterByAgency = (data) => data.filter((item) => agency.includes(item.AgencyID));

  const handlePlaceChange = (place) => {
    if (!place) onChange(PARTY_NAME, '');
    else onChange(PARTY_NAME, place.PlaceName);
  };

  const handlePersonChange = (person) => {
    if (!person) {
      onChange(PARTY_NAME, '');
      onChange(PARTY_RELATIONSHIP, '');
    } else onChange(PARTY_NAME, person.FullName);
  };

  const handleCallerChange = (caller) => {
    if (!caller) onChange(PARTY_NAME, '');
    else onChange(PARTY_NAME, caller.FullName);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container xs={12} spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xl={3} md={3} sm={12} xs={12}>
            {' '}
            <KeyboardDatePicker
              className={classes.datetime}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              label="Date From"
              value={dateFrom}
              onChange={(date) => onChange('dateFrom', date)}
              maxDate={dateTo}
              autoOk
              size="small"
            />{' '}
          </Grid>
          <Grid item xl={3} md={3} sm={12} xs={12}>
            <KeyboardDatePicker
              className={classes.datetime}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              label="Date To"
              value={dateTo}
              onChange={(date) => onChange('dateTo', date)}
              minDate={dateFrom}
              autoOk
              size="small"
            />
          </Grid>
          <Grid item xl={3} md={3} sm={12} xs={12}>
            <KeyboardTimePicker
              className={classes.datetime}
              label="Start Time"
              inputVariant="outlined"
              size="small"
              margin={'normal'}
              ampm={!format24}
              value={timeFrom}
              onChange={(time) => onChange('timeFrom', time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xl={3} md={3} sm={12} xs={12}>
            <KeyboardTimePicker
              className={classes.datetime}
              label="End Time"
              inputVariant="outlined"
              size="small"
              margin={'normal'}
              ampm={!format24}
              value={timeTo}
              onChange={(time) => onChange('timeTo', time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <MultiSelectAutoComplete
              setValue={(val) => onChange('unit', val)}
              value={unit}
              type="Unit"
              label={'Unit'}
              options={filteredUnits}
            />
          </Grid>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <MultiSelectAutoComplete
              type="Zones"
              label={'Zone'}
              value={zone}
              options={filteredZones}
              setValue={(val) => onChange('zone', val)}
            />
          </Grid>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <MultiSelectAutoComplete
              type="Dispositions"
              label="Disposition"
              value={disposition}
              options={filteredDispositions}
              setValue={(val) => onChange('disposition', val)}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <FormLabel id="radio-label">Party Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="radio-label"
              name="row-radio-buttons-group"
              value={partyType}
              onChange={(event) => onChange('partyType', event.target.value)}>
              <FormControlLabel value={CALLER} control={<Radio />} label={CALLER} />
              <FormControlLabel value={PERSON} control={<Radio />} label={PERSON} />
              <FormControlLabel value={PLACE} control={<Radio />} label={PLACE} />
            </RadioGroup>
          </Grid>
          <Grid item xl={4} md={4} sm={12} xs={12} className={classes.marginTop}>
            {partyType === PLACE ? (
              <PlaceLookup
                onChange={handlePlaceChange}
                label="Place"
                disabled={partyType !== PLACE}
              />
            ) : partyType === CALLER ? (
              <CallerLookup label="Caller" onChange={handleCallerChange} />
            ) : (
              <PersonLookup2
                onPersonChange={handlePersonChange}
                compact="true"
                label="Person"
                disabled={partyType !== CALLER && partyType !== PERSON}
              />
            )}
          </Grid>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            {partyType === PERSON && (
              <Dictionary
                options="PartyRelationships"
                className={classes.marginTop}
                onChange={(ev, val) => onChange('partyRelationship', val)}
                value={partyRelationship}
                label="Party Relationship"
                compact="true"
                showAddIcon={false}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default EventFilters;
