import React, { useState, Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Button, Avatar, Box, Badge } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Tooltip from './Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prodMenuItems from 'config/configureMenu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MenuBulletIcon from '@material-ui/icons/FiberManualRecord';
import settings from 'config/settings';
import {
  addPageDialog,
  showReport,
  closeReport,
  showTestingDialog,
  showRotations,
} from 'reducers/DialogsReducer';
import Clock from './Clock';
import { getTestingLevel } from 'utils/functions';
import UnreadBulletins from './UnreadBulletins';
import UnitsNeedAttention from './UnitsNeedAttention';
import UserMenu from './UserMenu';
import { authorizeExternalApp, getUserClearanceOptions } from 'reducers/UserReducer';
import { getAccessPermission, getFullPermissions } from 'reducers/PermissionsReducer';
import Fab from '@material-ui/core/Fab';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import {
  setSettingsVal,
  getLocalSettings,
  changeAppMode,
  clearAppModeData,
} from 'reducers/ConfigReducer';
import { clearE911 } from 'reducers/E911Reducer';
import { green, pink } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const multipleScreens = process.env.REACT_APP_MULTIPLE_SCREENS === 'true';
const analyticsEnabled = process.env.REACT_APP_ANALYTICS === 'true';

function getNcicUrl() {
  let url = process.env.REACT_APP_NCIC_URL;
  if (!url) return '';
  if (url.substr(-1) !== '/') url += '/';
  url += 'Search';
  return url;
}

const ncicUrl = getNcicUrl();

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& button': {
      '& span': {
        marginRight: 8,
      },
    },
  },
  rightMenu: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.topNav.bg,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: settings.mainNavHeight,
    minHeight: settings.mainNavHeight,
    backgroundColor: theme.palette.bgElements,
  },
  btn: {
    width: '85px',
    height: '60px',
    border: '3px solid rgba(1, 83, 163, 0.5) !important',
  },
  btnLabel: {
    position: 'absolute',
    bottom: -3,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  activeNav: {
    '& > li': {
      fontWeight: 600,
    },
  },
  userName: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  arrowDn: {
    color: theme.colors.text1,
    marginLeft: 8,
  },
  fullWidthMenu: {
    zIndex: 2,
    position: 'absolute',
    top: settings.mainNavHeight,
    left: 0,
    right: 0,
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  searchMenu: {
    display: 'flex',
    '& h5': {
      paddingLeft: theme.spacing(2),
    },
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      opacity: 0.25,
    },
    '& a': {
      color: theme.colors.text1,
    },
    '& a:hover': {
      color: theme.colors.text1,
    },
  },
  fwMenuWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  menuUnread: {
    width: 3800,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
}));

const testingLevel = getTestingLevel();

const modeLabels = {
  master: 'Primary',
  slave: 'Secondary',
  standalone: 'Standalone',
};

const UserBadge = withStyles({
  badge: {
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const MainNav = (props) => {
  const classes = useStyles();
  const { show911, mode, serverConnection, Rotations, enableE911 } = props;
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [searchMenuVisible, setSearchMenuVisible] = useState(false);
  const [rotationsMenuOpen, setRotationsMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(prodMenuItems);
  const [disable911, setDisable911] = useState(true);
  const rotationsMenuRef = useRef(null);
  const userName = props.user?.userData?.user?.Username || '';
  const disableExexNcic = !getAccessPermission('globals', 'Execute NCIC Queries', 'any');
  const disableViewNcic = !getAccessPermission('globals', 'View NCIC Queries', 'any');
  const disableSendNotification = !getAccessPermission('globals', 'Send General Page', 'any');
  const disableNotificationSearch = !getAccessPermission('globals', 'View Paging History', 'any');
  const disableBulletinSearch = !getFullPermissions('cad', 'Alerts', 'any').Read;
  const analyticsPermission = getAccessPermission('modules', 'Analytics', 'any');
  const workstationID = getLocalSettings('workstationID');
  const e911Len = Object.entries(props.e911).length;

  const StyledBadge = withStyles({
    badge: {
      backgroundColor: serverConnection === false ? 'var(--danger)' : 'var(--success)',
      color: serverConnection === false ? 'var(--danger)' : 'var(--success)',
    },
  })(UserBadge);

  // useEffect(() => {
  //   const menu = [...menuItems];
  //   if (testingLevel > 1) {
  //     menu.splice(4, 0, {
  //       label: 'Alternate',
  //       tooltip: 'Alternate home view',
  //       link: '/alt-home',
  //       icon: 'headset',
  //     });
  //   }
  //   setMenuItems(menu);
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (workstationID && enableE911) {
      disable911 && setDisable911(false);
    } else {
      !disable911 && setDisable911(true);
    }
    // eslint-disable-next-line
  }, [workstationID, enableE911]);

  useEffect(() => {
    getUserClearanceOptions();
  }, []);

  const handleUserMenuClick = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserMenuAnchor(null);
  };

  const handleSearchClick = () => {
    setSearchMenuVisible(!searchMenuVisible);
  };

  const handleaddPage = () => {
    props.addPageDialog();
  };

  const test = () => {
    props.showTestingDialog();
  };

  const toggle911 = () => {
    let new911;
    switch (show911) {
      case 2:
        new911 = 1;
        if (e911Len) props.clearE911();
        break;
    }
    props.setSettingsVal('show911', new911);
  };

  const render911 = () => {
    return (
      <Tooltip title={`Clear e911 cache (${e911Len})`}>
        <span>
          <Fab
            className="mr-4"
            size="small"
            onClick={toggle911}
            color={enableE911 ? 'primary' : undefined}
            disabled={disable911}
            style={{ background: e911Len && !disable911 ? '#d32f2f' : undefined }}>
            <PhoneCallbackIcon />
          </Fab>
        </span>
      </Tooltip>
    );
  };

  const handleRotationsClick = () => {
    setRotationsMenuOpen(true);
  };

  const showRotationsDialog = (Rotation) => {
    props.showRotations({ ptsEventID: 0, EventID: 'None', Rotation });
    setRotationsMenuOpen(false);
  };

  const renderDesktopMenu = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.leftMenu}>
          <div>
            {menuItems.map((btn, idx) => (
              <Tooltip title={btn.tooltip} key={idx}>
                <NavLink to={btn.link} activeClassName={classes.activeNav}>
                  <Button>{btn.label}</Button>
                </NavLink>
              </Tooltip>
            ))}
            {!disableSendNotification && (
              <Button onClick={handleaddPage}>
                <span>Send Notification</span>
              </Button>
            )}
            {analyticsEnabled && analyticsPermission && (
              <a href="/analytics/">
                <Button>Analytics</Button>
              </a>
            )}
            {testingLevel > 1 && (
              <Button onClick={test}>
                <span>Test</span>
              </Button>
            )}
            <Button onClick={handleSearchClick}>
              <span>SEARCH</span>
              <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
            </Button>
            <Button onClick={handleRotationsClick} ref={rotationsMenuRef}>
              <span>Rotations</span>
              <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
            </Button>
          </div>
        </div>

        <div className={classes.rightMenu}>
          {multipleScreens && renderAppMode()}
          {render911()}
          <UnreadBulletins />
          <UnitsNeedAttention />
          <div className="mr-3">
            <Clock />
          </div>
          <div>
            <Button
              color="inherit"
              onClick={handleUserMenuClick}
              className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
              <Box className={classes.avatar}>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot">
                  <Avatar sizes="30" alt={userName} />
                </StyledBadge>
              </Box>
              <span className={classes.userName}>{userName}</span>
              <span className={classes.arrowDn}>
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderSearchMenu = () => {
    return (
      <div className={classes.searchMenu}>
        <div>
          <h5>Communication</h5>
          <MenuList>
            {disableBulletinSearch && (
              <MenuItem button disabled>
                <MenuBulletIcon /> Bulletin
              </MenuItem>
            )}
            {!disableBulletinSearch && (
              <NavLink to="/search/bulletin" activeClassName={classes.activeNav}>
                <MenuItem>
                  <MenuBulletIcon /> Bulletin
                </MenuItem>
              </NavLink>
            )}
            {disableNotificationSearch && (
              <MenuItem button disabled>
                <MenuBulletIcon /> Notification History
              </MenuItem>
            )}
            {!disableNotificationSearch && (
              <NavLink to="/search/notifications" activeClassName={classes.activeNav}>
                <MenuItem>
                  <MenuBulletIcon /> Notification History
                </MenuItem>
              </NavLink>
            )}
            <NavLink to="/search/sops" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> SOPs
              </MenuItem>
            </NavLink>
            <NavLink to="/search/unit-status" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Unit Status
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>Events</h5>
          <MenuList>
            <NavLink to="/search/events" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Events
              </MenuItem>
            </NavLink>
            <NavLink to="/search/notes" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Notes
              </MenuItem>
            </NavLink>
            <NavLink to="/search/rotation-history" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Rotation History
              </MenuItem>
            </NavLink>
            {/* <MenuItem>
              <MenuBulletIcon /> Shifts
            </MenuItem> */}
            <NavLink to="/search/units" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Units
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>Parties</h5>
          <MenuList>
            <NavLink to="/search/agencies" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Agencies
              </MenuItem>
            </NavLink>
            <NavLink to="/search/credentials" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Credentials
              </MenuItem>
            </NavLink>
            <NavLink to="/search/employees" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Employees
              </MenuItem>
            </NavLink>
            <NavLink to="/search/images" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Images
              </MenuItem>
            </NavLink>
            <NavLink to="/search/names" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Names
              </MenuItem>
            </NavLink>
            <NavLink to="/search/people" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> People
              </MenuItem>
            </NavLink>
            <NavLink to="/search/phone-numbers" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Phone Numbers
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>Property</h5>
          <MenuList>
            <NavLink to="/search/addresses" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Addresses
              </MenuItem>
            </NavLink>
            <NavLink to="/search/advanced-addresses" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Advanced Addresses
              </MenuItem>
            </NavLink>
            <NavLink to="/search/items" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Items
              </MenuItem>
            </NavLink>
            <NavLink to="/search/places" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Places
              </MenuItem>
            </NavLink>
            <NavLink to="/search/vehicles" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Vehicles
              </MenuItem>
            </NavLink>
            <NavLink to="/search/vehicle-towings" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Vehicle Towings
              </MenuItem>
            </NavLink>
            <NavLink to="/search/zones" activeClassName={classes.activeNav}>
              <MenuItem>
                <MenuBulletIcon /> Zones
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div>
          <h5>State</h5>
          <MenuList>
            <MenuItem
              onClick={authorizeExternalApp}
              button
              component="a"
              href={ncicUrl}
              target="_ncic"
              disabled={disableExexNcic}>
              <MenuBulletIcon /> NCIC
            </MenuItem>
            <MenuItem>
              <MenuBulletIcon /> Password
            </MenuItem>
            {disableViewNcic && (
              <MenuItem button disabled>
                <MenuBulletIcon /> Queries
              </MenuItem>
            )}
            {!disableViewNcic && (
              <NavLink to="/search/ncic-queries" activeClassName={classes.activeNav}>
                <MenuItem>
                  <MenuBulletIcon /> Queries
                </MenuItem>
              </NavLink>
            )}
          </MenuList>
        </div>
      </div>
    );
  };

  const renderAppMode = () => {
    return (
      <Tooltip title="Data flow mode">
        <Button style={{ marginRight: 16 }}>{modeLabels[mode]}</Button>
      </Tooltip>
    );
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {renderDesktopMenu()}
          {Boolean(userMenuAnchor) && (
            <UserMenu anchorEl={userMenuAnchor} onClose={closeUserMenu} user={props.user} />
          )}
        </Toolbar>
      </AppBar>

      {searchMenuVisible && (
        <div className={classes.fwMenuWrap} onClick={() => setSearchMenuVisible(false)}>
          <Card className={classes.fullWidthMenu}>{renderSearchMenu()}</Card>
        </div>
      )}
      <Menu
        anchorEl={rotationsMenuRef.current}
        keepMounted
        open={rotationsMenuOpen}
        onClose={() => setRotationsMenuOpen(false)}>
        {Rotations?.map((item) => (
          <MenuItem onClick={() => showRotationsDialog(item)} key={item.Code}>
            {item.Description}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  show911: state.config.show911,
  mode: state.config.mode,
  e911: state.e911,
  eventDataPresent: state.config.eventDataPresent,
  unitDataPresent: state.config.unitDataPresent,
  serverConnection: state.serverConnection,
  Rotations: state.dictionary.Rotations,
  enableE911: state.userSettings.enableE911,
});

export default withRouter(
  connect(mapStateToProps, {
    addPageDialog,
    showReport,
    closeReport,
    showTestingDialog,
    setSettingsVal,
    clearE911,
    changeAppMode,
    clearAppModeData,
    showRotations,
  })(MainNav)
);
