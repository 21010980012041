import React, { useState, useEffect, useRef } from 'react';
import { getService } from 'reducers/service';
import Button from '@material-ui/core/Button';
import { useSelector, connect, useDispatch } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import TextField2 from 'components/TextField2';
import styled from '@emotion/styled';
import { newEvent, subscribeEvents, unsubscribeEvents } from 'reducers/EventsReducer';
import { asyncForEach } from 'utils/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { sleep } from 'utils/functions';
import { addUnit } from 'reducers/SearchReducer';
import { updateUnitStatusPromise } from 'reducers/UnitStatusReducer';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import { leaveChannel, joinChannel } from 'reducers/service';
import { subscribeUnits, unsubscribeUnits } from 'reducers/UnitsReducer';
import { getAddressDetails } from 'reducers/AddressReducer';
import { addCoordsToLocation } from 'utils/mapFunctions';
import { getUnits } from 'reducers/UnitsReducer';
import moment from 'moment';

let minMaxCoords = {
  latMin: 30, // less go down
  latMax: 31,
  lngMin: -91.8, // less - go left
  lngMax: -90.5,
};

const storedCoords = window.localStorage.getItem('testCoords');
if (storedCoords) {
  minMaxCoords = JSON.parse(storedCoords);
}

let unitsCoords = {};

const coordStep = 0.005;

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Checkbox = styled.div`
  align-self: end;
  margin-left: 8px;
  cursor: pointer;
  input {
    margin-right: 8px;
  }
`;

const Console = styled.div`
  position: relative;
  div {
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    height: 400px;
    font-size: 14px;
    background: #252526;
    color: #cccccc;
    border: 1px solid #000;
    border-radius: 4px;
    white-space: pre-line;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 12px;
  }
  button {
    position: absolute;
    top: 2px;
    right: 5px;
    color: #f6f2f2;
    background: #333;
    transition: all 0.3s;
    border-radius: 3px;
    &:hover {
      background: #555;
      color: #fff;
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;

const Row = styled.div`
  margin: 0 0 16px;
  display: flex;
  button {
    margin: 0 8px;
  }
`;

const Content = styled.div`
  padding: 20px;
  label {
    margin-right: 10px;
    font-weight: 500;
    display: inline-block;
    width: 250px;
  }
`;

let eventTypeIdx = 0;
const waitTime = 500;

function generateString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}

let prevPtsEventID = null;

function LegacyRMSExport(props) {
  const dispatch = useDispatch();
  const [ptsEventID, setPtsEventID] = useState(prevPtsEventID);
  const [busy, setBusy] = useState(false);
  const [consoleText, setConsoleText] = useState('');
  const consoleRef = useRef('');
  const clearRef = useRef(false);

  const handlePtsEventIDChange = (val) => {
    const ptsEventID = parseInt(val);
    setPtsEventID(ptsEventID);
    prevPtsEventID = ptsEventID;
  };

  const legacyRMSExport = async () => {
    const service = getService('event-push-to-legacy');
    setBusy(true);
    try {
      await service.create({ ptsEventID });
      dbg('Legacy RMS export complete ' + ptsEventID);
    } catch (err) {
      dispatch(handleError(err));
      dbg('Error exporting legacy RMS export ' + ptsEventID);
    }
    setBusy(false);
  };

  const removeRMSExport = async () => {
    const service = getService('event-push-to-legacy');
    setBusy(true);
    try {
      await service.remove(ptsEventID);
      dbg('Removed RMS export ' + ptsEventID);
    } catch (err) {
      dispatch(handleError(err));
      dbg('Error removing RMS export ' + ptsEventID);
    }
    setBusy(false);
  };

  const checkIfExists = async () => {
    const service = getService('event-push-to-legacy');
    setBusy(true);
    try {
      const result = await service.get(ptsEventID);
      dbg(`Legacy RMS export for ${ptsEventID} ${result ? 'exists' : "doesn't exists"}`);
    } catch (err) {
      dispatch(handleError(err));
      dbg('Error testing for legacy RMS export ' + ptsEventID);
    }
    setBusy(false);
  };

  const extLegacyRMSExport = async () => {
    const service = getService('event-export-legacy-rms');
    setBusy(true);
    try {
      await service.create({ ptsEventID });
      dbg('Law Enforcement RMS Export complete for ' + ptsEventID);
    } catch (err) {
      dispatch(handleError(err));
      dbg('Error exporting Law Enforcement RMS for ' + ptsEventID);
    }
    setBusy(false);
  };

  const dbg = (text) => {
    let prevText = consoleRef.current;
    if (clearRef.current) {
      clearRef.current = false;
      prevText = '';
    }
    const t = new Date().getTime();
    const time = moment(t).format('HH:mm:ss');
    const out = prevText + '\n' + time + ': ' + text;
    consoleRef.current = out;
    setConsoleText(out);
  };

  const renderConsole = () => {
    const clearConsole = () => {
      clearRef.current = true;
      setConsoleText('');
    };
    return (
      <Console>
        <h5>Console</h5>
        <button onClick={clearConsole}>Clear</button>
        <div>{consoleText}</div>
      </Console>
    );
  };

  return (
    <Content>
      <hr />
      <Row>
        <TextField2
          label="ptsEventID"
          value={ptsEventID}
          onChange={(ev, val) => handlePtsEventIDChange(val)}
          style={{ width: 100 }}
          compact="true"
        />
      </Row>
      <h5>Export to legacy RMS</h5>
      <Row>
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={legacyRMSExport}
          disabled={busy}>
          Create
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={checkIfExists}
          disabled={busy}>
          Check if exists
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={removeRMSExport}
          disabled={busy}>
          Remove
        </Button>
      </Row>
      <h5>Export to remote legacy RMS</h5>
      <Row>
        <Button
          variant="contained"
          style={{ marginRight: 8 }}
          color="primary"
          onClick={extLegacyRMSExport}
          disabled={busy}>
          Law Enforcement RMS Export
        </Button>
      </Row>
      <hr />
      {renderConsole()}
    </Content>
  );
}

export default connect(null, {
  subscribeEvents,
  subscribeUnits,
})(LegacyRMSExport);
