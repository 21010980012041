import React from 'react';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '3px 20px 3px 10px',
    minHeight: 54,
    marginBottom: 10,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h3': {
      margin: 0,
    },
  },
}));

function ToolBar(props) {
  const classes = useStyles();
  return <Card className={clsx(classes.card, props.className)}>{props.children}</Card>;
}

export default ToolBar;
