import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { firstNameLookup } from 'reducers/CallerReducer';
import { handleError } from 'reducers/ErrorReducer';
import TextFieldPlus from './TextFieldPlus';

function CallerFirstNameLookup(props) {
  const throttleRef = useRef(0);
  const [searchMatch, setSearchMatch] = useState([]);
  const [FirstName, setFirstName] = useState('');
  const { label = 'First Name', value } = props;
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (value === FirstName) return;
    setFirstName(value)
    // eslint-disable-next-line
  }, [value]);

  const onInputChange = (text) => {
    props.onChange && props.onChange(text);
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      firstNameLookup(text)
        .then((result) => {
          if (!mountedRef.current) return;
          const searchMatch = result.map(r => r.FirstName);
          setSearchMatch(searchMatch);
        })
        .catch((err) => props.handleError(err, 'Cannot contact API'));
    }, 500);
  };

  const onSearchSelect = (ev, selection) => {
    if (!selection) {
      setFirstName('');
      props.onChange && props.onChange('');
      return;
    }
    setFirstName(selection);
    props.onChange && props.onChange(selection);
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      className={props.className}
      fullWidth
      autoHighlight
      options={searchMatch}
      value={FirstName}
      size="small"
      onChange={onSearchSelect}
      onInputChange={(ev, val) => onInputChange(val)}
      renderInput={(params) => {
        params.label = label;
        params.variant = 'outlined';
        return <TextFieldPlus {...params} />
      }}
      freeSolo
    />
  );
}

export default connect(null, {
  handleError,
})(CallerFirstNameLookup);
