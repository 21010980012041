import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { addPageDialog } from 'reducers/DialogsReducer';
import { getExcerpt } from 'utils/functions';
import { displayTime, displayDate, displayDateTime } from 'reducers/TimeReducer';
import { getEventPages } from 'reducers/PagingReducer';
import { XGrid } from '@material-ui/x-grid';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const panelHeight = 300;
const useStyles = makeStyles((theme) => ({
  notes: {
    textAlign: 'left',
  },
  note: {
    padding: '10px 15px 5px',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    borderBottom: `1px solid ${theme.card.hr}`,
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    // borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  textField: {
    margin: `0 0 ${theme.spacing(1)}px 0`,
  },
  rowActions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  editNote: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
  },
  gridWrap: {
    width: '100%',
    height: panelHeight,
  },
}));

const columns = [
  { field: 'MessageTo', headerName: 'Recipient', width: 170 },
  { field: 'Subject', headerName: 'Subject', width: 200 },
  { field: 'excerpt', headerName: 'Message', width: 250 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 120 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
  },
];

function NotificationsTab(props) {
  const { event, disableEdit } = props;
  const perms = getFullPermissions('cad', 'Events', 'any');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    updatePages();
    // eslint-disable-next-line
  }, [event.pagingHistoryCount]);

  const updatePages = () => {
    setLoading(true);
    getEventPages(event.EventID)
      .then((data) => setData(processData(data)))
      .catch((err) => props.handleError(err, 'Error, Cannot load paging history.'))
      .finally(() => setLoading(false));
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.PrimaryKeyValue,
        excerpt: getExcerpt(row.Body, 150),
      };
    });
  };

  const onaddPageDialog = () => {
    const {
      EventID,
      CallType,
      FullLocationAddress,
      ReceiveDate,
      ptsEventID,
      CallTypeDescription,
      coordinates,
      CrossStreet,
    } = event;
    props.addPageDialog({
      EventID,
      ptsEventID,
      EventType: CallType,
      Address: FullLocationAddress,
      Date: displayDate(ReceiveDate),
      Time: displayTime(ReceiveDate),
      EventDescription: CallTypeDescription,
      Coordinates: coordinates ? coordinates.replace(/\s/g, '') : '',
      CrossStreet,
    });
  };

  return (
    <div className={classes.notes}>
      <div className={classes.actions}>
        {!disableEdit && (
          <Tooltip title="Add Page">
            <IconButton
              color="primary"
              size="small"
              component="span"
              onClick={onaddPageDialog}
              disabled={!perms.Edit}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={loading}
          disableColumnMenu
          density={'compact'}
          hideFooter
        />
      </div>
    </div>
  );
}

export default connect(null, {
  notify,
  handleError,
  addPageDialog,
})(NotificationsTab);
