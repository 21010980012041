import React from 'react';

export default function NotAuthorized() {
  return (
    <div style={{ padding: 16, textAlign: 'center' }}>
      <h1>401 Unauthorized</h1>
      <h4>You don't have permissions to show this application</h4>
    </div>
  );
}
