import React from 'react';
import Events from 'pages/Events';

const style = { padding: 16 };

function EventsPage() {
  return (
    <div style={style}>
      <Events />
    </div>
  );
}

export default EventsPage;
