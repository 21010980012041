
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8doctorsbag100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M19 5c-1.652 0-3 1.348-3 3v3H3c-1.652 0-3 1.348-3 3v5h6c0-1.102.898-2 2-2h4c1.102 0 2 .898 2 2h22c0-1.102.898-2 2-2h4c1.102 0 2 .898 2 2h6v-5c0-1.652-1.348-3-3-3H34V8c0-1.652-1.348-3-3-3Zm0 2h12c.55 0 1 .45 1 1v3H18V8c0-.55.45-1 1-1ZM8 19v2h4v-2Zm30 0v2h4v-2ZM0 21v21c0 1.652 1.348 3 3 3h44c1.652 0 3-1.348 3-3V21h-6c0 1.102-.898 2-2 2h-4c-1.102 0-2-.898-2-2H14c0 1.102-.898 2-2 2H8c-1.102 0-2-.898-2-2Zm23 4h4v4h4v4h-4v4h-4v-4h-4v-4h4Z"/></SvgIcon>
      );
    }
  
    export default icons8doctorsbag100Icon;