import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { getPeopleFeatures } from 'reducers/SearchReducer';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import { showPersonFeature } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const defaultColumns = [
  'ptsFeatureID',
  'AttributeCategory',
  'Attribute',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];
const Features = (props) => {
  const classes = useStyles();
  const { ptsPersonID, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const mountedRef = useRef(true);
  const perms = getFullPermissions('globals', 'People', 'any');
  const canEditPerson = perms.Edit;

  useEffect(() => {
    ptsPersonID && getData();
  }, []);

  useEffect(() => {
    if (dataUpdate?.type === 'person-feature') getData();
  }, [dataUpdate]);

  const getData = async () => {
    try {
      const data = await getPeopleFeatures(ptsPersonID);
      if (!mountedRef.current) return;

      setRows(processData(data.data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };
  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const handleAdd = () => props.showPersonFeature(ptsPersonID);

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch
            rows={rows}
            columns={columns}
            setFilteredRows={setFilteredRows}
            style={{ marginTop: 0, paddingBottom: 14 }}
          />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Feature">
            <span>
              <Fab size="small" color="secondary" onClick={handleAdd} disabled={!canEditPerson}>
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>
        </div>
      </div>
      <XGrid3
        name="PeopleFeatures"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editPeopleEmployment: state.dialogs.editPeopleEmployment,
    dataUpdate: state.dataUpdate,
  };
};
export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  showPersonFeature,
})(Features);
