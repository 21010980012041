import React, { useState, useEffect } from 'react';
import XGrid3 from 'components/XGrid3';

function MergeAddrTargetDialog(props) {
  const { sourceID, columns, setTargetID, targetID } = props;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const rows = props.rows.filter((row) => row.id !== sourceID && !row.IsDeleted);
    setRows(rows);
    // eslint-disable-next-line
  }, []);

  return (
    <XGrid3
      name="Addresses History"
      columns={columns}
      setColumns={() => { }}
      rows={rows}
      setSelection={setTargetID}
      style={{ height: 600 }}
      selectionModel={[targetID]}
    />
  );
}

export default MergeAddrTargetDialog;
