import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { editEvent } from 'reducers/DialogsReducer';
import { Button } from '@material-ui/core';

const parsePtsEventID = (ptsEventIDStr) => {
  if (!(ptsEventIDStr && isFinite(ptsEventIDStr) && parseInt(ptsEventIDStr) !== 0)) {
    return null;
  }
  return parseInt(ptsEventIDStr);
};

const style = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const EventPage = (props) => {
  const dispatch = useDispatch();
  const ptsEventID = parsePtsEventID(props.match.params.ptsEventID);

  useEffect(() => {
    if (!ptsEventID) return;
    dispatch(editEvent(ptsEventID));
    // eslint-disable-next-line
  }, []);

  const closeWindow = () => {
    window.close();
  };

  if (!ptsEventID) return 'Infalid ptsEventID';

  return (
    <div style={style}>
      <Button onClick={closeWindow} variant="contained" color="primary">
        Close Window
      </Button>
    </div>
  );
};

export default EventPage;
