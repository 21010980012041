import { getService } from 'reducers/service';
import store from 'config/configureStore';
import { getAddressCoordinates } from 'reducers/AddressReducer';

// Configuration
export const defaultBounds = { latMin: -90, latMax: 90, lngMin: -180, lngMax: 180 };
export const defaultBoundsRef = { latMin: -90, latMax: 90, lngMin: -180, lngMax: 180 };
export const defaultCenterRef = { lat: 30.39, lng: -91.07 };
export const defaultMenuPos = { x: 100, y: 100 };

/** returns address details when coords provided { lat, lng } */
export const geocodeCoords = (latlng) => {
  return new Promise((resolve, reject) => {
    let geocoder;
    try {
      geocoder = new window.google.maps.Geocoder();
    } catch (err) {
      return Promise.reject(err);
    }
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        resolve(parseGoogleAddress(results));
      } else {
        reject(status);
      }
    });
  });
};

export function parseGoogleAddress(results) {
  const addresses = [];
  results.forEach((rec) => {
    const addressType = rec?.types[0];
    if (addressType === 'premise' || addressType === 'street_address') {
      let address = {
        formattedAddress: rec.formatted_address,
        type: addressType,
      };
      rec.address_components.forEach((c) => {
        const type = c.types[0];
        switch (type) {
          case 'street_number':
            address.AddressNumber = c.short_name;
            break;
          case 'route':
            address = { ...address, ...parseStreet(c.short_name) };
            break;
          case 'locality':
            address.ptsCityID = getCityID(c.short_name);
            break;
          case 'administrative_area_level_2':
            address.placeName = c.short_name;
            break;
          case 'administrative_area_level_1':
            address.State = getStateCode(c.short_name);
            break;
          case 'postal_code':
            address.PostalCode = c.short_name;
            break;
          default:
        }
      });
      addresses.push(address);
    }
  });
  return addresses;
}

/** returns ptsCityID from city name */
export function getCityID(city) {
  const state = store.store.getState();
  const { Cities } = state.dictionary;
  let ptsCityID = null;
  const City = Cities.find((c) => c.Code.toLowerCase() === city.toLowerCase());
  if (City) {
    ptsCityID = City.ptsCityID;
  }
  return ptsCityID;
}

/** returns State code  */
function getStateCode(st) {
  const state = store.store.getState();
  const { States } = state.dictionary;
  let Code = null;
  const State = States.find((c) => c.Code === st);
  if (State) {
    Code = State.Code;
  }
  return Code;
}

const StreetDirections = ['E', 'N', 'NE', 'NW', 'S', 'SE', 'SW', 'W'];
// const StreetTypes = ['AL', 'AVE', 'BLVD', 'CR', 'CT', 'DR', 'HWY', 'LN', 'PL', 'RD', 'ST', 'TRL'];

/** converts street name like 'Renoir Ave' to object { StreetName: 'Renoir', StreetType: 'Ave' } */
export function parseStreet(rawStreet) {
  const strObj = {
    StreetName: null,
    StreetType: null,
    PreDirection: null,
    PostDirection: null,
  };
  if (!rawStreet) return strObj;
  let street = rawStreet.trim();
  // check for pre-direction
  const idxPre = street.indexOf(' ');
  if (idxPre > -1 && idxPre < 3) {
    const pre = street.substr(0, idxPre).trim().toUpperCase();
    if (StreetDirections.indexOf(pre) > -1) {
      strObj.PreDirection = pre;
      street = street.substr(idxPre).trim();
    }
  }
  // check for post direction
  let idxPost = street.lastIndexOf(' ');
  if (idxPost > -1 && street.length - idxPost < 4) {
    const post = street.substr(idxPost).trim().toUpperCase();
    if (StreetDirections.indexOf(post) > -1) {
      strObj.PostDirection = post;
      street = street.substr(0, idxPost).trim();
    }
  }

  // check street type
  let idxType = street.lastIndexOf(' ');
  if (idxType > -1 && street.length - idxPost < 6) {
    const state = store.store.getState();
    const StreetTypes = state.dictionary.StreetTypes.map((obj) => obj.Code);
    const type = street.substr(idxType).trim().toUpperCase();
    if (StreetTypes.indexOf(type) > -1) {
      strObj.StreetType = type;
      strObj.StreetName = street.substr(0, idxType).trim();
    } else {
      strObj.StreetName = street;
    }
  } else {
    strObj.StreetName = street;
  }

  // set empty County and Notes
  strObj.County = null;
  strObj.Notes = '';

  return strObj;
}

/** Geocode address cache */
const coordsCache = {};

function getTime() {
  return new Date().getTime();
}

/** Remove the oldest cache if cache size > 1000 records */
function cleanCoordsCache() {
  const cacheLen = Object.entries(coordsCache).length;
  if (cacheLen > 1000) {
    let time = getTime();
    let address = null;
    Object.entries(coordsCache).forEach(([key, obj]) => {
      console.log(obj.time, obj);
      if (obj.time < time) {
        time = obj.time;
        address = key;
      }
    });
    if (address) {
      delete coordsCache[address];
    }
  }
}

/** Geocode address using caching */
export const geocodeAddress = (address) => {
  cleanCoordsCache();
  return new Promise((resolve, reject) => {
    let rec = coordsCache[address];
    if (rec) {
      if (rec.status === 200) {
        rec.time = getTime();
        resolve(rec.coords);
      } else {
        rec.subscribers.push({ resolve, reject });
      }
    } else {
      rec = {
        status: 'waiting',
        coords: null,
        time: getTime(),
        subscribers: [{ resolve, reject }],
      };
      coordsCache[address] = rec;
      geocodeGoogleAddress(address)
        .then((coords) => {
          rec.status = 200;
          rec.coords = coords;
          const len = rec.subscribers.length;
          for (let i = 0; i < len; i++) {
            const { resolve } = rec.subscribers.shift();
            resolve(coords);
          }
        })
        .catch((err) => {
          for (let i = 0; i < rec.subscribers.length; i++) {
            const { reject } = rec.subscribers.shift();
            reject(err);
          }
          delete coordsCache[address];
        });
    }
  });
};

/** returns { lat, lng } when provided address string */
const geocodeGoogleAddress = (address) => {
  return new Promise((resolve, reject) => {
    let geocoder;
    try {
      geocoder = new window.google.maps.Geocoder();
    } catch (err) {
      return Promise.reject(err);
    }
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        resolve({ lat, lng });
      } else {
        reject(status);
      }
    });
  });
};

/** returns { lat, lng } when provided location object */
export const geocodeLocation = (location) => {
  const { ptsCoordinateID } = location;
  if (ptsCoordinateID) {
    return getAddressCoordinates(ptsCoordinateID);
  } else {
    const state = store.store.getState();
    const { dictionary } = state;
    const validLocation = {
      ...location,
      PostalCode: location.PostalCode?.length >= 5 ? location.PostalCode.substr(0, 5) : null,
    };
    const Address = getAddressFromLocation(validLocation, dictionary);
    return geocodeAddress(Address);
  }
};

/** Returns full text address location */
export const getAddressFromLocation = (location) => {
  const state = store.store.getState();
  const { dictionary } = state;
  const AddressNumberPrefix = location.AddressNumberPrefix
    ? `${location.AddressNumberPrefix} `
    : '';
  const AddressNumberSuffix = location.AddressNumberSuffix
    ? ` ${location.AddressNumberSuffix}`
    : '';
  const AddressNumber = location.AddressNumber !== null ? location.AddressNumber : '';
  const PreDirection = location.PreDirection ? ` ${location.PreDirection}` : '';
  const StreenName = location.StreetName ? ` ${location.StreetName}` : '';
  const StreetType = location.StreetType ? ` ${location.StreetType}` : '';
  const PostDirection = location.PostDirection ? ` ${location.PostDirection}` : '';
  const cityObj = dictionary.Cities.find((city) => city.ptsCityID === location.ptsCityID);
  const City = cityObj ? `, ${cityObj.Code}` : '';
  const State = location.State ? `, ${location.State}` : '';
  const PostalCode = location.PostalCode ? ` ${location.PostalCode}` : '';
  const Unit =
    location.UnitType && location.UnitIdentifier
      ? `, ${location.UnitType} ${location.UnitIdentifier}`
      : '';
  return (
    AddressNumberPrefix +
    AddressNumber +
    AddressNumberSuffix +
    PreDirection +
    StreenName +
    StreetType +
    PostDirection +
    Unit +
    City +
    State +
    PostalCode
  );
};

/** Returns location object with coordinates added */
export const addCoordsToLocation = (location) => {
  const state = store.store.getState();
  const { dictionary } = state;
  const { ptsCoordinateID } = location;
  if (ptsCoordinateID) {
    return new Promise((resolve, reject) => {
      getCoordinates(ptsCoordinateID)
        .then((coords) => {
          resolve({ ...location, ...coords });
        })
        .catch((err) => reject(err));
    });
  } else {
    return new Promise((resolve, reject) => {
      geocodeLocation(location, dictionary)
        .then((coords) => {
          const lat = coords ? coords.lat : null;
          const lng = coords ? coords.lng : null;
          resolve({ ...location, lat: lat, lng });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

/** returns { lat, lng } - center point of the path */
export const getPathCenter = (path) => {
  let latMin = 999;
  let latMax = -999;
  let lngMin = 999;
  let lngMax = -999;
  path.forEach((point) => {
    const { lat, lng } = point;
    latMin = Math.min(lat, latMin);
    latMax = Math.max(lat, latMax);
    lngMin = Math.min(lng, lngMin);
    lngMax = Math.max(lng, lngMax);
  });
  const center = {
    lat: (latMax + latMin) / 2,
    lng: (lngMax + lngMin) / 2,
  };
  return center;
};

/** convert Google Maps polygon to path [{ lat, lng }] */
export const getPathFromPolygon = (polygon) => {
  const vertices = polygon.getPath();
  if (!vertices) return null;
  const path = [];
  const len = vertices.getLength();
  for (var i = 0; i < len; i++) {
    const point = vertices.getAt(i);
    const lat = point.lat();
    const lng = point.lng();
    path.push({ lat, lng });
  }
  return path;
};

/** returns path as string that could be saved to DB */
export const pathToStr = (path) => {
  let pathStr = '';
  path.forEach((point, idx) => {
    if (idx > 0) pathStr += ', ';
    pathStr += `${point.lng} ${point.lat}`;
  });
  pathStr += `, ${path[0].lng} ${path[0].lat}`;
  return pathStr;
};

/** converts string received from DB to path obj */
export const strToPath = (str) => {
  if (!str) return null;
  let pathStr = str.substr(10, str.length - 12);
  const arr = pathStr.split(',').map((point) => {
    const objArr = point.trim().split(' ');
    return {
      lat: Number(objArr[1]),
      lng: Number(objArr[0]),
    };
  });
  if (arr[0].lat === arr[arr.length - 1].lat && arr[0].lng === arr[arr.length - 1].lng) {
    arr.pop();
  }
  return arr;
};

/** Find max length for points alignment */
export const maxAlignLength = (path, perCent) => {
  let latMin = 999;
  let latMax = -999;
  let lngMin = 999;
  let lngMax = -999;
  path.forEach((point) => {
    const { lat, lng } = point;
    latMin = Math.min(lat, latMin);
    latMax = Math.max(lat, latMax);
    lngMin = Math.min(lng, lngMin);
    lngMax = Math.max(lng, lngMax);
  });
  return (Math.max(latMax - latMin, lngMax - lngMin) * perCent) / 100;
};

/** Returns path with points aligned to other paths */
export const alignToPaths = (path, geofences, len) => {
  const newPath = [];
  path.forEach((point) => {
    const matches = []; // points within the range
    geofences.forEach((geofence) => {
      geofence.path &&
        geofence.path.forEach((point2) => {
          const latDif = Math.abs(point.lat - point2.lat);
          const lngDif = Math.abs(point.lng - point2.lng);
          if (latDif <= len && lngDif <= len) matches.push(point2);
        });
    });
    if (matches.length) {
      let newPoint = { ...point };
      let len = 999;
      matches.forEach((point2) => {
        const latDif = Math.abs(newPoint.lat - point2.lat);
        const lngDif = Math.abs(newPoint.lng - point2.lng);
        const newLen = Math.max(latDif, lngDif);
        if (newLen < len) {
          len = newLen;
          newPoint.lat = point2.lat;
          newPoint.lng = point2.lng;
        }
      });
      newPath.push(newPoint);
    } else {
      newPath.push(point);
    }
  });
  return newPath;
};

export const calculateDistance = (p1, p2) => {
  const rad = (x) => (x * Math.PI) / 180;
  const R = 6378137;
  var dLat = rad(p2.lat - p1.lat);
  var dLong = rad(p2.lng - p1.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return parseInt(d);
};

export const getCoordinates = (ptsCoordinateID) => {
  const service = getService('ptscoordinates');
  return new Promise((resolve, reject) => {
    service
      .get(ptsCoordinateID)
      .then((result) => {
        const coords = getCoords(result);
        resolve(coords);
      })
      .catch((err) => reject(err));
  });
};

export const getCoords = (res) => {
  return {
    lat: res.LatitudeDegree * (res.LatitudeSign === '-' ? -1 : 1),
    lng: res.LongitudeDegree * (res.LongitudeSign === '-' ? -1 : 1),
  };
};

/** returns coords from unit state */
export const getUnitCoords = (unit) => {
  return {
    lat: unit.UnitLatitudeDegree * (unit.UnitLatitudeSign === '-' ? -1 : 1),
    lng: unit.UnitLongitudeDegree * (unit.UnitLongitudeSign === '-' ? -1 : 1),
  };
};

/** Convert { lat, lng } to { LatitudeSign, LongitudeDegree... }*/
export const parseCoords = (coords) => {
  const { lat, lng } = coords;
  let LatitudeSign = null;
  let LatitudeDegree = null;
  let LongitudeSign = null;
  let LongitudeDegree = null;
  if (!isNaN(lat)) {
    const latitude = parseFloat(lat);
    LatitudeSign = latitude < 0 ? '-' : '+';
    LatitudeDegree = Math.abs(latitude);
  }
  if (!isNaN(lng)) {
    const longitude = parseFloat(lng);
    LongitudeSign = longitude < 0 ? '-' : '+';
    LongitudeDegree = Math.abs(longitude);
  }
  return {
    LatitudeSign,
    LatitudeDegree,
    LongitudeSign,
    LongitudeDegree,
  };
};
