import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import splashScreen from 'assets/images/login-splash.jpg';
import { checkForUpdates } from 'reducers/AppInfoReducer';
import { notify } from 'reducers/NotifierReducer';
import { Card, CardContent, Button } from '@material-ui/core';
import projectLogo from 'assets/images/icon-CAD.svg';
import ChangeUserPass from 'components/ChangeUserPass';
import CloseIcon from '@material-ui/icons/Close';
import { hidePassExpiration } from 'reducers/UserReducer';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
    '& h4': {
      color: '#333',
    },
  },
  card: {
    width: 550,
    maxWidth: '95%',
    background: '#fff',
    '& img': {
      width: '100%',
    },
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ce0000',
    '& img': {
      width: 150,
      height: 'auto',
    },
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto',
    },
  },
  actions: {
    padding: '8px 16px',
    textAlign: 'right',
    borderTop: `1px solid #bbb`,
    '& button': {
      color: '#0153a3',
    },
    '& svg': {
      marginRight: 8,
    }
  },
  message: {
    fontSize: 17,
    color: '#333',
  },
}));

const PageLogin = (props) => {
  const classes = useStyles();
  const { passExpirationDays } = props;

  return (
    <div className={clsx(classes.pageWrap, 'login-form')}>
      <Card className={classes.card}>
        <div className={classes.branding}>
          <img src={projectLogo} alt="CAD icon" />
        </div>
        <CardContent className={classes.content}>
          {passExpirationDays > 0 && (<div className={classes.message}>
            <p>Your password will expire in <strong>{passExpirationDays}</strong> {passExpirationDays === 1 ? <>day</> : <>days</>}.
              Would you like to change it now?</p>
          </div>)}
          {passExpirationDays < 1 && (
            <div className={classes.message}>
              <p>Your password has expired. Please set another one.</p>
            </div>
          )}
          <ChangeUserPass />
        </CardContent>
        <div className={classes.actions}>
          <Button color="primary" onClick={props.hidePassExpiration} disabled={passExpirationDays < 1}>
            <CloseIcon /> Close
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.theme.mode,
  appInfo: state.appInfo,
});

export default connect(mapStateToProps, {
  hidePassExpiration,
  checkForUpdates,
  notify,
})(PageLogin);
