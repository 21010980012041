import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { showUnitResource } from 'reducers/DialogsReducer';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gridStyle } from 'utils/formStyles';
import XGrid3 from 'components/XGrid3';
import { removeUnitResource } from 'reducers/SearchReducer';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {
  getFormat24,
  getCurrentDate,
} from 'reducers/TimeReducer';
import { getFormStyle } from 'utils/functions';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { displayDate } from 'reducers/TimeReducer';
import { getService } from 'reducers/service';

const useStyles = makeStyles((theme) => ({
  item: {
    ...gridStyle(200, 320),
    marginTop: 10,
  },
  date: getFormStyle(200, 300),
  action: {
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

const typeOptions = ['Person', 'Vehicle'];

const PersonColumns = [
  'ComponentName',
  {
    field: 'Beginning',
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
  },
  {
    field: 'Ending',
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
  },
  'ChildType',
  'CapabilityDetails',
  'CapabilityType',
  'CapabilityTypeQuantity'
];

const VehicleColumns = [
  'VehicleID',
  'VIN',
  'Make',
  'Model',
  'PrimaryColor',
  'Year',
  {
    field: 'Beginning',
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
  },
  {
    field: 'Ending',
    valueFormatter: (params) => displayDate(params.value),
    format: 'date',
  },
  'CapabilityDetails',
  'CapabilityType',
  'CapabilityTypeQuantity'
]

function UnitResourceDialog(props) {
  const classes = useStyles();
  const { ptsUnitID, dataUpdate } = props;
  const [type, setType] = useState('Person');
  const [personCols, setPersonCols] = useState(PersonColumns);
  const [vehicleCols, setVehicleCols] = useState(VehicleColumns);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [personData, setPersonData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);

  useEffect(() => {
    searchUnitResources();
  }, []);

  useEffect(() => {
    if (dataUpdate?.type === 'unit-resource' ) searchUnitResources();
  }, [dataUpdate]);

  const searchUnitResources = async () => {
    const service = getService('cad-search-resources');
    setLoaded(false);
    try {
      const resources = await service.find({
        query: {
          ParentType: 'Unit',
          ChildType: 'Person',
          ptsParentID: ptsUnitID,
        }
      });
      setPersonData(resources.Person.map(r => ({ ...r, id: r.ptsResourceID })));
      setVehicleData(resources.Vehicle.map(r => ({ ...r, id: r.ptsResourceID })));
    } catch (err) {
      props.handleError(err);
    }
    setLoaded(true);
  }

  const renderType = () => {
    const handleTypeChange = (ev, val) => {
      setType(val);
      setSelection(null);
    }
    return (
      <Autocomplete
        options={typeOptions}
        value={type}
        className={classes.item}
        size="small"
        onChange={handleTypeChange}
        renderInput={(params) => <TextField {...params} label="Assignment Type" variant="outlined" />}
      />
    );
  }

  const renderXgrid = () => {
    return (
      <>
        {
          type === 'Person'
          &&
          <XGrid3
            name="unitResources"
            columns={personCols}
            setColumns={setPersonCols}
            rows={personData}
            setSelection={setSelection}
            loading={!loaded}
          />
        }
        {
          type === 'Vehicle'
          &&
          <XGrid3
            name="unitResources"
            columns={vehicleCols}
            setColumns={setVehicleCols}
            rows={vehicleData}
            setSelection={setSelection}
            loading={!loaded}
          />
        }
      </>
    )
  }


  const deleteResource = async () => {
    if (!window.confirm('Are you sure you want to remove this resource?')) return;
    try {
      await removeUnitResource(selection)
      searchUnitResources();
      setSelection(null);
    } catch (err) {
      props.handleError(err, 'Error deleting person');
    }
  }

  const handleAdd = () => props.showUnitResource({ type, ptsUnitID });

  const handleEdit = () => props.showUnitResource({ ptsResourceID: selection, type, ptsUnitID });

  const renderUnitActions = () => {
    return (
      <div className={classes.action}>
        <Fab size="small" color="secondary" onClick={handleAdd}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={handleEdit} disabled={!selection}>
          <EditIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={deleteResource} disabled={!selection}>
          <DeleteIcon />
        </Fab>
      </div>
    )
  }

  return (
    <div className='m-4'>
      {renderType()}
      {type && renderUnitActions()}
      {renderXgrid()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate
});

export default connect(mapStateToProps, { showUnitResource, handleError, showSpinner, hideSpinner })(UnitResourceDialog);
