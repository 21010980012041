import { useEffect } from 'react';
import { connect } from 'react-redux';
import { eventStatusChange } from 'reducers/EventsReducer';

function EventHotKeys(props) {
  const { event, reqDispositions, keyboard, copyEvent, setTab } = props;

  useEffect(() => {
    const kbdSubscription = keyboard.subject.subscribe(handleKbd);
    return () => {
      kbdSubscription.unsubscribe();
    }
    // eslint-disable-next-line
  }, []);

  const setEventStatus = (status) => {
    props.eventStatusChange(event.ptsEventID, status);
  };

  const closeEvent = () => {
    const reqDispMet = reqDispositions.reqDispAgencies.length === 0
      && reqDispositions.reqMergedDisp.length === 0;
    if (reqDispMet) {
      props.eventStatusChange(event.ptsEventID, 'Closed');
    } else {
      props.dispNotMet();
    }
  };

  const handleKbd = (keyCode) => {

    switch (keyCode) {
      case 'F2':
        setEventStatus('Dispatched');
        break;
      case 'F3':
        setEventStatus('Enroute');
        break;
      case 'F4':
        setEventStatus('Arrived');
        break;
      case 'F6':
        setEventStatus('Controlled');
        break;
      case 'F7':
        setEventStatus('Completed');
        break;
      case 'F8':
        closeEvent();
        break;
      case 'Ctrl+F8':
        setEventStatus('Pending');
        break;
      case 'Ctrl+Alt+KeyC':
        copyEvent();
        break;
      case 'Ctrl+Alt+KeyM':
        setTab('Notes');
        break;
      case 'Ctrl+Alt+KeyR':
        setTab('Routing');
        break;
    }
  }

  return ''
}

const mapStateToProps = (state) => ({
  keyboard: state.keyboard,
})

export default connect(mapStateToProps, { eventStatusChange })(EventHotKeys);