import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import UnitPanel1 from './UnitPanel1';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 20,
    padding: '20px 20px 0',
    position: 'relative',
    '& table': {
      width: '100%',
    },
    '& hr': {
      margin: '10px -20px',
    },
  },
  cardIndicator: {
    position: 'absolute',
    width: '60%',
    left: '20%',
    height: 6,
    borderBottomLeftRadius: '.75rem',
    borderBottomRightRadius: '.75rem',
    top: 0,
  },
  block1: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: -5,
    color: '#263055',
    overflow: 'hidden',
    marginRight: 5,
    width: '100%',
    '& h5': {
      marginBottom: 2,
      whiteSpace: 'nowrap',
      direction: 'rtl',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    '& p': {
      marginBottom: 0,
      lineHeight: 1.3,
      fontSize: 16,
      opacity: 0.85,
    },
  },
  block1Details: {
    flex: '1 1 100%',
  },
  avatar: {
    flex: '0 0 44px',
    height: 66,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dcdfed',
      borderRadius: '50%',
      width: 44,
      height: 44,
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    '& span': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      color: '#fff',
      fontSize: 12,
    },
  },
  evFullContent: {
    padding: '10px 5px 2px',
    background: '#fafafa',
    '& img': {
      height: 48,
      width: 'auto',
      padding: 1,
      background: '#fff',
      marginRight: 10,
    },
    '& table': {
      background: '#fff',
      marginBottom: 10,
    },
  },
  units: {
    minHeight: 70,
    display: 'flex',
    justifyContent: 'spaces-between',
    margin: '5px -20px 0',
    background: '#c7cbe5',
    padding: 5,
    '& > div': {
      width: '50%',
      margin: 5,
    },
  },
  extraInfo: {
    color: '#263055',
    fontSize: 13,
    '& > div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      '& > div': {
        fontWeight: 500,
        opacity: 0.6,
      },
    },
    '& label': {
      fontWeight: 600,
      marginBottom: '0.5em',
    },
  },
}));

function EventDetail(props) {
  const classes = useStyles();
  const { statusColors } = props.config;
  const { activeEvents } = props;

  return (
    <>
      {activeEvents.map((ev) => {
        const {
          EventId,
          Status,
          Created,
          CreatedBy,
          EventType,
          RespondingLocation,
          RoutingRecommendation,
          Updated,
          UpdatedBy,
          units,
        } = ev;
        const bgColor = statusColors[Status].bgColor;
        return (
          <Card className={classes.card} key={ev.EventId}>
            <div className={classes.cardIndicator} style={{ background: bgColor }} />
            <div className={classes.block1}>
              {/* <div className={classes.avatar} title={`Event Type: ${EventType}`}>
                <div style={{ backgroundImage: `url(${eventTypes[EventType].icon})` }}></div>
              </div> */}
              <div className={classes.block1Details}>
                <h5>
                  <span title="Event Type" style={{ color: '#f66f27' }}>
                    {EventType}
                  </span>
                  <span title="Event Id">{EventId}</span>
                </h5>
                <p title="Responding Location">{RespondingLocation}</p>
              </div>
            </div>
            <div className={classes.status}>
              <span style={{ background: bgColor }}>{Status}</span>
            </div>
            <hr />
            <div className={classes.extraInfo}>
              <div className={classes.userInfo}>
                <label>Created By</label>
                <div>
                  {CreatedBy} | <span>{Created}</span>
                </div>
              </div>
              <div className={classes.userInfo}>
                <label>Updated By</label>
                <div>
                  {UpdatedBy} | <span>{Updated}</span>
                </div>
              </div>
              <div className={classes.routingRec}>
                <label>Routing Recommendation</label>
                <div>
                  {RoutingRecommendation.map((rec, idx) => (
                    <Fragment key={idx}>
                      {idx > 0 && <span>, </span>}
                      <span>{rec}</span>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.units}>
              {units.map((unit, idx) => (
                <UnitPanel1 unit={unit} key={idx} config={props.config} />
              ))}
            </div>
          </Card>
        );
      })}
    </>
  );
}

export default EventDetail;
