import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Subject } from 'rxjs';
import { makeStyles } from '@material-ui/core/styles';
import UnitList from './UnitList';
import Tooltip from 'components/Tooltip';
import Toolbar from 'components/ToolBar';
import IconButton from '@material-ui/core/IconButton';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';
import settings from 'config/settings';
import TextField from '@material-ui/core/TextField';
import { addOutserviceUnitDialog } from 'reducers/DialogsReducer';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { setUnitFilter, saveUnitSort } from 'reducers/UnitSortingReducer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { saveUserSetting } from 'reducers/UserSettingsReducer';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    height: `calc(100vh - ${settings.mainNavHeight + 90}px)`,
    margin: -5,
    position: 'relative',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
  },
  select: {
    marginTop: 5,
    marginRight: 10,
  },
  scrollbar: {
    padding: 5,
    width: '100%',
    overflowY: 'auto',
  },
  filter: {
    width: 250,
    marginTop: 5,
    marginRight: 10,
  },
  addUnit: {
    marginRight: theme.spacing(1),
  },
  switch: {
    marginLeft: theme.spacing(1),
    marginBottom: 0,
  },
  toolbar: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& h3': {
      marginLeft: theme.spacing(1),
    },
  },
  checkboxControl: {
    margin: 0,
  },
  checkbox: {
    padding: ' 0 8px 0 0',
  },
  iconBadge: {
    '& .MuiBadge-badge': {
      marginTop: 5,
    },
  },
  mnuItem: {
    padding: 0,
    '& .MuiFormControlLabel-root': {
      padding: '8px 12px',
    },
  },
  scrollToTop: {
    position: 'absolute',
    bottom: 0,
    right: 15,
    opacity: 0.5,
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1,
    },
  },
}));

function Units(props) {
  const classes = useStyles();
  const [unfold, setUnfold] = useState(false);
  const [unfoldSubject, setUnfoldSubject] = useState(null);
  const [showPinned, setShowPinned] = useState(false);
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [filteredAgencies, setFilteredAgencies] = useState([]);
  const {
    config,
    unitSort,
    setUnitFilter,
    Agencies,
    unitAgencyFilter,
    unitGroupBy,
    unitSortBy,
    unitSortOrder,
  } = props;
  const { filter, folded } = unitSort;
  const { colorPalette } = config;
  const scrollRef = useRef(null);
  const visibleRef = useRef(false);
  const selectAll = useRef(false);
  const deselectAll = useRef(false);
  const firstRun = useRef(true);

  useEffect(() => {
    setUnfoldSubject(new Subject());
    scrollRef.current.addEventListener('scroll', addOnScrollHandler);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!firstRun.current) props.saveUnitSort();
    firstRun.current = false;
    // eslint-disable-next-line
  }, [unitSortOrder, unitGroupBy, unitSortBy, unitAgencyFilter, folded]);

  const addOnScrollHandler = () => {
    if (!visibleRef.current && scrollRef.current && scrollRef.current.scrollTop > 10) {
      visibleRef.current = true;
      setScrollTopVisible(true);
    }
    if (visibleRef.current && scrollRef.current && scrollRef.current.scrollTop < 2) {
      visibleRef.current = false;
      setScrollTopVisible(false);
    }
  };

  const scrollToTop = () => {
    scrollRef.current.scroll({ top: 0, behavior: 'smooth' });
  };

  const toggleFold = () => {
    unfoldSubject && unfoldSubject.next(!unfold);
    setUnfold(!unfold);
  };

  const addUnit = () => {
    props.addOutserviceUnitDialog();
  };

  const toggleShowAgencyFilter = (ev) => {
    if (filterAnchor) {
      setFilterAnchor(null);
    } else {
      setFilterAnchor(ev.currentTarget);
    }
  };

  const toggleAgencyFilter = (AgencyID) => {
    let newAgencyFilter;
    if (AgencyID === 'selectAll') {
      newAgencyFilter = [];
      selectAll.current = !selectAll.current;
      deselectAll.current = false;
    } else if (AgencyID === 'deselectAll') {
      const allAgencies = Agencies.filter((agency) => {
        const canReadAgencies = getAgenciesFullPermission('cad', 'Units', 'Read');
        return canReadAgencies.indexOf(agency.AgencyID) !== -1;
      }).map(({ AgencyID }) => AgencyID);
      newAgencyFilter = [...allAgencies];
      deselectAll.current = !deselectAll.current;
      selectAll.current = false;
    } else {
      const present = unitAgencyFilter.indexOf(AgencyID) !== -1;
      if (present) {
        newAgencyFilter = unitAgencyFilter.filter((id) => id !== AgencyID);
      } else {
        newAgencyFilter = [...unitAgencyFilter];
        newAgencyFilter.push(AgencyID);
      }
      selectAll.current = false;
      deselectAll.current = false;
    }
    props.saveUserSetting('unitAgencyFilter', newAgencyFilter);
  };

  const setUnitGroupBy = (ev) => {
    const val = ev.target.value;
    props.saveUserSetting('unitGroupBy', val);
  };

  const setUnitSortOrder = (ev) => {
    const val = ev.target.value;
    props.saveUserSetting('unitSortOrder', val);
  };

  const setUnitSortBy = (ev) => {
    const val = ev.target.value;
    props.saveUserSetting('unitSortBy', val);
  };

  const renderToolbar = () => {
    return (
      <Toolbar className={classes.toolbar}>
        <div>
          <IconButton onClick={toggleFold}>
            {unfold && (
              <Tooltip title="Collapse all">
                <UnfoldLessIcon />
              </Tooltip>
            )}
            {!unfold && (
              <Tooltip title="Expand all">
                <UnfoldMoreIcon />
              </Tooltip>
            )}
          </IconButton>
          <div className={classes.actions}>
            <Tooltip title="Add Unit">
              <Fab color="secondary" className={classes.addUnit} onClick={addUnit} size="small">
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
          <FormControl variant="outlined" size="small" className={classes.select}>
            <InputLabel htmlFor="unit-sort-options" className={classes.selectLabel}>
              Sort By
            </InputLabel>
            <Select
              native
              value={unitSortBy}
              onChange={setUnitSortBy}
              label="Sort By"
              inputProps={{
                id: 'unit-sort-options',
              }}>
              <option value={'Unit'}>Unit Name</option>
              <option value={'UnitStatus'}>Status</option>
              <option value={'Division'}>Division</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" className={classes.select}>
            <InputLabel htmlFor="unit-sort-options" className={classes.selectLabel}>
              Order
            </InputLabel>
            <Select
              native
              value={unitSortOrder}
              onChange={setUnitSortOrder}
              label="Order"
              inputProps={{
                id: 'unit-sort-options',
              }}>
              <option value={'ASC'}>ASC</option>
              <option value={'DESC'}>DESC</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" className={classes.select}>
            <InputLabel htmlFor="unit-group-by" className={classes.selectLabel}>
              Group By
            </InputLabel>
            <Select
              native
              value={unitGroupBy}
              onChange={setUnitGroupBy}
              label="Group By"
              inputProps={{ id: 'unit-group-by' }}>
              <option value="none">None</option>
              <option value="agency">Agency</option>
              <option value="status">Status</option>
              <option value="zone">Zone</option>
              <option value="Division">Division</option>
            </Select>
          </FormControl>
          <TextField
            label="Filter"
            type="search"
            className={classes.filter}
            variant="outlined"
            size="small"
            onChange={(ev) => setUnitFilter(ev.target.value)}
            value={filter}
          />
          <Tooltip title="Show pinned only">
            <FormControlLabel
              control={
                <Switch
                  checked={showPinned}
                  onChange={(ev, val) => setShowPinned(val)}
                  name="showPinned"
                />
              }
              className={classes.switch}
            />
          </Tooltip>
          <Tooltip title="Filter units by agencies">
            <span onClick={toggleShowAgencyFilter}>
              {unitAgencyFilter.length ? (
                <Badge
                  badgeContent={unitAgencyFilter.length}
                  color="secondary"
                  className={classes.iconBadge}>
                  <IconButton size="small">
                    <FilterListIcon />
                  </IconButton>
                </Badge>
              ) : (
                <IconButton size="small">
                  <FilterListIcon />
                </IconButton>
              )}
            </span>
          </Tooltip>

          <Menu
            anchorEl={filterAnchor}
            keepMounted
            open={Boolean(filterAnchor)}
            onClose={toggleShowAgencyFilter}>
            <MenuItem value="filter" size="small" onKeyDown={(e) => e.stopPropagation()}>
              <QuickFilterSearch
                rows={Agencies}
                columns={['AgencyID']}
                setFilteredRows={setFilteredAgencies}
              />
            </MenuItem>
            <MenuItem value="Select All" size="small" className={classes.mnuItem}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll.current}
                    onChange={() => toggleAgencyFilter('selectAll')}
                    className={classes.checkbox}
                  />
                }
                label="Select All"
                className={classes.checkboxControl}
              />
            </MenuItem>
            <MenuItem value="Deselect All" size="small" className={classes.mnuItem}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deselectAll.current}
                    onChange={() => toggleAgencyFilter('deselectAll')}
                    className={classes.checkbox}
                  />
                }
                label="Deselect All"
                className={classes.checkboxControl}
              />
            </MenuItem>
            <hr style={{ margin: 0 }} />

            {filteredAgencies
              .filter((agency) => {
                const canReadAgencies = getAgenciesFullPermission('cad', 'Units', 'Read');
                return canReadAgencies.indexOf(agency.AgencyID) !== -1;
              })
              .map(({ AgencyID }) => {
                const checked = unitAgencyFilter.indexOf(AgencyID) === -1;
                const checkbox = (
                  <Checkbox
                    checked={checked}
                    onChange={() => toggleAgencyFilter(AgencyID)}
                    className={classes.checkbox}
                    //disabled={selectAll.current || deselectAll.current}
                  />
                );
                return (
                  <MenuItem
                    key={AgencyID}
                    value={AgencyID}
                    size="small"
                    className={classes.mnuItem}>
                    <FormControlLabel
                      control={checkbox}
                      label={AgencyID}
                      className={classes.checkboxControl}
                    />
                  </MenuItem>
                );
              })}
          </Menu>
        </div>
      </Toolbar>
    );
  };

  return (
    <Fragment>
      {renderToolbar()}
      <div className={classes.wrap}>
        <div className={classes.scrollbar} ref={scrollRef}>
          <UnitList
            colorPalette={colorPalette}
            data={props.units}
            showPinned={showPinned}
            unfoldSubject={unfoldSubject}
          />
        </div>
        {scrollTopVisible && (
          <Tooltip title="Scroll to top" className={classes.scrollToTop}>
            <Fab onClick={scrollToTop} size="small" color="secondary">
              <KeyboardArrowUpIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    units: state.units,
    unitSort: state.unitSort,
    Agencies: state.dictionary.Agencies,
    unitAgencyFilter: state.userSettings.unitAgencyFilter,
    unitGroupBy: state.userSettings.unitGroupBy,
    unitSortBy: state.userSettings.unitSortBy,
    unitSortOrder: state.userSettings.unitSortOrder,
    unitShowPinned: state.userSettings.unitShowPinned,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  addOutserviceUnitDialog,
  setUnitFilter,
  saveUserSetting,
  saveUnitSort,
})(Units);
