import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { setUnitStatus, unitInitEvent, safetyCheck } from 'reducers/UnitStatusReducer';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
//import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FaceIcon from '@material-ui/icons/Face';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { newUnitStatus, editUnitStatus, setUnitZone, addNewNote } from 'reducers/DialogsReducer';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { showEditUnit } from 'reducers/DialogsReducer';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DispatchMenu from './DispatchMenu';
import SubUnitStatusMenu from './SubUnitStatusMenu';
import globals from 'components/globals';
import settings from 'config/settings';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
const useStyles = makeStyles(() => ({
  menu: {
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  menuIcon: {
    margin: '0 10px 0 -10px',
  },
  menuRight: {
    position: 'relative',
    '& > div': {
      width: 10,
      height: 10,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
}));

function getDefaultEvent(events, unit) {
  if (!unit) return null;
  const { ptsUnitID } = unit;
  let output = '';
  events.forEach((event) => {
    const result = event.assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (result) output = event.ptsEventID;
  });
  return output;
}

function UnitStatusMenu(props) {
  const classes = useStyles();
  const { events, unit, open, anchorEl, options, ptsEventID, noOfCalls } = props;
  const { ptsUnitID, ptsLastActionID, UnitStatus } = unit;
  const { UnitActionCodes } = options;
  const [dispatchMenuOpen, setDispatchMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [curStatus, setCurStatus] = useState('');
  const [eventsPerUnit, setEventsPerUnit] = useState([]);
  const dispatchRef = useRef();
  const subMenuRef = useRef();
  const preventRenderingRef = useRef(false);
  const menuTimeout = useRef(0);
  const perms = getFullPermissions('cad', 'Unit Status', 'any');
  const permsEvent = getFullPermissions('cad', 'Events', 'any');
  const { Edit } = perms;
  useEffect(() => {
    preventRenderingOn();
    delayedClose();
    return () => {
      preventRenderingOff();
      clearTimeout(menuTimeout.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    /* mapping number of events assigned for a unit */
    let list = [];
    for (const ev of events) {
      const { assignedUnits } = ev;
      if (assignedUnits.some((curUnit) => curUnit.ptsUnitID === ptsUnitID))
        list.push(ev.ptsEventID);
      if (list.length > 1) break;
    }
    setEventsPerUnit(list);
  }, [unit, events, ptsUnitID]);

  const closeMenu = () => {
    props.onClose();
  };

  const delayedClose = () => {
    clearTimeout(menuTimeout.current);
    menuTimeout.current = setTimeout(() => {
      closeMenu();
    }, settings.showMenuTime);
  };

  const preventRenderingOn = () => {
    preventRenderingRef.current = true;
    globals.menuOpen = true;
  };

  const preventRenderingOff = () => {
    if (!preventRenderingRef.current) return;
    preventRenderingRef.current = false;
    globals.menuOpen = false;
  };

  const setUnitStatus = (status) => {
    const eventID = ptsEventID ? ptsEventID : getDefaultEvent(events, unit);
    props.setUnitStatus(status, ptsUnitID, eventID);
    closeMenu();
  };

  const newStatus = () => {
    props.newUnitStatus(ptsUnitID, ptsEventID);
    closeMenu();
  };

  const addNewNote = () => {
    props.addNewNote(ptsUnitID, ptsEventID);
    closeMenu();
  };

  const newEvent = () => {
    props.unitInitEvent(ptsUnitID);
    closeMenu();
  };

  const editStatus = () => {
    closeMenu();
    if (window.confirm('Are you sure you want to edit status record?')) {
      const ptsActionID = ptsLastActionID;
      props.editUnitStatus(ptsUnitID, ptsEventID, ptsActionID);
    }
  };

  const editUnit = () => {
    props.showEditUnit(ptsUnitID);
    closeMenu();
  };

  const setZone = () => {
    props.setUnitZone(ptsUnitID);
    closeMenu();
  };

  const onSafetyCheckClick = () => {
    safetyCheck(ptsUnitID, unit.Unit);
    closeMenu();
  };

  const handleEventStatusClick = (status) => {
    /* checking if unit assigned to a single event or more */

    if (eventsPerUnit.length === 1) {
      props.setUnitStatus(status, ptsUnitID, eventsPerUnit[0]);
      closeMenu();
    } else {
      setCurStatus(status);
      setSubMenuOpen(true);
    }
  };

  const closeDispatchMenu = () => {
    setDispatchMenuOpen(false);
    closeMenu();
  };

  const IsDisabled = noOfCalls === 0;
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        onMouseMove={delayedClose}>
        <MenuItem onClick={() => setUnitStatus(UnitActionCodes.Inservice.status)} disabled={!Edit}>
          <FaceIcon className={classes.menuIcon} /> {UnitActionCodes.Inservice.status}
        </MenuItem>
        <MenuItem onClick={newStatus} disabled={!Edit}>
          <AssignmentIcon className={classes.menuIcon} /> Change Status
        </MenuItem>
        <MenuItem onClick={addNewNote} disabled={UnitStatus === UnitActionCodes.Inservice.status}>
          <NoteAddIcon className={classes.menuIcon} /> View/Add Note
        </MenuItem>
        <MenuItem onClick={onSafetyCheckClick}>
          <AccessTimeIcon className={classes.menuIcon} /> Safety Check
        </MenuItem>
        <MenuItem component={NavLink} to={`/search/unit-status?u=${unit.Unit}`}>
          <AssessmentIcon className={classes.menuIcon} /> Status History
        </MenuItem>
        <MenuItem onClick={newEvent} disabled={!permsEvent.Create}>
          <EventNoteIcon className={classes.menuIcon} /> Unit Initiated Event
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setDispatchMenuOpen(true)} disabled={!Edit}>
          <HeadsetMicIcon className={classes.menuIcon} /> {UnitActionCodes.Dispatch.status}
          <span style={{ marginLeft: 'auto' }}>
            <ArrowRightIcon ref={dispatchRef} />
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleEventStatusClick(UnitActionCodes.Enroute.status)}
          disabled={IsDisabled || !Edit}>
          <DriveEtaIcon className={classes.menuIcon} /> {UnitActionCodes.Enroute.status}
          {eventsPerUnit.length !== 1 && (
            <span style={{ marginLeft: 'auto' }}>
              <ArrowRightIcon ref={subMenuRef} />
            </span>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => handleEventStatusClick(UnitActionCodes.Arrived.status)}
          disabled={IsDisabled || !Edit}>
          <EmojiTransportationIcon className={classes.menuIcon} /> {UnitActionCodes.Arrived.status}
          {eventsPerUnit.length !== 1 && (
            <span style={{ marginLeft: 'auto' }}>
              <ArrowRightIcon ref={subMenuRef} />
            </span>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => handleEventStatusClick(UnitActionCodes.Completed.status)}
          disabled={IsDisabled || !Edit}>
          <AssignmentTurnedInIcon className={classes.menuIcon} />
          {UnitActionCodes.Completed.status}
          {eventsPerUnit.length !== 1 && (
            <span style={{ marginLeft: 'auto' }}>
              <ArrowRightIcon ref={subMenuRef} />
            </span>
          )}
        </MenuItem>
        <Divider />
        <MenuItem onClick={editStatus} disabled={!Edit}>
          <EditIcon className={classes.menuIcon} /> Edit Status
        </MenuItem>
        <MenuItem onClick={setZone} disabled={!Edit}>
          <LocationOnIcon className={classes.menuIcon} /> Edit Zone
        </MenuItem>
        <MenuItem onClick={editUnit} disabled={!Edit}>
          <EditIcon className={classes.menuIcon} /> Edit Unit
        </MenuItem>
        <MenuItem onClick={() => setUnitStatus(UnitActionCodes.Outservice.status)} disabled={!Edit}>
          <NotificationsOffIcon className={classes.menuIcon} /> {UnitActionCodes.Outservice.status}
        </MenuItem>
        <MenuItem onClick={() => setUnitStatus(UnitActionCodes.Standby.status)} disabled={!Edit}>
          <FaceIcon className={classes.menuIcon} /> {UnitActionCodes.Standby.status}
        </MenuItem>
      </Menu>
      <DispatchMenu
        anchorEl={dispatchRef.current}
        open={dispatchMenuOpen}
        onClose={closeDispatchMenu}
        units={[unit]}
        onMouseMove={delayedClose}
      />
      <SubUnitStatusMenu
        anchorEl={subMenuRef.current}
        open={subMenuOpen}
        onClose={() => setSubMenuOpen(false)}
        unit={unit}
        status={curStatus}
        onMouseMove={delayedClose}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    options: state.config.options,
  };
};

export default connect(mapStateToProps, {
  setUnitStatus,
  newUnitStatus,
  editUnitStatus,
  setUnitZone,
  unitInitEvent,
  showEditUnit,
  addNewNote,
})(UnitStatusMenu);
