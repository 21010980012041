import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toggleExpandUnitGroup } from 'reducers/UnitSortingReducer';

const useStyles = makeStyles((theme) => ({
  card: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48,
    },
    '&.Mui-expanded': {
      margin: 0,
      borderBottom: `1px solid ${theme.tooltip.tableBorder}`,
      borderTop: `1px solid ${theme.tooltip.tableBorder}`,
    },
  },
  summary: {
    flexDirection: 'row-reverse',
    paddingLeft: 0,
    justifyContent: 'flex-end',
  },
  avatar: {
    flex: '0 0 44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    '& > div': {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: theme.card.bg2,
      borderRadius: '50%',
      width: 35,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: theme.palette.accent1,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  status: {
    minWidth: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
    },
  },
  location: {
    width: 160,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    fontSize: 13,
    paddingRight: 5,
  },
  infoIco: {
    opacity: 0.5,
    transition: 'opacity 0.3s',
    margin: '0 10px',
    '&:hover': {
      opacity: 1,
    },
  },
  updateTime: {
    minWidth: 66,
    padding: '0 5px',
  },
  eventId: {
    width: 50,
    textAlign: 'center',
  },
  sops: {
    minWidth: 70,
    border: `1px solid ${theme.tooltip.tableBorder}`,
    boxSizing: 'border-box',
    padding: '0 10px',
    borderRadius: 5,
    textAlign: 'center',
    margin: '0 10px',
    '& > div:first-child': {
      fontWeight: 500,
      fontSize: 12,
    },
  },
  reccomendations: {
    fontSize: 12,
    fontWeight: 500,
  },
  units: {
    width: '100%',
    overflow: 'hidden',
  },
  accDetailsRoot: {
    display: 'block',
    padding: 0,
    overflow: 'hidden',
    borderTop: `1px solid ${theme.card.hr}`,
  },
}));

function UnitsGroup(props) {
  const classes = useStyles();
  const { unfoldSubject, header, unitList, folded, groupKey } = props;
  const [unfoldSubscription, setUnfoldSubscription] = useState(null);
  const expanded = folded.indexOf(groupKey) === -1;

  useEffect(() => {
    if (unfoldSubject) {
      const subscription = unfoldSubject.subscribe((unfold) => {
        setExpanded(unfold);
      });
      setUnfoldSubscription(subscription);
    }
    return () => {
      unfoldSubscription && unfoldSubscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const setExpanded = (val) => {
    props.toggleExpandUnitGroup(val, groupKey);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={classes.card}
      style={expanded ? { marginBottom: 5 } : {}}>
      <div {...props.dragHandleProps}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon color="primary" />}>
          {header()}
        </AccordionSummary>
      </div>
      {expanded && (
        <AccordionDetails classes={{ root: classes.accDetailsRoot }}>{unitList()}</AccordionDetails>
      )}
    </Accordion>
  );
}

const mapStateToProps = (state) => {
  return {
    folded: state.unitSort.folded,
  };
};

export default connect(mapStateToProps, {
  toggleExpandUnitGroup,
})(UnitsGroup);
