import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '../../components/Tooltip';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 15,
    padding: '0 15px 15px 15px',
  },
  header: {
    marginLeft: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 54,
  },
  chip: {
    color: theme.card.bg,
    fontSize: '0.8em',
    padding: '0.125em 0.5em',
    borderRadius: theme.shape.borderRadius,
    marginLeft: '1em',
  },
  primary: { backgroundColor: theme.palette.primary.main },
  secondary: { backgroundColor: theme.palette.secondary.main },
  info: { backgroundColor: theme.palette.info.main },
  success: { backgroundColor: theme.palette.success.main },
  error: { backgroundColor: theme.palette.error.main },
  warning: { backgroundColor: theme.palette.warning.main },
}));

export default function ContainerCard(props) {
  const classes = useStyles();
  const { actions, info } = props;

  return (
    <Card className={clsx(classes.wrapper, props.className)} variant="outlined">
      <div className={clsx(classes.header, 'font-size-lg')}>
        <div>
          {props.title}
          {!!info && (
            <Tooltip title={info.text}>
              <span className={clsx(classes[info.type], classes.chip)}>SOP</span>
            </Tooltip>
          )}
        </div>
        {actions !== undefined && <div>{actions}</div>}
      </div>
      <div className={classes.content}>{props.children}</div>
    </Card>
  );
}
