import { getService } from './service';
import { notify } from './NotifierReducer';
import { handleError } from './ErrorReducer';
import { notifyDataUpdate } from './DataUpdateReducer';

export const saveSop = (data) => {
  const sopData = { ...data };
  const DispatcherQuestions = parseDispatcherQuestions(data);
  sopData.DispatcherQuestions = `<DispatcherQuestions>
${DispatcherQuestions}
</DispatcherQuestions>`;
  const service = getService();
  if (data.ptsSOPID !== true) {
    return service.patch(data.ptsSOPID, { type: 'sop', data: sopData });
  } else {
    return service.create({ type: 'add-sop', data: sopData });
  }
};

export const updateSOPs = (rawSops) => {
  const data = encodeSOPs(rawSops);
  return async (dispatch) => {
    try {
      const service = getService();
      await service.patch(0, { type: 'update-event-sops', data });
      //dispatch(notify('SOPs updated', 'success'));
      //dispatch(notifyDataUpdate('sopsUpdated'));
    } catch (error) {
      dispatch(handleError(error, 'Error, sops not saved'));
    }
  };
};

export const approveSOP = (ptsSOPID) => {
  const service = getService();
  return service.patch(ptsSOPID, { type: 'approve-sop' });
};

export const getSops = () => {
  const service = getService();
  return service.get({
    type: 'sops',
  });
};

export const getSopAutoPages = (ptsSOPID) => {
  const service = getService();
  return service.get({
    type: 'sop-autopages',
    data: ptsSOPID,
  });
};

export const saveSopAutoPage = (data) => {
  const service = getService();
  if (data.ptsSOPAutopageID) {
    return service.patch(data.ptsSOPAutopageID, { type: 'update-sop-autopage', data });
  } else {
    return service.create({ type: 'add-sop-autopage', data });
  }
};

export const removeSOPAutoPage = (data) => {
  const { ptsSOPAutopageID } = data;
  const service = getService();
  return service.patch({ ptsSOPAutopageID }, { type: 'remove-sop-autopage' });
};

export const getSop = (ptsSOPID) => {
  const service = getService();
  return service.get({
    type: 'sop',
    data: ptsSOPID,
  });
};

export const removeSop = (ptsSOPID) => {
  const service = getService();
  return service.patch(ptsSOPID, { type: 'remove-sop', data: ptsSOPID });
};

export function processSOPs(newEvent) {
  if (!newEvent?.sops) return [];
  return newEvent.sops.map((sop) => parseXmlSOP(sop));
}

export function parseXmlSOP(sop) {
  const { Actions, ptsSOPID, SOPID } = sop;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(Actions, 'text/xml');
  const getContent = (name) => {
    const res = xmlDoc.getElementsByTagName(name);
    if (res && res.length) return res[0].textContent;
    return null;
  };
  const getCheckboxVal = (val) => (val === 'True' ? true : false);
  const DispatcherMessage = getContent('Message');
  const ParentType = getContent('ParentType');
  const ParentName = getContent('ParentName');
  const Notified = {};
  const NotifiedObj = xmlDoc.getElementsByTagName('Notified')[0];
  if (NotifiedObj) {
    Notified.Name = NotifiedObj.textContent;
    Notified.Time = NotifiedObj.getAttribute('Time');
  }
  const DispatcherQuestions = [];
  const dispQ = xmlDoc.getElementsByTagName('DispatcherQuestions');
  const DispatcherQuestionsNodes = dispQ && dispQ.length ? dispQ[0].childNodes : [];
  for (let i = 0; i < DispatcherQuestionsNodes.length; i++) {
    const node = DispatcherQuestionsNodes[i];
    if (!node.tagName) continue;
    // node.tagName === 'CheckBox' && console.log(node.tagName, node.textContent);

    DispatcherQuestions.push({
      type: node.tagName,
      name: node.getAttribute('Name'),
      text: node.getAttribute('Text'),
      value: node.tagName === 'CheckBox' ? getCheckboxVal(node.textContent) : node.textContent,
    });
  }
  return {
    DispatcherMessage,
    ParentType,
    ParentName,
    Notified,
    DispatcherQuestions,
    ptsSOPID,
    SOPID,
  };
}

export const decodeDispatcherQuestions = (xml) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const getCheckboxVal = (val) => (val === 'True' ? true : false);
  const DispatcherQuestions = [];
  const DispatcherQuestionsNodes = xmlDoc.getElementsByTagName('DispatcherQuestions')[0].childNodes;
  for (let i = 0; i < DispatcherQuestionsNodes.length; i++) {
    const node = DispatcherQuestionsNodes[i];
    if (!node.tagName) continue;
    DispatcherQuestions.push({
      type: node.tagName,
      name: node.getAttribute('Name'),
      text: node.getAttribute('Text'),
      value: node.tagName === 'CheckBox' ? getCheckboxVal(node.textContent) : node.textContent,
    });
  }
  return DispatcherQuestions;
};

/* Encode during updating answers */
export function encodeSOPs(rawSops) {
  return rawSops.map((sop) => {
    const { ptsEventID, ptsSOPID } = sop;
    const Actions = encodeSOP(sop);
    return { Actions, ptsEventID, ptsSOPID };
  });
}

/* Encode new SOP */
export function encodeSOP(sop) {
  const { DispatcherMessage, Notified, ParentName, ParentType } = sop;
  const notified =
    Notified && Notified.Name
      ? `<Notified Time="${Notified.Time}">${Notified.Name}</Notified>\n`
      : '';
  const DispatcherQuestions = parseDispatcherQuestions(sop);
  const Actions = `<Actions>
  <DispatcherMessage>
    <Message>${DispatcherMessage ? DispatcherMessage : ''}</Message>
  </DispatcherMessage>
  <DispatcherQuestions>
${DispatcherQuestions}
  </DispatcherQuestions>
  <ParentType>${ParentName ? ParentName : ''}</ParentType>
  <ParentName>${ParentType ? ParentType : ''}</ParentName>
  ${notified}
</Actions>`;
  return Actions;
}
const escapeEOL = (str) => str.replace(/(?:\r\n|\r|\n)/g, '&#13;');

const parseDispatcherQuestions = (sop) => {
  const getTextStr = (q) => `    <Text Name="${q.name}" Text="${escapeEOL(q.text)}"/>\n`;
  const getCheckboxStr = (q) =>
    `    <CheckBox Name="${q.name}" Text="${q.text}">${q.value ? 'True' : ''}</CheckBox>\n`;
  const getQuestionStr = (q) =>
    `    <Question Name="${q.name}" Text="${q.text}">${q.value}</Question>\n`;
  const DispatcherQuestions = sop.DispatcherQuestions.map((item) => {
    switch (item.type) {
      case 'Text':
        return getTextStr(item);
      case 'Question':
        return getQuestionStr(item);
      case 'CheckBox':
        return getCheckboxStr(item);
      default:
        return '';
    }
  }).reduce((res, val) => res + val, '');
  return DispatcherQuestions;
};
