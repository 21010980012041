import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from 'components/Tooltip';
import { asyncForEach, sortObjArr } from 'utils/functions';
import { compareDate, displayDateTime, getCurrentDate, formatSaveDate } from 'reducers/TimeReducer';
import { notify } from 'reducers/NotifierReducer';
import { saveEventNote, removeEventNote } from 'reducers/EventsReducer';
import TextField from '@material-ui/core/TextField';
import settings from 'config/settings';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getAccessPermission, getFullPermissions } from 'reducers/PermissionsReducer';
import clsx from 'clsx';
import { fireRMSexport } from 'reducers/ReportsReducer';

const useStyles = makeStyles((theme) => ({
  notes: {
    textAlign: 'left',
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  textField: {
    fontSize: '0.9em',
  },
  note: {
    padding: '10px 15px 10px',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
      paddingRight: 50,
      whiteSpace: 'pre-line',
    },
    borderBottom: `1px solid ${theme.card.hr}`,
    '&:hover': {
      '& $rowActionsSingle': {
        opacity: 1,
      },
      '& $rowActionsMultiple': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  rowActionsSingle: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  rowActionsMultiple: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(6) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  editNote: {
    padding: theme.spacing(1, 2),
  },
}));

function Note(props) {
  const classes = useStyles();
  const { canEdit, canDelete, note } = props;
  const { CreatedBy, Comment, Created, ptsCommentID, Updated, UpdatedBy } = note;

  const editNote = () => {
    props.editNote(ptsCommentID, Comment);
  };

  const delNote = () => {
    props.delNote(ptsCommentID);
  };

  return (
    <div className={classes.note}>
      <div className={classes.header}>
        <h4>Created By: {CreatedBy}</h4>
        <span>{displayDateTime(Created)}</span>
      </div>
      {!compareDate(Created, Updated) && (
        <div className={classes.header}>
          <h4>Updated By: {UpdatedBy}</h4>
          <span>{displayDateTime(Updated)}</span>
        </div>
      )}
      <p>{Comment}</p>
      <div
        className={clsx({
          [classes.rowActionsMultiple]: !compareDate(Created, Updated),
          [classes.rowActionsSingle]: compareDate(Created, Updated),
        })}>
        {canEdit && (
          <Tooltip title="Edit note">
            <IconButton color="primary" size="small" onClick={editNote}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {canDelete && (
          <Tooltip title="Delete note">
            <IconButton color="primary" size="small" onClick={delNote}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

function NotesTab(props) {
  const { sortOption, ptsEventID, setFocus, disableEdit, eventRouting } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [notes, setNotes] = useState([]);
  const [noteText, setNoteText] = useState(null);
  const [editedNoteID, setEditedNoteID] = useState(null);
  const [inFocus, setInFocus] = useState(false);
  const inputRef = useRef();
  const timeoutRef = useRef(0);
  const mountedRef = useRef(true);
  const canDelete = getAccessPermission('cad', 'Delete Event Notes', 'any') && !disableEdit;
  const canEdit = getAccessPermission('cad', 'Edit Event Notes', 'any') && !disableEdit;
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const Agencies = useSelector((state) => state.dictionary.Agencies);
  useEffect(() => {
    inputRef.current && setFocus && inputRef.current.focus();
    if (!disableEdit) setNoteText('');
    return () => {
      mountedRef.current = false;
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!notes) return;
    setNotes(sortObjArr(props.notes, 'Created', sortOption));
    // eslint-disable-next-line
  }, [props.notes, sortOption]);

  const onAddNoteClick = () => {
    focusOnNote();
    setNoteText('');
  };

  const focusOnNote = () => {
    if (!mountedRef.current) return;
    clearTimeout(timeoutRef.current);
    if (!inputRef.current) {
      timeoutRef.current = setTimeout(() => {
        focusOnNote();
      }, 100);
      return;
    }
    setFocus && inputRef.current.focus();
  };

  const closeEdit = () => {
    setNoteText(null);
    setEditedNoteID(null);
  };

  const saveNote = () => {
    if (!noteText) return;
    setNoteText('');
    setEditedNoteID(null);
    saveEventNote(
      {
        Comment: noteText,
        CreatedAt: formatSaveDate(getCurrentDate()),
        ptsCommentID: editedNoteID,
      },
      ptsEventID
    )
      .then(async () => {
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
        await asyncForEach(eventRouting, async (routedAgency) => {
          const realTimeEnabled = Agencies.find(
            (agency) =>
              agency.AgencyID === routedAgency.AgencyId && agency?.FireHouseExportOnRealTime
          );
          if (realTimeEnabled) dispatch(fireRMSexport(ptsEventID, routedAgency.AgencyId, true));
        });
      })
      .catch((error) => props.handleError(error, 'Error, note not created'));
  };

  const delNote = (ptsCommentID) => {
    if (!window.confirm('Are you sure you want to delete the note?')) return;
    removeEventNote(ptsCommentID)
      .then(() => {
        //props.notify('Note deleted', 'info');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((error) => props.handleError(error, 'Error, note not removed'));
  };

  const editNote = (ptsCommentID, text) => {
    setEditedNoteID(ptsCommentID);
    setNoteText(text);
  };

  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13 && !ev.shiftKey) {
      ev.preventDefault();
      saveNote();
    }
  };

  return (
    <div className={classes.notes}>
      <div className={classes.actions}>
        {!disableEdit && noteText === null && (
          <Tooltip title="Add note">
            <IconButton
              color="primary"
              size="small"
              component="span"
              onClick={onAddNoteClick}
              disabled={!permissions.Edit}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        {noteText !== null && !!noteText && (
          <Tooltip title="Save note">
            <IconButton
              color="primary"
              size="small"
              onClick={saveNote}
              disabled={!permissions.Edit}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        )}
        {noteText !== null && !noteText && (
          <IconButton color="primary" size="small" disabled>
            <SaveIcon />
          </IconButton>
        )}
        {noteText !== null && (
          <Tooltip title="Discard changes">
            <IconButton color="primary" size="small" onClick={closeEdit}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>

      {noteText !== null && (
        <div className={classes.editNote}>
          <TextField
            label="Note"
            multiline
            rows="1"
            fullWidth
            value={noteText}
            onChange={(ev) => setNoteText(ev.target.value)}
            variant="outlined"
            size="small"
            inputProps={{
              className: classes.textField,
              style: inFocus ? { height: 60 } : undefined,
              onFocus: () => setInFocus(true),
              onBlur: () => setInFocus(false),
              ref: inputRef,
            }}
            onKeyDown={handleKeyDown}
            disabled={!permissions.Edit}
          />
        </div>
      )}

      {notes.map((note, idx) => (
        <Note
          key={idx}
          note={note}
          delNote={delNote}
          editNote={editNote}
          canEdit={canEdit}
          canDelete={canDelete}
          disableEdit={disableEdit}
        />
      ))}
    </div>
  );
}

export default connect(null, {
  notify,
  handleError,
  notifyDataUpdate,
})(NotesTab);
