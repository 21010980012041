import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import { displayDateTime } from 'reducers/TimeReducer';
import settings from 'config/settings';
import { getEventUnitHistory } from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from 'components/Tooltip';

const statusWidth = 100;

const useStyles = makeStyles((theme) => ({
  histories: {
    minHeight: 41,
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  history: {
    padding: '5px 15px 5px',
    '& p': {
      fontSize: 13,
    },
    borderBottom: `1px solid ${theme.card.hr}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  date: {
    maxWidth: 150,
    display: 'inline-block',
  },
  statusTd: {
    flex: `0 0 ${statusWidth}px`,
    width: statusWidth,
    boxSizing: 'border-box',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    textAlign: 'center',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      background: theme.card.bg1,
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
    },
  },
  agency: {
    padding: '0 4px',
  },
  actions: {
    textAlign: 'right',
  },
  progress: {
    width: '24px !important',
    height: '24px !important',
  },
}));

function History(props) {
  const classes = useStyles();
  const { colorPalette } = props;
  const { Unit, Occurred, UnitStatus, AgencyID } = props.history;
  const statusColor = colorPalette.Units[UnitStatus];

  return (
    <div className={classes.history}>
      <div className={classes.header}>
        <div className={classes.left}>
          <h4>{Unit}</h4>
          <div className={classes.statusTd}>
            <div className={classes.status}>
              <i style={{ background: statusColor, color: '#000' }}>{UnitStatus}</i>
            </div>
          </div>
          <div className={classes.agency}>{AgencyID}</div>
        </div>
        <div>
          <span className={classes.date}>{displayDateTime(Occurred)}</span>
        </div>
      </div>
    </div>
  );
}

function HistoryTab(props) {
  const dispatch = useDispatch();
  const { colorPalette, sortOption, ptsEventID } = props;
  const [rawData, setRawData] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    updateEventHistory();
    // eslint-disable-next-line
  }, [ptsEventID]);

  useEffect(() => {
    setHistory(sortObjArr(rawData, 'Created', sortOption));
    // eslint-disable-next-line
  }, [sortOption, rawData]);

  const updateEventHistory = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const rawData = await getEventUnitHistory(ptsEventID);
      if (!mountedRef.current) return;
      setRawData(rawData);
    } catch (err) {
      dispatch(handleError(err));
    }
    setLoading(false);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Reload data">
          <span>
            <Button onClick={updateEventHistory} disabled={loading}>
              {!loading && <RefreshIcon color="primary" />}
              {loading && <CircularProgress className={classes.progress} />}
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  };

  const renderHistory = () => {
    return history.map((historySingle, idx) => (
      <History key={idx} history={historySingle} colorPalette={colorPalette} />
    ));
  };

  if (!history) return '';

  return (
    <div className={classes.histories}>
      {renderActions()}
      {renderHistory()}
    </div>
  );
}

export default HistoryTab;
