import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPersonRecordDetails } from 'reducers/SearchReducer';
import { Grid, Badge, Card, Button, Box, Avatar } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { genColHeaderName } from 'utils/functions';
import { showPersonRelatedRecordsDetail } from 'reducers/DialogsReducer';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4)
  },
  arrow: {
    display: 'flex', 
    alignItems: 'center'
  }
}));

const categories = ['Addresses', 'Alerts', 'Bookings', 'CadIncidents', 'Citations',
  'ContactInfo', 'Employment', 'RmsIncidents', 'Warrants'];

function RelatedRecords(props) {
  const { ptsPersonID } = props;
  const classes = useStyles();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await getPersonRecordDetails(ptsPersonID);
    const dataArr = [];
    Object.keys(data).map(key => {
      if (categories.find(cat => cat === key) && data[key]) dataArr.push([key, JSON.parse(data[key])]);
    })
    setData(dataArr);
  }

  const RenderPersonItem = (record, idx) => {
    const handleCardClick = () => props.showPersonRelatedRecordsDetail(record);
    return (
      <Grid item xs={12} md={4} key={idx}>
            <Card
              onClick={handleCardClick}
            >
              <Grid container>
                <Grid item sm={10}>
                  <div className='ml-2'>
                    <div className="display-3">{record[1].length}</div>
                    <div className="divider mt-2 mb-3 border-2 w-25 rounded" />
                    <div className="font-weight-bold font-size-sm text-uppercase">
                      {genColHeaderName(record[0])}
                    </div>
                  </div>
                </Grid>
                <Grid item sm={2} className={classes.arrow}>
                  <ChevronRightIcon />
                </Grid>
              </Grid>
            </Card>
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {data.map((cur, idx) => {
          return RenderPersonItem(cur, idx);
        })}
        {data.length === 0 && <h6> No Record Available. </h6>}
      </Grid>
    </div>
  )
}

export default connect(null, { showPersonRelatedRecordsDetail })(RelatedRecords);