import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import UnitStatusHistory from './UnitStatusHistory';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
function UnitStatusSearch() {
  const canReadAgencies = getAgenciesFullPermission('cad', 'Unit Status', 'Read');
  if (!canReadAgencies.length) return <Redirect to="/" />;

  return (
    <SearchMenu agencyList={canReadAgencies}>
      <FilterConsumer>
        {(AgencyFilter) => <UnitStatusHistory filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default UnitStatusSearch;
