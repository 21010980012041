import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import TextField2 from 'components/TextField2';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ContainerCard from './ContainerCard';
import { veryfySOP } from 'reducers/EventsReducer';
import Fab from '@material-ui/core/Fab';
import Dictionary from 'components/Dictionary';
import Autocomplete2 from 'components/Autocomplete2';
import { connect, useDispatch, useSelector } from 'react-redux';
import { showEditDictionary } from 'reducers/DialogsReducer';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  textField: {
    margin: '0 4px 8px',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
  w300: {
    maxWidth: '100%',
    flexBasis: 300,
    minWidth: 300,
    flexGrow: 1,
  },
  w100pr: {
    width: '100%',
  },
  actions: {
    padding: theme.spacing(1.5, 0),
    '& button': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));

function EventForm(props) {
  const classes = useStyles();
  const {
    dictionary,
    title,
    idx,
    data,
    updateData,
    setPriority,
    editingForm = false,
    streetName,
  } = props;
  const dispatch = useDispatch();
  const [CallType, setCallType] = useState(null);
  const [CallSubType, setCallSubType] = useState(null);
  const [CallMethod, setCallMethod] = useState(null);
  const [RequestedAction, setRequestedAction] = useState(null);
  const [LocationDescription, setLocationDescription] = useState('');
  const [EventDescription, setEventDescription] = useState('');
  const [validForm, setValidForm] = useState(false);
  const [sopsAvailable, setSopsAvailable] = useState(false);
  const [changed, setChanged] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [subTypesByCallType, setSubTypesByCallType] = useState([]);
  const subTypes = useSelector((state) => state.dictionary.CallSubTypes);
  useEffect(() => {
    if (!data) return;
    formatInputData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!updateData) return;
    updateData(formatOutputData());
    if (validateForm() && !validForm) setValidForm(true);
    if (!validateForm() && validForm) setValidForm(false);
    // eslint-disable-next-line
  }, [
    CallType,
    CallSubType,
    CallMethod,
    RequestedAction,
    LocationDescription,
    lat,
    lng,
    EventDescription,
  ]);

  useEffect(() => {
    if (CallType || props.dataUpdate?.type === 'code-added') {
      const callSubTypes =
        subTypes && subTypes.filter((type) => type.ptsParentID === CallType.IntCode);
      setSubTypesByCallType(callSubTypes || []);
      veryfySOP('CADType', null, CallType.Code).then(setSopsAvailable).catch(console.log);
    } else {
      sopsAvailable && setSopsAvailable(false);
    }
    const priority = CallType ? CallType.Priority : null;
    setPriority && setPriority(priority);
    // eslint-disable-next-line
  }, [CallType, props.dataUpdate]);

  useEffect(() => {
    props.setLocationDescription && props.setLocationDescription(LocationDescription);
  }, [LocationDescription]);

  const formatOutputData = () => {
    return {
      CallType: CallType ? CallType.Code : null,
      CallSubType: CallSubType ? CallSubType.Code : null,
      CallMethod: CallMethod ? CallMethod.Code : null,
      RequestedAction: RequestedAction ? RequestedAction.Code : null,
      LocationDescription: LocationDescription ? LocationDescription : null,
      EventDescription: EventDescription ? EventDescription : null,
      lat: lat ? parseFloat(lat) : null,
      lng: lng ? parseFloat(lng) : null,
      ptsEventID: data && data.ptsEventID,
    };
  };

  const formatInputData = () => {
    const { lat, lng } = data;
    const CallTypeObj = dictionary.CallTypes.find((c) => c.Code === data.CallType);
    !CallType && CallTypeObj && setCallType(CallTypeObj ? CallTypeObj : null);
    !CallSubType &&
      data.CallSubType &&
      setCallSubType(getDataObj('CallSubTypes', data.CallSubType));
    !CallMethod && data.CallMethod && setCallMethod(getDataObj('CallMethods', data.CallMethod));
    !RequestedAction && setRequestedAction(getDataObj('RequestedActions', data.RequestedAction));
    !LocationDescription &&
      setLocationDescription(data.LocationDescription ? data.LocationDescription : '');

    /* if (LocationDescription) */

    setEventDescription(data.EventDescription ? data.EventDescription : '');
    setLat(lat ? String(lat) : '');
    setLng(lng ? String(lng) : '');
  };

  const getDataObj = (type, Code) => {
    const obj = dictionary[type].find((obj) => obj.Code === Code);
    return obj ? obj : null;
  };

  const clear = (ev) => {
    ev.stopPropagation();
    props.clear(idx);
    setChanged(false);
  };

  const save = (ev) => {
    ev.stopPropagation();
    props.save();
    setChanged(false);
  };

  const isLatValid = () => {
    if (lat === '') return true;
    const val = parseFloat(lat);
    return val > -85.05112878 && val < 85.05112878;
  };

  const isLngValid = () => {
    if (lng === '') return true;
    const val = parseFloat(lng);
    return val > -180 && val < 180;
  };

  const validateForm = () => {
    if (!streetName && !LocationDescription) return false;
    if (!CallType || !isLatValid() || !isLngValid()) return false;
    return true;
  };

  const renderLocationDescription = () => {
    const onChange = (ev) => {
      const value = ev.target.value;
      if (value) !changed && setChanged(true);
      if (value === '' || value.length < 2000) {
        setLocationDescription(value);
      }
    };
    return (
      <ContainerCard title="Location">
        <div className={classes.form}>
          <TextField
            className={clsx(classes.textField, classes.w100pr)}
            label="Location Description**"
            variant="outlined"
            value={LocationDescription}
            onChange={onChange}
            size="small"
            error={!LocationDescription && !streetName}
          />
        </div>
      </ContainerCard>
    );
  };

  const renderCallType = () => {
    const onChange = (ev, val) => {
      setCallType(val);
      !changed && setChanged(true);
      setCallSubType(null);
      setSubTypesByCallType([]);
    };
    return (
      <Dictionary
        className={classes.w180}
        options="CallTypes"
        value={CallType}
        onChange={onChange}
        label="Event Type*"
        error={!CallType}
        compact="true"
      />
    );
  };

  const renderActions = () => {
    const closePresent = !!props.clear;
    const savePresent = !!props.save;
    const disabled = !validForm || !changed;
    return (
      <div className={classes.actions}>
        {savePresent && (
          <Fab color="secondary" aria-label="save" onClick={save} disabled={disabled} size="small">
            <SaveIcon />
          </Fab>
        )}
        {closePresent && (
          <Fab color="secondary" aria-label="close" onClick={clear} size="small">
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  const renderCallSubType = () => {
    const onChange = (ev, val) => {
      setCallSubType(val);
      !changed && setChanged(true);
    };
    return (
      <Autocomplete2
        options={subTypesByCallType}
        className={classes.item}
        onChange={onChange}
        value={CallSubType}
        label="Event Subtype"
        compact={true}
        onAdd={() =>
          dispatch(
            showEditDictionary({
              name: 'CallSubTypes',
              title: 'Event Subtype',
              ptsParentID: CallType.IntCode,
            })
          )
        }
        getOptionSelected={(option, value) => option.Code === value.Code}
        getOptionLabel={(option) => (option.Code ? option.Code : '')}
        renderOption={(option) => (
          <span>
            {option.Code} - {option.Description}
          </span>
        )}
        disabled={!CallType}
      />
    );
  };
  const renderCallMethod = () => {
    const onChange = (ev, val) => {
      setCallMethod(val);
      !changed && setChanged(true);
    };
    return (
      <Dictionary
        className={classes.w180}
        options="CallMethods"
        value={CallMethod}
        onChange={onChange}
        label="Call Method"
        compact="true"
        editingForm={editingForm}
      />
    );
  };

  const renderRequestedAction = () => {
    const onChange = (ev, val) => {
      setRequestedAction(val);
      !changed && setChanged(true);
    };
    return (
      <Dictionary
        className={classes.w180}
        options="RequestedActions"
        value={RequestedAction}
        onChange={onChange}
        label="Requested Action"
        compact="true"
      />
    );
  };

  const renderLatitude = () => {
    const onChange = (ev) => {
      !changed && setChanged(true);
      setLat(ev.target.value);
    };
    return (
      <TextField
        className={clsx(classes.textField, classes.w180)}
        label="Latitude"
        variant="outlined"
        value={lat}
        onChange={onChange}
        size="small"
        type="number"
        error={!isLatValid()}
      />
    );
  };

  const renderLongitude = () => {
    const onChange = (ev) => {
      !changed && setChanged(true);
      setLng(ev.target.value);
    };
    return (
      <TextField
        className={clsx(classes.textField, classes.w180)}
        label="Longitude"
        variant="outlined"
        value={lng}
        onChange={onChange}
        size="small"
        type="number"
        error={!isLngValid()}
      />
    );
  };

  const renderEventDescription = () => {
    const onChange = (ev, value) => {
      setEventDescription(value);
      !changed && setChanged(true);
    };
    return (
      <TextField2
        className={clsx(classes.textField, classes.w100pr)}
        label="Event Description"
        value={EventDescription}
        onChange={onChange}
        max={2000}
        compact="true"
      />
    );
  };

  return (
    <>
      {renderLocationDescription()}
      <ContainerCard
        title={title}
        actions={renderActions()}
        info={sopsAvailable ? { text: 'Selections have attached SOPs', type: 'error' } : null}>
        <div className={classes.form}>
          {renderCallType()}
          {renderCallSubType()}
          {renderCallMethod()}
        </div>
        <div className={classes.form}>
          {renderRequestedAction()}
          {renderLatitude()}
          {renderLongitude()}
          {renderEventDescription()}
        </div>
      </ContainerCard>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, null)(EventForm);
