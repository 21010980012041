export const locationEmptyData = {
  ptsAddressID: null,
  AddressNumber: null,
  PreDirection: null,
  StreetName: null,
  StreetType: null,
  PostDirection: null,
  UnitType: null,
  UnitIdentifier: null,
  City: null,
  State: null,
  PostalCode: null,
  County: null,
  IsPrimary: false,
  Notes: '',
  CrossStreet: '',
};

export const locationPrimaryEmptyData = {
  ptsAddressID: null,
  AddressNumber: null,
  PreDirection: null,
  StreetName: null,
  StreetType: null,
  PostDirection: null,
  UnitType: null,
  UnitIdentifier: null,
  City: null,
  State: null,
  PostalCode: null,
  County: null,
  IsPrimary: true,
  Notes: '',
  lat: null,
  lng: null,
  CrossStreet: '',
  /* Reverting PP-702 */
  // ESN: null,
  // Locale: null,
  // SurroundingArea: null,
  // Area: null,
  // CrossStreet: null,
  // Highway: null,
  // LandmarkText: null,
  // MapLocation: null,
  // RangeDescription: null,
  // RelativeLocation: null,
  // AltitudeMeasure: null,
  // DepthMeasure: null,
  // GeographicElevation: null,
  // MGRSCoordinate: null,
  // TwoDGeographicCoordinate: null,
  // UTMCoordinate: null,
};

export const callerEmptyData = {
  CallerLocation: null,
  Unknown: false, // checkbox true/false
  Refused: false, // checkbox true/false
  FirstName: null,
  LastName: null,
  PhoneNo: null,
  // home address info
  AddressNumber: null,
  PreDirection: null,
  StreetName: null,
  StreetType: null,
  PostDirection: null,
  ptsCityID: null,
  State: null,
  PostalCode: null,
};

export const eventEmptyData = {
  CallType: null,
  CallSubType: null,
  CallMethod: null,
  RequestedAction: null,
  LocationDescription: null,
  EventDescription: null,
};

export const notesEmptyData = {
  Comment: '',
};
