import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { assignUnitToEvent } from 'reducers/UnitStatusReducer';
import { setEventFilter } from 'reducers/EventSortingReducer';
import { asyncForEach } from 'utils/functions';

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 14,
    '& > div:first-child': {
      paddingRight: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > div:last-child': {
      minWidth: 90,
    },
  },
}));

function DispatchMenu(props) {
  const classes = useStyles();
  const { open, anchorEl, onClose, events, colorPalette, units, filteredEvents } = props;
  const EventColors = colorPalette.Events;
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    const eventList = filteredEvents.map((ev) => processEvent(ev));
    setEventList(eventList);
  }, [filteredEvents]);

  const processEvent = (event) => {
    const { ptsEventID, EventID, Status, CallType, FullLocationAddress, assignedUnits } = event;
    return { ptsEventID, EventID, Status, CallType, FullLocationAddress, assignedUnits };
  };

  const handleEventClick = (event) => {
    const { assignedUnits, ptsEventID, EventID } = event;

    asyncForEach(units, (unit) => {
      const { ptsUnitID } = unit;
      const unitExists =
        assignedUnits.length > 0 && !!assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
      if (!unitExists) {
        props.assignUnitToEvent(ptsUnitID, ptsEventID);
      }
    });
    //props.setEventFilter(EventID);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      {eventList.map((option) => (
        <MenuItem key={option.ptsEventID} onClick={() => handleEventClick(option)}>
          <div className={classes.option}>
            <div>
              <b>
                {option.CallType} - {option.EventID}
              </b>{' '}
              - {option.FullLocationAddress}
            </div>
            <div className="badge" style={{ background: EventColors[option.Status] }}>
              {option.Status}
            </div>
          </div>
        </MenuItem>
      ))}
    </Menu>
  );
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    filteredEvents: state.filteredEvents,
    colorPalette: state.config.colorPalette,
  };
};

export default connect(mapStateToProps, {
  assignUnitToEvent,
  setEventFilter,
})(DispatchMenu);
