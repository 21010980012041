import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

export default function RecordLimitAlert({ handleNextPage, rowCount, currentRows = 1000 }) {
  return (
    <Alert
      severity="warning"
      action={
        <Button variant="outlined" onClick={handleNextPage}>
          Next
        </Button>
      }>
      Currently displaying {currentRows} records of total {rowCount} records. Click next to see more
      results.
    </Alert>
  );
}
