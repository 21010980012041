import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ListItem, List, Menu, makeStyles, Tooltip, Fab } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  showEditVehicle,
  showTowingDialog,
  editEvent,
  showEditPlace,
  showEditPerson,
} from 'reducers/DialogsReducer';
const useStyles = makeStyles(() => ({
  button: {
    padding: '0px !important',
    '&:hover': {
      background: 'transparent',
    },
    border: 'none',
    background: 'transparent',
    minWidth: (props) => (props.isEditPage ? '40px' : '64px'),
    marginRight: '-8px',
  },
  menu: {
    width: '220px',
    padding: '0px !important',
    margin: '0px !important',
  },
}));

const VehicleTowingEditMenu = (props) => {
  const classes = useStyles(props);
  const { selection, rows } = props;

  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [row, setRow] = useState({});
  useEffect(() => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    setRow(item);
  }, [selection]);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  const onEditTowing = () => props.showTowingDialog(row);
  const onEditVehicle = () => props.showEditVehicle(row);
  const onEditEvent = () => props.editEvent(row?.ptsEventID);
  const onEditTowCompany = () =>
    props.showEditPlace({ ptsPlaceID: row.ptsPlaceID, ptsAddressID: null });
  const onEditDriver = () => props.showEditPerson(row);

  return (
    <>
      <Tooltip title="Print list">
        <Fab size="small" color="secondary" onClick={handleClickMenu}>
          <EditIcon />
        </Fab>
      </Tooltip>

      <Menu
        className={classes.menu}
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className="overflow-hidden dropdown-menu-xl">
          <List className="flex-column">
            <ListItem
              disabled={selection === null}
              button
              onClick={() => {
                onEditTowing();
                handleCloseMenu();
              }}>
              <span>Edit Towing</span>
            </ListItem>
            <ListItem
              disabled={selection === null}
              button
              onClick={() => {
                onEditVehicle();
                handleCloseMenu();
              }}>
              <span>Edit Vehicle</span>
            </ListItem>
            <ListItem
              disabled={!row?.ptsEventID}
              button
              onClick={() => {
                onEditEvent();
                handleCloseMenu();
              }}>
              <span>Edit Event</span>
            </ListItem>
            <ListItem
              disabled={!row.ptsPlaceID}
              button
              onClick={() => {
                onEditTowCompany();
                handleCloseMenu();
              }}>
              <span>Edit Tow Company</span>
            </ListItem>
            <ListItem
              disabled={!row.ptsPersonID}
              button
              onClick={() => {
                onEditDriver();
                handleCloseMenu();
              }}>
              <span>Edit Driver</span>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  showEditVehicle,
  showTowingDialog,
  editEvent,
  showEditPlace,
  showEditPerson,
})(VehicleTowingEditMenu);
