import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BurglaryIcon from './img/burglary2.png';
import ShotsFiredIcon from './img/sf.png';
import RobberyIcon from './img/robbery.png';
import SuspiciousPerson from './img/robbery2.png';
import { makeStyles } from '@material-ui/core/styles';
import data from './data';
import EventList1 from './EventList1';
import EventList2 from './EventList2';
import EventList3 from './EventList3';
import EventList4 from './EventList4';
import EventList5 from './EventList5';
import EventDetail1 from './EventDetail1';
import EventDetail2 from './EventDetail2';
import EventDetail3 from './EventDetail3';
import EventDetail4 from './EventDetail4';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Units from './Units';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(2),
    '& > div': {
      width: '50%',
      heihgt: '100%',
    },
    '& h3': {
      color: '#0153a3',
      fontSize: '1.25rem',
    },
    '& .h-table': {
      '& th': {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 13,
        background: '#f4f5fd',
        color: theme.palette.text.primary,
      },
      '& td': {
        textAlign: 'center',
      },
    },
  },
  events: {
    borderRight: '4px solid rgba(255, 255, 255, 0.5)',
  },
  eventList: {
    width: '25%',
  },
  eventsWrap: {
    display: 'flex',
  },
  btnGroup: {
    marginBottom: 10,
    marginLeft: 10,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  eventDetails: {
    width: '75%',
    boxSizing: 'border-box',
    padding: '0 10px 0 10px',
  },

  units: {
    paddingLeft: '1em',
  },
}));

let timeout = 0;

const statusColors = {
  Completed: { bgColor: '#1a9837', color: '#fff' },
  Enroute: { bgColor: '#daa319', color: '#fff' },
  Arrived: { bgColor: '#0088e8', color: '#fff' },
  Dispatched: { bgColor: '#8603d1', color: '#fff' },
};

const unitStatusColors = {
  COMPLETED: { bgColor: '#1a9837', color: '#fff' },
  ENROUTE: { bgColor: '#daa319', color: '#fff' },
  ARRIVED: { bgColor: '#0088e8', color: '#fff' },
  DISPATCH: { bgColor: '#8603d1', color: '#fff' },
};

const eventTypes = {
  BURG: { description: 'bruglary', icon: BurglaryIcon },
  'ROBBERY-A': { description: 'robbery', icon: RobberyIcon },
  SHOTSFIRED: { description: 'shots fired', icon: ShotsFiredIcon },
  'SUSP-P': { description: 'suspicious person', icon: SuspiciousPerson },
};

const config = { statusColors, unitStatusColors, eventTypes };

function PageDeveloper(props) {
  const classes = useStyles();
  const [activeEventIds, setActiveEventIds] = useState([data[0].EventId]);
  const [activeEvents, setActiveEvents] = useState([data[0]]);
  const [listStyle, setListStyle] = useState(2);
  const [evDetail, setEvDetail] = useState(1);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleUnitClick = (event, ev) => {
    if (event.ctrlKey) {
      if (activeEventIds.indexOf(ev.EventId) !== -1) {
        setActiveEventIds(activeEventIds.filter((EventId) => EventId !== ev.EventId));
        setActiveEvents(activeEvents.filter((e) => e.EventId !== ev.EventId));
      } else {
        const eventIds = [...activeEventIds];
        const events = [...activeEvents];
        eventIds.push(ev.EventId);
        events.push(ev);
        setActiveEventIds(eventIds);
        setActiveEvents(events);
      }
    } else {
      setActiveEventIds([ev.EventId]);
      setActiveEvents([ev]);
    }
  };

  return (
    <div className={classes.pageWrap}>
      <div className={classes.events}>
        <h3>Active Events</h3>
        <div>
          <ButtonGroup size="small" color="primary" className={classes.btnGroup}>
            <Button onClick={() => setListStyle(0)}>1</Button>
            <Button onClick={() => setListStyle(1)}>2</Button>
            <Button onClick={() => setListStyle(2)}>3</Button>
            <Button onClick={() => setListStyle(3)}>4</Button>
            <Button onClick={() => setListStyle(4)}>5</Button>
          </ButtonGroup>
          <ButtonGroup size="small" color="primary" className={classes.btnGroup}>
            <Button onClick={() => setEvDetail(0)}>1</Button>
            <Button onClick={() => setEvDetail(1)}>2</Button>
            <Button onClick={() => setEvDetail(2)}>3</Button>
            <Button onClick={() => setEvDetail(3)}>4</Button>
          </ButtonGroup>
        </div>
        <div className={classes.eventsWrap}>
          <div className={classes.eventList}>
            {listStyle === 0 && (
              <EventList1
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                handleUnitClick={handleUnitClick}
              />
            )}
            {listStyle === 1 && (
              <EventList2
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                handleUnitClick={handleUnitClick}
              />
            )}
            {listStyle === 2 && (
              <EventList3
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                handleUnitClick={handleUnitClick}
              />
            )}
            {listStyle === 3 && (
              <EventList4
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                handleUnitClick={handleUnitClick}
              />
            )}
            {listStyle === 4 && (
              <EventList5
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                handleUnitClick={handleUnitClick}
              />
            )}
          </div>
          <div className={classes.eventDetails}>
            {evDetail === 0 && (
              <EventDetail1
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                activeEvents={activeEvents}
              />
            )}
            {evDetail === 1 && (
              <EventDetail2
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                activeEvents={activeEvents}
              />
            )}
            {evDetail === 2 && (
              <EventDetail3
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                activeEvents={activeEvents}
              />
            )}
            {evDetail === 3 && (
              <EventDetail4
                data={data}
                config={config}
                activeEventIds={activeEventIds}
                activeEvents={activeEvents}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.units}>
        <h3>Active Units</h3>
        <Units config={config} />
      </div>
    </div>
  );
}

export default connect(null, {})(PageDeveloper);
