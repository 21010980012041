import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    '& button[disabled]': {
      color: theme.colors.disabledBtn,
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.content.bg,
  },
  actions: {
    '& button': {
      color: theme.card.bg,
      // textTransform: 'uppercase',
      fontWeight: 400,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog(props) {
  const classes = useStyles();
  const { open = true, onClose, title, children, actions, toolbar } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={Boolean(open)}
        onClose={handleClose}
        TransitionComponent={Transition}
        color="primary">
        {Boolean(toolbar) && (
          <AppBar className={classes.appBar}>
            <Toolbar variant="dense">
              <IconButton edge="start" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
              <div className={classes.actions}>{actions}</div>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent className={clsx(props.className, classes.content)}>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
