import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Unit from 'pages/Units/Unit';
import { setUnitStatus } from 'reducers/UnitStatusReducer';
import { sortObjArr } from 'utils/functions';
import settings from 'config/settings';
import {
  setSelectedEvents,
  setSelectedUnitFromEvent,
  setSelectedUnits,
} from 'reducers/SelectionReducer';

const useStyles = makeStyles(() => ({
  units: {
    width: '100%',
    minHeight: 41,
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
}));

function EventUnitsTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selection = useSelector((state) => state.selection.eventUnitSelection);
  const {
    colorPalette,
    UnitStatuses,
    ptsEventID,
    sortOption,
    fromEditEventWindow,
    disableEdit,
  } = props;
  const [units, setUnits] = useState([]);
  const dataPass = useRef({});
  useEffect(() => {
    if (!units) return;

    const newUnits = props.units?.map((unit) => {
      return {
        ...unit,
        UnitStatuses,
        LastActionOccurred: getLastActionOccurred(unit),
      };
    });
    setUnits(sortObjArr(newUnits, 'Created', 'DESC'));
    // eslint-disable-next-line
  }, [props.units]);

  const getLastActionOccurred = (unit) => {
    let history = [];
    if (UnitStatuses) {
      UnitStatuses.forEach((status) => {
        if (status.Unit === unit.Unit) {
          history.push(status);
        }
      });
      history = sortObjArr(history, 'Occurred', 'ASC');
    }
    return history.length ? history[0].Occurred : unit.Occurred;
  };

  const onUnitClick = (ev, unit) => {
    const { ptsUnitID } = unit;
    dispatch(setSelectedUnits());
    dispatch(setSelectedEvents());
    if (selection?.ptsUnitID === ptsUnitID && selection?.ptsEventID === ptsEventID)
      dispatch(setSelectedUnitFromEvent());
    else dispatch(setSelectedUnitFromEvent({ ptsUnitID, ptsEventID }));
  };

  dataPass.current.selection = selection;
  //dataPass.current.setSelection = setSelection;
  dataPass.current.onUnitClick = onUnitClick;
  dataPass.current.colorPalette = colorPalette;

  return (
    <div className={classes.units}>
      {units?.map((unit) => (
        <div key={unit.ptsUnitID}>
          <Unit
            unit={unit}
            ptsEventID={ptsEventID}
            parent="event"
            selected={
              selection?.ptsUnitID === unit.ptsUnitID && selection?.ptsEventID === ptsEventID
            }
            dataPass={dataPass}
            fromEditEventWindow={fromEditEventWindow}
            disableEdit={disableEdit}
          />
        </div>
      ))}
    </div>
  );
}

export default connect(null, { setUnitStatus })(EventUnitsTab);
