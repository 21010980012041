import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import { getClockTime } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  clock: {
    color: theme.colors.text1,
    fontSize: '25px',
    background: theme.colors.grey5,
    padding: '0 25px',
    borderRadius: '5px',
  },
}));

const Clock = () => {
  const classes = useStyles();
  const [time, setTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getClockTime();
      setTime(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.clock}>
      {time ? <>{time}</> : <AccessTimeOutlinedIcon fontSize="large" />}
    </div>
  );
};

export default Clock;
