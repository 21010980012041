import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeReport } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { getReport } from 'reducers/ReportsReducer';
import { handleError } from 'reducers/ErrorReducer';
import CircularProgress from '@material-ui/core/CircularProgress';
import printJS from 'print-js';
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialogContent-root': {
      padding: 0,
      '& > div': {
        padding: 0,
      },
    },
  },
  reportWrap: {
    maxWidth: '100%',
    width: 1500,
    minHeight: 'calc(100vh - 140px)',
    '& > object': {
      width: '100%',
      border: 'none',
      height: 'calc(100vh - 140px)',
    },
  },
  loader: {
    maxWidth: '100%',
    width: 1500,
    minHeight: 'calc(100vh - 140px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    'z-index': 1000,
  },
}));

function ExampleWindow(props) {
  const classes = useStyles();
  const { report } = props;

  useEffect(() => {
    if (!report) return;
    const { id, data, title } = report;
    if (!id || !data) return;
    getReportData(id, data);
    // eslint-disable-next-line
  }, [report]);

  const getReportData = async (id, data) => {
    try {
      const reportData = await getReport(id, data);
      printJS({
        printable: reportData,
        type: 'pdf',
        base64: true,
        onPrintDialogClose: () => close(),
      });
    } catch (err) {}
  };

  const close = () => {
    props.closeReport();
  };

  const renderLoader = () => (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );

  return renderLoader();
}

export default connect(null, {
  closeReport,
  handleError,
})(ExampleWindow);
