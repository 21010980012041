import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { filterResults } from 'utils/functions';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import formStyles, { gridStyle, Row } from 'utils/formStyles';
import { formatSaveDateFrom, formatSaveDateTo } from 'reducers/TimeReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrapper: {
    width: 550,
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  item: gridStyle(200, 320),
  search: gridStyle(200, '100%'),
}));

function QuickSearch(props) {
  const classes = useStyles();
  const { rows, setRows, cols, search, dates, loaded } = props;
  const [text, setText] = useState('');
  const [searchTerm, setSearchTerm] = useState(null);
  const [filters, setFilters] = useState(null);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const formValid = text.length >= 3 || (dateFrom && dateTo);

  useEffect(() => {
    processSearchText();
    // eslint-disable-next-line
  }, [text]);

  useEffect(() => {
    if (!filters) return setRows && setRows(rows);
    applyFilter(rows, filters);
    // eslint-disable-next-line
  }, [rows]);

  const processSearchText = () => {
    if (!text) {
      setText('');
      return;
    }
    const words = text
      .split(' ')
      .map((w) => w.trim())
      .filter((w) => Boolean(w));
    if (!words.length) {
      setSearchTerm('');
      setFilters(null);
    } else if (words.length === 1) {
      setSearchTerm(words[0]);
      setFilters(null);
    } else {
      const longestIdx = words.reduce(
        (result, val, idx) => (val.length > words[result].length ? idx : result),
        0
      );
      const longestWord = words[longestIdx];
      words.splice(longestIdx, 1);
      setSearchTerm(longestWord);
      setFilters(words);
    }
  };

  const applyFilter = (rows, filters) => {
    const filteredRows = filterResults(filters[0], rows, cols);
    if (filters.length === 1) {
      setRows(filteredRows);
      return;
    }
    const newFilters = [...filters];
    newFilters.shift();
    applyFilter(filteredRows, newFilters);
  };

  const onChange = (ev) => {
    const val = ev.target.value;
    setText(val);
  };

  const onKeyDown = (ev) => {
    if (ev.code === 'Enter') onSearch();
  };

  const renderSearch = () => {
    return (
      <TextField
        label="Search"
        type="search"
        className={classes.search}
        variant="outlined"
        size="small"
        onChange={onChange}
        value={text}
        onKeyDown={onKeyDown}
      />
    );
  };

  const renderDateFrom = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.item}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Date From"
          value={dateFrom}
          onChange={(date) => setDateFrom(date)}
          maxDate={dateTo}
          autoOk
          size="small"
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDateTo = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.item}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Date To"
          value={dateTo}
          onChange={(date) => setDateTo(date)}
          minDate={dateFrom}
          autoOk
          size="small"
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderSendBtn = () => {
    const disabled = !dates && (!formValid || !loaded || !text || text.length < 3);
    return (
      <div className={classes.btn}>
        <Button onClick={onSearch} variant="outlined" disabled={disabled}>
          Search
        </Button>
      </div>
    );
  };

  const onSearch = () => {
    if (dates) {
      search({
        search: searchTerm,
        dateFrom: formatSaveDateFrom(dateFrom),
        dateTo: formatSaveDateTo(dateTo),
      });
    } else {
      search(searchTerm);
    }
  };

  return (
    <div className={classes.wrapper} style={props.style}>
      <Row>
        {renderSearch()}
        {!dates && renderSendBtn()}
      </Row>
      {dates && (
        <Row>
          {renderDateFrom()}
          {renderDateTo()}
          {renderSendBtn()}
        </Row>
      )}
    </div>
  );
}

export default QuickSearch;
