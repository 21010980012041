import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import useStyles from './event-list-styles';
import DisplayData from './DisplayData';
import PoliceIco from './PoliceIco';
import FiremanIco from './FiremanIco';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    padding: 6,
    borderRadius: 5,
    backgroundColor: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #666',
    color: '#333',
    fontWeight: 400,
    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
    '& b': {
      fontWeight: 600,
    },
    '& ht': {
      background: 'red !important',
    },
  },
}))(Tooltip);

function EventList(props) {
  const classes = useStyles();
  const { statusColors, unitStatusColors } = props.config;
  const { data, activeEventIds, handleUnitClick } = props;

  return (
    <>
      {data.map((ev) => {
        const { EventId, Status, EventType, RespondingLocation, units } = ev;
        const bgColor = statusColors[Status].bgColor;
        const active = activeEventIds.indexOf(EventId) !== -1;
        const cardStyle = active
          ? { border: `3px solid ${bgColor}` }
          : { borderBottom: `3px solid ${bgColor}` };
        return (
          <Card
            className={classes.eventCard}
            key={EventId}
            style={cardStyle}
            onClick={(event) => handleUnitClick(event, ev)}>
            <div className={classes.evCardHead}>
              <div>{EventType}</div>
              <div>{EventId}</div>
            </div>
            <div className={classes.evCardContent}>
              <div className={classes.location} title={RespondingLocation}>
                <div>{RespondingLocation}</div>
              </div>

              <div className={classes.evBottom}>
                <div className={classes.status}>
                  <span style={{ background: bgColor }}>{Status}</span>
                </div>
                <div className={classes.dispatch}>
                  {units.map((unit, idx) => {
                    const { AgencyId, UnitStatus } = unit;
                    const unitData = {};
                    Object.entries(unit).forEach(([key, value]) => {
                      switch (value) {
                        case false:
                          unitData[key] = 'NO';
                          break;
                        case true:
                          unitData[key] = 'YES';
                          break;
                        case '':
                          break;
                        case null:
                          break;
                        default:
                          unitData[key] = value;
                      }
                    });

                    const Icon = AgencyId === 'POLICEDEP' ? PoliceIco : FiremanIco;
                    const bgColor = unitStatusColors[UnitStatus].bgColor;
                    return (
                      <span className={classes.department} key={idx} style={{ fill: bgColor }}>
                        <HtmlTooltip title={<DisplayData variant="V" data={unitData} />}>
                          <span>
                            <Icon />
                          </span>
                        </HtmlTooltip>
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          </Card>
        );
      })}
    </>
  );
}

export default EventList;
