import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getAddressFromLocation } from 'utils/mapFunctions';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { checkMergeStats } from 'reducers/AddressReducer';
import { handleError } from 'reducers/ErrorReducer';
import Checkbox2 from 'components/Checkbox2';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { genColHeaderName } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  error: {
    marginBottom: 16,
  },
  stats: {
    '& td': {
      fontSize: 16,
      paddingRight: 16
    },
  },
  addressDetails: {
    display: 'inline-block',
    padding: '8px 16px',
    fontSize: 20,
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
    '& span': {
      display: 'inline-block',
      color: theme.palette.secondary.main,
      marginRight: 8,
    },
  }
}));

function Review(props) {
  const {
    source,
    target,
    data,
    targetPrePlan,
    prePlan,
    dictionary,
    setLoading,
    addressData,
    setAddressData,
    setPrePlanData,
    deleteSource,
    setDeleteSource,
  } = props;
  const [noCity, setNoCity] = useState(null);
  const [stats, setStats] = useState(null);
  const mountedRef = useRef(true);
  const classes = useStyles();

  useEffect(() => {
    processTargetData();
    processTargetPrePlan();
    checkStats();
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line
  }, []);

  const processTargetData = () => {
    const newData = { ...target };
    data.forEach((i) => {
      const key = i.field;
      const val = i.isTargetVal ? i.targetVal : i.sourceVal;
      newData[key] = val;
    });
    const CityDescription = newData.City;
    if (CityDescription) {
      const { Cities } = dictionary;
      const city = Cities.find((city) => city.CityDescription === CityDescription);
      if (city) {
        newData.ptsCityID = city.ptsCityID;
      } else {
        setNoCity(true);
      }
    } else {
      newData.ptsCityID = null;
    }
    const FullAddressText = getAddressFromLocation(newData);
    newData.FullAddressText = FullAddressText;
    setAddressData(newData);
  }

  const processTargetPrePlan = () => {
    if (!prePlan) return;
    const newData = { ...targetPrePlan };
    prePlan.forEach((i) => {
      const key = i.field;
      const val = i.isTargetVal ? i.targetVal : i.sourceVal;
      newData[key] = val;
    });
    setPrePlanData(newData);
  }

  const checkStats = async () => {
    try {
      setLoading(true);
      const result = await checkMergeStats(source.ptsAddressID);
      if (!result || !mountedRef.current) return;
      const stats = [];
      Object.entries(result).forEach(([key, val]) => {
        stats.push({ key, val });
      });
      setStats(stats);
    } catch (err) {
      props.handleError(err);
    }
    setLoading(false);
  }

  const renderAddress = () => {
    if (!addressData) return '';
    const { AddressID, FullAddressText } = addressData;
    return (
      <Paper variant="outlined" className={classes.addressDetails}>
        <span>{AddressID}</span> {FullAddressText}
      </Paper>
    );
  }

  const renderDataUpdates = () => {
    if (!stats) return '';
    return (
      <div className={classes.stats}>
        <h5>Records to be updated:</h5>
        <table>
          <tbody>
            {stats.map((row) => <tr key={row.key}>
              <td>{genColHeaderName(row.key)}:</td>
              <td>{row.val}</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    );
  }

  const renderDeleteSource = () => {
    return (
      <div>
        <FormControlLabel
          control={<Checkbox2 checked={deleteSource} onChange={(ev, val) => setDeleteSource(val)} />}
          label={`Delete Source - ${source.AddressID}`}
        />
      </div>
    );
  }

  const displayErrors = () => {
    return (
      <>
        {Boolean(noCity) && <Alert severity="error" className={classes.error}>
          Error, no configuration found for the city: <strong>{addressData.City}</strong>
        </Alert>}
      </>
    );
  }

  return (
    <div>
      {displayErrors()}
      {renderAddress()}
      {renderDeleteSource()}
      {renderDataUpdates()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  dictionary: state.dictionary,
})

export default connect(mapStateToProps, { handleError })(Review);
