import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { closePersonRelatedRecordsDetail } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { connect } from 'react-redux';
import XGrid3 from 'components/XGrid3';
import { genColHeaderName } from 'utils/functions';


function RelatedRecordDetails(props) {
  const { data } = props;
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setColumns(processCols(data[1][0]));
    setRows(processRows(data[1]));
  }, []);

  const processCols = (row) => {
    const cols = [];
    for (const [key, value] of Object.entries(row)) {
      if (key.includes('pts') || key === 'id') continue;
      if (key.includes('Date')) cols.push({field: key, format: 'date'});
      else if (typeof value === 'boolean') cols.push({field: key, format: 'bool'});
      else cols.push(key);
    }
    return cols;
  }

  const processRows = (rows) => {
    return rows.map((row, idx) => {
      row.id = idx;
      return row;
    })
  }

  const close = () => {
    props.closePersonRelatedRecordsDetail();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog fullScreen toolbar onClose={close} title={genColHeaderName(data[0])} actions={renderActions()}>
      <XGrid3 
        name={data[0]}
        columns={columns}
        setColumns={setColumns}
        rows={rows}
        disableSelectionOnClick
      />
    </Dialog>
  );
}

export default connect(null, { closePersonRelatedRecordsDetail })(RelatedRecordDetails);
