export const SET_FILTERED_EVENTS = 'FILTERED_EVENTS/SET_FILTERED_EVENTS';

export const setFilteredEvents = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_FILTERED_EVENTS, payload: data });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_FILTERED_EVENTS:
      return [...action.payload];
    default:
      break;
  }
  return state;
}
