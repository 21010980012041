import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Card, Divider, Grid, List, ListItem, Tooltip } from '@material-ui/core';
import { displayDate } from 'reducers/TimeReducer';

const WarrantCard = (props) => {
  const { warrants } = props;
  console.log('warrant ', warrants);
  return (
    <div>
      <div spacing={2} className="p-1" style={{ backgroundColor: 'none' }}>
        {warrants.length > 0 &&
          warrants.map((row) => (
            <div key={row.ROWGUID}>
              <Card className="card-box mb-4    py-3 px-1" style={{ height: '400px', width: 500 }}>
                <div className="card-tr-actions">
                  <div>
                    {row.IsActive ? (
                      <div className="badge badge-success badge-pill">
                        <span>ACTIVE</span>
                      </div>
                    ) : (
                      <div sx={{ bgcolor: 'text.secondary' }} className="badge  badge-pill">
                        <span>DEACTIVE</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex  px-3 mb-1">
                  <div className="avatar-icon-wrapper rounded mr-3" style={{ textAlign: 'center' }}>
                    <Tooltip arrow title={row.IssueAgencyID || 'Unknown'}>
                      <div
                        style={{
                          cursor: 'pointer',

                          padding: '10px',
                        }}>
                        <div>
                          <AssignmentIcon fontSize="large" />
                        </div>
                      </div>
                    </Tooltip>
                    <h6>{row.IssueAgencyID}</h6>
                  </div>

                  <div className="w-100">
                    <p className="font-weight-bold font-size-lg mb-0" title="...">
                      {row.FullName || ''}
                    </p>
                    <p className="text-black-70 mb-0">{row.FullAddress || ''}</p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <p className="text-black-70 mb-1">DOB:{displayDate(row.DOB) || ''}</p>
                      <p className="text-black-70 mb-1">SSN:{row.SSN || ''}</p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <p className="text-black-70 align-left">OLN State: : {row.OLNState || ''}</p>
                      <p className="text-black-70 align-left">OLN: : {row.OLNNumber || ''}</p>
                    </div>
                  </div>
                </div>
                <Divider className="my-2" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 8px',
                  }}>
                  <p>
                    <b>NUMBER:</b> {row.WarrantID || ''}
                  </p>
                  <p>
                    <b>ISSUED:</b> {displayDate(row.IssuedDate) || ''}
                  </p>
                  <p>
                    <b>BOND:</b> {row.BondAmount || ''}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 8px',
                  }}>
                  <p>
                    <b>Disposition:</b> {row.WarrantDisposition || ''}
                  </p>
                  <p>
                    <b>Disposition Date:</b>
                    {displayDate(row.DispositionDate) || ''}
                  </p>
                  <p></p>
                </div>
                <p className="font-weight-bold font-size-md mb-0 px-2" title="...">
                  Offenses
                </p>
                <Divider className="my-1" />
                <div
                  className="font-size-sm px-2 rounded-sm"
                  style={{ maxHeight: '200px', overflow: 'auto' }}>
                  <List className="py-0">
                    <ListItem button className="border-0 align-box-row px-0">
                      <div className="align-box-row w-100">
                        <div className="d-flex justify-content-between w-100">
                          <div className=" d-block">{row.OffenseDescription}</div>
                          <div className="mr-3">
                            <div className="badge badge-warning badge-pill">Main</div>
                          </div>
                        </div>
                      </div>
                    </ListItem>
                    <div className="divider mt-1 mb-1" />
                    {row.AdditionalOffenses &&
                      JSON.parse(row.AdditionalOffenses).map((offese) => (
                        <>
                          <ListItem className="px-0">{offese.Description}</ListItem>
                          <Divider />
                        </>
                      ))}
                  </List>
                </div>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
};

export default WarrantCard;
