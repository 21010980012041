import * as Sentry from "@sentry/react";
import { getService } from './service';

const SET_SERVER_CONNECTION = 'SET_SERVER_CONNECTION';

export const updateServerConnection = (data) => (dispatch) => dispatch({ type: SET_SERVER_CONNECTION, data });

export default function reducer(state = true, action) {
  switch (action.type) {
    case SET_SERVER_CONNECTION:
      return action.data;
    default:
      break;
  }
  return state;
}

let connectionService;
export const subscribeConnectionStatus = () => {
  return async (dispatch) => {
    try {
      connectionService = getService('service-broker');
      connectionService.on('connect', () => {
        dispatch(updateServerConnection(true));
      });
      connectionService.on('disconnect', () => {
        dispatch(updateServerConnection(false));
        Sentry.captureException(new Error("SQL connection lost"), {
          level: 'info',
        });
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const unsubscribeConnectionStatus = () => (dispatch) => {
  if (connectionService) {
    try {
      connectionService.off('connection');
    } catch (error) {
      console.log('ErrorReducer/ErrorReducer: error: ', error, error.code);
    }
  }
};