import React from 'react';

import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import Zone from './Zone';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';

function ZoneIndex() {
  const canReadAgencies = getAgenciesFullPermission('globals', 'Zones', 'Read');
  if (!canReadAgencies.length) return <Redirect to="/" />;
  return (
    <SearchMenu agencyList={canReadAgencies}>
      <FilterConsumer>
        {(AgencyFilter) => <Zone agencyList={canReadAgencies} filter={AgencyFilter} />}
      </FilterConsumer>
    </SearchMenu>
  );
}

export default ZoneIndex;
