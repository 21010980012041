import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { closeEditPlace } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import AddPlace from './AddPlace';
import CloseIcon from '@material-ui/icons/Close';
import { getPtsPlace } from 'reducers/SearchReducer';
import { Button, makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import formStyles from 'utils/formStyles';
import PlaceAddress from './PlaceAddress';
import PlaceContact from './PlaceContact';
import PlaceEmployees from './PlaceEmployees';
import PlaceSops from './PlaceSops';
import PlaceVehicles from './PlaceVehicles';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(3),
  },
}));

function PlaceAdd(props) {
  const classes = useStyles();
  const { ptsPlaceID, ptsAddressID } = props;
  const [rowData, setRowData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [tab, setTab] = useState('edit');
  const addPlace = ptsPlaceID === true;
  const mountedRef = useRef(true);
  const newPlace = !ptsPlaceID || ptsPlaceID === true;

  useEffect(() => {
    if (props.tab) setTab(props.tab);
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [props.tab]);

  useEffect(() => {
    if (!ptsPlaceID || ptsPlaceID === true) return;
    getPlace();
    // eslint-disable-next-line
  }, [ptsPlaceID, needRefresh]);

  const getPlace = () => {
    props.showSpinner();
    getPtsPlace(ptsPlaceID).then(processData).catch(props.handleError).finally(props.hideSpinner);
  };

  const processData = (data) => {
    const { PlaceAddress, Employement, ContactInfo } = data;
    console.log('data ', data);
    const add = PlaceAddress?.map((pa) => {
      return {
        ...pa,
        ...pa.Address,
        City: pa.Address.City?.CityDescription || '',
      };
    });
    const emp = Employement?.map((employee) => {
      return { ...employee, ...employee.PersonInfo };
    });
    setEmployees(emp || []);
    setContactInfo(ContactInfo || []);
    setAddressData(add || []);
    setRowData(data);
  };

  const renderActions = () => {
    return (
      <div className={classes.dialogActions}>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </div>
    );
  };
  const close = () => {
    props.closeEditPlace();
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      actions={renderActions()}
      title={addPlace ? 'Add Place' : 'Edit Place'}
      fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Place" value="edit" />
            <Tab label="Address" value="address" disabled={newPlace} />
            <Tab label="Contact" value="contact" disabled={newPlace} />
            <Tab label="Employees" value="employee" disabled={newPlace} />
            <Tab label="SOP" value="sop" disabled={newPlace} />
            <Tab label="Vehicle Registrations" value="vehicles" disabled={newPlace} />
            {/* <Tab label="Coordinates" value="coordinates" /> */}
          </Tabs>
        </Paper>
        {tab === 'edit' && (
          <AddPlace
            close={close}
            ptsPlaceID={ptsPlaceID}
            rowData={rowData}
            ptsAddressID={ptsAddressID}
          />
        )}
        {tab === 'address' && (
          <PlaceAddress
            needRefresh={needRefresh}
            setNeedRefresh={setNeedRefresh}
            close={close}
            ptsPlaceID={ptsPlaceID}
            addressData={addressData}
          />
        )}
        {tab === 'contact' && (
          <PlaceContact close={close} ptsPlaceID={ptsPlaceID} contactInfo={contactInfo} />
        )}
        {tab === 'employee' && (
          <PlaceEmployees close={close} ptsPlaceID={ptsPlaceID} employees={employees} />
        )}
        {tab === 'sop' && <PlaceSops close={close} ptsPlaceID={ptsPlaceID} />}
        {tab === 'vehicles' && <PlaceVehicles close={close} ptsPlaceID={ptsPlaceID} />}
        {/* {tab === 'parties' && <AddressParties ptsAddressID={ptsAddressID} />} */}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeEditPlace,
  handleError,
  showSpinner,
  hideSpinner,
})(PlaceAdd);
