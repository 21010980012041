import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePersonNameDialog, closePersonNameType } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField2 from 'components/TextField2';
import Dictionary from 'components/Dictionary';
import formStyles, { gridStyle, Row, Fills, useError, formatSaveData } from 'utils/formStyles';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { addOrEditPerson, createPersonName } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  content: {
    width: 700,
    maxWidth: '100%',
  },
  fullName: {
    justifyContent: 'space-between',
  },
  item: gridStyle(200, '100%'),
  nameType: gridStyle(150, 220),
  fontWeight: 'bold',
}));

const emptyFormData = {
  FirstName: null,
  MiddleName: null,
  LastName: null,
  Prefix: null,
  Suffix: null,
  NickName: null,
  FullName: null,
  Notes: null,
  NameType: null,
  IsDefault: false,
  IsActive: true,
};

const currentNameObj = {
  FirstName: '',
  MiddleName: '',
  LastName: '',
  NickName: '',
  Prefix: null,
  Suffix: null,
};
function PersonNameTypeDialog(props) {
  const classes = useStyles();
  const { personData } = props;
  const { data, personId, previousName } = personData;
  const [formData, setFormData] = useState(emptyFormData);
  const [errors, setErrors] = useState();
  const title = 'Add Person Name Type';
  const { err, isValid } = useError(errors, setErrors, true);

  useEffect(() => {
    if (previousName) {
      const FullName = getFullName();
      setFormData((prevState) => ({ ...prevState, ...previousName, FullName }));
    }
  }, [previousName]);

  const close = () => {
    props.closePersonNameType();
  };

  const save = async () => {
    const saveData = formatSaveData(formData);
    saveData.ptsPersonID = personId;
    props.showSpinner();
    try {
      await addOrEditPerson(personId, data);
      await createPersonName(saveData);
      props.notifyDataUpdate({
        type: 'Person',
        data: { action: 'Update', personId },
      });
      props.notifyDataUpdate({ type: 'person-name' });
      close();
    } catch (error) {
      props.handleError(error);
    }
    props.hideSpinner();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!isValid()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const getFullName = () => {
    const { FirstName, LastName, MiddleName, Prefix, Suffix } = previousName;
    let FullName = Prefix ? Prefix.Code + ' ' : '';
    FullName += FirstName ? FirstName + ' ' : '';
    FullName += MiddleName ? MiddleName + ' ' : '';
    FullName += LastName ? LastName + ' ' : '';
    FullName += Suffix ? Suffix.Code : '';

    return FullName.trim();
  };

  const renderFullName = () => {
    return (
      <Typography variant="subtitle2">
        {`Previous Name for this person: "${formData.FullName}" will be saved.\n Select a type for this previous name.`}
      </Typography>
    );
  };

  const onChange = (fieldName) => (ev, val) => {
    setFormData((prevState) => ({ ...prevState, [fieldName]: val }));
  };

  const renderNameType = () => {
    const error = err(formData.NameType, 'NameType');
    return (
      <Dictionary
        options="NameTypes"
        className={classes.nameType}
        onChange={onChange('NameType')}
        value={formData.NameType}
        label="Name Type"
        error={error}
        compact="true"
      />
    );
  };

  const renderNotes = () => {
    return (
      <TextField2
        className={classes.w100pr}
        label="Notes"
        value={formData.Notes}
        onChange={onChange('Notes')}
        max={2000}
        multiline
        rows={5}
        compact="true"
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title={title} actions={renderActions()}>
      <div className={classes.content}>
        <Row className={classes.fullName}>{renderFullName()}</Row>
        <Row style={{ marginTop: '15px' }}>
          {renderNameType()}
          <Fills className={classes.item} />
        </Row>
        <Row>{renderNotes()}</Row>
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closePersonNameType,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(PersonNameTypeDialog);
