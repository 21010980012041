
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8pickingup100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="M36.5 4a5.36 5.36 0 0 0-2.178.451 5.499 5.499 0 0 0-2.943 3.082C30.799 6.83 29.782 6 28.143 6 25.303 6 23 8.5 23 8.5s2.303 2.5 5.143 2.5c1.338 0 2.26-.557 2.873-1.145.015.254.04.505.093.745A5.501 5.501 0 0 0 42 9.5C42 6.464 39.536 4 36.5 4zm-10.646 9.002a3.906 3.906 0 0 0-1.604.389l-7.42 3.478-.09.05c-1.02.51-1.91.952-2.43 1.212-1.14.58-2.26 1.459-3.06 2.549-.012.025-.02.052-.031.078-.651.89-1.068 1.92-1.078 3.012C10.12 27.67 7 34 7 34h14c0-3.16-2-8-2-8l2.225-1.59.275-.16 3.5-2.02v2.27c0 .34.07.67.2.98l3 7a2.507 2.507 0 0 0 3.28 1.32 2.508 2.508 0 0 0 1.32-3.28L30 23.99v-6.98c0-.57-.12-1.15-.38-1.7-.07-.14-.14-.28-.23-.41 0-.03-.01-.04-.03-.06-.01-.02-.02-.04-.04-.05a4.007 4.007 0 0 0-2.93-1.76 3.665 3.665 0 0 0-.536-.028zM10.609 36l-3.328 5.76c-.6 1.05-.21 2.38.89 2.97.35.18.72.27 1.1.27.79 0 1.559-.4 1.979-1.12l2.23-3.86-.76-4.02h-2.11zm4.15 0 .141.75 1.2 6.31c.22 1.14 1.26 1.94 2.43 1.94.15 0 .29-.01.44-.04 1.34-.23 2.24-1.46 1.99-2.75L19.78 36h-5.02zM36 38a1 1 0 0 0-.895.553L34.383 40H32a1 1 0 0 0-1 1v3a1 1 0 0 0 .684.95l3 1A1 1 0 0 0 35 46h3a1 1 0 0 0 .832-.445l2-3a1 1 0 0 0 0-1.11l-2-3A1 1 0 0 0 38 38h-2zm.617 2h.848l1.334 2-1.334 2h-2.303L33 43.28V42h2a1 1 0 0 0 .895-.553L36.617 40z"/></SvgIcon>
      );
    }
  
    export default icons8pickingup100Icon;