import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeRotationHistory } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField from '@material-ui/core/TextField';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getRotationHistory, updateRotationHistory } from 'reducers/RotationReducer';
import { Grid } from '@material-ui/core';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
  },
  textFiled: {
    width: '100%',
    paddingLeft: 5,
  },
}));

const rotationHistoryObj = {
  EventID: '',
  Unit: '',
  Rotation: '',
  Division: '',
  ContactType: '',
  Received: '',
  Dispatched: '',
  Arrived: '',
  UnderCont: '',
  ActionReason: '',
  ACTION: '',
  Zone: '',
  ContactInfo: '',
  ContactName: '',
  CallType: '',
  Queued: '',
  Enroute: '',
  Completed: '',
  Cancelled: '',
};
function EditRotationHistory(props) {
  const classes = useStyles();
  const { data } = props;
  const { ptsRotationHistoryID } = data;
  const [values, setValues] = useState(rotationHistoryObj);

  const getData = async () => {
    const result = await getRotationHistory(ptsRotationHistoryID);
    if (!result.length) return;
    setValues(result[0]);
  };
  useEffect(() => {
    props.showSpinner();
    (async () => {
      await getData();
    })();
    props.hideSpinner();
  }, [ptsRotationHistoryID]);

  const close = () => {
    props.closeRotationHistory();
  };

  const save = async () => {
    props.showSpinner();
    const { ActionReason } = values;
    await updateRotationHistory({ ActionReason, ptsRotationHistoryID });
    props.notifyDataUpdate({ type: 'rotation-history-update', data: {} });
    //props.notify('Rotation History Uploaded', 'success');
    close();
    props.hideSpinner();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Edit Rotation History" actions={renderActions()}>
      <>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={12}>
            <TextField
              className={classes.textFiled}
              label="Event"
              variant="outlined"
              value={values.EventID}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textFiled}
              label="Unit"
              variant="outlined"
              value={values.Unit}
              disabled
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Rotation"
                variant="outlined"
                value={values.Rotation}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Action"
                variant="outlined"
                value={values.ACTION}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Division"
                variant="outlined"
                value={values.Division}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Zone"
                variant="outlined"
                value={values.Zone}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Contact Type"
                variant="outlined"
                value={values.ContactType}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Contact Name"
                variant="outlined"
                value={values.ContactName}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Contact Info"
                variant="outlined"
                value={values.ContactInfo}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Call Type"
                variant="outlined"
                value={values.CallType}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Received"
                variant="outlined"
                value={values.Received}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Queued"
                variant="outlined"
                value={values.Queued}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Dispatched"
                variant="outlined"
                value={values.Dispatched}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Enroute"
                variant="outlined"
                value={values.Enroute}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Arrived"
                variant="outlined"
                value={values.Arrived}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Completed"
                variant="outlined"
                value={values.Completed}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Under Cont:"
                variant="outlined"
                value={values.UnderCont}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={classes.textFiled}
                label="Cancelled"
                variant="outlined"
                value={values.Cancelled}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textFiled}
              multiline
              rows={4}
              label="Action Reason"
              variant="outlined"
              value={values.ActionReason}
              onChange={(e) =>
                setValues((prevState) => ({ ...prevState, ['ActionReason']: e.target.value }))
              }
            />
          </Grid>
        </Grid>
      </>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeRotationHistory,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
  notify,
})(EditRotationHistory);
