import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { XGrid } from '@material-ui/x-grid';
import { displayDateTime } from 'reducers/TimeReducer';
import { findVehicleTowing } from 'reducers/VehicleReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import { showTowingDialog } from 'reducers/DialogsReducer';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  {
    field: 'IsTowed',
    headerName: 'Towed',
    width: 100,
    format: 'bool',
    renderCell: bool,
  },
  { field: 'PlaceID', headerName: 'Place ID', width: 150 },
  { field: 'PlaceName', headerName: 'Tower Reference', width: 150 },
  { field: 'PersonID', headerName: 'Person ID', width: 150 },
  { field: 'FullName', headerName: 'Driver', width: 150 },
  // {
  //   field: 'Hold',
  //   renderCell: bool,
  //   format: 'bool',
  // },
  { field: 'HoldBy', headerName: 'Hold By', width: 150 },
  {
    field: 'HoldDate',
    headerName: 'Hold Date',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  // {
  //   field: 'Released',
  //   headerName: 'Released',
  //   renderCell: bool,
  //   format: 'bool',
  // },
  {
    field: 'ReleasedDate',
    headerName: 'Released Date',
    width: 150,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },

  { field: 'ReleasedBy', headerName: 'Released By', width: 150 },
  { field: 'ReasonTowed', headerName: 'Reason Towed', width: 150 },
  { field: 'VIN', headerName: 'VIN', width: 150 },
  {
    field: 'TowingDate',
    headerName: 'TowingDate',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
];

function Towing(props) {
  const classes = useStyles();
  const { ptsVehicleID, towing, dataUpdate } = props;
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => (mountedRef.current = false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [towing]);

  useEffect(() => {
    if (!dataUpdate) return;
    if (dataUpdate.type === 'towing') getData();
    // eslint-disable-next-line
  }, [dataUpdate]);

  const getData = async () => {
    setLoaded(false);
    try {
      const data = await findVehicleTowing(ptsVehicleID);
      if (!mountedRef.current) return;
      setData(data.map((t) => ({ ...t, id: t.ptsTowingID })));
    } catch (err) {
      props.handleError(err, 'Error getting towing information');
    }
    setLoaded(true);
  };

  const editTowing = () => {
    const item = data.find((t) => t.ptsTowingID === selection);
    if (!item) return;
    const { ptsTowingID, ptsPlaceID, ptsEventID } = item;
    props.showTowingDialog({ ptsVehicleID, ptsTowingID, ptsPlaceID, ptsEventID });
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.actions}>
        <Tooltip title="Add Towing">
          <Fab
            size="small"
            color="secondary"
            onClick={() => props.showTowingDialog({ ptsVehicleID })}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <Tooltip title="Edit Towing">
            <Fab size="small" color="secondary" onClick={editTowing}>
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={!loaded}
          rowHeight={38}
          disableMultipleSelection={true}
          showToolbar
          disableColumnFilter
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection.selectionModel[0]);
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    towing: state.dialogs.towing,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  showTowingDialog,
})(Towing);
