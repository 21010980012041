import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 20,
  },
}));

function ExampleTesting(props) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <h1>Hello from example testing window</h1>
    </div>
  );
}

export default ExampleTesting;
