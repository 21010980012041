import { Button, Typography } from '@material-ui/core';
import Dialog from 'components/Dialog';
import React from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { closePlaceAddress } from 'reducers/DialogsReducer';
import { savePlaceAddress } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { handleError } from 'reducers/ErrorReducer';
const AddPlaceAddress = (props) => {
  const { data, closePlaceAddress } = props;

  const save = (IsPrimary = false) => {
    data.IsPrimary = IsPrimary;
    savePlaceAddress(data)
      .then(() => {
        props.notifyDataUpdate({ type: 'place-address', data: {} });
        close();
      })
      .catch(props.handleError);
  };
  const close = () => {
    closePlaceAddress();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={() => save(true)}>
          <SaveIcon /> Yes and Continue
        </Button>
        <Button color="primary" onClick={() => save()}>
          <CloseIcon /> No and Continue
        </Button>
      </>
    );
  };
  return (
    <Dialog toolbar onClose={close} title="Confirmation" actions={renderActions()}>
      <Typography variant="body1"> Do you want make this address primary?</Typography>
    </Dialog>
  );
};

export default connect(null, { closePlaceAddress, notifyDataUpdate, handleError })(AddPlaceAddress);
