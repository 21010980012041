import React from 'react';
import ReactDOM from 'react-dom';
import store from './config/configureStore';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import packageJson from '../package.json';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: packageJson.version,
    tracesSampler: context => {
      if (context.exception) return 1;
      return 0; // block all but exceptions
    },
    // beforeBreadcrumb(breadcrumb, hint) {
    //   if (breadcrumb.category === 'ui.click') {
    //     const target = hint.event.target;
    //     console.log('target', target);
    //     if (target.ariaLabel) breadcrumb.message = target.ariaLabel;
    //   }
    //   return breadcrumb;
    // }
  });
}

ReactDOM.render(
  <Provider store={store.store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
