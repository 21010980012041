import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import { closeTowingMail } from 'reducers/DialogsReducer';
import {
  updateTemplates,
  updateGroups,
  updateRecipients,
  updateGroupsRecipients,
} from 'reducers/PagingReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { getFormStyle, validateEmail } from 'utils/functions';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getVehicleTowing } from 'reducers/VehicleReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { getDefaultContact } from 'reducers/SearchReducer';
import { getService } from 'reducers/service';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: `calc(50% - ${theme.spacing(1)}px)`,
    },
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& h4': {
      margin: '0.5em 0',
    },
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  row: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
}));

function TowingMail(props) {
  const classes = useStyles();
  const { data } = props;
  const { ptsTowingID } = data;

  const [subject, setSubject] = useState('Towing Details');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    getTowingDetails();
  }, []);

  const getTowingDetails = async () => {
    if (!ptsTowingID) return;
    props.showSpinner();
    try {
      const data = await getVehicleTowing(ptsTowingID);
      generatingMessage(data);
      await getDefaultMail(data.ptsPlaceID);
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  };

  const getDefaultMail = async (ptsPlaceID) => {
    if (!ptsPlaceID) return;
    const result = await getDefaultContact(ptsPlaceID);
    const { data } = result;

    if (data && data.length && data[0].Info) setEmail(data[0].Info);
  };

  const generatingMessage = (data) => {
    const {
      HoldDate,
      HoldBy,
      ReleasedDate,
      ReleasedBy,
      PlateNumber,
      VIN,
      Make,
      Model,
      Year,
      Color,
      ReasonTowed,
      Comments,
      TowCompany,
    } = data;
    let message = '';
    if (!HoldBy || !HoldDate) message += 'Towed Vehicle\n';
    else if (ReleasedBy || ReleasedDate) message += 'Tow Vehicle-RELEASE\n';
    else if ((HoldBy || HoldDate) && (!ReleasedBy || !ReleasedDate))
      message += 'Tow Vehicle-HOLD\n';

    //else message += 'Tow Vehicle-HOLD\n';
    message += `LPN: ${PlateNumber || ''} VIN: ${VIN || ''} Make: ${Make || ''} Model: ${
      Model || ''
    } Year: ${Year || ''} Color: ${Color || ''}\n`;
    message += `Tow Company: ${TowCompany || ''}\n`;
    message += `Reason Towed: ${ReasonTowed || ''}\n`;
    message += `HoldDate: ${displayDateTime(HoldDate)} HoldBy: ${HoldBy || ''}\n`;
    message += `ReleasedDate: ${displayDateTime(ReleasedDate)} ReleasedBy: ${ReleasedBy || ''}\n`;
    message += `Comments: ${Comments || ''}`;
    setMessage(message);
  };

  const clearPage = () => {
    setMessage('');
    setSubject('');
    setEmail('');
  };

  const close = () => {
    clearPage();
    props.closeTowingMail();
  };

  const send = async () => {
    const data = {
      message,
      subject,
      email,
    };
    props.showSpinner();
    const service = getService('mail-towing-to-company');

    service
      .create(data)
      .then(() => {
        close();
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const handleSubjectChange = (ev) => {
    const val = ev.target.value;
    if (val.length > 100) return;
    setSubject(val);
  };

  const handleMessageChange = (ev) => {
    const val = ev.target.value;
    setMessage(val);
  };

  const renderForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={classes.w100pr}
          label="Default Email"
          size="small"
          variant={'outlined'}
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          error={email && !validateEmail(email)}
          helperText={
            email ? (validateEmail(email) ? '' : 'Please enter a valid email address') : ''
          }
          value={email}
        />
        <TextField
          className={classes.w100pr}
          label="Subject"
          value={subject}
          onChange={handleSubjectChange}
          variant="outlined"
          size="small"
        />
        <TextField
          //disabled
          className={classes.w100pr}
          label="Message"
          value={message}
          onChange={handleMessageChange}
          variant="outlined"
          size="small"
          multiline
          rows={10}
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={send}
          disabled={!email || !validateEmail(email)}>
          <SendIcon /> Send
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Cancel
        </Button>
      </>
    );
  };

  return (
    <Dialog title={'Send Towing Info'} actions={renderActions()} onClose={close}>
      <div className={classes.wrap}>{renderForm()}</div>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    paging: state.paging,
  };
};

export default connect(mapStateToProps, {
  closeTowingMail,
  updateTemplates,
  updateGroups,
  updateRecipients,
  updateGroupsRecipients,
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
})(TowingMail);
