import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { getFormat24 } from 'reducers/TimeReducer';

function DatePicker2(props) {
  const format24 = getFormat24();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        margin="normal"
        autoOk
        size="small"
        ampm={!format24}
        {...props}
        onChange={(date) => props.onChange(null, date)}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker2;
