import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CancelIcon from '@material-ui/icons/Cancel';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { eventStatusChange } from 'reducers/EventsReducer';
import { showSops } from 'reducers/DialogsReducer';
import globals from 'components/globals';
import settings from 'config/settings';
import { getFullPermissions } from 'reducers/PermissionsReducer';
const useStyles = makeStyles((theme) => ({
  menu: {
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  menuIcon: {
    margin: '0 10px 0 -10px',
  },
  menuRight: {
    position: 'relative',
    '& > div': {
      width: 10,
      height: 10,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
}));

function EventStatusMenu(props) {
  const classes = useStyles();
  const { event, open, onClose, anchorEl, closeMenu, reqDispositions } = props;
  const preventRenderingRef = useRef(false);
  const menuTimeout = useRef(0);
  const permissions = getFullPermissions('cad', 'Events', 'any');
  useEffect(() => {
    preventRenderingOn();
    delayedClose();
    return () => {
      preventRenderingOff();
      clearTimeout(menuTimeout.current);
    };
    // eslint-disable-next-line
  }, []);

  const delayedClose = () => {
    clearTimeout(menuTimeout.current);
    menuTimeout.current = setTimeout(() => {
      closeMenu();
    }, settings.showMenuTime);
  };

  const preventRenderingOn = () => {
    preventRenderingRef.current = true;
    globals.menuOpen = true;
  };

  const preventRenderingOff = () => {
    if (!preventRenderingRef.current) return;
    preventRenderingRef.current = false;
    globals.menuOpen = false;
  };

  const setEventStatus = (status) => {
    props.eventStatusChange(event.ptsEventID, status);
    closeMenu();
  };

  const closeEvent = () => {
    const reqDispMet =
      reqDispositions.reqDispAgencies.length === 0 && reqDispositions.reqMergedDisp.length === 0;
    if (reqDispMet) {
      //if (event.sops.length) props.showSops({ ptsEventID: event.ptsEventID, fromEventClose: true });
      props.eventStatusChange(event.ptsEventID, 'Closed');
    } else {
      props.dispNotMet();
    }
    closeMenu();
  };

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose} onMouseMove={delayedClose}>
      <MenuItem onClick={() => setEventStatus('Pending')} disabled={!permissions.Edit}>
        <CancelIcon className={classes.menuIcon} /> Cancel
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Dispatched')} disabled={!permissions.Edit}>
        <HeadsetMicIcon className={classes.menuIcon} /> Dispatched
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Enroute')} disabled={!permissions.Edit}>
        <DriveEtaIcon className={classes.menuIcon} /> Enroute
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Arrived')} disabled={!permissions.Edit}>
        <EmojiTransportationIcon className={classes.menuIcon} />
        Arrived
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Completed')} disabled={!permissions.Edit}>
        <AssignmentTurnedInIcon className={classes.menuIcon} /> Completed
      </MenuItem>
      <MenuItem onClick={() => setEventStatus('Controlled')} disabled={!permissions.Edit}>
        <ThumbUpIcon className={classes.menuIcon} /> Controlled
      </MenuItem>
      <MenuItem onClick={closeEvent} disabled={!permissions.Edit}>
        <RemoveCircleIcon className={classes.menuIcon} /> Closed
      </MenuItem>
    </Menu>
  );
}

export default connect(null, { eventStatusChange, showSops })(EventStatusMenu);
