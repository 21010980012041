import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditPerson } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { handleError } from 'reducers/ErrorReducer';
import ContactMethods from './ContactMethods';
import Credential from './Credential';
import Employment from './Employment';
import PersonTab from 'Dialogs/AddPersonDialog/PersonTab';
import PersonAssociate from './PersonAssociate';
import Features from './Features';
import Names from './Names';
import PersonVehicle from './PersonVehicle';
import Addresses from './Addresses';
import Sops from './Sops';
import Attachments from 'components/Attachments';
import DnaSamples from './DnaSamples';
import Photo from './Photo';
import RelatedRecords from './RelatedRecords';
import Bulletins from './Bulletins';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(3),
  },
}));

function Person(props) {
  const classes = useStyles();
  const { person } = props;
  const { ptsPersonID } = person;
  const [tab, setTab] = useState('edit');
  const mountedRef = useRef(true);
  const newAddress = !ptsPersonID;
  useEffect(() => {
    if (props.tab) setTab(props.tab);

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [props.tab]);

  const close = (data) => {
    props.closeEditPerson(data);
  };

  return (
    <Dialog toolbar onClose={close} title={newAddress ? 'Add Person' : 'Edit Person'} fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs variant="scrollable" value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Edit Person" value="edit" />
            <Tab label="Contact Methods" value="contactMethods" />
            <Tab label="Credential" value="credential" />
            <Tab label="Employment" value="employment" />
            <Tab label="Associate" value="associate" />
            <Tab label="Features" value="features" />
            <Tab label="Names" value="names" />
            <Tab label="Vehicle Registrations" value="vehicle" />
            <Tab label="Addresses" value="addresses" />
            <Tab label="Sops" value="sops" />
            <Tab label="Attachments" value="attachments" />
            <Tab label="DNA Samples" value="dna" />
            <Tab label="Photo" value="photo" />
            <Tab label="Related Records" value="relatedRecords" />
            <Tab label="Bulletins" value="bulletins" />
            {/* <Tab label="Coordinates" value="coordinates" /> */}
          </Tabs>
        </Paper>
        {tab === 'edit' && (
          <div className={classes.wrap}>
            <PersonTab personId={ptsPersonID} close={close} editingForm={true} />
          </div>
        )}
        {tab === 'employment' && <Employment ptsPersonID={ptsPersonID} />}
        {tab === 'contactMethods' && <ContactMethods personId={ptsPersonID} />}
        {tab === 'credential' && <Credential personId={ptsPersonID} />}
        {tab === 'associate' && <PersonAssociate personId={ptsPersonID} />}
        {tab === 'features' && <Features ptsPersonID={ptsPersonID} />}
        {tab === 'names' && <Names ptsPersonID={ptsPersonID} />}
        {tab === 'vehicle' && <PersonVehicle personId={ptsPersonID} />}
        {tab === 'addresses' && <Addresses ptsPersonID={ptsPersonID} />}
        {tab === 'sops' && <Sops personId={ptsPersonID} />}
        {tab === 'attachments' && <Attachments type="Person" id={ptsPersonID} />}
        {tab === 'dna' && <DnaSamples ptsPersonID={ptsPersonID} />}
        {tab === 'photo' && <Photo ptsPersonID={ptsPersonID} />}
        {tab === 'relatedRecords' && <RelatedRecords ptsPersonID={ptsPersonID} />}
        {tab === 'bulletins' && <Bulletins person={person} />}
        {/* {tab === 'parties' && <AddressParties ptsAddressID={ptsAddressID} />} */}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeEditPerson,
  handleError,
})(Person);
