/**
 * Menu item configurations for desktop and mobile
 */

const menuSettings = [
  {
    label: 'HOME',
    tooltip: 'Home page',
    link: '/home',
    icon: 'headset',
  },
  {
    label: 'EVENTS',
    tooltip: 'Event list',
    link: '/events',
    icon: 'headset',
  },
  {
    label: 'UNITS',
    tooltip: 'Active unit list',
    link: '/units',
    icon: 'headset',
  },
  {
    label: 'MAP',
    tooltip: 'MAP',
    link: '/map',
    icon: 'map-pin',
  },
];

export default menuSettings;
