import React from 'react';
import { IoIosCar } from 'react-icons/io';
import AssistantIcon from '@material-ui/icons/Assistant';
import { makeStyles } from '@material-ui/core/styles';

const avatarSize = 50;
const iconSize = avatarSize / 1.65;
const iconLeft = (avatarSize - iconSize) / 2;
const iconTop = (avatarSize - iconSize) / 2.6;
const borderSize = 2;

const useStyles = makeStyles((theme) => ({
  label: {
    position: 'absolute',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    fontSize: 12,
    top: -9,
    left: '50%',
    color: theme.map.labelColor,
    transform: 'translate(-50%, 0)',
    textShadow: `0 1px 0 ${theme.map.labelBorder},
                 0 -1px 0 ${theme.map.labelBorder},
                 1px 0 0 ${theme.map.labelBorder},
                 -1px 0 0 ${theme.map.labelBorder}`,
  },
  marker: {
    position: 'relative',
  },
  iconBorder: {
    position: 'absolute',
    color: theme.map.labelBorder,
    width: avatarSize + borderSize * 2,
    height: avatarSize + borderSize * 2,
    top: -borderSize,
    left: -borderSize,
  },
  selected: {
    position: 'absolute',
    color: theme.palette.secondary.main,
    width: avatarSize + borderSize * 4,
    height: avatarSize + borderSize * 4,
    top: -borderSize * 2,
    left: -borderSize * 2,
  },
  icon: {
    position: 'absolute',
    width: iconSize,
    height: iconSize,
    top: iconTop,
    left: iconLeft,
    textAlign: 'center',
    fontSize: iconSize,
    fontWeight: 600,
    '& > span': {
      position: 'absolute',
      inset: 0,
      display: 'block',
    },
    '& > div': {
      color: '#000',
      position: 'absolute',
      inset: 0,
      borderRadius: '15%',
      background: 'rgba(255, 255, 255, 0.75)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

function UnitMarker(props) {
  const classes = useStyles();
  const { colors, unit, selected, onUnitClick = () => {} } = props;

  const onMouseDown = (ev) => {
    // ev.preventDefault();
    // ev.stopPropagation();
    // return false;
  };

  return (
    <div onMouseDown={onMouseDown} onClick={(ev) => onUnitClick(ev, unit)}>
      <div className={classes.label}>{unit.Unit}</div>
      <div className={classes.marker}>
        <AssistantIcon className={selected ? classes.selected : classes.iconBorder} />
        <AssistantIcon
          style={{ color: colors[unit.UnitStatus], fontSize: avatarSize, position: 'relative' }}
        />
        <div className={classes.icon}>
          <span style={{ backgroundColor: colors[unit.UnitStatus] }} />
          <div>
            <IoIosCar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnitMarker;
