import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import { formatSaveDate } from 'reducers/TimeReducer';

/**
 * Set of functions to hanle form errors , changed - set to true if it should show
 * errors even if the form didn't change (by default it will not show errors on unchnaged form)*/
export const useError = (errObj, setErrObj, changed = false) => {
  const getErrors = () => {
    if (!errObj) return { errors: {}, changed };
    return errObj;
  };

  const setErrors = (errors) => {
    let obj;
    if (!errObj) {
      obj = { errors, changed };
    } else {
      obj = { ...errObj, errors };
    }
    setErrObj(obj);
  };

  const setChanged = (changed) => {
    let obj;
    if (!errObj) {
      obj = { errors: {}, changed };
    } else {
      obj = { ...errObj, changed };
    }
    setErrObj(obj);
  };

  /** returns error message and set global errors */
  const err = (condition, name, msg = 'This field is required') => {
    const { errors, changed } = getErrors();
    const error = changed && !Boolean(condition) ? msg : null;
    if (error) {
      if (errors[name] !== msg) {
        setErrors({ ...errors, [name]: msg });
      }
    } else {
      if (errors[name]) {
        const allErrors = { ...errors };
        delete allErrors[name];
        setErrors(allErrors);
      }
    }
    return error;
  };

  /** Clears error with given name from global errors [String||Array] */
  const delErr = (names) => {
    const { errors } = getErrors();
    const newErrors = { ...errors };
    if (Array.isArray(names)) {
      names.forEach((name) => {
        delete newErrors[name];
      });
    } else {
      delete newErrors[names];
    }
    setErrors(newErrors);
  };

  /** Clear global errors and sets changed to false */
  const clearErr = () => {
    setErrObj(null);
  };

  const formChanged = () => {
    const { changed } = getErrors();
    if (changed) return;
    setChanged(true);
  };

  const isValidAndChanged = () => {
    const { errors, changed } = getErrors();
    return changed && Object.entries(errors).length === 0;
  };

  const isValid = () => {
    const { errors } = getErrors();
    return Object.entries(errors).length === 0;
  };

  return { err, delErr, clearErr, isValid, formChanged, isValidAndChanged };
};

const useStyles = makeStyles((theme) => ({
  col: {
    padding: '16px 16px 0',
  },
  row: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    '& .MuiFormHelperText-root': errStyle,
  },
  innerRow: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    alignItems: 'flex-start',
    '& .MuiFormHelperText-root': errStyle,
  },
}));

export const codeOrNull = (obj) => (obj ? obj.Code : null);

export const strOrNull = (str) => (str ? str : null);

export const prepOutputVal = (val, sequelize) => {
  if (val === '' || val === null || val === undefined) return null;
  if (typeof val === 'string') return val;
  if (typeof val === 'object' && val.Code) return val.Code;
  if (typeof val === 'object' && typeof val.getMonth === 'function') {
    return formatSaveDate(val);
  }
  if (typeof val === 'object' && val._isAMomentObject) {
    if (sequelize) return val;
    return formatSaveDate(val);
  }
  if (typeof val === 'boolean') {
    return val ? 1 : 0;
  }
  if (typeof val === 'number') {
    return val;
  }
  return val;
};

export const formatSaveData = (obj, sequelize = false) => {
  const out = {};
  Object.entries(obj).forEach(([k, v]) => {
    out[k] = prepOutputVal(v, sequelize);
  });
  return out;
};

export const isEmptySaveData = (obj) => {
  return Object.values(obj).reduce((res, val) => (val ? false : res), true);
};

export const validatePostcode = (Postal) => /(^\d{5}$)|(^\d{5} - \d{4}$)/.test(Postal);

export const validatePhoneNo = (phoneNo) =>
  /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/im.test(
    phoneNo
  );

export const validateEmail = (email) =>
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/im.test(
    email
  );

export const validateVin = (vin) =>
  /^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dX][A-HJ-NPR-Za-hj-npr-z\d]{2}\d{6}$/.test(vin);

const getPostalCode = (Postal) => {
  if (Postal.length !== 5 && Postal.length !== 12) return null;
  const pc = Postal.substr(0, 5);
  const reg = /^\d+$/;
  if (pc.match(reg)) return pc;
  return null;
};

const getPostalCodeExt = (Postal) => {
  if (Postal.length !== 12) return null;
  const ext = Postal.substr(8, 4);
  const reg = /^\d+$/;
  if (ext.match(reg)) return ext;
  return null;
};

export function parsePostcode(Postal) {
  const PostalCode = getPostalCode(Postal);
  const PostalCodeExtension = getPostalCodeExt(Postal);
  return {
    PostalCode,
    PostalCodeExtension,
  };
}

export function Row(props) {
  const classes = useStyles();
  const { className, children } = props;
  return (
    <div {...props} className={clsx(classes.row, className)}>
      {children}
    </div>
  );
}

export function RowInner(props) {
  const classes = useStyles();
  const { className, children } = props;
  return (
    <div {...props} className={clsx(classes.innerRow, className)}>
      {children}
    </div>
  );
}

export function ColCard(props) {
  const classes = useStyles();
  const { className, children, minWidth = 1, maxWidth = '100%' } = props;
  return (
    <Card
      className={clsx(classes.col, className)}
      variant="outlined"
      style={gridStyle(minWidth, maxWidth)}>
      {children}
    </Card>
  );
}

export function Fills(props) {
  const { className, no = 5 } = props;
  const arr = Array.from(Array(no).keys());
  return (
    <>
      {arr.map((n) => (
        <div className={className} key={n} style={{ marginTop: 0, marginBottom: 0 }} />
      ))}
    </>
  );
}

export function decodePhoneNo(Info) {
  let PhoneNumber = null;
  let AreaCode = null;
  let Extension = null;
  const len = Info.length;
  if (len > 20) {
    Extension = Info.substr(19);
  }
  if (len > 12) {
    const NumberStr = Info.substr(0, 19).replace(/\D/g, '');
    AreaCode = parseInt(NumberStr.substr(0, 3));
    PhoneNumber = parseInt(NumberStr.substr(3));
  }
  return { PhoneNumber, AreaCode, Extension };
}

export const fieldMargin = '0 4px 8px';
export const errStyle = {
  margin: '2px 0 0 4px',
  lineHeight: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
export default {
  // form row
  row: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    '& .MuiFormHelperText-root': errStyle,
  },
  rowSpaces: {
    margin: `0 -4px`,
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 8px)',
    justifyContent: 'space-between',
    '& .MuiFormHelperText-root': errStyle,
  },
  // 100% width
  w100pr: {
    width: '100%',
    margin: fieldMargin,
  },
  // 50% width
  w50pr: gridStyle(50, '50%'),
  autocomplete: {
    margin: '0 4px 8px',
  },
  btn: {
    margin: '2px 4px 8px',
  },
  option: {
    fontSize: 14,
  },
  dialogActions: {
    marginTop: 8,
    textAlign: 'right',
    '& svg': {
      marginRight: 6,
    },
    '& button': {
      marginLeft: 8,
    },
  },
};

/* Used in styling flex forms */
export function gridStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: fieldMargin,
  };
}
