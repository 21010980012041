import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from 'utils/functions';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from 'components/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { addEventRouting, newCaseID } from 'reducers/EventsReducer';
import settings from 'config/settings';
import Dictionary from 'components/Dictionary';
import TextField2 from 'components/TextField2';
import formStyles, { gridStyle, Row } from 'utils/formStyles';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import AgencyRoutingConfirmation from 'Dialogs/AgencyRoutingConfirmation';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const statusWidth = 100;

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(200, 800),
  cases: {
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  case: {
    padding: '5px 15px 5px',
    '& p': {
      fontSize: 13,
    },
    borderBottom: `1px solid ${theme.card.hr}`,
  },
  edit: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  date: {
    maxWidth: 150,
    display: 'inline-block',
  },
  statusTd: {
    flex: `0 0 ${statusWidth}px`,
    width: statusWidth,
    boxSizing: 'border-box',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    textAlign: 'center',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      background: theme.card.bg1,
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
    },
  },
}));

function Case(props) {
  const classes = useStyles();
  const { AgencyId, CaseId } = props.singleCase;

  return (
    <div className={classes.case}>
      <div className={classes.header}>
        <div className={classes.left}>
          <h4>{CaseId}</h4>
        </div>
        <div>
          <span className={classes.date}>{AgencyId}</span>
        </div>
      </div>
    </div>
  );
}

function CaseIDTab(props) {
  const { ColorScheme, sortOption, disableEdit, eventRouting } = props;
  const [cases, setCases] = useState([]);
  const [edited, setEdited] = useState(true);
  const [selectedCase, setSelectedCase] = useState(null);
  const [CaseID, setCaseID] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const permissions = getFullPermissions('cad', 'Events', 'any');
  useEffect(() => {
    if (!props.cases) return;
    setCases(sortObjArr(props.cases, 'CaseId', sortOption));
    // eslint-disable-next-line
  }, [props.cases, sortOption]);

  useEffect(() => {
    setCaseID('');
    if (!selectedCase) return;
    const { AgencyID, MultipleCaseID } = selectedCase;
    const agencyCaseExists = Boolean(cases.find((c) => c.AgencyId === AgencyID));
    const saveDisabled = agencyCaseExists && !MultipleCaseID;
    setSaveDisabled(saveDisabled);
    // eslint-disable-next-line
  }, [selectedCase]);
  const onSave = () => {
    const AgencyID = selectedCase.AgencyID;
    if (props.cases && props.cases.find((c) => c.AgencyId === AgencyID)) {
      if (
        !window.confirm(
          'Case ID for this agency already exists. Are you sure you want to create a new one?'
        )
      )
        return;
    }
    props.newCaseID(props.ptsEventID, selectedCase.AgencyID, CaseID);

    const isAgencyRouted =
      eventRouting.length && eventRouting.some((er) => er.AgencyId === AgencyID);

    if (!isAgencyRouted) setOpen(true);
    else setSelectedCase(null);
    setEdited(true);
    setCaseID('');
  };
  const onConfirmation = () => {
    const AgencyID = selectedCase.AgencyID;
    addEventRouting(AgencyID, props.ptsEventID)
      .then(() => {
        props.notifyDataUpdate({ type: 'event', data: props.ptsEventID });
      })
      .catch((err) => props.handleError(err, `Error, routing ${AgencyID} not created`));
    setSelectedCase(null);
    onClose();
  };
  const onClose = () => setOpen(false);

  const onDiscard = () => {
    setSelectedCase(null);
    setEdited(false);
  };

  const onAddCaseID = () => {
    setEdited(true);
  };

  const renderAddCaseIds = () => {
    return (
      <div className={classes.edit}>
        <Row>
          <Dictionary
            className={classes.item}
            options="Agencies"
            onChange={(ev, val) => setSelectedCase(val)}
            value={selectedCase}
            label="Agency"
            compact="true"
            //disabled={!permissions.Edit}
          />
          <TextField2
            label="Custom Case ID"
            className={classes.item}
            value={CaseID}
            onChange={(ev, val) => setCaseID(val)}
            compact="true"
            disabled={!selectedCase?.UserCanEnterCaseID}
          />
        </Row>
      </div>
    );
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!edited && (
          <Tooltip title="Add dispositions">
            <IconButton
              color="primary"
              size="small"
              onClick={onAddCaseID}
              disabled={!permissions.Edit}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
        {edited && selectedCase !== null && (
          <Tooltip title="Save changes">
            <span>
              <IconButton
                color="primary"
                size="small"
                onClick={onSave}
                disabled={saveDisabled || !permissions.Edit}>
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {edited && selectedCase === null && (
          <IconButton color="primary" size="small" disabled>
            <SaveIcon />
          </IconButton>
        )}

        {edited && (
          <Tooltip title="Discard changes">
            <IconButton color="primary" size="small" onClick={onDiscard}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };
  const renderCaseIds = () => {
    return (
      <>
        {cases.map((singleCase) => (
          <Case key={singleCase.CaseId} singleCase={singleCase} ColorScheme={ColorScheme} />
        ))}
      </>
    );
  };

  if (!cases) return '';
  return (
    <div className={classes.cases}>
      {!disableEdit && renderActions()}
      {edited && !disableEdit && renderAddCaseIds()}
      {renderCaseIds()}
      <AgencyRoutingConfirmation open={open} close={onClose} confirmation={onConfirmation} />
    </div>
  );
}

export default connect(null, { newCaseID, handleError, notifyDataUpdate })(CaseIDTab);
