import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import DisplayData from './DisplayData';
import PoliceIco from './PoliceIco';
import FiremanIco from './FiremanIco';

const useStyles = makeStyles((theme) => ({
  eventCard: {
    marginBottom: 5,
    boxSizing: 'border-box',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '1px solid #a2a9d7',
    background: '#fff',
    '&:hover': {
      background: '#f4f5fd',
    },
  },
  evCardHead: {
    textAlign: 'center',
    padding: '8px 2px 5px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    color: theme.palette.text.primary,
    '& > div': {
      padding: '0 5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:nth-child(2)': {
        direction: 'rtl',
        textAlign: 'left',
      },
    },
  },
  avatar: {
    flex: '0 0 64px',
    height: 66,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: '#dcdfed',
      borderRadius: '50%',
      width: 44,
      height: 44,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: -5,
  },
  details: {
    color: '#263055',
    overflow: 'hidden',
    marginRight: 5,
    '& h5': {
      fontSize: 15,
      marginBottom: 2,
      whiteSpace: 'nowrap',
      direction: 'rtl',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& p': {
      marginBottom: 0,
      fontSize: 12,
      lineHeight: 1.2,
      opacity: 0.75,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
    },
  },
  evBottom: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0 5px 1px',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      color: '#fff',
      fontSize: 11,
    },
  },
  dispatch: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  department: {
    boxSizing: 'border-box',
    background: '#fff',
    marginRight: 2,
    display: 'block',
    width: 28,
    height: 28,
    padding: 2,
    borderRadius: '50%',
    '& svg': {
      marginBottom: 2,
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    padding: 6,
    borderRadius: 5,
    backgroundColor: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #666',
    color: '#333',
    fontWeight: 400,
    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
    '& b': {
      fontWeight: 600,
    },
    '& ht': {
      background: 'red !important',
    },
  },
}))(Tooltip);

function EventList(props) {
  const classes = useStyles();
  const { statusColors, eventTypes, unitStatusColors } = props.config;
  const { data, activeEventIds, handleUnitClick } = props;

  return (
    <>
      {data.map((ev) => {
        const { EventId, Status, EventType, RespondingLocation, units } = ev;
        const bgColor = statusColors[Status].bgColor;
        const active = activeEventIds.indexOf(EventId) !== -1;
        const cardStyle = active
          ? { border: `3px solid ${bgColor}` }
          : { borderBottom: `3px solid ${bgColor}` };
        return (
          <Card
            className={classes.eventCard}
            key={EventId}
            style={cardStyle}
            onClick={(event) => handleUnitClick(event, ev)}>
            <div className={classes.content}>
              <div className={classes.avatar} title={EventType}>
                <div style={{ backgroundImage: `url(${eventTypes[EventType].icon})` }}></div>
              </div>
              <div className={classes.details}>
                <h5>{EventId}</h5>
                <p>{RespondingLocation}</p>
              </div>
            </div>
            <div className={classes.evBottom}>
              <div className={classes.status}>
                <span style={{ background: bgColor }}>{Status}</span>
              </div>
              <div className={classes.dispatch}>
                {units.map((unit, idx) => {
                  const { AgencyId, UnitStatus } = unit;
                  const unitData = {};
                  Object.entries(unit).forEach(([key, value]) => {
                    switch (value) {
                      case false:
                        unitData[key] = 'NO';
                        break;
                      case true:
                        unitData[key] = 'YES';
                        break;
                      case '':
                        break;
                      case null:
                        break;
                      default:
                        unitData[key] = value;
                    }
                  });

                  const Icon = AgencyId === 'POLICEDEP' ? PoliceIco : FiremanIco;
                  const bgColor = unitStatusColors[UnitStatus].bgColor;
                  return (
                    <span className={classes.department} key={idx} style={{ fill: bgColor }}>
                      <HtmlTooltip title={<DisplayData variant="V" data={unitData} />}>
                        <span>
                          <Icon />
                        </span>
                      </HtmlTooltip>
                    </span>
                  );
                })}
              </div>
            </div>
          </Card>
        );
      })}
    </>
  );
}

export default EventList;
