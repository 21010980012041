import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { setUnitStatus } from 'reducers/UnitStatusReducer';

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 14,
    '& > div:first-child': {
      paddingRight: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > div:last-child': {
      minWidth: 90,
    },
  },
}));


function DispatchMenu(props) {
  const classes = useStyles();
  const { open, anchorEl, onClose, events, colorPalette, unit, status } = props;
  const EventColors = colorPalette.Events;
  const [eventList, setEventList] = useState([]);
  
  useEffect(() => {
    const eventList = events.map((ev) => processEvent(ev));
    const filteredEventList = filterList(eventList);
    setEventList(filteredEventList);
  }, [events]);

  const filterList = (eventList) => {
    const list = [];

    eventList.forEach(ev => {
      const { assignedUnits } = ev;

      if (assignedUnits.some(curUnit => curUnit.ptsUnitID === unit.ptsUnitID)) 
        list.push(ev);
    });

    return list;
  }

  const processEvent = (event) => {
    const { ptsEventID, EventID, Status, CallType, FullLocationAddress, assignedUnits } = event;
    return { ptsEventID, EventID, Status, CallType, FullLocationAddress, assignedUnits };
  };

  const handleEventClick = (event) => {
    if(!status) return;
    props.setUnitStatus(status, unit.ptsUnitID, event.ptsEventID);
    onClose();
  }

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      {eventList.map(option => 
        <MenuItem key={option.ptsEventID} onClick={() => handleEventClick(option)}>
          <div className={classes.option}>
            <div>
              <b>
                {option.CallType} - {option.EventID}
              </b>{' '}
              - {option.FullLocationAddress}
            </div>
            <div className="badge" style={{ background: EventColors[option.Status] }}>
              {option.Status}
            </div>
          </div>
        </MenuItem>  
      )}
    </Menu>
  );
}
 

const mapStateToProps = (state) => {
  return {
    events: state.events,
    colorPalette: state.config.colorPalette,
  };
};

export default connect(mapStateToProps, {
  setUnitStatus,
})(DispatchMenu);