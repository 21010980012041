import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePersonNameDialog } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField2 from 'components/TextField2';
import Dictionary from 'components/Dictionary';
import formStyles, { gridStyle, Row, Fills, useError, formatSaveData } from 'utils/formStyles';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { createPersonName } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  content: {
    width: 700,
    maxWidth: '100%',
  },
  fullName: {
    justifyContent: 'space-between',
  },
  item: gridStyle(200, '100%'),
  nameType: gridStyle(150, 220),
}));

const emptyFormData = {
  FirstName: null,
  MiddleName: null,
  LastName: null,
  Prefix: null,
  Suffix: null,
  NickName: null,
  FullName: null,
  Notes: null,
  NameType: null,
  IsDefault: false,
  IsActive: true,
};

function PersonNameDialog(props) {
  const classes = useStyles();
  const { data } = props;
  const { ptsPersonID } = data;
  const [formData, setFormData] = useState(emptyFormData);
  const [errors, setErrors] = useState();
  const title = data.ptsNameID ? 'Edit Person Name' : 'Add Person Name';
  const { err, isValid } = useError(errors, setErrors, true);

  const close = () => {
    props.closePersonNameDialog();
  };

  const save = () => {
    const saveData = formatSaveData(formData);
    saveData.FullName = getFullName();
    saveData.ptsPersonID = ptsPersonID;
    props.showSpinner();
    createPersonName(saveData)
      .then(() => {
        props.notifyDataUpdate({ type: 'person-name' });
        props.closePersonNameDialog();
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!isValid()}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const getFullName = () => {
    const { FirstName, LastName, MiddleName, Prefix, Suffix } = formData;
    let FullName = Prefix ? Prefix.Code + ' ' : '';
    FullName += FirstName ? FirstName + ' ' : '';
    FullName += MiddleName ? MiddleName + ' ' : '';
    FullName += LastName ? LastName + ' ' : '';
    FullName += Suffix ? Suffix.Code : '';
    return FullName.trim();
  };

  const renderFullName = () => {
    return <h5 className={classes.item}>{getFullName()}</h5>;
  };

  const onChange = (fieldName) => (ev, val) => {
    setFormData((prevState) => ({ ...prevState, [fieldName]: val }));
  };

  const renderNameType = () => {
    const error = err(formData.NameType, 'NameType');
    return (
      <Dictionary
        options="NameTypes"
        className={classes.nameType}
        onChange={onChange('NameType')}
        value={formData.NameType}
        label="Name Type"
        error={error}
        compact="true"
      />
    );
  };

  const renderPrefix = () => {
    return (
      <Dictionary
        options="NamePrefixes"
        className={classes.item}
        onChange={onChange('Prefix')}
        value={formData.Prefix}
        label="Prefix"
        compact="true"
      />
    );
  };

  const renderFirstName = () => {
    return (
      <TextField2
        className={classes.item}
        label="First Name"
        value={formData.FirstName}
        onChange={onChange('FirstName')}
        max={100}
        compact="true"
      />
    );
  };

  const renderMiddleName = () => {
    return (
      <TextField2
        className={classes.item}
        label="Middle Name"
        value={formData.MiddleName}
        onChange={onChange('MiddleName')}
        max={50}
        compact="true"
      />
    );
  };

  const renderLastName = () => {
    const error = err(formData.LastName, 'LastName');
    return (
      <TextField2
        className={classes.item}
        label="Last Name"
        value={formData.LastName}
        onChange={onChange('LastName')}
        error={error}
        compact="true"
        max={100}
      />
    );
  };

  const renderSuffix = () => {
    return (
      <Dictionary
        options="NameSuffixes"
        className={classes.item}
        onChange={onChange('Suffix')}
        value={formData.Suffix}
        label="Suffix"
        compact="true"
      />
    );
  };

  const renderNickName = () => {
    return (
      <TextField2
        className={classes.item}
        label="Nick Name"
        value={formData.NickName}
        onChange={onChange('NickName')}
        max={20}
        compact="true"
      />
    );
  };

  const renderNotes = () => {
    return (
      <TextField2
        className={classes.w100pr}
        label="Notes"
        value={formData.Notes}
        onChange={onChange('Notes')}
        max={2000}
        multiline
        rows={5}
        compact="true"
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title={title} actions={renderActions()}>
      <div className={classes.content}>
        <Row className={classes.fullName}>
          {renderFullName()}
          {renderNameType()}
        </Row>
        <Row>
          {renderPrefix()}
          {renderFirstName()}
          {renderMiddleName()}
          {renderLastName()}
          {renderSuffix()}
          {renderNickName()}
          <Fills className={classes.item} />
        </Row>
        <Row>{renderNotes()}</Row>
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closePersonNameDialog,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(PersonNameDialog);
