import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {
  showEditPerson,
  showReport,
  showAddPerson,
  showAddAttachment,
} from 'reducers/DialogsReducer';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { getAddressPerson, removeAddressPerson } from 'reducers/AddressReducer';
import DeleteIcon from '@material-ui/icons/Delete';
import { displayDate } from 'reducers/TimeReducer';
import CheckIcon from '@material-ui/icons/Check';
import PersonLookup2 from 'components/PersonLookup2';
import { Button } from '@material-ui/core';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { notify } from 'reducers/NotifierReducer';
import { getService } from 'reducers/service';
import Spinner from 'components/Spinner';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginBottom: 10,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);
const defaultColumns = [
  'PersonID',
  'FullName',
  'FirstName',
  'MiddleName',
  'LastName',
  'Suffix',
  'NickName',
  'Age',
  'Sex',
  'OLN',
  'SSN',
  { field: 'Juvenile', headerName: 'Juvenile', width: 150, hide: false, renderCell: bool },
  'BirthDate',
  { field: 'Deceased', renderCell: bool },
  { field: 'Died', valueFormatter: (params) => displayDate(params.value) },
  { field: 'ActiveWarrant', renderCell: bool },
  { field: 'Incarcerated', renderCell: bool },
  { field: 'Officer', renderCell: bool },
  { field: 'PublicOfficial', renderCell: bool },
  { field: 'ProtectiveOrder', renderCell: bool },
  { field: 'SexOffender', renderCell: bool },
  { field: 'Military', renderCell: bool },
  { field: 'ConcealedWeaponPermit', headerName: 'Concealed Weapon', renderCell: bool },
  { field: 'SeniorCitizen', renderCell: bool },
  { field: 'Dangerous', renderCell: bool },
  { field: 'Gang', renderCell: bool },
  'Ethnicity',
  'EyeColor',
  'HairColor',
  'Race',
  'Height',
  'Weight',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
  'DeletedIP',
];

const noDeletedColumns = [
  'PersonID',
  'FullName',
  'FirstName',
  'MiddleName',
  'LastName',
  'Suffix',
  'NickName',
  'Age',
  'Sex',
  'OLN',
  'SSN',
  { field: 'Juvenile', headerName: 'Juvenile', width: 150, hide: false, renderCell: bool },
  'BirthDate',
  { field: 'Deceased', renderCell: bool },
  { field: 'Died', valueFormatter: (params) => displayDate(params.value) },
  { field: 'ActiveWarrant', renderCell: bool },
  { field: 'Incarcerated', renderCell: bool },
  { field: 'Officer', renderCell: bool },
  { field: 'PublicOfficial', renderCell: bool },
  { field: 'ProtectiveOrder', renderCell: bool },
  { field: 'SexOffender', renderCell: bool },
  { field: 'Military', renderCell: bool },
  { field: 'ConcealedWeaponPermit', headerName: 'Concealed Weapon', renderCell: bool },
  { field: 'SeniorCitizen', renderCell: bool },
  { field: 'Dangerous', renderCell: bool },
  { field: 'Gang', renderCell: bool },
  'Ethnicity',
  'EyeColor',
  'HairColor',
  'Race',
  'Height',
  'Weight',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

function AddressPerson(props) {
  const classes = useStyles();
  const { dataUpdate, ptsAddressID } = props;
  const permissions = getFullPermissions('globals', 'Places', 'any');
  const userColumns = permissions.ViewDeleted ? defaultColumns : noDeletedColumns;
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [placeColumns, setPlaceColumns] = useState(userColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const mountedRef = useRef(true);
  const [person, setPerson] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'Person') {
      getData();
    }
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [ptsAddressID]);

  const getData = async () => {
    try {
      const data = await getAddressPerson(ptsAddressID);
      if (!mountedRef.current) return;
      setRows(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: row.ROWGUID,
        BirthDate: displayDate(row.BirthDate),
      };
    });
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.ROWGUID === selection);
    if (!item) return;
    props.showReport({ id: 45, data: { ptsPersonID: item.ptsPersonID }, title: 'Person Details' });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.ROWGUID === selection);
    const { ptsPersonID } = item;
    props.showAddAttachment({ id: ptsPersonID, type: 'Person' });
  };

  const add = () => props.showAddPerson({ ptsAddressID });
  const save = async () => {
    setLoading(true);
    try {
      const { ptsPersonID } = person;
      const service = getService('cad-address-person');
      await service.create({ ptsPersonID, ptsAddressID });
      props.notifyDataUpdate({
        type: 'Person',
        data: { action: ptsPersonID ? 'Update' : 'Create', ptsPersonID },
      });
      //props.notify('Person data saved', 'success');
      setPerson(null);
    } catch (error) {
      props.handleError(error);
    }
    setLoading(false);
  };
  const edit = () => {
    const item = rows.find((row) => row.ROWGUID === selection);
    if (!item) return;
    props.showEditPerson(item);
  };
  const removePerson = async () => {
    if (!window.confirm('Are you sure you want to delete this record?')) return;
    setLoaded(false);
    try {
      await removeAddressPerson(selection);
      if (!mountedRef.current) return;
      await getData();
    } catch (err) {
      props.handleError(err);
    }
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div style={{ margin: 10 }}>
        <PersonLookup2 onPersonChange={(val) => setPerson(val)} compact="true" />
      </div>
      <div style={{ marginLeft: 10 }}>
        <Button disabled={!person} variant="outlined" onClick={save}>
          Save
        </Button>
      </div>
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        <Tooltip title="Add place">
          <Fab className="ml-2" size="small" color="secondary" onClick={add}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <>
            <Tooltip title="Edit place">
              <Fab className="ml-2" size="small" color="secondary" onClick={edit}>
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Add attachment">
              <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                <AttachFileIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete party">
              <Fab size="small" color="secondary" onClick={removePerson}>
                <DeleteIcon />
              </Fab>
            </Tooltip>
          </>
        )}
        {/*        </div> */}
      </div>

      {/*  </div> */}
      <XGrid3
        name="Address Person"
        columns={placeColumns}
        setColumns={setPlaceColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddAttachment,
  showEditPerson,
  showAddPerson,
  notifyDataUpdate,
  notify,
})(AddressPerson);
