import React, { Fragment } from 'react';
import { displayDateTime } from 'reducers/TimeReducer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popOver: {
    padding: 6,
    borderRadius: 5,
    backgroundColor: theme.tooltip.bg,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.tooltip.border}`,
    fontWeight: 400,
    boxShadow: `1px 1px 3px ${theme.tooltip.shadow}`,
  },
  wrap: {
    background: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: '#0153a3',
    },
  },
}));

const UnitHistory = (props) => {
  const classes = useStyles();
  const { unit, statusHistory } = props;
  const { LastActionOccurred } = unit;

  const getStatuses = () => {
    return (
      <Fragment>
        <h4>Units History</h4>
        <table>
          <tbody>
            {statusHistory.map((status, idx) => {
              return (
                <tr key={idx}>
                  <td>{status.UnitStatus}</td>
                  <td>{displayDateTime(status.Occurred)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  };

  return (
    <>
      <div className={classes.popOver}>
        <h4>Unit</h4>
        <table>
          <tbody>
            <tr>
              <th>Last update</th>
              <td>{displayDateTime(LastActionOccurred)}</td>
            </tr>
          </tbody>
        </table>

        {statusHistory.length && getStatuses()}
      </div>
    </>
  );
};

export default UnitHistory;
