import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
//Components
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
//Reducers
import { handleError } from 'reducers/ErrorReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { showAutopageDialog } from 'reducers/DialogsReducer';
import { getSopAutoPages, removeSOPAutoPage } from 'reducers/SopsReducer';
const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));

const defaultColumns = [
  {
    field: 'Label',
    headerName: 'Title',
    //valueGetter: (params) => params.row.SOP.SOPID,
    width: 200,
    hide: false,
  },

  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
  //   { field: 'IsDeleted', headerName: 'Is Deleted', width: 150, hide: false },
  //   {
  //     field: 'Deleted',
  //     headerName: 'Deleted',
  //     width: 120,
  //     valueFormatter: (params) => displayDateTime(params.value),
  //     format: 'date',
  //     hide: false,
  //   },
  //   { field: 'DeletedBy', headerName: 'Deleted By', width: 150, hide: false },
  //   { field: 'DeletedIP', headerName: 'Deleted IP', width: 150, hide: false },
];

const Autopages = (props) => {
  const classes = useStyles();
  const { ptsSOPID, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const mountedRef = useRef(true);

  useEffect(() => {
    if (ptsSOPID || dataUpdate?.type === 'sop-autopage') getData();
  }, [dataUpdate, ptsSOPID]);

  const getData = async () => {
    try {
      dispatch(showSpinner());
      const data = await getSopAutoPages(ptsSOPID);
      if (!mountedRef.current) return;
      setRows(processData(data));
      setLoaded(true);
    } catch (err) {
      props.handleError(err, 'Error receiving person sops data.');
    }
    dispatch(hideSpinner());
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const add = () => {
    dispatch(showAutopageDialog({ op: 'add', data: { ptsSOPID } }));
  };
  const edit = () => {
    const item = filteredRows.find((autopage) => autopage.id === selection);
    if (!item) return;
    dispatch(showAutopageDialog({ op: 'edit', data: item }));
  };

  const deleteSopAutopage = async () => {
    const item = filteredRows.find((row) => row.id === selection);

    const updatedData = rows.filter((row) => row.id !== selection);
    setRows(updatedData);

    if (item) {
      dispatch(showSpinner());
      await removeSOPAutoPage(item);
      dispatch(hideSpinner());
    }
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Autopage">
            <span>
              <Fab size="small" color="secondary" onClick={add}>
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Delete Autopage">
                <span>
                  <Fab size="small" color="secondary" onClick={deleteSopAutopage}>
                    <DeleteIcon />
                  </Fab>
                </span>
              </Tooltip>
              <Tooltip title="Edit Autopage">
                <Fab size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>

      <XGrid3
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    addPeopleSOP: state.dialogs.addPeopleSOP,
    dataUpdate: state.dataUpdate,
  };
};
export default connect(mapStateToProps, {
  handleError,
  showSpinner,
  hideSpinner,
  showAutopageDialog,
})(Autopages);
