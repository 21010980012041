import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import SearchPanel from './SearchPanel';
import { makeStyles } from '@material-ui/core/styles';
import { editEvent } from 'reducers/DialogsReducer';
import { Checkbox, FormControlLabel, List, ListItem, Tooltip } from '@material-ui/core';
import AgencyIcon from 'components/AgencyIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const infoWidth = 230;

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    width: '100%',
  },
  listItem: {
    borderBottom: `1px solid ${theme.colors.grey1}`,
    cursor: 'pointer',
  },
  locationInfo: {
    width: '100%',
    maxHeight: 44,
    lineHeight: '1.3em',
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    fontSize: 12,
    marginTop: 0,
  },
  info: {
    width: infoWidth,
    flex: `0 0 ${infoWidth}px`,
    marginRight: 10,
  },
  agency: {
    display: 'inline-block',
    width: 100,
  },
  checkbox: {
    display: 'inline-block',
    width: 150,
    textAlign: 'end',
  },
  zoneCode: {
    fontWeight: 500,
    display: 'inline-block',
    width: 100,
  },
  agencyIcon: {
    display: 'inline-block',
    width: 26,
    marginRight: 10,
    textAlign: 'center',
  },
  header: {
    padding: '12px 20px',
    fontWeight: 500,
    fontSize: '1.2em',
  },
  panel: {
    maxHeight: 280,
  },
  esnLabel: {
    display: 'inlline-block',
    width: 110,
  },
  accordion: {
    margin: 0,
    minHeight: 0,
    '&.Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  title: {
    fontWeight: 500,
    background: theme.colors.grey5,
    '&.Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: '5px 0 0',
    },
  },
}));

function EventDuplicates(props) {
  const classes = useStyles();
  const { zones, esns, priority, agencyRoutes, setAgencyRoutes } = props;
  const agencies = useSelector((state) => state.dictionary.Agencies);
  const [checked, setChecked] = useState({});

  useEffect(() => {
    const agencyCheckedList = agencies.reduce(
      (acc, cur) => ({ ...acc, [cur.AgencyID]: false }),
      {}
    );
    setChecked(agencyCheckedList);
  }, [agencies]);

  useEffect(() => {
    setAgencyRoutes(zones);
  }, [zones]);

  const onClickCheckbox = (shouldRoute, zone) => {
    setChecked((prev) => ({ ...prev, [zone.AgencyID]: shouldRoute }));
    const k = agencyRoutes.map((agency) => {
      if (agency.AgencyID === zone.AgencyID) return { ...agency, shouldRoute };
      return agency;
    });
    setAgencyRoutes(k);
  };
  const renderZones = () => {
    return (
      <Accordion disabled={!zones.length} className={classes.accordion} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.title}>
          <Typography className={classes.heading}>Zones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.list}>
            {zones.map((zone, idx) => {
              const { AgencyID, ZoneCode, Description } = zone;
              return (
                <ListItem className={classes.listItem} key={ZoneCode}>
                  <span className={classes.agencyIcon}>
                    <AgencyIcon AgencyID={AgencyID} />
                  </span>
                  <span className={classes.agency}>{AgencyID}</span>
                  <span className={classes.zoneCode}>{ZoneCode}</span>
                  <span className={classes.agency}>{Description}</span>
                  <span className={classes.checkbox}>
                    <Tooltip title="Route on Save">
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            checked={checked[zone.AgencyID]}
                            onChange={(ev) => onClickCheckbox(ev.target.checked, zone)}
                          />
                        }
                      />
                    </Tooltip>
                  </span>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderEsns = () => {
    return (
      <Accordion disabled={!esns.length} className={classes.accordion} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.title}>
          <Typography className={classes.heading}>ESNs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List className={classes.list}>
            {esns.map((zone) => {
              const {
                ESN,
                Description,
                LawEnforcementAgencyID,
                FireResponseAgencyID,
                EMSResponseAgencyID,
              } = zone;
              return (
                <React.Fragment key={ESN}>
                  <div className={classes.header}>
                    ESN: {ESN} {Boolean(Description)}
                  </div>
                  {Boolean(EMSResponseAgencyID) && (
                    <ListItem className={classes.listItem}>
                      <span className={classes.agencyIcon}>
                        <AgencyIcon AgencyID={EMSResponseAgencyID} />
                      </span>
                      <div className={classes.esnLabel}>EMS Agency: </div>
                      <span className={classes.zoneCode}>{EMSResponseAgencyID}</span>
                    </ListItem>
                  )}
                  {Boolean(FireResponseAgencyID) && (
                    <ListItem className={classes.listItem}>
                      <span className={classes.agencyIcon}>
                        <AgencyIcon AgencyID={FireResponseAgencyID} />
                      </span>
                      <div className={classes.esnLabel}>Fire Agency: </div>
                      <span className={classes.zoneCode}>{FireResponseAgencyID}</span>
                    </ListItem>
                  )}
                  {Boolean(LawEnforcementAgencyID) && (
                    <ListItem className={classes.listItem}>
                      <span className={classes.agencyIcon}>
                        <AgencyIcon AgencyID={LawEnforcementAgencyID} />
                      </span>
                      <div className={classes.esnLabel}>Police Agency: </div>
                      <span className={classes.zoneCode}>{LawEnforcementAgencyID}</span>
                    </ListItem>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <SearchPanel title="Additional Information" className={classes.panel}>
      <div className={classes.header} style={{ opacity: priority !== null ? 1 : 0.25 }}>
        Priority: {priority}
      </div>
      {zones.length > 0 && renderZones()}
      {esns.length > 0 && renderEsns()}
    </SearchPanel>
  );
}

export default connect(null, { editEvent })(EventDuplicates);
