import React from 'react';
import HtmlTooltip from 'components/HtmlTooltip';
import { displayDateTime } from 'reducers/TimeReducer';

const tooltipLabels = {
  Item: 'Item',
  UnitCapability: 'Unit Capability',
  VehicleCapability: 'Vehicle Roles',
  PersonalCapability: 'Personal Skill',
  ItemCapability: 'Item Capability',
};

function UnitTooltip(props) {
  const { Resources, unit, children, coords } = props;
  const { Unit } = unit;
  const lat = coords.lat.toFixed(5);
  const lng = coords.lng.toFixed(5);

  const vehicles = Resources.filter((r) => r.ChildType === 'Vehicle');
  const personnel = Resources.filter((r) => r.ChildType === 'Person');
  const other = Resources.filter((r) => r.ChildType !== 'Person' && r.ChildType !== 'Vehicle');
  const tooltip = (
    <div>
      <h4>{Unit}</h4>
      <table>
        <tbody>
          <tr>
            <td colSpan={2} style={{ textAlign: 'center' }}>
              {lat}, {lng}
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: 'center' }}>
              {displayDateTime(coords.Updated)}
            </td>
          </tr>
          {vehicles.length > 0 && (
            <tr>
              <th>Vehicles</th>
              <td>
                {vehicles.map((v, idx) => (
                  <span key={v.ptsResourceID}>
                    {idx !== 0 && <br />}
                    {v.ComponentName}
                  </span>
                ))}
              </td>
            </tr>
          )}
          <tr>
            <th>Personnel</th>
            <td>
              {personnel.map((v, idx) => (
                <span key={v.ptsResourceID}>
                  {idx !== 0 && <br />}
                  {v.ComponentName}
                </span>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
      {other.length > 0 && (
        <>
          <h4>Other Resources</h4>
          <table>
            <tbody>
              {other.map((v, idx) => (
                <tr key={v.ptsResourceID}>
                  <th>{tooltipLabels[v.ChildType]}</th>
                  <td>{v.ComponentName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );

  return (
    <HtmlTooltip title={tooltip}>
      <div>{children}</div>
    </HtmlTooltip>
  );
}

export default UnitTooltip;
