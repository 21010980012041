import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { addEventRouting, newCaseID } from 'reducers/EventsReducer';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EventStatusMenu from './EventStatusMenu';
import { addPageDialog, showAddressDialog, showRotations } from 'reducers/DialogsReducer';
import { displayDate, displayTime } from 'reducers/TimeReducer';
import { getEventLocationAddresses } from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { showReport } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { sendRipAndRun } from 'reducers/ReportsReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { areObjEqual, asyncForEach } from 'utils/functions';
import Tooltip from 'components/Tooltip';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import globals from 'components/globals';
import settings from 'config/settings';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
      '& a': {
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  checkbox: {
    padding: ' 0 8px 0 0',
  },
  menuIcon: {
    margin: '0 10px 0 -10px',
  },
  menuRight: {
    position: 'relative',
  },
}));

function EventDropdownMenu(props) {
  const classes = useStyles();
  const { event, menuAnchor, Rotations, copyEvent, Agencies, context, dispNotMet } = props;
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [caseIDMenuOpen, setCaseIDMenuOpen] = useState(false);
  const [rotationsMenuOpen, setRotationsMenuOpen] = useState(false);
  const [reportMenuOpen, setReportMenuOpen] = useState(false);
  const [routingMenuOpen, setRoutingMenuOpen] = useState(false);
  const [availAgencies, setAvailAgencies] = useState([]);
  const [disableRouteAgencySaveBtn, setDisableRouteAgencySaveBtn] = useState(false);
  const [filteredAgencies, setFilteredAgencies] = useState([]);
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const statusMenuRef = useRef();
  const rotationsMenuRef = useRef();
  const caseIDMenuRef = useRef();
  const reportMenuRef = useRef();
  const routeMenuRef = useRef();
  const mountedRef = useRef(true);
  const preventRenderingRef = useRef(false);
  const menuTimeout = useRef(0);

  useEffect(() => {
    preventRenderingOn();
    delayedClose();
    return () => {
      mountedRef.current = false;
      preventRenderingOff();
      clearTimeout(menuTimeout.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isAgencySelected = availAgencies.some((agency) => agency.checked);
    setDisableRouteAgencySaveBtn(isAgencySelected);
    // eslint-disable-next-line
  }, [availAgencies]);

  useEffect(() => {
    // const { Agencies } = dictionary;
    if (!event.EventRouting) {
      setAvailAgencies(Agencies);
      return;
    }
    const newData = event.EventRouting.map((route) => {
      const agency = Agencies.find((a) => a.AgencyID === route.AgencyId);
      const Description = agency ? agency.Description : '';
      return {
        Code: route.AgencyId,
        Description,
        CreatedDate: route.Created,
        CreatedBy: route.CreatedBy,
        //checked: false,
      };
    });
    //setData(sortObjArr(newData, 'Code', sortOption));
    const newAvailAgencies = Agencies.filter(
      (a) => !newData.find((d) => d.Code === a.AgencyID)
    ).map((agency) => ({ ...agency, checked: false }));
    if (!areObjEqual(newAvailAgencies, availAgencies)) {
      setAvailAgencies(newAvailAgencies);
    }
    // eslint-disable-next-line
  }, [event]);

  const delayedClose = () => {
    clearTimeout(menuTimeout.current);
    menuTimeout.current = setTimeout(() => {
      closeMenu();
    }, settings.showMenuTime);
  };

  const preventRenderingOn = () => {
    preventRenderingRef.current = true;
    globals.menuOpen = true;
  };

  const preventRenderingOff = () => {
    if (!preventRenderingRef.current) return;
    preventRenderingRef.current = false;
    globals.menuOpen = false;
  };

  const ripAndRun = async () => {
    closeMenu();
    try {
      await sendRipAndRun(event.ptsEventID);
      //props.notify('Report sent', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const editEvent = () => {
    closeMenu();
    context.current.editEvent(event.ptsEventID);
  };

  const createCaseID = (AgencyID) => {
    props.newCaseID(event.ptsEventID, AgencyID);
    closeMenu();
  };

  const showStatusMenu = () => {
    setStatusMenuOpen(true);
  };

  const showCaseIDMenu = () => {
    setCaseIDMenuOpen(true);
  };

  const showRotationsMenu = () => {
    setRotationsMenuOpen(true);
  };

  const showRoutingMenu = () => {
    setRoutingMenuOpen(true);
  };
  const addPageDialog = () => {
    // EventID, EventType, Address, Date, Time
    const {
      EventID,
      CallType,
      FullLocationAddress,
      ReceiveDate,
      ptsEventID,
      CallTypeDescription,
      coordinates,
      CrossStreet,
    } = event;
    props.addPageDialog({
      EventID,
      ptsEventID,
      EventType: CallType,
      Address: FullLocationAddress,
      Date: displayDate(ReceiveDate),
      Time: displayTime(ReceiveDate),
      EventDescription: CallTypeDescription,
      Coordinates: coordinates ? coordinates.replace(/\s/g, '') : '',
      CrossStreet,
    });
    closeMenu();
  };

  const addressHistoryDialog = async () => {
    try {
      const ptsAddressID = await getEventLocationAddresses(event.ptsEventID);
      if (!mountedRef.current || !ptsAddressID) {
        props.closeMenu();
        return props.notify('No address found for selected event', 'warning');
      }
      props.showAddressDialog({ ptsAddressID, tab: 'history' });
    } catch (err) {
      props.handleError(err, 'Error receiving event location data.');
    }
    closeMenu();
  };

  const showRotationsDialog = (Rotation) => {
    props.showRotations({ ptsEventID: event.ptsEventID, EventID: event.EventID, Rotation });
    closeMenu();
  };

  const closeMenu = () => {
    setStatusMenuOpen(false);
    setCaseIDMenuOpen(false);
    setRotationsMenuOpen(false);
    props.closeMenu();
  };

  const handleCopyEvent = () => {
    copyEvent();
    props.closeMenu();
  };

  const viewInternalReport = () => {
    props.closeMenu();
    props.showReport({ id: 24, data: { ptsEventID: event.ptsEventID }, title: 'Event Details' });
  };

  const viewExternalReport = () => {
    props.closeMenu();
    props.showReport({ id: 72, data: { ptsEventID: event.ptsEventID }, title: 'Event Details' });
  };

  const handleAgencyChecked = (AgencyID, checked) => {
    const agencies = [...availAgencies];
    const selectedAgencies = agencies.map((agency) => {
      if (agency.AgencyID === AgencyID) agency.checked = checked;
      return agency;
    });
    setAvailAgencies(selectedAgencies);
  };
  const onSaveAgencyRouting = async () => {
    const checkedAgencies = availAgencies.filter((agency) => agency.checked);
    await asyncForEach(checkedAgencies, ({ AgencyID }) => {
      addEventRouting(AgencyID, event.ptsEventID)
        .then(() => {
          //props.notify('Routing created', 'success');
          props.notifyDataUpdate({ type: 'event', data: event.ptsEventID });
        })
        .catch((err) => {
          console.log('err ', err);
          props.handleError(err, `Error, routing ${AgencyID} not created`);
        });
    });
  };

  return (
    <>
      <Menu
        className={classes.menu}
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onMouseMove={delayedClose}
        onClose={closeMenu}>
        <MenuItem onClick={editEvent} disabled={!permissions.Edit}>
          Edit Event
        </MenuItem>
        <MenuItem onClick={handleCopyEvent} disabled={!permissions.Edit}>
          Copy Event
        </MenuItem>
        <MenuItem onClick={addPageDialog}>Send Notification</MenuItem>
        <MenuItem onClick={addressHistoryDialog}>History</MenuItem>
        <MenuItem onClick={() => setReportMenuOpen(true)} className={classes.menuRight}>
          <span>Reports</span>
          <ArrowRightIcon ref={reportMenuRef} />
        </MenuItem>
        <MenuItem
          onClick={showRotationsMenu}
          className={classes.menuRight}
          disabled={!Rotations?.length || !permissions.Edit}>
          <span>Rotations</span>
          <ArrowRightIcon ref={rotationsMenuRef} />
        </MenuItem>
        <MenuItem
          onClick={showStatusMenu}
          className={classes.menuRight}
          disabled={!permissions.Edit}>
          <span>Set Status</span>
          <ArrowRightIcon ref={statusMenuRef} />
        </MenuItem>
        <MenuItem
          onClick={showCaseIDMenu}
          className={classes.menuRight}
          disabled={!permissions.Edit}>
          <span>New Case ID</span>
          <ArrowRightIcon ref={caseIDMenuRef} />
        </MenuItem>
        <MenuItem
          onClick={showRoutingMenu}
          className={classes.menuRight}
          disabled={!permissions.Edit}>
          <span>Route To Agency</span>
          <ArrowRightIcon ref={routeMenuRef} />
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={routeMenuRef.current}
        keepMounted
        open={routingMenuOpen}
        onMouseMove={delayedClose}
        onClose={() => setRoutingMenuOpen(false)}>
        <MenuItem value="filter" size="small" onKeyDown={(e) => e.stopPropagation()}>
          <QuickFilterSearch
            rows={availAgencies}
            columns={['AgencyID']}
            setFilteredRows={setFilteredAgencies}
          />
        </MenuItem>
        <MenuItem value="ActionBtn" size="small" className={classes.mnuItem}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Tooltip title="Save Agency">
              <span>
                {' '}
                <IconButton
                  color="primary"
                  size="small"
                  onClick={onSaveAgencyRouting}
                  disabled={!disableRouteAgencySaveBtn}>
                  <SaveIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Discard changes">
              <span>
                <IconButton color="primary" size="small" onClick={() => setRoutingMenuOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </MenuItem>
        <hr style={{ margin: 0 }} />
        {filteredAgencies.map(({ AgencyID, checked }) => {
          const checkbox = (
            <Checkbox
              checked={checked}
              onChange={(e) => handleAgencyChecked(AgencyID, !checked)}
              className={classes.checkbox}
            />
          );
          return (
            <MenuItem key={AgencyID} value={AgencyID} size="small" className={classes.mnuItem}>
              <FormControlLabel
                control={checkbox}
                label={AgencyID}
                className={classes.checkboxControl}
              />
            </MenuItem>
          );
        })}
      </Menu>

      <Menu
        anchorEl={caseIDMenuRef.current}
        keepMounted
        open={caseIDMenuOpen}
        onMouseMove={delayedClose}
        onClose={() => setCaseIDMenuOpen(false)}>
        {Agencies.map((agency) => (
          <MenuItem key={agency.AgencyID} onClick={() => createCaseID(agency.AgencyID)}>
            {agency.AgencyID}
          </MenuItem>
        ))}
      </Menu>
      <EventStatusMenu
        anchorEl={statusMenuRef.current}
        open={statusMenuOpen}
        onClose={() => setStatusMenuOpen(false)}
        closeMenu={closeMenu}
        event={event}
        reqDispositions={props.reqDispositions}
        dispNotMet={dispNotMet}
      />
      <Menu
        anchorEl={rotationsMenuRef.current}
        keepMounted
        open={rotationsMenuOpen}
        onMouseMove={delayedClose}
        onClose={() => setRotationsMenuOpen(false)}>
        {Rotations?.map((item) => (
          <MenuItem onClick={() => showRotationsDialog(item)} key={item.Code}>
            {item.Description}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={reportMenuRef.current}
        keepMounted
        open={reportMenuOpen}
        onMouseMove={delayedClose}
        onClose={() => setReportMenuOpen(false)}>
        <MenuItem onClick={viewInternalReport}>Internal</MenuItem>
        <MenuItem onClick={viewExternalReport}>External</MenuItem>
        <MenuItem onClick={ripAndRun}>Rip & Run</MenuItem>
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    Rotations: state.dictionary.Rotations,
    Agencies: state.dictionary.Agencies,
  };
};

export default connect(mapStateToProps, {
  newCaseID,
  addPageDialog,
  showAddressDialog,
  handleError,
  notify,
  showRotations,
  showReport,
  notifyDataUpdate,
})(EventDropdownMenu);
