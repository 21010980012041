import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'components/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { newCaseID } from 'reducers/EventsReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { showSops } from 'reducers/DialogsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    maxHeight: 300,
    overflowY: 'auto',
  },
  row: {
    padding: '5px 15px 5px',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& p': {
      fontSize: 13,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      minWidth: 130,
      marginBottom: 0,
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  rowActions: {
    textAlign: 'right',
    opacity: 0,
    transition: 'opacity 0.3s',
    '& label': {
      marginBottom: 0,
    },
  },
  loading: {
    textAlign: 'center',
  },
  emptySop: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
}));

function SopTab(props) {
  const classes = useStyles();
  const { ptsEventID, Sops } = props;

  const perms = getFullPermissions('globals', 'SOPs');
  const { Edit } = perms;

  const onEditSop = (sop) => {
    props.showSops({ ptsEventID, fromClosedEvent: true, sop: [sop] });
  };

  const renderSops = () => {
    return (
      <>
        {Sops.map((sop) => (
          <div key={sop.ptsSOPID} className={classes.row}>
            <div className={classes.header}>
              <div className={classes.left}>
                <h4>{sop.SOPID}</h4>
              </div>
              <div className={classes.rowActions}>
                <Tooltip title="Edit SOPS">
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      component="span"
                      disabled={!Edit}
                      onClick={() => onEditSop(sop)}>
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={classes.wrap}>
      <PerfectScrollbar>{renderSops()}</PerfectScrollbar>
      {!Sops.length && <p className={classes.emptySop}>No Sop found</p>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  newCaseID,
  handleError,
  notify,
  showSpinner,
  hideSpinner,
  showSops,
})(SopTab);
