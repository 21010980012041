import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getFormStyle } from '../../utils/functions';

const useStyles = makeStyles(() => ({
  formControl: {
    ...getFormStyle(120, 120),
  },
}));

function LogicOperator(props) {
  const classes = useStyles();
  const { value, setValue, noLabel, disabled, variant } = props;

  return (
    <FormControl className={classes.formControl} size="small" disabled={disabled}>
      {noLabel !== true && <InputLabel>Operators</InputLabel>}
      <Select
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        label={noLabel !== true ? 'Operator' : undefined}
        className={classes.select}
        variant={variant}>
        <MenuItem value="And">And</MenuItem>
        <MenuItem value="Or">Or</MenuItem>
        <MenuItem value="NotAnd">NotAnd</MenuItem>
        <MenuItem value="NotOr">NotOr</MenuItem>
      </Select>
    </FormControl>
  );
}

export default LogicOperator;
