import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  eventCard: {
    marginBottom: 5,
    boxSizing: 'border-box',
    overflow: 'hidden',
    cursor: 'pointer',
    border: '1px solid #a2a9d7',
    background: '#fff',
    '&:hover': {
      background: '#f4f5fd',
    },
  },
  evCardHead: {
    textAlign: 'center',
    padding: '8px 2px 5px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    color: theme.palette.text.primary,
    '& > div': {
      padding: '0 5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:nth-child(2)': {
        direction: 'rtl',
        textAlign: 'left',
      },
    },
  },
}));

function EventList(props) {
  const classes = useStyles();
  const { statusColors } = props.config;
  const { data, activeEventIds, handleUnitClick } = props;

  return (
    <>
      {data.map((ev) => {
        const { EventId, Status, EventType } = ev;
        const bgColor = statusColors[Status].bgColor;
        const active = activeEventIds.indexOf(EventId) !== -1;
        const cardStyle = active
          ? { border: `3px solid ${bgColor}` }
          : { borderBottom: `3px solid ${bgColor}` };
        return (
          <Card
            className={classes.eventCard}
            key={EventId}
            style={cardStyle}
            onClick={(event) => handleUnitClick(event, ev)}>
            <div className={classes.evCardHead}>
              <div>{EventType}</div>
              <div>{EventId}</div>
            </div>
          </Card>
        );
      })}
    </>
  );
}

export default EventList;
