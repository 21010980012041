import React from 'react';
import { connect } from 'react-redux';
import UnitMarker from './MarkersUnits/UnitMarker';

const offsetX = -27;
const offsetY = -52;

const getScreenPos = (coords, bounds, zoom) => {
  const { latMin, latMax, lngMin, lngMax } = bounds;
  const { lat, lng } = coords;
  const offsetY2 = offsetY + getZoomOffset(zoom);
  return {
    top: `calc(${100 - ((lat - latMin) / (latMax - latMin)) * 100}% ${
      offsetY2 > 0 ? '+' : '-'
    } ${Math.abs(offsetY2)}px)`,
    left: `calc(${((lng - lngMin) / (lngMax - lngMin)) * 100}% ${
      offsetX > 0 ? '+' : '-'
    } ${Math.abs(offsetX)}px)`,
  };
};

const getZoomOffset = (zoom) => {
  if (zoom > 11) return 0;
  switch (zoom) {
    case 11:
      return 1;
    case 10:
      return 2;
    case 9:
      return 3;
    case 8:
      return 6;
    case 7:
      return 8;
    default:
      return 0;
  }
};

function SelectedUnitMarker(props) {
  const { colors, onClick, unit, mapBounds, dragStart, dragEnd, zoom, coords } = props;
  const screen = getScreenPos(coords, mapBounds, zoom);

  const style = {
    top: screen.top,
    left: screen.left,
    position: 'absolute',
    padding: 2,
  };

  return (
    <div
      style={style}
      draggable={true}
      onClick={(ev) => onClick(ev, unit)}
      onDragStart={dragStart}
      onDragEnd={dragEnd}>
      <UnitMarker colors={colors} unit={unit} selected />
    </div>
  );
}

const mapStateToProps = (state) => ({
  colors: state.config.colorPalette.Units,
});

export default connect(mapStateToProps)(SelectedUnitMarker);
