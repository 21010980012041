import store from '../config/configureStore';

const SHOW_SPINNER = 'UI/SHOW_SPINNER';
const HIDE_SPINNER = 'UI/HIDE_SPINNER';

// Drawers

export const SET_NAVIGATION_DRAWER_MOBILE_STATE = 'UI/SET_NAVIGATION_DRAWER_MOBILE_STATE';
export const SET_EVENTS_DRAWER_DESKTOP_STATE = 'UI/SET_EVENTS_DRAWER_DESKTOP_STATE';
export const SET_UNITS_DRAWER_DESKTOP_STATE = 'UI/SET_UNITS_DRAWER_DESKTOP_STATE';
export const SET_EVENTS_DRAWER_MOBILE_STATE = 'UI/SET_EVENTS_DRAWER_MOBILE_STATE';
export const SET_UNITS_DRAWER_MOBILE_STATE = 'UI/SET_UNITS_DRAWER_MOBILE_STATE';

// Map Search Box
const SHOW_MAP_SEARCH_BOX = 'UI/SHOW_MAP_SEARCH_BOX';
const HIDE_MAP_SEARCH_BOX = 'UI/HIDE_MAP_SEARCH_BOX';

//New trip dialog
export const SET_NEW_TRIP_DIALOG_STATE = 'UI/SET_NEW_TRIP_DIALOG_STATE';

export const showSpinner = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_SPINNER });
  };
};

export const hideSpinner = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_SPINNER });
  };
};

export const setNavigationDrawerMobileState = (state) => {
  const currentState = store.store.getState().ui.navigationDrawerMobileState;
  return async (dispatch) => {
    if (state) {
      dispatch({ type: SET_NAVIGATION_DRAWER_MOBILE_STATE, payload: state });
    } else {
      dispatch({
        type: SET_NAVIGATION_DRAWER_MOBILE_STATE,
        payload: !currentState,
      });
    }
  };
};

export const setEventsDrawerDesktopState = (state) => {
  const currentState = store.store.getState().ui.eventsDrawerDesktopState;
  return async (dispatch) => {
    if (state) {
      dispatch({ type: SET_EVENTS_DRAWER_DESKTOP_STATE, payload: state });
    } else {
      dispatch({
        type: SET_EVENTS_DRAWER_DESKTOP_STATE,
        payload: !currentState,
      });
    }
  };
};

export const setUnitsDrawerDesktopState = (state) => {
  const currentState = store.store.getState().ui.unitsDrawerDesktopState;
  return async (dispatch) => {
    if (state) {
      dispatch({ type: SET_UNITS_DRAWER_DESKTOP_STATE, payload: state });
    } else {
      dispatch({
        type: SET_UNITS_DRAWER_DESKTOP_STATE,
        payload: !currentState,
      });
    }
  };
};

export const setEventsDrawerMobileState = (state) => {
  const currentState = store.store.getState().ui.eventsDrawerMobileState;
  return async (dispatch) => {
    if (state) {
      dispatch({ type: SET_EVENTS_DRAWER_MOBILE_STATE, payload: state });
    } else {
      dispatch({
        type: SET_EVENTS_DRAWER_MOBILE_STATE,
        payload: !currentState,
      });
    }
  };
};

export const setUnitsDrawerMobileState = (state) => {
  const currentState = store.store.getState().ui.unitsDrawerMobileState;
  return async (dispatch) => {
    if (state) {
      dispatch({ type: SET_UNITS_DRAWER_MOBILE_STATE, payload: state });
    } else {
      dispatch({
        type: SET_UNITS_DRAWER_MOBILE_STATE,
        payload: !currentState,
      });
    }
  };
};

export const showMapSearchBox = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_MAP_SEARCH_BOX });
  };
};

export const hideMapSearchBox = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_MAP_SEARCH_BOX });
  };
};

export const toggleNewTripDialog = () => {
  const currentState = store.store.getState().ui.newTripDialogState;
  return (dispatch) => {
    dispatch({ type: SET_NEW_TRIP_DIALOG_STATE, payload: !currentState });
  };
};

export default function reducer(
  state = {
    showSpinner: false,
    // Drawers
    navigationDrawerMobileState: false,
    eventsDrawerDesktopState: true,
    unitsDrawerDesktopState: true,
    eventsDrawerMobileState: false,
    unitsDrawerMobileState: false,
  },
  action
) {
  switch (action.type) {
    case SHOW_SPINNER:
      return { ...state, showSpinner: true };
    case HIDE_SPINNER:
      return { ...state, showSpinner: false };
    // Drawers
    case SET_NAVIGATION_DRAWER_MOBILE_STATE:
      return {
        ...state,
        navigationDrawerMobileState: action.payload,
      };
    case SET_EVENTS_DRAWER_DESKTOP_STATE:
      return {
        ...state,
        eventsDrawerDesktopState: action.payload,
      };
    case SET_UNITS_DRAWER_DESKTOP_STATE:
      return {
        ...state,
        unitsDrawerDesktopState: action.payload,
      };
    case SET_EVENTS_DRAWER_MOBILE_STATE:
      return {
        ...state,
        eventsDrawerMobileState: action.payload,
      };
    case SET_UNITS_DRAWER_MOBILE_STATE:
      return {
        ...state,
        unitsDrawerMobileState: action.payload,
      };
    case SHOW_MAP_SEARCH_BOX:
      return {
        ...state,
        mapSearchBoxVisibility: true,
      };
    case HIDE_MAP_SEARCH_BOX:
      return {
        ...state,
        mapSearchBoxVisibility: false,
      };
    case SET_NEW_TRIP_DIALOG_STATE:
      return {
        ...state,
        newTripDialogState: action.payload,
      };
    default:
      break;
  }
  return state;
}
