import React, { useState, useEffect } from 'react';
import SearchPanel from './SearchPanel';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const infoWidth = 230;

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    maxHeight: 250,
  },
  listItem: {
    borderBottom: `1px solid ${theme.colors.grey1}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
  locationInfo: {
    width: '100%',
    maxHeight: 44,
    lineHeight: '1.3em',
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    fontSize: 12,
    marginTop: 0,
  },
  info: {
    width: infoWidth,
    flex: `0 0 ${infoWidth}px`,
    marginRight: 10,
  },
}));

function AddressDuplicates(props) {
  const classes = useStyles();
  const [evLookupOnly, setEvLookupOnly] = useState(false);
  const [data, setData] = useState([]);
  const { setLocation, loading, error } = props;

  useEffect(() => {
    const data = evLookupOnly ? props.data.filter((d) => d.ShowInEventLookup) : props.data;
    setData(data);
  }, [props.data, evLookupOnly]);

  const action = (
    <FormControlLabel
      style={{ marginTop: 8 }}
      control={
        <Checkbox
          checked={evLookupOnly}
          onChange={(ev, val) => setEvLookupOnly(val)}
          name="Show In Event Lookup"
          color="primary"
          style={{ padding: '0 6px' }}
        />
      }
      label="Show In Event Lookup"
    />
  );

  return (
    <SearchPanel title="Address Duplicates" loading={loading} error={error} action={action}>
      <List className={classes.list}>
        {data.map((addr) => (
          <ListItem
            className={classes.listItem}
            key={addr.ptsAddressID}
            onClick={() => setLocation({ ...addr, IsPrimary: true })}>
            <span>{addr.FullAddressText}</span>
            {Boolean(addr.ShowInEventLookup) && <CheckCircleIcon color="secondary" />}
          </ListItem>
        ))}
      </List>
    </SearchPanel>
  );
}

export default AddressDuplicates;
