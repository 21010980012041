import React from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeSettings } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';

const useStyles = makeStyles((theme) => ({}));

function AgencyRoutingConfirmation(props) {
  const classes = useStyles();
  const { open, confirmation, close } = props;

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={confirmation}>
          <SaveIcon /> Yes
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> No
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar open={open} onClose={close} title="Routing" actions={renderActions()}>
      <h5>Route event to this agency?</h5>
    </Dialog>
  );
}

export default AgencyRoutingConfirmation;
