import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeAddUnit } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import formStyles, { gridStyle, Row, RowInner, ColCard } from 'utils/formStyles';
import Autocomplete2 from 'components/Autocomplete2';
import TextField2 from 'components/TextField2';
import PersonLookup2 from 'components/PersonLookup2';
import PlaceLookup from 'components/PlaceLookup';
import { addUnit, isUnitAlreadyExist } from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { handleError } from 'reducers/ErrorReducer';
import Dictionary from 'components/Dictionary';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
import { notify } from 'reducers/NotifierReducer';
import Checkbox2 from 'components/Checkbox2';
const useStyles = makeStyles(() => ({
  ...formStyles,
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
}));

function AddUnitDialog(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const { Zones } = dictionary;

  const [unit, setUnit] = useState('');
  const [agency, setAgency] = useState(null);
  const [division, setDivision] = useState(null);
  const [zone, setZone] = useState(null);
  const [contactType, setContactType] = useState({ Code: 'Person' });
  const [person, setPerson] = useState(null);
  const [place, setPlace] = useState(null);
  const [note, setNote] = useState('');
  const [IsActive, setIsActive] = useState(true);
  const canReadAgencies = getAgenciesFullPermission('cad', 'Units', 'Read');

  const close = () => {
    props.closeAddUnit();
  };

  const save = async () => {
    const data = {
      Unit: unit,
      AgencyID: agency ? agency.AgencyID : null,
      Division: division ? division.Code : null,
      Zone: zone ? zone.ZoneCode : null,
      ContactType: contactType ? contactType.Code : null,
      ptsContactID: getContactId(),
      Notes: note,
      IsActive,
    };
    try {
      const result = await addUnit(data);

      const { ptsUnitID } = result;
      props.notifyDataUpdate({ type: 'add-unit', data: { ptsUnitID } });
      props.closeAddUnit();
    } catch (err) {
      props.handleError(err);
    }
  };

  const getContactId = () => {
    if (!contactType) return null;
    if (contactType.Code == 'Person') return person ? person.ptsPersonID : null;
    return place ? place.ptsPlaceID : null;
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!unit}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderUnit = () => {
    const onChange = (ev, val) => setUnit(val);
    return (
      <TextField2 className={classes.item} label="Unit" value={unit} onChange={onChange} max={25} />
    );
  };

  const renderAgency = () => {
    const onChange = (ev, val) => {
      setAgency(val);
      setZone(null);
    };
    return (
      <Dictionary
        options="Agencies"
        className={classes.item}
        onChange={onChange}
        value={agency}
        permittedAgency={canReadAgencies}
        label="Agency"
      />
    );
  };

  const renderDivision = () => {
    const onChange = (ev, val) => setDivision(val);
    return (
      <Dictionary
        options="Division"
        className={classes.item}
        onChange={onChange}
        value={division}
        label="Division"
      />
    );
  };

  const renderZone = () => {
    const onChange = (ev, val) => setZone(val);
    const filteredZones = Zones ? Zones.filter((z) => z.AgencyID === agency?.AgencyID) : [];
    return (
      <Autocomplete2
        options={agency ? filteredZones : Zones}
        className={classes.item}
        onChange={onChange}
        value={zone}
        label="Zone"
        getOptionSelected={(option, value) => option.ZoneCode === value.ZoneCode}
        getOptionLabel={(option) => (option.ZoneCode ? option.ZoneCode : '')}
        renderOption={(option) => (
          <span>
            {option.ZoneCode} - {option.Description} - {option.AgencyID}
          </span>
        )}
      />
    );
  };

  const renderPrimaryContactType = () => {
    const onChange = (ev, val) => setContactType(val);
    const types = [{ Code: 'Person' }, { Code: 'Place' }];
    return (
      <Autocomplete2
        options={types}
        className={classes.item}
        onChange={onChange}
        value={contactType}
        label="Primary Contact Type"
      />
    );
  };

  const renderPerson = () => {
    const handlePersonChange = (value) => setPerson(value);
    return (
      <PersonLookup2 className={classes.item} onPersonChange={handlePersonChange} label="Person" />
    );
  };

  const renderPlace = () => {
    const handleChange = (value) => setPlace(value);
    return <PlaceLookup onChange={handleChange} className={classes.item} label="Place" />;
  };

  const renderNotes = () => {
    const onChange = (ev, val) => setNote(val);
    return (
      <TextField2
        className={classes.item}
        label="Note"
        value={note}
        onChange={onChange}
        max={8000}
        rows={5}
        multiline
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Add Unit" actions={renderActions()} maxWidth="sm">
      <Row>
        <ColCard minWidth={500}>
          <RowInner>
            <Checkbox2 checked={IsActive} onChange={() => setIsActive(!IsActive)} label="Active" />
          </RowInner>
          <RowInner>
            {renderUnit()}
            {renderAgency()}
          </RowInner>
          <RowInner>
            {renderDivision()}
            {renderZone()}
          </RowInner>
          <RowInner>
            {renderPrimaryContactType()}
            {contactType?.Code == 'Person' && renderPerson()}
            {contactType?.Code == 'Place' && renderPlace()}
          </RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeAddUnit,
  notifyDataUpdate,
  handleError,
  notify,
})(AddUnitDialog);
