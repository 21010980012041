import React from 'react';
import Divider from '@material-ui/core/Divider';

function FormDivider(props) {
  return (
    <>
      <span className="mb-2 badge badge-primary">{props.title}</span>
      <Divider style={{ marginBottom: 12 }} />
    </>
  );
}

export default (FormDivider);
