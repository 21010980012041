import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';
import { updateServerConnection } from 'reducers/ServerConnectionReducer';
import { getAccessPermission } from 'reducers/PermissionsReducer';
import { setWebsocketConnection } from './reducers/WebsocketReducer';
import { setUserAuthenticated, setUserData, logOut } from './reducers/UserReducer';
import * as serviceWorker from './serviceWorker';
import { notifyPanel, notify } from './reducers/NotifierReducer';

// Layout Blueprints
import { MinimalLayout, CadLayout } from './layout-blueprints';

// Pages
import PageLogin from './pages/PageLogin';
import Page404 from './pages/Page404';
import ResetPassword from 'pages/ResetPassword';
import ForcePasswordReset from 'pages/ForcePasswordReset';
import routeDefinitions, { defaultUrl } from './routeDefinitions';
import Loading from 'pages/Loading';
import NotAuthorized from 'pages/NotAuthorized';
import { registerAppUpdate } from 'reducers/AppInfoReducer';

let reloadNeeded = false;
let registration = false;
let noPermissionShown = false;

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  if (!isAuthenticated && reloadNeeded) updateApp(registration);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Redirect to={{ pathname: defaultUrl, state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const Routes = (props) => {
  const {
    setWebsocketConnection,
    setUserAuthenticated,
    setUserData,
    setLoggedInUserData,
    config,
    user,
    permissions,
  } = props;

  const { isAuthenticated, passExpirationDays, showPassChange } = user;
  const cadPermission = getAccessPermission('modules', 'CAD', 'any');
  const isLoaded = config.loaded && permissions?.loaded && config.dictionaryLoaded;

  useEffect(() => {
    // handle service worker updates
    const updateApp = (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener('statechange', (event) => {
          if (event.target.state === 'activated') {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    };
    const openNotification = (registration) => {
      const notification = {
        title: `Application Update Available!`,
        message: `New release of CAD Application has been released.It is highly recomended to launch the new version!!`,
        position: 'tc',
        level: 'warning',
        autoDismiss: 0,
        action: {
          label: 'LAUNCH!',
          callback: () => updateApp(registration),
        },
      };
      props.notifyPanel(notification, 'success');
    };
    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: (reg) => {
        if (isAuthenticated) {
          openNotification(reg);
          registration = reg;
          props.registerAppUpdate(reg);
        } else {
          updateApp(reg);
        }
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function establishConnection() {
      const client = feathers();
      const socket = io(process.env.REACT_APP_API_URL, {
        transports: ['websocket', 'polling'],
        perMessageDeflate: false,
        timeout: 20000,
        requestTimeout: 20000,
      });
      client.configure(socketio(socket));
      client.configure(auth({ storageKey: 'auth' }));
      socket.on('disconnect', () => props.updateServerConnection(false));
      socket.on('connect', () => props.updateServerConnection(true));
      try {
        const user = await client.reAuthenticate();
        setWebsocketConnection(client);
        setUserData(user);
        setUserAuthenticated(true);
      } catch (error) {
        if (error.code === 401) {
          setUserAuthenticated(false);
          setUserData(null);
          setWebsocketConnection(client);
        }
        setWebsocketConnection(client);
      }
    }
    establishConnection();
    // eslint-disable-next-line
  }, [setWebsocketConnection, setUserAuthenticated, setLoggedInUserData]);

  const renderRoutes = () =>
    routeDefinitions.map((route, key) => {
      let Layout = isLoaded ? route.layout : MinimalLayout;
      let component = isLoaded ? route.component : Loading;
      if (isLoaded && !cadPermission)
        if (isLoaded && !cadPermission) {
          component = NotAuthorized;
          Layout = MinimalLayout;
          if (!noPermissionShown) {
            noPermissionShown = true;
            props.notify('No sufficient permissions to run this application', 'error');
            props.logOut();
          }
        }

      return (
        <Route path={[route.url]} key={key}>
          <Layout>
            <ProtectedRoute
              path={route.url}
              component={component}
              isAuthenticated={isAuthenticated}
            />
          </Layout>
        </Route>
      );
    });

  return (
    <Switch>
      <Redirect exact from="/" to="/Login" />
      <Route path={['/Login']}>
        <MinimalLayout>
          <LoginRoute path="/Login" component={PageLogin} isAuthenticated={isAuthenticated} />
        </MinimalLayout>
      </Route>
      <Route path={['/Reset-password/:hash']}>
        <MinimalLayout>
          <ResetPassword isAuthenticated={isAuthenticated} />
        </MinimalLayout>
      </Route>
      {passExpirationDays !== null && !showPassChange && renderRoutes()}
      {showPassChange && (
        <Route path={['/']}>
          <MinimalLayout>
            <ForcePasswordReset passExpirationDays={passExpirationDays} />
          </MinimalLayout>
        </Route>
      )}
      {passExpirationDays === null && (
        <Route path={['/']}>
          <MinimalLayout>
            <ProtectedRoute component={Loading} isAuthenticated={isAuthenticated} />
          </MinimalLayout>
        </Route>
      )}
      <Route>
        <CadLayout>
          <ProtectedRoute component={Page404} isAuthenticated={isAuthenticated} />
        </CadLayout>
      </Route>
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  network: state.offline,
  user: state.user,
  config: state.config,
  permissions: state.permissions,
});

export default connect(mapStateToProps, {
  setWebsocketConnection,
  setUserAuthenticated,
  setUserData,
  notifyPanel,
  registerAppUpdate,
  notify,
  logOut,
  updateServerConnection,
})(Routes);
