import { useEffect } from 'react';
import { connect } from 'react-redux';

function SearchHotKeys(props) {
  const { keyboard, setKey, allowedKeys = ['F5', 'Ctrl+KeyP', 'Ctrl+KeyL'] } = props;

  useEffect(() => {
    const kbdSubscription = keyboard.subject.subscribe(handleKbd);
    return () => {
      kbdSubscription.unsubscribe();
    }
    // eslint-disable-next-line
  }, []);

  const handleKbd = (keyCode) => {
    if (allowedKeys.indexOf(keyCode) !== -1) setKey(keyCode);
  }
  return ''
}

const mapStateToProps = (state) => ({
  keyboard: state.keyboard,
})

export default connect(mapStateToProps)(SearchHotKeys);