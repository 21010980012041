import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@material-ui/core/TextField';
import { closeAddPageDialog, addPageDialog } from 'reducers/DialogsReducer';
import CustomAutocomplete from 'components/CustomAutocomplete1';
import SelectList from 'components/SelectList';
import {
  updateTemplates,
  updateGroups,
  updateRecipients,
  updateGroupsRecipients,
  sendPage,
} from 'reducers/PagingReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { getFormStyle } from 'utils/functions';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: 800,
    maxWidth: '100%',
    margin: '0 auto',
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: `calc(50% - ${theme.spacing(1)}px)`,
    },
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& h4': {
      margin: '0.5em 0',
    },
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  row: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
}));

const snippets = [
  'EventID',
  'EventType',
  'Address',
  'Date',
  'Time',
  'EventDescription',
  'CrossStreet',
  'Coordinates',
];

function Paging(props) {
  const classes = useStyles();
  const { addPage, paging } = props;
  const { recipients, groups, groupRecipients } = paging;
  const [editedValid, setEditedValid] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [template, setTemplate] = useState(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const recipientsRef = useRef([]);
  const groupsRef = useRef([]);

  useEffect(() => {
    props.updateTemplates();
    props.updateGroups();
    props.updateRecipients();
    props.updateGroupsRecipients();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!paging.templates) return;
    const templates = paging.templates.filter((t) => t.IsActive === true);
    setTemplates(templates);
    const defaultTemplate = templates.find((t) => t.IsDefault);
    defaultTemplate && setTemplate(defaultTemplate.Code);
    // eslint-disable-next-line
  }, [paging.templates]);

  useEffect(() => {
    const templ = templates.find((t) => t.Code === template);
    if (!templ) return;
    setSubject(parseTemplate(templ.Description));
    setMessage(parseTemplate(templ.Template));
    // eslint-disable-next-line
  }, [template]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [template, subject, message, selectedRecipients]);

  const validate = () => {
    if (!subject || !message || !recipientsRef.current.length) {
      editedValid && setEditedValid(false);
    } else {
      !editedValid && setEditedValid(true);
    }
  };

  const parseTemplate = (Template) => {
    if (!addPage) return;
    let result = Template;
    snippets.forEach((key) => {
      const snippet = `{${key}}`;
      let val = addPage[key] ? addPage[key] : '';
      result = result.replaceAll(snippet, val);
    });
    return result;
  };

  const clearPage = () => {
    setMessage('');
    setTemplate(null);
    setSubject('');
    setSelectedRecipients([]);
  };

  const close = () => {
    clearPage();
    props.closeAddPageDialog();
  };

  const send = () => {
    const activeRecipients = recipientsRef.current
      .map((ROWGUID) => recipients.find((r) => r.ROWGUID === ROWGUID))
      .filter((rec) => !rec.IsDisabled)
      .map((rec) => rec.ptsRecipientID);
    const data = {
      ptsEventID: addPage.ptsEventID,
      Subject: subject,
      Body: message,
      recipients: activeRecipients,
    };
    props.showSpinner();
    sendPage(data)
      .then(() => {
        props.notifyDataUpdate({ type: 'event', data: addPage.ptsEventID });
        close();
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const handleSubjectChange = (ev) => {
    const val = ev.target.value;
    if (val.length > 100) return;
    setSubject(val);
  };

  const handleMessageChange = (ev) => {
    const val = ev.target.value;
    setMessage(val);
  };

  const renderForm = () => {
    const templateOptions = templates.map((t) => {
      return { Code: t.Code };
    });

    const onTemplateChange = (val) => {
      setTemplate(val);
      if (!val) {
        setSubject('');
        setMessage('');
      }
    };

    return (
      <div className={classes.form}>
        <CustomAutocomplete
          label="Template"
          options={templateOptions}
          value={template}
          onChange={onTemplateChange}
          className={classes.w100pr}
          size="small"
        />
        <TextField
          className={classes.w100pr}
          label="Subject"
          value={subject}
          onChange={handleSubjectChange}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.w100pr}
          label="Message"
          value={message}
          onChange={handleMessageChange}
          variant="outlined"
          size="small"
          multiline
          rows={10}
        />
      </div>
    );
  };

  const selectRecipients = (recipients) => {
    recipientsRef.current = recipients;
    validate();
  };

  const selectGroups = (groups) => {
    const removedGroups = groupsRef.current.filter((id) => groups.indexOf(id) === -1);
    removedGroups.forEach((id) => {
      const rec = groupRecipients[id].Recipients;
      rec.forEach((recipient) => {
        const idx = recipientsRef.current.indexOf(recipient.ROWGUID);
        if (idx !== -1) {
          recipientsRef.current.splice(idx, 1);
        }
      });
    });
    groups.forEach((id) => {
      const rec = groupRecipients[id].Recipients;
      rec.forEach((recipient) => {
        if (recipientsRef.current.indexOf(recipient.ROWGUID) === -1) {
          recipientsRef.current.push(recipient.ROWGUID);
        }
      });
    });
    setSelectedRecipients([...recipientsRef.current]);
    groupsRef.current = groups;
  };

  const renderGroups = () => {
    return (
      <div className={classes.recipients}>
        <SelectList
          options={groups}
          setOptions={selectGroups}
          keyID="ROWGUID"
          labelID="Label"
          title="Groups"
          height={400}
        />
      </div>
    );
  };

  const renderRecipients = () => {
    return (
      <div className={classes.recipients}>
        <SelectList
          selected={selectedRecipients}
          options={recipients}
          setOptions={selectRecipients}
          keyID="ROWGUID"
          labelID="Recipient"
          title="Recipients"
          height={400}
        />
      </div>
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={send}
          disabled={!editedValid}>
          <SendIcon /> Send
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Cancel
        </Button>
      </>
    );
  };

  return (
    <Dialog
      open={Boolean(addPage)}
      onClose={close}
      title={'Send Notification'}
      actions={renderActions()}
      toolbar>
      <div className={classes.wrap}>
        {renderForm()}
        <div className={classes.columns}>
          {renderGroups()}
          {renderRecipients()}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    addPage: state.dialogs.addPage,
    paging: state.paging,
  };
};

export default connect(mapStateToProps, {
  closeAddPageDialog,
  addPageDialog,
  updateTemplates,
  updateGroups,
  updateRecipients,
  updateGroupsRecipients,
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
})(Paging);
