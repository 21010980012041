import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getWorkstationID } from 'reducers/ConfigReducer';
import { do911Test } from 'reducers/E911Reducer';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 20,
  },
  btnWrap: {
    marginBottom: theme.spacing(1),
    '& button': {
      marginRight: theme.spacing(1),
    },
  },
}));

function E911Testing() {
  const classes = useStyles();

  const doTest = (no) => async () => {
    await do911Test(getWorkstationID(), no);
  };

  return (
    <div className={classes.content}>
      <div className={classes.btnWrap}>
        <Button variant="contained" color="primary" onClick={doTest(0)}>
          Test 1
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(1)}>
          Test 2
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(2)}>
          Test 3
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(3)}>
          Test 4
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(4)}>
          Test 5
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(5)}>
          MSG Fload 1
        </Button>
        <Button variant="contained" color="primary" onClick={doTest(6)}>
          MSG Fload 2
        </Button>
      </div>
    </div>
  );
}

export default E911Testing;
