import React, { useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closeDisplayDataWindow } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import DisplayDataContent from './DisplayDataContent';
import { printRef } from 'utils/functions';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';

const useStyles = makeStyles(() => ({
  pageWrap: {
    width: 800,
    maxWidth: '100%',
  },
  iframe: {
    border: 'none',
    overflow: 'hidden',
    width: 1,
    height: 1,
  }
}));

function DisplayData(props) {
  const classes = useStyles();
  const [ref, setRef] = useState(null);
  const { data, title } = props;

  const close = () => {
    props.closeDisplayDataWindow();
  };

  const print = () => {
    printRef(ref, title)
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={print}>
          <LocalPrintshopIcon /> Print
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title={title} actions={renderActions()}>
      <div className={classes.pageWrap}>
        <DisplayDataContent data={data} title={title} setRef={setRef} />
        <iframe id="print-iframe" className={classes.iframe}></iframe>
      </div>
    </Dialog>
  );
}

export default connect(null, { closeDisplayDataWindow })(DisplayData);
