import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import SearchDatePicker from '../components/SearchDatePicker2';
import { showDisplayDataWindow } from 'reducers/DialogsReducer';
import { searchQueries } from 'reducers/SearchReducer';
import QuickFilterSearch from '../components/QuickFilterSearch';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const defaultColumns = [
  { field: 'Unit', headerName: 'Unit', width: 100, hide: false },
  {
    field: 'QueryDate',
    headerName: 'Query Date',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'Notes', headerName: 'Notes', width: 100, hide: false },
  { field: 'QueryType', headerName: 'Query Type', width: 120, hide: false },
  { field: 'QueryOperator', headerName: 'Query Operator', width: 120, hide: false },
  { field: 'AgencyID', headerName: 'Agency ID', width: 120, hide: false },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150, hide: false },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
    hide: false,
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150, hide: false },
];

const today = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 70);

function QueryHistory(props) {
  const classes = useStyles();
  const { filter } = props;
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(today);
  const [loaded, setLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const limit = 1000;
  useEffect(() => {
    search();
    getallQueries();
    // eslint-disable-next-line
  }, [filter]);

  // Code commented as it only throws errors and doesn't seem to do anything
  const getallQueries = async () => {
    //const res = await searchQueries(dateFrom, dateTo, filter);
  };

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const processData = (data) => {
    return data.map((row) => {
      return { ...row, id: row.ptsQueryID };
    });
  };

  const search = (offset = 0, mergeRows = false) => {
    setLoaded(false);
    searchQueries(dateFrom, dateTo, filter, offset, limit)
      .then(async (result) => {
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);

        //TRUE means, fetching next rows
        //so merging new rows with existing
        if (mergeRows) setRows((prevState) => [...prevState, ...processData(result)]);
        else {
          //new search result
          setRows(processData(result));
          //resetting page
          setPage(0);
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewDetails = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `Query Details - ${item.QueryType}/ ${item.QueryOperator}`,
    });
  };

  const filterData = (rawData) => {
    const {
      EventID,
      Unit,
      QueryDate,
      Tag,
      TagState,
      OLN,
      OLNState,
      Notes,
      QueryType,
      QueryOperator,
      AgencyID,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = rawData;

    return {
      EventID,
      Unit,
      QueryDate,
      Tag,
      TagState,
      OLN,
      OLNState,
      Notes,
      QueryType,
      QueryOperator,
      AgencyID,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    };
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        {!!selection && (
          <>
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <h5>NCIC Queries</h5>
      <QuickFilterSearch rows={rows} setFilteredRows={setFilteredRows} columns={columns} />
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={renderActions()}
        loaded={loaded}
      />
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="NCIC Queries"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

export default connect(null, {
  handleError,
  showDisplayDataWindow,
})(QueryHistory);
