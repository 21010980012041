import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Event from './Event';

function EventWrap(props) {
  const { ev, unfoldSubject, editEvent, togglePin, pinned, selectEvent, selected } = props;
  const context = useRef({ unfoldSubject, editEvent, togglePin, selectEvent });
  const units = useSelector((state) => state.units);
  const eventSort = useSelector((state) => state.eventSort);
  const { open, tab } = eventSort.state[ev.ptsEventID] || {
    open: false,
    tab: eventSort.lastTab || 'Units',
  };
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    if (!units) return;
    const newUnitList = units.map((unit) => ({
      ptsUnitID: unit.ptsUnitID,
      Unit: unit.Unit,
      AgencyID: unit.AgencyID,
    }));
    if (unitListChanged(newUnitList)) {
      setUnitList(newUnitList);
    }
    // eslint-disable-next-line
  }, [units]);

  const unitListChanged = (newUnitList) => {
    if (newUnitList.length !== unitList.length) {
      return true;
    }
    const unitsChanged = newUnitList.reduce(
      (res, unit, idx) => (unit.ptsUnitID !== unitList[idx].ptsUnitID ? true : res),
      false
    );
    return unitsChanged;
  };

  const MemoizedEvent = useMemo(
    () => (
      <Event
        ev={ev}
        pinned={pinned}
        selected={selected}
        unitList={unitList}
        context={context}
        open={open}
        tab={tab}
      />
    ),
    [ev, pinned, unitList, selected, open, tab]
  );

  return MemoizedEvent;
}

export default EventWrap;
