import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePersonPhotoDialog } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField from '@material-ui/core/TextField';
import { uploadPersonPhoto } from 'reducers/FileReducer';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import Tooltip from 'components/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from 'components/AttachmentIcon';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  formatSaveData,
} from 'utils/formStyles';
import Dictionary from 'components/Dictionary';
import Checkbox2 from 'components/Checkbox2';

const useStyles = makeStyles((theme) => ({
  txtField: {
    minWidth: 600,
  },
  fileInput: {
    display: 'none',
  },
  dropZone: {
    background: theme.colors.grey1,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    marginBottom: 8,
    '& svg': {
      marginRight: 8,
    },
  },
  dropZoneOver: {
    background: theme.colors.grey1,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `3px dashed ${theme.palette.primary.main}`,
    marginBottom: 8,
    '& svg': {
      marginRight: 8,
    },
  },
  fileInfo: {
    minHeight: 35,
    '& img': {
      maxHeight: 24,
      borderRadius: 3,
      marginRight: 8,
    },
  },
  item: gridStyle(200, 800),
}));

function AddPersonPhotoAttachment(props) {
  const classes = useStyles();
  const { ptsPersonID } = props;
  const [Description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [draggedOver, setDraggedOver] = useState(false);
  const [ImageTypeCode, setImageTypeCode] = useState(null);
  const [MarkType, setMarkType] = useState(null);
  const [Location, setLocation] = useState(null);
  const [Gang, setGang] = useState(null);
  const [IsMark, setIsMark] = useState(false);

  const close = () => {
    props.closePersonPhotoDialog();
  };

  const handleAttachmentChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const save = async () => {
    const data = formatSaveData({
      ImageTypeCode,
      IsMark,
      Location,
      Gang,
      Description,
      MarkType,
    });
    data.ptsPersonID = ptsPersonID;
    data.file = file;
    props.showSpinner();
    try {
      await uploadPersonPhoto(data);
      props.notifyDataUpdate({ type: 'person-image-upload', data: {} });
      //props.notify('File uploaded', 'success');
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
    close();
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!file}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const onDrop = (ev) => {
    const files = ev.dataTransfer.files;
    if (!files.length) return setDraggedOver(false);
    setFile(files[0]);
    ev.preventDefault();
  };

  const onDragOver = (ev) => {
    if (!draggedOver) setDraggedOver(true);
    ev.preventDefault();
  };

  const onDragLeave = (ev) => {
    if (draggedOver) setDraggedOver(false);
    ev.preventDefault();
  };

  const renderFileInfo = () => {
    const idx = file.name.lastIndexOf('.');
    const type = file.name.substr(idx);
    return (
      <>
        <AttachmentIcon type={type} />
        <span>{file.name}</span>
      </>
    );
  };

  const renderInfoForm = () => {
    return (
      <div>
        <Dictionary
          options="MugshotTypes"
          className={classes.item}
          onChange={(ev, val) => setImageTypeCode(val)}
          value={ImageTypeCode}
          label="Image Type"
          compact="true"
        />
        <Checkbox2
          checked={IsMark}
          onChange={() => setIsMark(!IsMark)}
          label="Scar, Mark, or Tattoo"
        />
        {IsMark && (
          <Row>
            <ColCard minWidth={500}>
              <RowInner>
                <Dictionary
                  options="Marks"
                  className={classes.item}
                  onChange={(ev, val) => setMarkType(val)}
                  value={MarkType}
                  label="Mark Type"
                  compact="true"
                />
                <Dictionary
                  options="TattooLocation"
                  className={classes.item}
                  onChange={(ev, val) => setLocation(val)}
                  value={Location}
                  label="Location"
                  compact="true"
                />
              </RowInner>
              <RowInner>
                <Dictionary
                  options="Gang"
                  className={classes.item}
                  onChange={(ev, val) => setGang(val)}
                  value={Gang}
                  label="Gang"
                  compact="true"
                />
              </RowInner>
              <RowInner>
                <TextField
                  label="Description"
                  variant="outlined"
                  size="small"
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={classes.item}
                />
              </RowInner>
            </ColCard>
          </Row>
        )}
      </div>
    );
  };

  const inputId = `upload-file`;
  const className = draggedOver || Boolean(file) ? classes.dropZoneOver : classes.dropZone;
  return (
    <Dialog toolbar onClose={close} title="Add Photo" actions={renderActions()}>
      <div className={className} onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
        <Tooltip title="Add Attachment">
          <label htmlFor={inputId}>
            <Button color="primary" variant="outlined" component="span">
              <GetAppIcon /> Add Attachment
            </Button>
          </label>
        </Tooltip>
        <input
          accept="image/*"
          className={classes.fileInput}
          onChange={handleAttachmentChange}
          id={inputId}
          type="file"
        />
      </div>
      <div className={classes.fileInfo}>{Boolean(file) && renderFileInfo()}</div>
      {renderInfoForm()}
    </Dialog>
  );
}

export default connect(null, {
  closePersonPhotoDialog,
  notify,
  handleError,
  showSpinner,
  hideSpinner,
  notifyDataUpdate,
})(AddPersonPhotoAttachment);
