import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditPerson, closeEditZone } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { handleError } from 'reducers/ErrorReducer';
import Sops from './Sops';
import Attachments from 'components/Attachments';
const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(3),
  },
}));

function Zone(props) {
  const classes = useStyles();
  const { data } = props;
  const { ZoneCode, IntCode } = data;
  const [tab, setTab] = useState('sops');
  const mountedRef = useRef(true);
  //const newAddress = !ptsPersonID;
  useEffect(() => {
    if (props.tab) setTab(props.tab);

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [props.tab]);

  const close = (data) => {
    //props.closeEditPerson(data);
    props.closeEditZone();
  };

  return (
    <Dialog toolbar onClose={close} title={ZoneCode ? 'Add Zone' : 'Edit Zone'} fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs variant="scrollable" value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Sops" value="sops" />
            <Tab label="Attachments" value="attachments" />

            {/* <Tab label="Coordinates" value="coordinates" /> */}
          </Tabs>
        </Paper>

        {tab === 'sops' && <Sops IntCode={IntCode} />}
        {tab === 'attachments' && <Attachments type="Zone" id={IntCode} />}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeEditPerson,
  handleError,
  closeEditZone,
})(Zone);
