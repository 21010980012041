import React from 'react';
import data from './unitsData';
import UnitPanel1 from './UnitPanel1';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  units: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '44px -10px 20px',
    '& > div': {
      width: 350,
      margin: 5,
      cursor: 'grab',
    },
  },
}));

function Units(props) {
  const classes = useStyles();

  return (
    <div className={classes.units}>
      {data.map((unit, idx) => (
        <UnitPanel1 unit={unit} key={idx} config={props.config} />
      ))}
    </div>
  );
}

export default Units;
