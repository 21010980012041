import { handleError } from './ErrorReducer';
const SET_EVENT_LOCATION = 'EVENT_LOCATION/SET_EVENT_LOCATION';
const CLEAR_EVENT_LOCATION = 'EVENT_LOCATION/CLEAR_EVENT_LOCATION';

export const setEventLocation = (location) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_EVENT_LOCATION, payload: location });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const clearEventLocation = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CLEAR_EVENT_LOCATION });
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_EVENT_LOCATION:
      return { ...action.payload };
    case CLEAR_EVENT_LOCATION:
      return {};
    default:
      return state;
  }
}
