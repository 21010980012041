import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import SearchDatePicker from '../components/SearchDatePicker2';
import { showDisplayDataWindow, editEvent, showRotationHistory } from 'reducers/DialogsReducer';
import { searchRotations } from 'reducers/SearchReducer';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import InfoIcon from '@material-ui/icons/Info';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import TextField2 from 'components/TextField2';
import EditIcon from '@material-ui/icons/Edit';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const defaultColumns = [
  'Rotation',
  'ActionReason',
  'Action',
  'Unit',
  'AgencyID',
  'ContactType',
  'ContactName',
  'ContactInfo',
  'EventID',
  'CallType',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'DeletedBy',
  'DeletedIP',
];

function RotationHistory(props) {
  const classes = useStyles();
  const { filter, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [loaded, setLoaded] = useState(true);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [text, setText] = useState('');
  //const showAlert = rows.length >= 1000;
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [eventID, setEventID] = useState(null);
  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataUpdate?.type === 'rotation-history-update') search(currentOffset, limit);
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (!selection) return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { EventID } = item;
    setEventID(EventID);
    // eslint-disable-next-line
  }, [selection]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const processData = (data) => {
    return data.map((row) => {
      return { ...row, id: row.ptsRotationHistoryID };
    });
  };

  const search = (offset = 0, mergeRows = false) => {
    setLoaded(false);
    searchRotations(text, dateFrom, dateTo, filter, offset, limit)
      .then((result) => {
        //adding total number of rows
        result.length && setRowCount(result[0].Count);

        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows) setRows((prevState) => [...prevState, ...processData(result)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };
  const editEvent = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.editEvent(item.ptsEventID);
  };

  const editRotationHistory = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsRotationHistoryID } = item;
    props.showRotationHistory({ ptsRotationHistoryID });
  };
  const viewDetails = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showDisplayDataWindow({
      data: filterData(item),
      title: `Rotation Details - ${item.Rotation}`,
    });
  };

  const filterData = (rawData) => {
    const {
      Rotation,
      ActionReason,
      Action,
      Unit,
      AgencyID,
      Division,
      Zone,
      ContactType,
      ContactName,
      ContactInfo,
      EventID,
      CallType,
      Received,
      Queued,
      Dispatched,
      Enroute,
      Arrived,
      Completed,
      UnderControl,
      Cancelled,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    } = rawData;

    return {
      Rotation,
      ActionReason,
      Action,
      Unit,
      AgencyID,
      Division,
      Zone,
      ContactType,
      ContactName,
      ContactInfo,
      EventID,
      CallType,
      Received,
      Queued,
      Dispatched,
      Enroute,
      Arrived,
      Completed,
      UnderControl,
      Cancelled,
      Created,
      CreatedBy,
      Updated,
      UpdatedBy,
    };
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    setCurrentOffset(offset);
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        {!!selection && (
          <>
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Print list">
              <Fab size="small" color="secondary" onClick={handlePrintClick}>
                <LocalPrintshopRoundedIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Edit Rotation history">
              <Fab size="small" color="secondary" onClick={editRotationHistory}>
                <EditIcon />
              </Fab>
            </Tooltip>

            <Tooltip title="Edit Event">
              <Fab disabled={!eventID} size="small" color="secondary" onClick={editEvent}>
                <EditCalendarIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  return (
    <div>
      <h5>Rotation History</h5>
      {renderSearch()}
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={renderActions()}
        loaded={loaded}
      />
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Rotation History"
        columns={columns}
        setColumns={setColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  editEvent,
  handleError,
  showDisplayDataWindow,
  showRotationHistory,
})(RotationHistory);
