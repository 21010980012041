import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}

function Loading() {
  return (
    <div style={style}>
      <div style={{ marginBottom: 8, fontSize: 22 }}>Loading...</div>
      <CircularProgress />
    </div>
  );
}

export default Loading;