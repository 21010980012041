import React from 'react';

export default function Police(props) {
  return (
    <svg
      width="100%"
      height="100%"
      enableBackground="new 29.044 16 237 252"
      version="1.1"
      viewBox="29.044 16 237 252"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg">
      <path d="m247.04 87c-2.91 17.09-12.385 27.615-23 37 0.434 10.768-0.052 20.615-4 27 16.101 9.886 11.926 54.584-10 56-4.621 30.575-28.363 53.891-59 57-37.54 3.811-67.297-22.813-73-56-20.342-2.096-27.389-46.209-10-56-3.242-7.091-4.732-15.935-4-27-9.174-11.492-19.47-21.863-23-39 11.251-11.54 28.179-15.926 44-26 15.024-9.567 25.811-24.045 38-31 35.042-19.995 55.836 17.509 77 32 15.636 10.706 30.799 15.155 47 26zm-110-13c-5.25-0.584-7.571 1.762-13 1 1.766 5.568 7.408 7.258 10 12-2.04 3.627-1.44 9.893-3 14 5.726-1.274 8.992-5.008 14-7 3.93 2.737 7.745 5.588 13 7-1.5-4.5-1.251-10.749-3-15 3.607-3.392 8.055-5.945 10-11-5.694 0.694-8.306-1.694-14-1-1.775-5.224-4.105-9.895-7-14-1.776 5.224-4.106 9.895-7 14zm-62 66h138v-13h-137c-2.3 2.367-0.358 8.975-1 13zm6 19c-0.981 5.352-10.817 1.85-11 8-1.133 7.479 2.056 19.269 4 27 2.293 2.373 9.707-0.373 12 2 5.056 15.086 6.013 28.797 16 39 22.267 22.748 63.283 22.658 85-1 9.014-9.819 9.512-24.29 16-38 2.434-1.9 8.366-0.301 11-2 1.961-8.142 5.336-19.858 4-28-5.273-3.495-13.414-5.657-11-14-29.426 21.573-97.314 20.614-127 0-0.489 3.156 1.802 3.531 1 7z" />
      <path d="m149.04 179h-8c-7.007 8.993-13.129 18.871-25 23-24.476 3.151-33.78-18.439-28-39 29.888 14.706 84.98 13.051 112 0 0.81 0.19 0.997 1.003 2 1 3.378 21.897-4.745 40.452-29 38-11.238-4.428-18.63-12.704-24-23z" />
      <path d="m159.04 211c-5.439 8.088-22.976 6.804-31 2-1.139-11.153 9.885-5.475 15-4 5.685-0.147 14.866-7.533 16 2z" />
    </svg>
  );
}
