import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'Search/components/QuickFilterSearch';
import { notify } from 'reducers/NotifierReducer';
import { showPlaceEmployment } from 'reducers/DialogsReducer';
import { deletePlaceEmployment, getPlaceEmployment } from 'reducers/PlacesReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
}));

const defaultColumns = [
  'FullName',
  'FirstName',
  'LastName',
  'Position',
  'Rank',
  // { field: 'ptsSupervisorID', headerName: 'SupervisorID', width: 150, hide: false },
  {
    field: 'Hired',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Terminated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy'
];
const PlaceEmployees = (props) => {
  const classes = useStyles();
  const { ptsPlaceID } = props;
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const [isDeleted, setIsDeleted] = useState(false);

  const editPlaceEmployment = useSelector((state) => state.dialogs.editPlaceEmployment);

  useEffect(() => {
    const data = processData(props.employees);
    setRows(data);
    setLoaded(true);
  }, [props.employees]);

  useEffect(() => {
    getData();
  }, [editPlaceEmployment]);

  const getData = async () => {
    try {
      const data = await getPlaceEmployment(ptsPlaceID);
      let mergedData = [];
      data.data.map((employee) => {
        const { FullName, FirstName, LastName } = employee.PersonInfo;
        let empData = { FullName, FirstName, LastName, ...employee };
        mergedData.push(empData);
      });
      setRows(processData(mergedData));
      setLoaded(true);
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const processData = (data) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx,
      };
    });
  };

  const add = () => {
    dispatch(showPlaceEmployment({ ptsPlaceID: ptsPlaceID }));
  };

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    dispatch(showPlaceEmployment({ ...item }));
  };

  const deleteEmp = async () => {
    if (!window.confirm('Are you sure you want to remove this record?')) return;
    const item = rows.find((row) => row.id === selection);
    const updatedData = rows.filter((row) => row.id !== selection);
    setRows(updatedData);
    try {
      dispatch(showSpinner());
      await deletePlaceEmployment({ ptsPlaceID: item.ptsPlaceID, ptsPersonID: item.ptsPersonID });
      //dispatch(notify('Record deleted', 'info'));
      getData();
      setIsDeleted(!isDeleted);
    } catch (err) {
      dispatch(handleError(err));
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add Credential">
            <Fab size="small" color="secondary" onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Edit Employment">
                <Fab size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>

              <Tooltip title="Delete">
                <Fab size="small" color="secondary" onClick={deleteEmp}>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3
        name="PlaceEmployment"
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        loading={!loaded}
        setSelection={setSelection}
      />
    </div>
  );
};

export default PlaceEmployees;
