import React from 'react';
import FileIcon from 'assets/images/icons/icon-file.png';
import AviIcon from 'assets/images/icons/icon-avi.png';
import ImageIcon from 'assets/images/icons/icon-image.png';
import Mp3Icon from 'assets/images/icons/icon-mp3.png';
import PdfIcon from 'assets/images/icons/icon-pdf.png';
import PptIcon from 'assets/images/icons/icon-pp.png';
import WordIcon from 'assets/images/icons/icon-word.png';
import XlsIcon from 'assets/images/icons/icon-xls.png';
import ZipIcon from 'assets/images/icons/icon-zip.png';
import TextIcon from 'assets/images/icons/icon-txt.png';

function AttachmentIcon(props) {
  const fileType = props.type || '';
  let type = fileType.toLowerCase();
  if (fileType[0] === '.') type = type.substr(1);

  switch (type) {
    case 'avi':
      return <img src={AviIcon} alt="" {...props} />;
    case 'mp4':
      return <img src={AviIcon} alt="" {...props} />;
    case 'mp3':
      return <img src={Mp3Icon} alt="" {...props} />;
    case 'jpg':
      return <img src={ImageIcon} alt="" {...props} />;
    case 'jpeg':
      return <img src={ImageIcon} alt="" {...props} />;
    case 'gif':
      return <img src={ImageIcon} alt="" {...props} />;
    case 'pcx':
      return <img src={ImageIcon} alt="" {...props} />;
    case 'png':
      return <img src={ImageIcon} alt="" {...props} />;
    case 'bmp':
      return <img src={ImageIcon} alt="" {...props} />;
    case 'pdf':
      return <img src={PdfIcon} alt="" {...props} />;
    case 'ppt':
      return <img src={PptIcon} alt="" {...props} />;
    case 'pptx':
      return <img src={PptIcon} alt="" {...props} />;
    case 'doc':
      return <img src={WordIcon} alt="" {...props} />;
    case 'docx':
      return <img src={WordIcon} alt="" {...props} />;
    case 'xls':
      return <img src={XlsIcon} alt="" {...props} />;
    case 'xlsx':
      return <img src={XlsIcon} alt="" {...props} />;
    case 'zip':
      return <img src={ZipIcon} alt="" {...props} />;
    case 'tar':
      return <img src={ZipIcon} alt="" {...props} />;
    case 'rar':
      return <img src={ZipIcon} alt="" {...props} />;
    case 'lzh':
      return <img src={ZipIcon} alt="" {...props} />;
    case 'txt':
      return <img src={TextIcon} alt="" {...props} />;
    default:
      return <img src={FileIcon} alt="" {...props} />;
  }
}

export default AttachmentIcon;