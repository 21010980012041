import React, { useEffect, useRef, useState } from 'react';
import { printRef } from '../../utils/functions';
import { displayDateTime } from 'reducers/TimeReducer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iframe: {
    border: 'none',
    overflow: 'hidden',
    width: 1,
    height: 1,
  },
  tableWrap: {
    display: "none"
  },
}));

function TableCreator(props) {
  const { data, cols } = props;

  const formatBool = (str) => {
    if (str === true) return '✔';
    if (str === false) return '';
    return str;
  }

  return (
    <table>
      <thead>
        <tr>
          {cols.map(c =>
            <th key={`header-${c.headerName}`}>
              {c.headerName}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map(item =>
          <tr key={`${item.id}-row`}>
            {cols.map(c =>
              <td key={`${item.id}-${c.field}`}>
                {(c.format === 'date') && displayDateTime(item[c.field])}
                {(c.format === 'bool') && formatBool(item[c.field])}
                {(c.format !== 'date' && c.format !== 'bool') && item[c.field]}
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  )
}

function PrintsSearch3(props) {
  const classes = useStyles();
  const { title, data, cols, kbdKey, print } = props;
  const ref = useRef();
  const [printFlag, setPrintFlag] = useState(false);

  useEffect(() => {
    if (kbdKey === 'Ctrl+KeyL') handlePrint();
  }, [kbdKey]);

  useEffect(() => {
    if (print) handlePrint();
  }, [print])

  const handlePrint = async () => {
    await setPrintFlag(true);
    printRef(ref, title);
  }

  const filterColumns = (columns) => columns.filter(col => !col.hide);

  return (
    <div>
      {/* <Fab size="small" color="secondary" onClick={print}>
        <LocalPrintshopRoundedIcon />
      </Fab> */}
      <iframe id="print-iframe" className={classes.iframe}></iframe>
      {printFlag &&
        <div className={classes.tableWrap} ref={ref}>
          <TableCreator data={data} cols={filterColumns(cols)} />
        </div>
      }
    </div>
  )
}

export default PrintsSearch3;