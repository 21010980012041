import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { closeVehicleEditReg } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { Button } from '@material-ui/core';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';
import Autocomplete2 from 'components/Autocomplete2';
import TextField2 from 'components/TextField2';
import Checkbox2 from 'components/Checkbox2';
import formStyles, {
  gridStyle,
  Row,
  RowInner,
  ColCard,
  Fills,
  formatSaveData,
} from 'utils/formStyles';
import PersonLookup2 from 'components/PersonLookup2';
import DatePicker2 from 'components/DatePicker2';
import {
  searchPlaceAddresses,
  addVehicleReg,
  searchAddress,
  updateVehicleReg,
} from 'reducers/SearchReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import PlaceLookup from 'components/PlaceLookup';
import Dictionary from 'components/Dictionary';
import VehicleLookup from 'components/VehicleLookup';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(200, 320),
  field: gridStyle(210, 400),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  wrap: {
    padding: theme.spacing(3),
  },
  lookup: {
    marginBottom: theme.spacing(2),
  },
}));

const PartyTypes = [{ Code: 'Person' }, { Code: 'Place' }, { Code: 'Caller' }];

function VehicleAddRegDialog(props) {
  const classes = useStyles();
  const { vehicleData } = props;
  const disableParty = vehicleData?.disableParty || false;
  console.log('vehicle data ', vehicleData);
  // ptsVehicleReg table entries
  const [PartyType, setPartyType] = useState(PartyTypes[0]);
  const [ptsParentID, setPtsParentID] = useState(null);
  const [PlateNumber, setPlateNumber] = useState('');
  const [PlateState, setPlateState] = useState(null);
  const [PlateType, setPlateType] = useState(null);
  const [PlateExpiration, setPlateExpiration] = useState(null);
  const [authority, setAuthority] = useState(null);
  const [authorityAddress, setAuthorityAddress] = useState(null);
  const [authorityAddressOptions, setAuthorityAddressOptions] = useState(null);
  const [PlateColor, setPlateColor] = useState(null);
  const [PlateBackgroundText, setPlateBackgroundText] = useState('');
  const [PlateStatus, setPlateStatus] = useState(null);
  const [DecalExpiration, setDecalExpiration] = useState(null);
  const [PlateDetail, setPlateDetail] = useState(null);
  const [TitleText, setTitleText] = useState('');
  const [InsuranceCompany, setInsuranceCompany] = useState('');
  const [VehicleValue, setVehicleValue] = useState('');
  const [OdometerReading, setOdometerReading] = useState('');
  const [GrossWeightMeasure, setGrossWeightMeasure] = useState('');
  const [isPermanentReg, setisPermanentReg] = useState(false);
  const [RoadIndicator, setRoadIndicator] = useState(false);
  const [IsLeased, setIsLeased] = useState(false);
  const [LesseeType, setLesseeType] = useState(PartyTypes[0]);
  const [LessorType, setLessorType] = useState(PartyTypes[0]);
  const [ptsLesseeID, setPtsLesseeID] = useState(null);
  const [ptsLessorID, setPtsLessorID] = useState(null);
  const [ptsVehicleID, setPtsVehicleID] = useState();
  const [showLookup, setShowLookup] = useState(false);

  useEffect(() => {
    const { ptsVehicleID } = vehicleData;
    if (ptsVehicleID) {
      setPtsVehicleID(ptsVehicleID);
    } else {
      setShowLookup(true);
    }
    if (typeof vehicleData === 'object' && Object.keys(vehicleData).length > 1) getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!authority) return;
    renderPlaceAddresses(authority.ptsPlaceID);
    // eslint-disable-next-line
  }, [authority]);

  const getData = (id = ptsVehicleID) => {
    if (!vehicleData) return;
    const {
      DecalExpiration,
      GrossWeightMeasure,
      InsuranceCompany,
      IsLeased,
      LesseeType,
      LessorType,
      OdometerReading,
      PartyType,
      PlateBackgroundText,
      PlateColor,
      PlateDetail,
      PlateExpiration,
      PlateNumber,
      PlateState,
      PlateStatus,
      PlateType,
      RoadIndicator,
      TitleText,
      VehicleValue,
      isPermanentReg,
      ptsPlateIssuingAuthorityID,
      ptsAddressID,
      ptsParentID,
      ptsLessorID,
      ptsLesseeID,
      ptsVehicleID,
    } = vehicleData;

    setPartyType(PartyType);
    setPlateNumber(PlateNumber);
    setPlateState(PlateState);
    setPlateType(PlateType);
    setPlateExpiration(PlateExpiration || null);
    setPtsParentID(ptsParentID);
    setPlateColor(PlateColor);
    setPlateBackgroundText(PlateBackgroundText);
    setPlateStatus(PlateStatus);
    setDecalExpiration(DecalExpiration || null);
    setPlateDetail(PlateDetail);
    setTitleText(TitleText);
    setInsuranceCompany(InsuranceCompany);
    setVehicleValue(VehicleValue);
    setOdometerReading(OdometerReading);
    setGrossWeightMeasure(GrossWeightMeasure);
    setisPermanentReg(isPermanentReg || false);
    setRoadIndicator(RoadIndicator || false);
    setIsLeased(IsLeased || false);
    setPtsLesseeID(ptsLesseeID);
    setPtsLessorID(ptsLessorID);
    setLesseeType(LesseeType);
    setLessorType(LessorType);
    renderExistingAuthAdrs(ptsAddressID);
    setPtsVehicleID(ptsVehicleID || null);
  };

  const renderExistingAuthAdrs = async (ptsAddressID) => {
    if (!ptsAddressID) return;
    const adrs = await searchAddress(ptsAddressID);
    setAuthorityAddress(adrs);
  };

  const renderPlaceAddresses = async (ptsPlaceID) => {
    const addresses = await searchPlaceAddresses(ptsPlaceID);
    setAuthorityAddressOptions(addresses);
  };

  const renderActions = () => {
    const disabled = !ptsVehicleID;
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={disabled}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const save = async () => {
    const data = formatSaveData({
      PlateNumber,
      PlateState,
      PlateType,
      PlateExpiration,
      PlateColor,
      PlateBackgroundText,
      PlateDetail,
      PlateStatus,
      DecalExpiration,
      InsuranceCompany,
      OdometerReading,
      TitleText,
      VehicleValue,
      GrossWeightMeasure,
      IsLeased,
      isPermanentReg,
      RoadIndicator,
      PartyType,
      ptsParentID,
      LesseeType,
      LessorType,
      ptsLesseeID,
      ptsLessorID,
      ptsPlateIssuingAuthorityID: authority?.ptsPlaceID,
      ptsAddressID: authorityAddress?.ptsAddressID,
      ptsVehicleID,
    });

    if (!PlateNumber || !PlateState) {
      props.notify('Plate number and plate state are required', 'warning');
      return;
    }

    try {
      if (vehicleData.ptsRegistrationID) {
        await updateVehicleReg(vehicleData.ptsRegistrationID, data);
      } else {
        await addVehicleReg(data);
      }
      props.notifyDataUpdate({
        type: 'vehicle-reg',
        data: { ptsVehicleID: vehicleData.ptsVehicleID },
      });
      props.closeVehicleEditReg();
    } catch (err) {
      props.handleError(err, 'Error saving registration.');
    }
  };

  const close = () => {
    props.closeVehicleEditReg();
    props.notifyDataUpdate({ type: 'vehicle-reg' });
  };

  const handlePartyPersonChange = (person) => setPtsParentID(person?.ptsPersonID);

  const handlePartyPlaceChange = (place) => setPtsParentID(place?.ptsPlaceID);

  const renderPartyType = () => {
    const onChange = (ev, val) => setPartyType(val);
    return (
      <>
        <Dictionary
          options="PartyTypes"
          className={classes.item}
          onChange={onChange}
          value={PartyType}
          label="Type"
        />
        {(PartyType?.Code === 'Person' || PartyType?.Code === 'Caller') && (
          <PersonLookup2
            className={classes.item}
            onPersonChange={handlePartyPersonChange}
            label="Party Person Search"
            ptsPersonID={ptsParentID}
          />
        )}
        {PartyType?.Code === 'Place' && (
          <PlaceLookup
            onChange={handlePartyPlaceChange}
            className={classes.item}
            label="Search Party Place"
            ptsPlaceID={ptsParentID}
          />
        )}
      </>
    );
  };

  const renderPlateNumber = () => {
    const onChange = (ev, val) => setPlateNumber(val);
    return (
      <TextField2
        className={classes.item}
        label="Plate Number"
        value={PlateNumber}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderPlateState = () => {
    const onChange = (ev, val) => setPlateState(val);
    return (
      <Dictionary
        options="States"
        className={classes.item}
        onChange={onChange}
        value={PlateState}
        label="Plate State"
        editingForm={Object.keys(vehicleData).length > 1 ? true : false}
      />
    );
  };

  const renderPlateType = () => {
    const onChange = (ev, val) => setPlateType(val);
    return (
      <Dictionary
        options="PlateTypes"
        className={classes.item}
        onChange={onChange}
        value={PlateType}
        label="Plate Type"
        editingForm={Object.keys(vehicleData).length > 1 ? true : false}
      />
    );
  };

  const renderPlateExpiration = () => {
    const onChange = (ev, val) => setPlateExpiration(val);
    return (
      <DatePicker2
        onChange={onChange}
        label="Plate Expiration Date"
        value={PlateExpiration}
        className={classes.field}
      />
    );
  };

  const renderPlateIssuingAuthority = () => {
    const onChange = (place) => setAuthority(place);
    return (
      <PlaceLookup
        onChange={onChange}
        className={classes.item}
        style={{ marginBottom: 16 }}
        label="Plate Issuing Authority"
        ptsPlaceID={vehicleData?.ptsPlateIssuingAuthorityID}
      />
    );
  };

  const renderPlateIssuingAuthorityAddress = () => {
    const onChange = (ev, val) => setAuthorityAddress(val);
    return (
      <Autocomplete2
        options={authorityAddressOptions}
        className={classes.item}
        onChange={onChange}
        value={authorityAddress}
        label="Issuing Authority Address"
        getOptionLabel={(option) => option.FullAddressText}
        getOptionSelected={(option, value) => option.ptsAddressID === value.ptsAddressID}
        renderOption={(option) => <span>{option.FullAddressText}</span>}
      />
    );
  };

  const renderPlateColor = () => {
    const onChange = (ev, val) => setPlateColor(val);
    return (
      <Dictionary
        options="PlateColors"
        className={classes.item}
        onChange={onChange}
        value={PlateColor}
        label="Plate Color"
        editingForm={Object.keys(vehicleData).length > 1 ? true : false}
      />
    );
  };

  const renderPlateBgText = () => {
    const onChange = (ev, val) => setPlateBackgroundText(val);
    return (
      <TextField2
        className={classes.item}
        label="Plate Background Text"
        value={PlateBackgroundText}
        onChange={onChange}
        max={2000}
      />
    );
  };

  const renderPlateStatus = () => {
    const onChange = (ev, val) => setPlateStatus(val);
    return (
      <Dictionary
        options="PlateStatuses"
        className={classes.item}
        onChange={onChange}
        value={PlateStatus}
        label="Plate Status"
        editingForm={Object.keys(vehicleData).length > 1 ? true : false}
      />
    );
  };

  const renderDecalExpiration = () => {
    const onChange = (ev, val) => setDecalExpiration(val);
    return (
      <DatePicker2
        onChange={onChange}
        label="Decal Expiration"
        value={DecalExpiration}
        className={classes.field}
      />
    );
  };

  const renderPlateDetails = () => {
    const onChange = (ev, val) => setPlateDetail(val);
    return (
      <Dictionary
        options="PlateDetails"
        className={classes.item}
        onChange={onChange}
        value={PlateDetail}
        label="Plate Detail"
        editingForm={Object.keys(vehicleData).length > 1 ? true : false}
      />
    );
  };

  const renderTitleText = () => {
    const onChange = (ev, val) => setTitleText(val);
    return (
      <TextField2
        className={classes.item}
        label="Title Text"
        value={TitleText}
        onChange={onChange}
        max={2000}
      />
    );
  };

  const renderInsuranceCompany = () => {
    const onChange = (ev, val) => setInsuranceCompany(val);
    return (
      <TextField2
        className={classes.item}
        label="Insurance Company"
        value={InsuranceCompany}
        onChange={onChange}
        max={300}
      />
    );
  };

  const renderVehicleValue = () => {
    const onChange = (ev, val) => setVehicleValue(val);
    return (
      <TextField2
        className={classes.item}
        label="Vehicle Value"
        value={VehicleValue}
        onChange={onChange}
        type="number"
        min={0}
        max={100000000000}
      />
    );
  };

  const renderOdometerReading = () => {
    const onChange = (ev, val) => setOdometerReading(val);
    return (
      <TextField2
        className={classes.item}
        label="Odometer Reading"
        value={OdometerReading}
        onChange={onChange}
        type="number"
        min={-900000000}
        max={900000000}
      />
    );
  };

  const renderGrossWeightMeasure = () => {
    const onChange = (ev, val) => setGrossWeightMeasure(val);
    return (
      <TextField2
        className={classes.item}
        label="Gross Weight Measure"
        value={GrossWeightMeasure}
        onChange={onChange}
        type="number"
        min={-90}
        max={90}
      />
    );
  };

  const renderPermanentReg = () => {
    const onChange = () => setisPermanentReg(!isPermanentReg);
    return (
      <Checkbox2 checked={isPermanentReg} onChange={onChange} label="Permanent Registration" />
    );
  };

  const renderRoadIndicator = () => {
    const onChange = () => setRoadIndicator(!RoadIndicator);
    return <Checkbox2 checked={RoadIndicator} onChange={onChange} label="Road Indicator" />;
  };

  const renderLeased = () => {
    const onChange = () => setIsLeased(!IsLeased);
    return <Checkbox2 checked={IsLeased} onChange={onChange} label="Leased" />;
  };

  const handleLesseePersonChange = (person) => setPtsLesseeID(person?.ptsPersonID);

  const handleLessePlaceChange = (place) => setPtsLesseeID(place?.ptsPlaceID);

  const renderLessee = () => {
    const onChange = (ev, val) => setLesseeType(val);
    return (
      <>
        <Dictionary
          options="PartyTypes"
          className={classes.item}
          onChange={onChange}
          value={LesseeType}
          label="Lessee"
          editingForm={Object.keys(vehicleData).length > 1 ? true : false}
        />
        {(LesseeType?.Code === 'Person' || LesseeType?.Code === 'Caller') && (
          <PersonLookup2
            className={classes.item}
            onPersonChange={handleLesseePersonChange}
            label="Lesse Person Search"
            ptsPersonID={ptsLesseeID}
          />
        )}
        {LesseeType?.Code === 'Place' && (
          <PlaceLookup
            onChange={handleLessePlaceChange}
            className={classes.item}
            label="Search Lessee Place"
            ptsPlaceID={ptsLesseeID}
          />
        )}
      </>
    );
  };

  const handleLessorPersonChange = (person) => setPtsLessorID(person?.ptsPersonID);

  const handleLessorPlaceChange = (place) => setPtsLessorID(place?.ptsPlaceID);

  const renderLessor = () => {
    const onChange = (ev, val) => setLessorType(val);
    return (
      <>
        <Dictionary
          options="PartyTypes"
          className={classes.item}
          onChange={onChange}
          value={LessorType}
          label="Lessor"
          editingForm={Object.keys(vehicleData).length > 1 ? true : false}
        />
        {(LessorType?.Code === 'Person' || LessorType?.Code === 'Caller') && (
          <PersonLookup2
            className={classes.item}
            onPersonChange={handleLessorPersonChange}
            label="Lessor Person Search"
            ptsPersonID={ptsLessorID}
          />
        )}
        {LessorType?.Code === 'Place' && (
          <PlaceLookup
            onChange={handleLessorPlaceChange}
            className={classes.item}
            label="Search Lessor Place"
            ptsPlaceID={ptsLessorID}
          />
        )}
      </>
    );
  };

  const renderVehicleLookup = () => {
    const onChange = (vehicle) => {
      setPtsVehicleID(vehicle?.ptsVehicleID || null);
    };
    return <VehicleLookup className={classes.lookup} onChange={onChange} />;
  };

  return (
    <Dialog toolbar onClose={close} title="Vehicle Registration" actions={renderActions()}>
      <Row>
        {showLookup && (
          <ColCard minWidth={500}>
            <FormDivider title="Vehicle Lookup" />
            <RowInner>{renderVehicleLookup()}</RowInner>
          </ColCard>
        )}
        <ColCard minWidth={500}>
          {!disableParty && (
            <>
              <FormDivider title="Party" />
              <RowInner>
                {renderPartyType()}
                <Fills className={classes.item} />
              </RowInner>
            </>
          )}
          <FormDivider title="Plate" />
          <RowInner>
            {renderPlateNumber()}
            {renderPlateState()}
            {renderPlateType()}
            {renderPlateExpiration()}
            {renderPlateIssuingAuthority()}
            {renderPlateIssuingAuthorityAddress()}
            {renderPlateColor()}
            {renderPlateBgText()}
            {renderPlateStatus()}
            {renderDecalExpiration()}
            {renderPlateDetails()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Registration" />
          <RowInner>
            {renderTitleText()}
            {renderInsuranceCompany()}
            {renderVehicleValue()}
            {renderOdometerReading()}
            {renderGrossWeightMeasure()}
            {renderPermanentReg()}
            {renderRoadIndicator()}
            {renderLeased()}
            {renderLessee()}
            {renderLessor()}
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, {
  closeVehicleEditReg,
  handleError,
  notifyDataUpdate,
  notify,
})(VehicleAddRegDialog);
