import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ListItem, List, Menu, Button, makeStyles, Tooltip, Fab } from '@material-ui/core';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';

const useStyles = makeStyles(() => ({
  button: {
    padding: '0px !important',
    '&:hover': {
      background: 'transparent',
    },
    border: 'none',
    background: 'transparent',
    minWidth: (props) => (props.isEditPage ? '40px' : '64px'),
    marginRight: '-8px',
  },
  menu: {
    width: '220px',
    padding: '0px !important',
    margin: '0px !important',
  },
}));

const PersonsPrintMenu = (props) => {
  const classes = useStyles(props);
  const { tooltipText, size, handlePersonListPrint, handlePersonHistoryPrint, selection } = props;

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <Tooltip title="Print list">
        <Fab size="small" color="secondary" onClick={handleClickMenu}>
          <LocalPrintshopRoundedIcon />
        </Fab>
      </Tooltip>

      <Menu
        className={classes.menu}
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className="overflow-hidden dropdown-menu-xl">
          <List className="flex-column">
            <ListItem
              button
              onClick={() => {
                handlePersonListPrint();
                handleCloseMenu();
              }}>
              <span>Person List</span>
            </ListItem>
            <ListItem
              disabled={selection === null}
              button
              onClick={() => {
                handlePersonHistoryPrint();
                handleCloseMenu();
              }}>
              <span>Person History Report</span>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PersonsPrintMenu);
