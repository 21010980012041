
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8chase100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100" height="100"><path d="m15.172 2.682-10.729.85A.454.454 0 0 0 4 3.956V8h.05c-.017.166-.05.329-.05.5a4.5 4.5 0 0 0 9 0c0-.171-.033-.334-.05-.5h3.325a.5.5 0 0 0 .477-.252v-.002a.313.313 0 0 0-.197-.396l-2.98-1.479 2.046-2.523a.38.38 0 0 0 .014-.453.52.52 0 0 0-.463-.213zM42.338 5a4.501 4.501 0 1 0 .006 8.998A4.501 4.501 0 0 0 42.338 5zM.506 11a.5.5 0 0 0-.506.5v22c0 .026.012.047.016.072.005.035.006.07.02.104.011.03.031.058.048.086.016.026.03.053.05.076.026.027.057.045.087.066.02.014.032.032.054.043l3.666 1.832-1.587 8.729a2.571 2.571 0 0 0 2.53 3.023 2.54 2.54 0 0 0 1.376-.404 2.543 2.543 0 0 0 1.136-1.686l2.069-10.935a.497.497 0 0 0 .004-.123 1.89 1.89 0 0 0 .021-.262c0-.663-.272-1.305-.75-1.775a.502.502 0 0 0-.078-.088l-5.111-4.531 2.398-4.665 1.787 3.147c.01.016.02.032.032.047C8.196 27.06 9.5 28 10.5 28H18c1.103 0 2-.897 2-2s-.897-2-2-2h-6.682l-3.361-7.7a.498.498 0 0 0-.103-.154C5.842 14.13.964 11.196.758 11.072A.496.496 0 0 0 .506 11zm27.334 1c-.396 0-.762.117-1.072.316l-.041.006-6.928 4.973.002.004A1.99 1.99 0 0 0 18.84 19a2 2 0 0 0 2 2c.49 0 .935-.185 1.283-.479l.006.008 5.994-4.31 4.408 1.4-4.933 8.041.002.01a3.966 3.966 0 0 0-.76 2.33c0 1.544.886 2.868 2.168 3.535l.002.012 7.586 4.68-1.703 8.761-.002.018-.002.004A2.5 2.5 0 0 0 37.34 48c1.223 0 2.237-.88 2.453-2.04l.004.003 2.027-10.674-.01-.027c.011-.086.026-.173.026-.262a1.99 1.99 0 0 0-.653-1.469l-.007-.023-5.403-4.787 4.67-9.235-.006-.02c.246-.434.399-.93.399-1.466a3 3 0 0 0-1.807-2.75l-.002-.006-2.506-1.394-8.166-1.784-.02.004a2.018 2.018 0 0 0-.5-.07zM42 21l-1.879 3.617c.421.243.905.383 1.41.383H48a2 2 0 0 0 0-4h-6zm-16.643 9.414L23.082 34H15.34a2.5 2.5 0 1 0 0 5h8.431c1.297 0 2.514-.63 3.264-1.688l2.285-3.222a481.21 481.21 0 0 1-1.431-.887 6.009 6.009 0 0 1-2.532-2.789z"/></SvgIcon>
      );
    }
  
    export default icons8chase100Icon;