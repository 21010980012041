import React, { useState } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { closePersonDnaSample } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import TextField2 from 'components/TextField2';
import PersonLookup2 from 'components/PersonLookup2';
import PlaceLookup from 'components/PlaceLookup';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { getFormStyle } from 'utils/functions';
import { gridStyle, Row, RowInner, ColCard, formatSaveData } from 'utils/formStyles';
import { formatSaveDate, getFormat24, getDateTimeFormat } from 'reducers/TimeReducer';
import DateFnsUtils from '@date-io/date-fns';
import Dictionary from 'components/Dictionary';
import { createDnaSample } from 'reducers/SearchReducer';
import { handleError } from 'reducers/ErrorReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  date: getFormStyle(200, 300),
  item: gridStyle(200, 800),
}));

function AddDnaSampleDialog(props) {
  const classes = useStyles();
  const { ptsPersonID } = props;
  const [DNASampleID, setDNASampleID] = useState('');
  const [Taken, setTaken] = useState(null);
  const [ptsTakenByPersonID, setPtsTakenByPersonID] = useState(null);
  const [Type, setType] = useState(null);
  const [Method, setMethod] = useState(null);
  const [Reason, setReason] = useState(null);
  const [Status, setStatus] = useState(null);
  const [ptsCrimeLabPlaceID, setPtsCrimeLabPlaceID] = useState(null);
  const [Notes, setNotes] = useState('');
  const [CrimeLabSentDateTime, setCrimeLabSetDateTime] = useState(null);
  const format24 = getFormat24();

  const close = () => {
    props.closePersonDnaSample();
  };

  const save = async () => {
    const data = formatSaveData({
      DNASampleID,
      Taken: formatSaveDate(Taken),
      ptsTakenByPersonID,
      Type,
      Method,
      Reason,
      Status,
      ptsCrimeLabPlaceID,
      Notes,
      CrimeLabSentDateTime: formatSaveDate(CrimeLabSentDateTime),
      ptsPersonID,
    });
    try {
      await createDnaSample(data);
      props.notifyDataUpdate({ type: 'dna-sample', data: {} });
      props.closePersonDnaSample();
    } catch (err) {
      props.handleError(err, 'Error saving sample.');
    }
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderDnaSampleId = () => {
    const onChange = (ev, val) => setDNASampleID(val);
    return (
      <TextField2
        className={classes.item}
        label="DNA Sample ID"
        value={DNASampleID}
        onChange={onChange}
        max={25}
      />
    );
  };

  const renderNotes = () => {
    const onChange = (ev, val) => setNotes(val);
    return (
      <TextField2
        className={classes.item}
        label="Notes"
        value={Notes}
        onChange={onChange}
        max={8000}
        rows={5}
        multiline
      />
    );
  };

  const renderTakenBy = () => {
    const handlePersonChange = (person) => setPtsTakenByPersonID(person?.ptsPersonID);
    return (
      <PersonLookup2
        className={classes.item}
        onPersonChange={handlePersonChange}
        label="Taken By"
      />
    );
  };

  const renderCrimeLab = () => {
    const handleChange = (place) => setPtsCrimeLabPlaceID(place?.ptsPlaceID);
    return <PlaceLookup onChange={handleChange} className={classes.item} label="Crime Lab" />;
  };

  const renderTakenDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format={getDateTimeFormat()}
          margin="normal"
          label="Taken Date"
          value={Taken}
          onChange={setTaken}
          autoOk
          size="small"
          className={classes.date}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderSentDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          ampm={!format24}
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format={getDateTimeFormat()}
          margin="normal"
          label="Crime Lab Sent Date"
          value={CrimeLabSentDateTime}
          onChange={setCrimeLabSetDateTime}
          autoOk
          size="small"
          className={classes.date}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderSampleStatus = () => {
    const onChange = (ev, val) => setStatus(val);
    return (
      <Dictionary
        options="DNACollection"
        className={classes.item}
        onChange={onChange}
        value={Status}
        label="Sample Status"
        // compact="true"
      />
    );
  };

  const renderSampleReason = () => {
    const onChange = (ev, val) => setReason(val);
    return (
      <Dictionary
        options="DNASampleReason"
        className={classes.item}
        onChange={onChange}
        value={Reason}
        label="Sample Reason"
        // compact
      />
    );
  };

  const renderSampleMethod = () => {
    const onChange = (ev, val) => setMethod(val);
    return (
      <Dictionary
        options="DNASampleMethod"
        className={classes.item}
        onChange={onChange}
        value={Method}
        label="Sample Method"
        // compact
      />
    );
  };

  const renderSampleType = () => {
    const onChange = (ev, val) => setType(val);
    return (
      <Dictionary
        options="DNASampleType"
        className={classes.item}
        onChange={onChange}
        value={Type}
        label="Sample Type"
        // compact
      />
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Add DNA Sample" actions={renderActions()}>
      <Row>
        <ColCard minWidth={500}>
          <RowInner>{renderDnaSampleId()}</RowInner>
          <RowInner>
            {renderTakenBy()}
            {renderTakenDate()}
          </RowInner>
          <RowInner>
            {renderSampleType()}
            {renderSampleMethod()}
          </RowInner>
          <RowInner>
            {renderSampleReason()}
            {renderSampleStatus()}
          </RowInner>
          <RowInner>
            {renderCrimeLab()}
            {renderSentDate()}
          </RowInner>
          <RowInner>{renderNotes()}</RowInner>
        </ColCard>
      </Row>
    </Dialog>
  );
}

export default connect(null, { closePersonDnaSample, handleError, notifyDataUpdate })(
  AddDnaSampleDialog
);
