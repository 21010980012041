import React, { useState } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from 'components/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import SelectTarget from './SelectTarget';
import ConfigureMerge from './ConfigureMerge';
import Review from './Review';
import { handleError } from 'reducers/ErrorReducer';
import { addressMerge } from 'reducers/AddressReducer';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: 1200,
    minHeight: 'calc(100vh - 250px)',
  },
  steps: {
    width: '100%',
    marginBottom: 16,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

function getSteps() {
  return ['Select target address', 'Configure merge', 'Confirmation'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Please select the target address for merging. Source address will be deleted and the target will be updated.';
    case 1:
      return 'Select fields you want to use to in the target address';
    case 2:
      return 'Please confirm that all data is correct. Use the back button to return to merge options.';
    default:
      return 'Unknown stepIndex';
  }
}

function AddressMerge(props) {
  const classes = useStyles();
  const steps = getSteps();
  const { close, sourceID, rows, columns } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [targetID, setTargetID] = useState(null);
  const [source, setSource] = useState(null);
  const [target, setTarget] = useState(null);
  const [data, setData] = useState([]);
  const [sourcePrePlan, setSourcePrePlan] = useState(null);
  const [targetPrePlan, setTargetPrePlan] = useState(null);
  const [prePlan, setPrePlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressData, setAddressData] = useState(null);
  const [prePlanData, setPrePlanData] = useState(null);
  const [deleteSource, setDeleteSource] = useState(true);

  const isNextActive = () => {
    if (loading) return false;
    switch (activeStep) {
      case 0:
        return targetID !== null;
      default:
        return true;
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 2) merge();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderSteps = () => {
    return (
      <div className={classes.steps}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - address merged
              </Typography>
            </div>
          ) : (
            <div className={classes.nav}>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={!isNextActive()}
                >
                  {activeStep === steps.length - 1 ? 'Merge Addresses' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  const merge = async () => {
    const sourcePtsAddressID = source.ptsAddressID;
    let prePlan = null;
    if (prePlanData) {
      const targetPtsAddressID = target.ptsAddressID;
      const ptsAddressPrePlanID = targetPrePlan.ptsAddressPrePlanID
        ? targetPrePlan.ptsAddressPrePlanID
        : sourcePrePlan.ptsAddressPrePlanID;
      prePlan = {
        ...prePlanData,
        ptsAddressID: targetPtsAddressID,
        ptsAddressPrePlanID,
      };
    }
    const data = {
      sourcePtsAddressID,
      deleteSource,
      address: addressData,
      prePlan,
    };
    try {
      await addressMerge(data);
      props.notifyDataUpdate({ type: 'Address' });
    } catch (err) {
      props.handleError(err);
    }
  }

  return (
    <Dialog toolbar onClose={close} title="Address Merge" actions={renderActions()} maxWidth="lg">
      <div className={classes.wrap}>
        {renderSteps()}
        {activeStep === 0 && <SelectTarget
          rows={rows}
          sourceID={sourceID}
          columns={columns}
          setTargetID={setTargetID}
          targetID={targetID}
        />}
        {activeStep === 1 && <ConfigureMerge
          rows={rows}
          sourceID={sourceID}
          targetID={targetID}
          source={source}
          setSource={setSource}
          target={target}
          setTarget={setTarget}
          data={data}
          setData={setData}
          sourcePrePlan={sourcePrePlan}
          setSourcePrePlan={setSourcePrePlan}
          targetPrePlan={targetPrePlan}
          setTargetPrePlan={setTargetPrePlan}
          prePlan={prePlan}
          setPrePlan={setPrePlan}
          loading={loading}
          setLoading={setLoading}
        />}
        {activeStep === 2 && <Review
          source={source}
          target={target}
          data={data}
          targetPrePlan={targetPrePlan}
          prePlan={prePlan}
          loading={loading}
          setLoading={setLoading}
          addressData={addressData}
          setAddressData={setAddressData}
          prePlanData={prePlanData}
          setPrePlanData={setPrePlanData}
          deleteSource={deleteSource}
          setDeleteSource={setDeleteSource}
        />}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  handleError,
  notifyDataUpdate
})(AddressMerge);
