
    import React from 'react';
    import SvgIcon from '@material-ui/core/SvgIcon';
    
    function icons8physicalabuse100Icon(props) {
      return (
        <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100" height="100"><path fill="#ffb74d" d="M13.5 10a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zm22 7a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7z"/><path fill="#394a51" d="M4.999 43.75a1.75 1.75 0 0 1-1.564-2.533l2.795-5.589-.36-7.179a1.75 1.75 0 0 1 3.496-.176l.383 7.638c.015.301-.048.601-.183.87l-3 6a1.749 1.749 0 0 1-1.567.969z"/><path fill="#394a51" d="M14.998 43.75a1.75 1.75 0 0 1-1.73-1.503l-.944-6.606-3.779-5.67a1.751 1.751 0 0 1 2.912-1.942l4 6c.145.218.239.465.276.724l1 7a1.749 1.749 0 0 1-1.735 1.997z"/><path fill="#90a4ae" d="M40 43.75h-8a1.75 1.75 0 0 1-1.05-3.15l8-6a1.75 1.75 0 0 1 2.099 2.801l-3.8 2.85H40a1.75 1.75 0 1 1 0 3.499z"/><path fill="#394a51" d="M7.872 30.642a3 3 0 0 1-1.853-3.817l2.292-6.614a3 3 0 1 1 5.67 1.964l-2.292 6.614a3 3 0 0 1-3.817 1.853z"/><path fill="#90a4ae" d="M39.744 37.957a2.999 2.999 0 0 1-3.323-2.637l-.801-6.954a2.999 2.999 0 1 1 5.96-.686l.801 6.954a3 3 0 0 1-2.637 3.323z"/><path fill="#394a51" d="M19.499 31c-.313 0-.629-.098-.899-.3l-4-3a1.504 1.504 0 0 1-.441-.529l-3-6a1.501 1.501 0 0 1 2.684-1.342l2.842 5.684L20.4 28.3a1.5 1.5 0 0 1-.901 2.7z"/><path fill="#90a4ae" d="M38.5 28h-7a1.499 1.499 0 0 1-1.286-2.272l3-5a1.5 1.5 0 1 1 2.573 1.543L34.149 25H38.5a1.5 1.5 0 0 1 0 3z"/><path fill="#394a51" d="M2.502 28a1.499 1.499 0 0 1-1.473-1.794l1-5a1.5 1.5 0 0 1 1.13-1.167l7.585-1.771a1.501 1.501 0 0 1 .682 2.922l-6.645 1.551-.811 4.053A1.499 1.499 0 0 1 2.502 28zm9.001-.419 3.949 6.487-2.776 2.058-4.931-6.534z"/><path fill="#263238" d="M46.5 43.5A1.5 1.5 0 0 1 45 42V15H27v10.5a1.5 1.5 0 0 1-3 0v-12a1.5 1.5 0 0 1 1.5-1.5h21a1.5 1.5 0 0 1 1.5 1.5V42a1.5 1.5 0 0 1-1.5 1.5zm-21 .5a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 3 0v9a1.5 1.5 0 0 1-1.5 1.5z"/><path fill="#90a4ae" d="m30.851 40.693 6.973-6.048 2.123 1.979z"/><path fill="#394a51" d="M25 29h-5.5v1H23v1h1v-1h1z"/></SvgIcon>
      );
    }
  
    export default icons8physicalabuse100Icon;