import React from 'react';
import HtmlTooltip from 'components/HtmlTooltip';
import { displayDateTime } from 'reducers/TimeReducer';

function EventTooltip(props) {
  const { event, children } = props;
  const {
    EventID,
    CallTypeDescription,
    Status,
    CallType,
    CallSubType,
    FullLocationAddress,
    ReceiveDate,
    QueuedDate,
    DispatchedDate,
    EnrouteDate,
    ArrivedDate,
    UnderContDate,
    CompletedDate,
    CancelledDate,
    CaseIds,
    zones,
    PlaceName,
    LocationDescription,
    Priority,
    coordinates,
    callers,
    CrossStreet,
  } = event;

  const locationInfo = (
    <>
      {PlaceName !== null && <>{PlaceName} - </>}
      {FullLocationAddress}
      {Boolean(LocationDescription && FullLocationAddress) ? <> ({LocationDescription})</> : ''}
      {Boolean(LocationDescription && !FullLocationAddress) ? LocationDescription : ''}
    </>
  );

  const evTooltip = (
    <div>
      <h4>{EventID}</h4>
      <table>
        <tbody>
          <tr>
            <td>{locationInfo}</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }}>{coordinates}</td>
          </tr>
          {Boolean(CrossStreet) && (
            <tr>
              <td>{CrossStreet}</td>
            </tr>
          )}
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <th>Status:</th>
            <td>{Status}</td>
          </tr>
          <tr>
            <th>Event Type:</th>
            <td>
              {CallType} ({CallTypeDescription})
            </td>
          </tr>
          {CallSubType !== null && (
            <tr>
              <th>Event Subtype:</th>
              <td>{CallSubType}</td>
            </tr>
          )}
          <tr>
            <th>Priority:</th>
            <td>{Priority}</td>
          </tr>
          <tr>
            <th>Description:</th>
            <td>{LocationDescription}</td>
          </tr>
          {ReceiveDate !== null && (
            <tr>
              <th>Received:</th>
              <td>{displayDateTime(ReceiveDate)}</td>
            </tr>
          )}
          {QueuedDate !== null && (
            <tr>
              <th>Queued:</th>
              <td>{displayDateTime(QueuedDate)}</td>
            </tr>
          )}
          {DispatchedDate !== null && (
            <tr>
              <th>Dispatched:</th>
              <td>{displayDateTime(DispatchedDate)}</td>
            </tr>
          )}
          {EnrouteDate !== null && (
            <tr>
              <th>Enroute:</th>
              <td>{displayDateTime(EnrouteDate)}</td>
            </tr>
          )}
          {ArrivedDate !== null && (
            <tr>
              <th>Arrived:</th>
              <td>{displayDateTime(ArrivedDate)}</td>
            </tr>
          )}
          {UnderContDate !== null && (
            <tr>
              <th>Under Control:</th>
              <td>{displayDateTime(UnderContDate)}</td>
            </tr>
          )}
          {CompletedDate !== null && (
            <tr>
              <th>Completed:</th>
              <td>{displayDateTime(CompletedDate)}</td>
            </tr>
          )}
          {CancelledDate !== null && (
            <tr>
              <th>Canceled:</th>
              <td>{displayDateTime(CancelledDate)}</td>
            </tr>
          )}
        </tbody>
      </table>
      <h4>Callers</h4>
      <table>
        <tbody>
          {callers.map((caller) => (
            <React.Fragment key={caller.ptsCallerID}>
              <tr>
                <th rowSpan={2}>{caller.FullName ? caller.FullName : 'Unknown'}</th>
                <td>{caller.Info ? caller.Info : ' - '}</td>
              </tr>
              <tr>
                <td>{caller.CallerLocation ? caller.CallerLocation : ' - '}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <h4>Case IDs</h4>
      <table>
        <tbody>
          {!!CaseIds &&
            CaseIds.map((c) => (
              <React.Fragment key={c.CaseId}>
                <tr>
                  <th>{c.AgencyId}</th>
                  <td>{c.CaseId}</td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
      <h4>Zones</h4>
      <table>
        <tbody>
          {zones.map((zone) => (
            <React.Fragment key={zone.ZoneCode}>
              <tr>
                <th>{zone.AgencyID}</th>
                <td>{zone.ZoneCode}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );

  return <HtmlTooltip title={evTooltip}>{children}</HtmlTooltip>;
}

export default EventTooltip;
