import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { clearCustomMsgBox } from '../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  notification: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
  warning: {
    border: `2px solid ${theme.palette.warning.main}`,
    color: theme.palette.warning.main,
  },
  info: {
    border: `2px solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
  },
  success: {
    border: `2px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
  },
  close: {
    position: 'absolute',
    top: 3,
    right: 3,
    fontSize: '1em',
    cursor: 'pointer',
  },
}));

function CustomMsgBox(props) {
  const classes = useStyles();
  const { customMsgBox } = props;
  const [box, setBox] = useState(false);

  useEffect(() => {
    if (!customMsgBox) return;
    const { type, title } = customMsgBox;
    if (type === 'hide') {
      setBox(false);
      return;
    }
    const msgs = [];
    customMsgBox.messages.forEach((msg) => msgs.push(Object.values(msg)[0]));
    setBox({ title, type, messages: msgs });
    props.clearCustomMsgBox();
    // eslint-disable-next-line
  }, [customMsgBox]);

  const close = () => {
    setBox(false);
  };

  const getClassName = () => {
    switch (box.type) {
      case 'success':
        return classes.success;
      case 'info':
        return classes.info;
      case 'warning':
        return classes.warning;
      default:
        return classes.error;
    }
  };

  return (
    <Fragment>
      {!!box && (
        <Card className={clsx(classes.notification, props.className, getClassName())}>
          <MuiAlert severity={box.type}>
            <div className="d-flex align-items-center align-content-center">
              <div>
                <strong className="d-block">{box.title}</strong>
                {box.messages.map((message, idx) => (
                  <div key={idx}>{message}</div>
                ))}
              </div>
            </div>
          </MuiAlert>
          <CloseIcon onClick={close} className={classes.close} />
        </Card>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    customMsgBox: state.notifier.customMsgBox,
  };
};

export default connect(mapStateToProps, { clearCustomMsgBox })(CustomMsgBox);
