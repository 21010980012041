export const callerEmptyData = {
  party: 'caller',
  FirstName: '',
  LastName: '',
  AdvisoryText: '',
  CallerLocation: '',
  Relationship: null,
  Info: '',
  ptsPersonID: null,
};

export const personEmptyData = {
  party: 'person',
  FirstName: '',
  LastName: '',
  Relationship: null,
  AdvisoryText: '',
};

export const placeEmptyData = {
  party: 'place',
  PlaceName: '',
  Relationship: null,
  AdvisoryText: '',
};
